import { AliasDto } from '@mezo/common/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useQuery } from '@tanstack/react-query';

const getAliasByVanityId = async (vanityId: string | undefined) => {
  const response = await ApiClient.CHAT_API.utility.get<AliasDto>(`aliases/${vanityId}`);

  return response?.data;
};

export const useAliasUrl = (vanityId: string | undefined) =>
  useQuery({
    queryKey: ['alias', vanityId],
    queryFn: async () => {
      return getAliasByVanityId(vanityId);
    },
    enabled: !!vanityId,
    retry: false,
  });
