import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export const Spinner = styled.div<{ size?: number; highlightColor: string }>(({ size = 24, highlightColor }) => [
  tw`rounded-[50%] relative m-auto`,
  css({
    width: `${size}px`,
    height: `${size}px`,
  }),
  css`
    ::after,
    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%;
      border: 4px solid rgba(0, 0, 0, 0.05);
    }
  `,
  css`
    ::before {
      border-right: 4px solid ${highlightColor};
      animation: spin 1s 1s linear infinite;
    }
  `,
  css`
    ::after {
      border-bottom: 4px solid ${highlightColor};
      animation: spin 1s 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
    }
  `,
  css`
    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  `,
]);

export default Spinner;
