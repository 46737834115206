import { IsOptional } from 'class-validator';

export class RegisterGuestResidentRequest {
  @IsOptional()
  customerId?: string;

  @IsOptional()
  brandingId?: string;

  isGuest: boolean;

  firstName: string;
  lastName: string;
  emailAddress: string;

  address1: string;
  @IsOptional()
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}
