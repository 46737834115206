import { t } from 'i18next';
import tw from 'twin.macro';
import { ButtonV2 } from '../../components';

const Content = tw.div`relative flex flex-col h-full gap-4 p-4 bg-white`;
const DetailsWrapper = tw.div`flex flex-col flex-grow gap-4`;
const DetailsTextArea = tw.textarea`h-32 resize-none border-background rounded-[10px] focus:ring-[0px] focus:outline-none active:outline-none`;
const DetailBulletPoints = tw.ul`list-disc list-inside`;
const DetailsBullet = tw.li`text-disabled`;
const SkipButton = tw.button`underline cursor-pointer text-primary`;
const SkipWrapper = tw.div`flex flex-col justify-center gap-4 text-center`;

type AdditionDetailsPageProps = {
  details: string;
  handleInput: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  handleSubmitAdditionalNotes?: () => void;
  handleSkip: () => void;
  showFooter?: boolean;
  showSkip?: boolean;
};

export const AdditionalDetailsPage: React.FC<AdditionDetailsPageProps> = ({
  details,
  handleInput,
  handleSubmitAdditionalNotes,
  handleSkip,
  showFooter = false,
  showSkip = true,
}) => (
  <Content>
    <DetailsWrapper>
      <DetailsTextArea id="details" onInput={handleInput} value={details} />
      <DetailBulletPoints>
        <DetailsBullet>{t('detailsForm.howToReplicate')}</DetailsBullet>
        <DetailsBullet>{t('detailsForm.whatHappened')}</DetailsBullet>
        <DetailsBullet>{t('detailsForm.whenHappened')}</DetailsBullet>
      </DetailBulletPoints>

      <SkipWrapper>
        {showFooter && (
          <ButtonV2 primary={true} disabled={details.length === 0} onClick={handleSubmitAdditionalNotes}>
            {t('detailsForm.proceed')}
          </ButtonV2>
        )}
        {showSkip && (
          <SkipButton aria-label="Skip" tabIndex={0} onClick={handleSkip}>
            {t('detailsForm.skip')}
          </SkipButton>
        )}
      </SkipWrapper>
    </DetailsWrapper>
  </Content>
);
