import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClickSource } from '@mezo/common/dtos';

import { RouteBuilder } from '@mezo/web/utils';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Modal from 'styled-react-modal';
import tw from 'twin.macro';
import ButtonV2 from './button.v2';
import TextWithLinkAtEnd from './text-with-link';

const IconButton = tw.button`p-0 border-0 cursor-pointer bg-none`;
const Title = tw.h5`m-0 text-xl font-medium leading-7 text-left`;
const StyledIcon = tw(FontAwesomeIcon)`fill-current text-disabled`;
const ModalContainer = tw.div`fixed flex flex-col justify-between w-full max-w-md gap-4 p-4 bg-background-light rounded-3xl top-4`;
const FlexContainer = tw.div`flex items-start justify-between gap-4`;
const Contents = tw.div`font-normal`;

type ExitModalProps = {
  isVisible: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
};

export const ExitModal = ({ isVisible, onClose, onConfirm }: ExitModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleSupportRoute = useCallback(() => {
    const supportFormUrl = new RouteBuilder()
      .buildSupportFormRoute(ClickSource.EXIT_MODAL)
      .prependPath(RouteBuilder.RESIDENT)
      .build();
    navigate(supportFormUrl);
  }, [navigate]);
  return (
    <Modal isOpen={isVisible} onBackgroundClick={onClose} onEscapeKeydown={onClose}>
      <ModalContainer tabIndex={0}>
        <FlexContainer>
          <Title>{t('chat.exitModal.title')}</Title>
          <IconButton onClick={onClose} aria-label={t('chat.exitModal.cancel')} autoFocus>
            <StyledIcon icon={faXmark} size="lg" />
          </IconButton>
        </FlexContainer>
        <Contents>{t('chat.exitModal.contents')}</Contents>
        <FlexContainer>
          <ButtonV2 primary={false} onClick={onClose}>
            {t('chat.exitModal.cancel')}
          </ButtonV2>
          <ButtonV2 primary={true} onClick={onConfirm}>
            {t('chat.exitModal.confirm')}
          </ButtonV2>
        </FlexContainer>
        <TextWithLinkAtEnd
          plainText={t('chat.exitModal.trouble')}
          linkText={t('chat.exitModal.supportLink')}
          onClick={handleSupportRoute}
          textAlign={'center'}
        />
      </ModalContainer>
    </Modal>
  );
};
