export enum ChatEventType {
  ABANDONED = 'abandoned',
  COLLECT_PRIME_SYMPTOM = 'collectPrimeSymptom',
  DIY_SUCCESS = 'diySuccess',
  INTAKE_COMPLETE = 'intakeComplete',
  SHOW_CHOICE_BUTTONS = 'showChoiceButtons',
  SHOW_GALLERY = 'showGallery',
  SHOW_MEDIA_UPLOAD_BUTTON = 'showMediaUploadButton',
  TRIGGER_SCHEDULING_COMPLETE = 'triggerSchedulingComplete',
  TRIGGER_SYMPTOM_SELECTION = 'triggerSymptomSelection',
  TRIGGER_BUTTON_SELECTION = 'triggerButtonSelection',
  TRIGGER_NEW_ISSUE = 'triggerNewIssue',
  SKIP_MEDIA_UPLOAD = 'skipMediaUpload',
}

export interface ChatEvent {
  type: ChatEventType;
  payload?: Record<string, any>;
}
