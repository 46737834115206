import tw from 'twin.macro';

interface PageHeaderSubheaderProps {
  translatedHeaderText: string;
  translatedSubHeaderText?: string;
  children?: React.ReactNode;
  subHeaderChild?: React.ReactNode;
}

const FlexLabel = tw.label`flex flex-col gap-2`;
const Subtitle = tw.span`text-sm `;

const Title = tw.h4`text-2xl font-medium`;
const FlexWrapper = tw.div`flex flex-col gap-4 `;

const Container = tw(FlexWrapper)`gap-8 p-4 bg-background-light h-screen`;

export const PageHeaderSubHeader: React.FC<PageHeaderSubheaderProps> = ({
  children,
  translatedHeaderText,
  translatedSubHeaderText,
  subHeaderChild,
}) => {
  return (
    <Container>
      <FlexWrapper>
        <FlexLabel>
          <Title>{translatedHeaderText}</Title>
          {translatedSubHeaderText && <Subtitle>{translatedSubHeaderText}</Subtitle>}
          {subHeaderChild && <Subtitle>{subHeaderChild}</Subtitle>}
        </FlexLabel>
      </FlexWrapper>
      {children}
    </Container>
  );
};

export default PageHeaderSubHeader;
