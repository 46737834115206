import { IsBoolean, IsNotEmpty, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class TypeDto {
  @IsString()
  @IsOptional()
  id?: string;

  @IsString()
  @IsOptional()
  label?: string;
}

export class ItemInfo {
  @IsOptional()
  @ValidateNested()
  @Type(() => TypeDto)
  type?: TypeDto;

  @IsOptional()
  @IsBoolean()
  isAccessible?: boolean;

  @IsOptional()
  @IsString()
  locationId?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  updatedBy?: string;

  @IsOptional()
  @IsNotEmpty()
  updatedAt?: number;
}

export class ItemInfoRequest {
  @IsOptional()
  @ValidateNested()
  @Type(() => TypeDto)
  type?: TypeDto;

  @IsOptional()
  isAccessible?: boolean | string;

  @IsOptional()
  @IsString()
  locationId?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  updatedBy?: string;

  @IsOptional()
  @IsNotEmpty()
  updatedAt?: number;
}

// fields used for itemInfo updates
export enum FieldIds {
  unitId = 'unitId',
  unitGroupId = 'unitGroupId',
  customerId = 'customerId',
}
