import { useStaggerDisplay } from '@mezo/web/hooks';

import { PropsWithChildren } from 'react';
import { MessageLoaderBubble } from '../../components';
import { DisplayNode, NODE_TYPES } from '../../types';
import { BotMessageContainer, MessageNode } from './intelliflow-message-list';

interface MessageSequencerProps {
  messageNodes: DisplayNode[];
  shouldStaggerDisplayNodes: boolean;
  customerIconUrl: string;
  onClick?: VoidFunction;
  onNodeDisplay?: VoidFunction;
  onBotResponseCompletion?: VoidFunction;
}

const WPM = 200;
const SEC_PER_MIN = 60;
const MS_PER_SEC = 1000;

const MAX_DELAY_MS = 3000;
const IMAGE_DELAY_MS = 1500;

const calculateDelay = (numWords: number) => {
  const delayTime = Math.ceil((numWords * SEC_PER_MIN * MS_PER_SEC) / WPM);
  return delayTime > MAX_DELAY_MS ? MAX_DELAY_MS : delayTime;
};
const delayTime = ({ type, msg }: DisplayNode) =>
  type === NODE_TYPES.text && msg ? calculateDelay(msg?.split(' ').length) : IMAGE_DELAY_MS;

export const IntelliflowMessageSequencer = ({
  messageNodes,
  onBotResponseCompletion,
  customerIconUrl,
  onClick,
  onNodeDisplay,
  children,
}: PropsWithChildren<MessageSequencerProps>) => {
  const [visibleDisplayNodes, showIndicator] = useStaggerDisplay(
    messageNodes,
    delayTime,
    onNodeDisplay,
    onBotResponseCompletion,
  );

  return (
    <BotMessageContainer customerIconUrl={customerIconUrl}>
      {visibleDisplayNodes.map((node, index) => {
        return (
          node && (
            <MessageNode
              key={`${node?.type}-${node?.timestamp}-${index}`}
              author={'receiver'}
              node={node}
              onClick={onClick}
              parseContent={true}
              onLoad={onNodeDisplay}
            />
          )
        );
      })}
      {!showIndicator && children}
      {showIndicator && <MessageLoaderBubble />}
    </BotMessageContainer>
  );
};
