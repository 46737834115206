import { Type } from 'class-transformer';
import 'reflect-metadata';
import { IsArray, IsNumber, IsOptional, ValidateNested } from 'class-validator';
import { FieldDto } from '.';

export class SearchRequestDto {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => FieldDto)
  readonly fields: FieldDto[];
  @IsOptional()
  @IsNumber()
  readonly resultsPerPage?: number;
  @IsNumber()
  @IsOptional()
  readonly nextPage?: number;
}
