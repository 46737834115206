import { FindUnitsAddressQueryDto, FindUnitsAddressQueryResponse, UnitResidentSearchResponse } from '@deprecated/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useQuery } from '@tanstack/react-query';

const sortUnitAddresses = (
  { entry: leftEntry }: FindUnitsAddressQueryResponse,
  { entry: rightEntry }: FindUnitsAddressQueryResponse
) => {
  if (!leftEntry) {
    return -1;
  }

  if (!rightEntry) {
    return 1;
  }

  if (leftEntry < rightEntry) {
    return -1;
  }

  if (leftEntry > rightEntry) {
    return 1;
  }

  return 0;
};

export const useUnitAddresses = (findAddressQuery: FindUnitsAddressQueryDto | undefined) => {
  const query = new URLSearchParams(findAddressQuery);
  return useQuery({
    queryKey: ['unitAddresses', query.toString()],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get<FindUnitsAddressQueryResponse[]>(
        `unit/search?${query.toString()}`
      );
      return response?.data;
    },
    enabled: !!findAddressQuery,
    select: (data) => [...data.sort(sortUnitAddresses)],
  });
};

export const useUnitResidentSearch = (unitId: string | undefined, residentLastName: string | undefined) =>
  useQuery({
    queryKey: ['validateUnitResidents', unitId, residentLastName],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get<UnitResidentSearchResponse[]>(
        `unit/${unitId}/residents?lastName=${residentLastName}`
      );
      return response?.data;
    },
    enabled: !!unitId && !!residentLastName,
  });
