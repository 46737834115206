import { StepStatus } from '@mezo/shared/common';

export const SOURCE_ID_PLACEHOLDER = 'sourceId TBD upon delivery';
export enum UserSessionStatusType {
  OPEN = 'open',
  COMPLETED = 'completed',
  ABANDONED = 'abandoned',
  REDIRECT = 'redirect', // Submit attempt redirected to the customer
}

export enum MezoCollection {
  ALIASES = 'aliases',
  API_KEY = 'apiKey',
  ADDRESS_2 = 'address2',
  ASSET_LOOKUP = 'assetLookup',
  CATEGORIES = 'categories',
  COMPONENTS = 'components',
  CONFIGURATION = 'configuration',
  CONFIG = 'config',
  CUSTOMERS = 'customers',
  DIAGNOSES = 'diagnoses',
  DIALOG_CONTEXTS = 'dialogContexts',
  EMERGENCIES = 'emergencies',
  GEOCODES = 'geocodes',
  ITEMS = 'items',
  LOCATIONS = 'locations',
  MESSAGE_TRANSACTIONS = 'messages',
  PRIME_TAXONOMY = 'primeTaxonomy',
  RESOLUTION_TYPES = 'resolutionTypes',
  SEED_HISTORY_COLLECTION = 'seedHistory',
  SERVICE_REQUESTS = 'serviceRequests',
  SERVICE_REQUESTS_USERS = 'serviceRequestsUsers',
  SKILL_LEVELS = 'skillLevels',
  SYMPTOMS = 'symptoms',
  TAXONOMY_MAP = 'taxonomyMap',
  TRANSLATIONS = 'translations',
  UNIT_GROUPS = 'unitGroups',
  UNITS = 'units',
  USERS = 'users',
  USER_SESSIONS = 'userSessions',
  RUN_ONCE = 'runOnce',
  INGESTION_JOBS = 'ingestionJobs',
}

export enum MezoInfraCollection {
  INTELLIFLOW_VERSION = 'intelliflowVersion',
}

export enum MezoSubCollection {
  RECENT_RESIDENT_PROFILES = 'recentResidentProfiles',
  TRANSLATIONS = 'translations',
  SUBMIT_ATTEMPTS = 'submitAttempts',
  HISTORY = 'history',
}

export enum PlatformStatus {
  ON = 'ON',
  OFF_CHURNED = 'OFF_CHURNED',
  OFF_SOLD = 'OFF_SOLD',
  OFF_INVALID = 'OFF_INVALID',
}
export enum AbandonReason {
  TIMEOUT = 'timeout',
  USER_EXIT = 'user exit', // Either reset in the chat or user back button
  ERROR = 'error',
}

export enum Page {
  ASSET_CAPTURE = 'Asset Capture',
  USER_CONFIRMATION = 'User Confirmation',
  ADDRESS_VALIDATION = 'Address Validation',
  EMAIL_LOGIN = 'Email Login',
  EMERGENCY_SCREENING_BEFORE_START = 'Emergency Screening Before Start',
  EMERGENCY_SCREENING_PICK_LIST = 'Emergency Screening Select Issue',
  EMERGENCY_SCREENING_ENTER_DETAILS = 'Emergency Screening Enter Details',
  EMERGENCY_SCREENING_SUBMITTED = 'Emergency Screening Submitted',
  ERROR_PAGE = 'Error',
  GUEST_RESIDENT_REGISTRATION = 'Guest Resident Registration',
  RESIDENT_HOME = 'Resident Home',
  SUBMIT_REQUEST_ADDITIONAL_NOTES = 'Additional Notes',
  SUBMIT_REQUEST_HOW_IT_WORKS = 'Submit Request How It Works',
  SUBMIT_REQUEST_BEFORE_START = 'Submit Request Before Start',
  SUBMIT_REQUEST_ENTRY_NOTES = 'Entry Notes',
  SUBMIT_REQUEST_FEEDBACK = 'Feedback',
  SUBMIT_REQUEST_INTELLIFLOW = 'Intelliflow',
  SUBMIT_REQUEST_MEDIA_CAPTURE = 'Media Capture',
  SUBMIT_REQUEST_PRIME_SYMPTOM = 'Prime Symptom',
  SUBMIT_REQUEST_SCHEDULING = 'Scheduling',
  SUPPORT = 'Support',
  SUPPORT_CONFIRMATION = 'Support Confirmation',
  UNIT_VALIDATION = 'Unit Validation',
  VIEW_SUBMITTED_REQUESTS = 'Submitted Service Requests',
  PROBLEM_CAPTURE = 'Problem Capture',

  // ADMIN PAGES
  SERVICE_REQUEST = 'Service Request',
  SR_REVIEW = 'SR Review',
  TECHNICIAN_VIEW = 'Technician Service Request',
}

export enum RequestHistoryStep {
  ADDITIONAL_DETAILS = 'additionalDetails',
  ASSET = 'asset',
  DELIVERED = 'delivered',
  ENTRY_NOTES = 'entryNotes', // THIS will include permission to enter or entry notes
  MEDIA = 'media',
  SCHEDULING = 'scheduling',
}

export type RequestHistoryItem = {
  completedAt: number;
  status: StepStatus;
  stepType: RequestHistoryStep;

  // for scheduling
  availabilityCount?: number;

  // for sr delivery
  deliveredTo?: string;

  // for media
  imageCount?: number;
  videoCount?: number;
};

export type FlowNode = string;

export enum ItemCategory {
  NON_MAINTENANCE = 'managementID',
}
