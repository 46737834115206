import { LocalStorageResident, MEZO_RESIDENT_STORAGE_KEY, useLocalStorage } from '@mezo/web/hooks';
import { Loading } from '@mezo/web/ui';
import { getEnvVar } from '@mezo/web/utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';

const Header = tw.div`absolute inset-x-0 top-0 z-10 h-20 [background-color:grey]`;
const Body = tw.div`absolute top-20 left-20 h-[800px] [background-color:white]`;
const SideBar = tw.div`absolute inset-y-0 left-0 w-20 [background-color:grey]`;
const Chat = tw.div`bg-white border h-[600px] w-[400px] [background-color:grey]`;

export const Embed = () => {
  const customerId = useParams().customerId ?? 'MYND';
  const residentId = useParams().residentId ?? '1';
  const [embedScriptLoaded, setEmbedScriptLoaded] = useState(false);
  const clientToken = `${customerId}`;
  const sdkURL = getEnvVar('WEB_EMBED_SDK_URL');
  const navigate = useNavigate();
  const localStorageResident = useLocalStorage<LocalStorageResident>(MEZO_RESIDENT_STORAGE_KEY);
  useEffect(() => {
    if (!clientToken || !sdkURL) {
      console.log('missing client token or sdk url; redirecting to login', { clientToken, sdkURL });
      navigate('/login', { replace: true });
      return;
    }

    if (!document.getElementById('sdk')) {
      console.log('injecting script');
      const sdk = document.createElement('script');
      sdk.id = 'sdk';
      sdk.type = 'module';
      sdk.src = sdkURL;
      sdk.addEventListener('load', () => setEmbedScriptLoaded(true));
      document.body.appendChild(sdk);
    }
    if (embedScriptLoaded) {
      console.log('initing embed api ');
      const script = document.createElement('script');
      script.type = 'module';
      script.id = 'init';

      const scriptHtml = `const errorHandler = function (error) {
        if (error) {
          alert(error.message);
          if (error.status == 500) {
            console.log('General Application Error:' + error.status);
          } else {
            console.log('Error:' + error.status);
          }
        }
      };
      const clientToken = '${clientToken}';
      const customerId = '${customerId.toUpperCase()}';
      const residentId = '${residentId}';

      const primaryBrandColor = '#000000';
      const resetSession = true;

  
      const optionalConfig = {
        errorHandler: errorHandler,
        primaryBrandColor: primaryBrandColor,
        resetSession: resetSession,
      };

      window.MEZO.bootstrap({clientToken, 'elementId' : 'chat', customerId,residentId, optionalConfig});`.trim();
      //remove newlines and carriage returns
      script.innerHTML = scriptHtml.replace(/[\r\n]/gm, '');
      document.body.appendChild(script);
    }
  }, [clientToken, customerId, embedScriptLoaded, localStorageResident, navigate, residentId, sdkURL]);

  if (!embedScriptLoaded) return <Loading />;
  else
    return (
      <>
        <Header></Header>
        <SideBar />
        <Body>
          <Chat id="chat"></Chat>
        </Body>
      </>
    );
};

export default Embed;
