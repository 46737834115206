import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator';

export class SearchTextRequestDto {
  @IsString()
  readonly searchText: string;
  @IsString()
  @IsOptional()
  filter: string;
  @IsNumber()
  readonly resultsPerPage: number;
  @IsOptional()
  @IsNumber()
  readonly pageNumber: number;
  @IsBoolean()
  readonly isTestUser?: boolean = false;
  @IsOptional()
  readonly tags?: string[];
}
