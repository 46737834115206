import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThumbnailType } from '@mezo/web/utils';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import Button from '../../components/button';
import Thumbnail from '../../components/thumbnail';

export interface FileUploadRowProps {
  handleRemoveThumbnail: (name: string) => void;
  contentType: ThumbnailType;
  url: string;
  name: string;
}

const FlexContainer = tw.div`flex items-center justify-between w-full gap-2`;
const FlexContainerRight = tw(FlexContainer)`justify-start`;
const IconContainer = tw.div``;
const CloseIcon = tw(FontAwesomeIcon)`text-black`;
const Name = tw.span`text-sm break-all`;

export const FileUploadRow = ({ name, url, handleRemoveThumbnail, contentType }: FileUploadRowProps) => {
  const { t } = useTranslation();

  return (
    <FlexContainer>
      <FlexContainerRight>
        <Thumbnail contentType={contentType} fileName={name} url={url} />
        <Name>{name}</Name>
      </FlexContainerRight>
      <IconContainer>
        <Button onClick={() => handleRemoveThumbnail(name)} aria-label={t('media.deleteMedia', { filename: name })}>
          <CloseIcon icon={faTimesCircle} size="lg" />
        </Button>
      </IconContainer>
    </FlexContainer>
  );
};
