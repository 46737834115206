import { useAliasUrl } from '@mezo/web/queries';
import { RouteBuilder, VANITY_KEY } from '@mezo/web/utils';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// This component watches for human-readable vanity urls,
// for example `/r/tricap`, `/r/wfi`, etc., then redirects the user
export const AliasRedirect = () => {
  const params = useParams();
  const navigate = useNavigate();
  const vanityId = params[VANITY_KEY];

  const { data: alias, isPending: isLoadingAlias } = useAliasUrl(vanityId);

  useEffect(() => {
    if (isLoadingAlias) {
      return;
    }
    if (alias?.target) {
      window.location.href = alias.target;
    } else {
      const addressesUrl = new RouteBuilder().buildAddressesRoute().prependPath(RouteBuilder.RESIDENT).build();
      navigate(addressesUrl);
    }
  }, [navigate, alias?.target, isLoadingAlias]);

  return null;
};
