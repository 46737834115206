type GalleryDetails = {
  images: {
    url: string;
  }[];
};

export default {
  adjustFillValve: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/adjust_fill_valve/Adjust-Fill-Valve-0123-Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/adjust_fill_valve/Adjust-Fill-Valve-0123-Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/adjust_fill_valve/Adjust-Fill-Valve-0123-Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/adjust_fill_valve/Adjust-Fill-Valve-0123-Step3.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/adjust_fill_valve/Adjust-Fill-Valve-0123-Step4.gif',
      },
    ],
  },
  circuitBreakerResetDiy: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/breaker_reset/Mezo-Breaker-0123-Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/breaker_reset/Mezo-Breaker-0123-Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/breaker_reset/Mezo-Breaker-0123-Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/breaker_reset/Mezo-Breaker-0123-Step3.gif',
      },
    ],
  },
  chainAdjust: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/chain_adjust/Mezo-Chain-Adjust-0123-Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/chain_adjust/Mezo-Chain-Adjust-0123-Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/chain_adjust/Mezo-Chain-Adjust-0123-Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/chain_adjust/Mezo-Chain-Adjust-0123-Step3.gif',
      },
    ],
  },
  doorComponentsStaticImage1: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/static_door/StaticDoor1.png',
      },
    ],
  },
  doorComponentsStaticImage2: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/static_door/StaticDoor2.png',
      },
    ],
  },
  electricWaterHeater: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/electric_water_heater/Electric%20Water%20Heater_Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/electric_water_heater/Electric%20Water%20Heater_Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/electric_water_heater/Electric%20Water%20Heater_Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/electric_water_heater/Electric%20Water%20Heater_Step3.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/electric_water_heater/Electric%20Water%20Heater_Step4.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/electric_water_heater/Electric%20Water%20Heater_Step5.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/electric_water_heater/Electric%20Water%20Heater_Step6.gif',
      },
    ],
  },
  exampleAssets: {
    images: [
      {
        url: 'https://m.mezo.io/example_assets/example-1.png',
      },
      { url: 'https://m.mezo.io/example_assets/example-2.gif' },
      {
        url: 'https://m.mezo.io/example_assets/example-3.png',
      },
    ],
  },
  flapper: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/flapper/flapper.jpg',
      },
    ],
  },
  garageDoorAdjustSensors: {
    images: [
      { url: 'https://m.mezo.io/diy_assets/adjust_garage_sensors/GarageDoorSensor-title.gif' },
      { url: 'https://m.mezo.io/diy_assets/adjust_garage_sensors/GarageDoorSensor-1.gif' },
      { url: 'https://m.mezo.io/diy_assets/adjust_garage_sensors/GarageDoorSensor-2.gif' },
      { url: 'https://m.mezo.io/diy_assets/adjust_garage_sensors/GarageDoorSensor-3.gif' },
    ],
  },
  garageDoorChangeBatteries: {
    images: [
      { url: 'https://m.mezo.io/diy_assets/change_garage_opener_batteries/GarageOpenerBatteries-title.gif' },
      { url: 'https://m.mezo.io/diy_assets/change_garage_opener_batteries/GarageOpenerBatteries-1.gif' },
      { url: 'https://m.mezo.io/diy_assets/change_garage_opener_batteries/GarageOpenerBatteries-2.gif' },
      { url: 'https://m.mezo.io/diy_assets/change_garage_opener_batteries/GarageOpenerBatteries-3.gif' },
    ],
  },
  garageDoorStatic1: {
    images: [{ url: 'https://m.mezo.io/diy_assets/static_garagedoor/StaticGarageDoor1.png' }],
  },
  garageDoorStatic2: {
    images: [{ url: 'https://m.mezo.io/diy_assets/static_garagedoor/StaticGarageDoor2.png' }],
  },
  garbageDisposalResetDiy: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/reset_garbage_disposal/ResetGarbageDisposal_Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/reset_garbage_disposal/ResetGarbageDisposal_Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/reset_garbage_disposal/ResetGarbageDisposal_Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/reset_garbage_disposal/ResetGarbageDisposal_Step3.gif',
      },
    ],
  },
  gfciOutletReset: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/gfci_outlet_reset/ResetGCFI_Step1.gif',
      },
    ],
  },
  hoseTightening: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/hose_tightening/Mezo-Hose-Tightening-0123-Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/hose_tightening/Mezo-Hose-Tightening-0123-Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/hose_tightening/Mezo-Hose-Tightening-0123-Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/hose_tightening/Mezo-Hose-Tightening-0123-Step3.gif',
      },
    ],
  },
  plungeToiletDiy: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/clogged_toilet/Mezo-Clogged-Toilet-0123-Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/clogged_toilet/Mezo-Clogged-Toilet-0123-Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/clogged_toilet/Mezo-Clogged-Toilet-0123-Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/clogged_toilet/Mezo-Clogged-Toilet-0123-Step3.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/clogged_toilet/Mezo-Clogged-Toilet-0123-Step4.gif',
      },
    ],
  },
  pinpointTheLeak: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/pinpoint_the_leak/Mezo-Pinpoint-The-Leak-0123-Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets//pinpoint_the_leak/Mezo-Pinpoint-The-Leak-0123-Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets//pinpoint_the_leak/Mezo-Pinpoint-The-Leak-0123-Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets//pinpoint_the_leak/Mezo-Pinpoint-The-Leak-0123-Step3.gif',
      },
    ],
  },
  popupStopperDiy: {
    images: [
      {
        url: 'https://m.mezo.io/ss',
      },
    ],
  },
  shutOffWaterDiy: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/shut_off_water_supply_mitigation/ShutOffWaterSupply_Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/shut_off_water_supply_mitigation/WaterOff_OnlyStep.gif',
      },
    ],
  },
  sinkAerator: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/sink_aerator/Mezo-Sink-Aerator-0123-Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/sink_aerator/Mezo-Sink-Aerator-0123-Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/sink_aerator/Mezo-Sink-Aerator-0123-Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/sink_aerator/Mezo-Sink-Aerator-0123-Step3.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/sink_aerator/Mezo-Sink-Aerator-0123-Step4.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/sink_aerator/Mezo-Sink-Aerator-0123-Step5.gif',
      },
    ],
  },
  smartDoorLockBattery: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/smart_lock_battery/DoorLockBattery-1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/smart_lock_battery/DoorLockBattery-2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/smart_lock_battery/DoorLockBattery-3.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/smart_lock_battery/DoorLockBattery-4.gif',
      },
    ],
  },
  stopperCleanDiy: {
    images: [
      {
        url: 'https://m.mezo.io/sr',
      },
    ],
  },
  thermostatBatteryReplaceDiy: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/replace_thermostat_battery/ThermostatBattery_Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/replace_thermostat_battery/ThermostatBattery_Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/replace_thermostat_battery/ThermostatBattery_Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/replace_thermostat_battery/ThermostatBattery_Step3.gif',
      },
    ],
  },
  tightenSeatBolts: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/tighten_seat_bolts/Tighten-Seat-Bolts-0123-Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/tighten_seat_bolts/Tighten-Seat-Bolts-0123-Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/tighten_seat_bolts/Tighten-Seat-Bolts-0123-Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/tighten_seat_bolts/Tighten-Seat-Bolts-0123-Step3.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/tighten_seat_bolts/Tighten-Seat-Bolts-0123-Step4.gif',
      },
    ],
  },
  toiletWaterTurnOffDiy: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/shut_off_water_supply_mitigation/ShutOffWaterSupply_Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/shut_off_water_supply_mitigation/WaterOff_OnlyStep.gif',
      },
    ],
  },
  waterHeaterPilotDiy: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/relight_gas_water_heater/Relight-Gas-Water-Heater-0123-Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/relight_gas_water_heater/Relight-Gas-Water-Heater-0123-Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/relight_gas_water_heater/Relight-Gas-Water-Heater-0123-Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/relight_gas_water_heater/Relight-Gas-Water-Heater-0123-Step3.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/relight_gas_water_heater/Relight-Gas-Water-Heater-0123-Step4.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/relight_gas_water_heater/Relight-Gas-Water-Heater-0123-Step5.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/relight_gas_water_heater/Relight-Gas-Water-Heater-0123-Step6.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/relight_gas_water_heater/Relight-Gas-Water-Heater-0123-Step7.gif',
      },
    ],
  },
  waterHeaterTempDiy: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/gas_water_temp/GasWaterTemp_Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/gas_water_temp/GasWaterTemp_Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/gas_water_temp/GasWaterTemp_Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/gas_water_temp/GasWaterTemp_Step3.gif',
      },
    ],
  },
  waterHeaterTriage: {
    images: [
      {
        url: 'https://m.mezo.io/diy_assets/gas_or_electric/GasorElectric_Title.jpg',
      },
      {
        url: 'https://m.mezo.io/diy_assets/gas_or_electric/GasorElectric_Step1.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/gas_or_electric/GasorElectric_Step2.gif',
      },
      {
        url: 'https://m.mezo.io/diy_assets/gas_or_electric/GasorElectric_Step3.gif',
      },
    ],
  },
  windowStaticImage1: {
    images: [{ url: 'https://m.mezo.io/diy_assets/static_garagedoor/StaticWindow1.gif' }],
  },
  windowStaticImageHardware: {
    images: [{ url: 'https://m.mezo.io/diy_assets/static_garagedoor/StaticWindowHardware.gif' }],
  },
} as Record<string, GalleryDetails | undefined>;
