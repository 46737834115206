import { ReactNode } from 'react';
import tw from 'twin.macro';

type ButtonType = 'button' | 'submit' | 'reset';

export interface ButtonProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  type?: ButtonType;
}

const StyledButton = tw.button`p-1 border-0 bg-none hover:cursor-pointer`;

export const Button = (props: ButtonProps) => {
  const { children, className, onClick, type = 'button' } = props;

  return (
    <StyledButton onClick={onClick} type={type} className={className} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
