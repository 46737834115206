import styled, { css } from 'styled-components';
import tw, { theme } from 'twin.macro';
import ButtonV2, { ButtonProps } from '../button.v2';

type ChatScreenFooter = {
  hasError?: boolean;
  isActive: boolean;
  showButtonLoader?: boolean;
  label: string;
  highlightColor?: string;
  onClick: () => void;
  textAbove?: string;
  shouldRenderAnchor?: boolean;
  anchorHref?: string;
};

type StyleProps = {
  isActive: boolean;
  highlightColor?: string;
};

export const LinkButton = styled.a<ButtonProps>(({ highlightColor = theme`colors.primary`, primary = false }) => [
  tw`block w-full p-4 text-center rounded-[10px] min-h-[56px] border-[1px]`,
  primary ? css({ 'background-color': highlightColor }) : css({ 'border-color': highlightColor }),
  primary ? tw`text-white` : css({ color: highlightColor }),
]);

const FooterWrapper = tw.div`flex flex-col items-center justify-center w-full pb-2 mx-auto bg-white rounded-t-xl`;
const ButtonWrapper = tw.div`w-full px-2`;

const Highlight = styled.div<StyleProps>(({ isActive, highlightColor }) => [
  tw`flex w-full pb-2 mx-auto border-t-2 opacity-50 rounded-t-xl`,
  isActive ? css({ 'border-color': highlightColor }) : tw`border-text-light`,
]);

const TextAboveWrapper = styled.div<{ hasError: boolean }>(({ hasError }) => [
  tw`pb-2 mx-auto text-xs text-center`,
  hasError && tw`text-error`,
]);

export const ChatScreenFooter: React.FC<ChatScreenFooter> = ({
  hasError = false,
  isActive,
  label,
  showButtonLoader = false,
  onClick,
  highlightColor = theme`colors.primary`,
  textAbove,
  shouldRenderAnchor = false,
  anchorHref,
}) => {
  return (
    <FooterWrapper>
      <Highlight isActive={isActive} highlightColor={highlightColor} />
      {textAbove && <TextAboveWrapper hasError={hasError}>{textAbove}</TextAboveWrapper>}
      <ButtonWrapper>
        {shouldRenderAnchor && anchorHref ? (
          <LinkButton primary={true} href={anchorHref} highlightColor={highlightColor}>
            {label}
          </LinkButton>
        ) : (
          <ButtonV2
            primary={true}
            disabled={!isActive || showButtonLoader}
            onClick={onClick}
            highlightColor={highlightColor}
            isLoading={showButtonLoader}
          >
            {label}
          </ButtonV2>
        )}
      </ButtonWrapper>
    </FooterWrapper>
  );
};

export default ChatScreenFooter;
