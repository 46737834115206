import { UserSessionDto } from '@mezo/common/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useMutation } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

export const useSubmitMedia = (userSessionId: string, submitAttemptId: string) =>
  useMutation({
    mutationFn: async ({
      data,
      onUploadProgress,
    }: {
      data: FormData;
      onUploadProgress: (progressEvent: ProgressEvent) => void;
    }) => {
      const config: AxiosRequestConfig = {
        onUploadProgress,
        headers: { contentType: 'multipart/form-data' },
      };
      return await ApiClient.CHAT_API.utility.patch<UserSessionDto>(
        `user-session/${userSessionId}/attempt/${submitAttemptId}/media`,
        data,
        config
      );
    },
  });

export const useSubmitAsset = (userSessionId: string, submitAttemptId: string) =>
  useMutation({
    mutationFn: async ({
      data,
      onUploadProgress,
    }: {
      data: FormData;
      onUploadProgress: (progressEvent: ProgressEvent) => void;
    }) => {
      const config: AxiosRequestConfig = {
        onUploadProgress,
        headers: { contentType: 'multipart/form-data' },
      };
      return await ApiClient.CHAT_API.utility.patch<UserSessionDto>(
        `user-session/${userSessionId}/attempt/${submitAttemptId}/asset`,
        data,
        config
      );
    },
  });

export const useDeleteMedia = () =>
  useMutation({
    mutationFn: async ({
      userSessionId,
      submitAttemptId,
      filename,
    }: {
      userSessionId: string;
      submitAttemptId: string;
      filename: string;
    }) => {
      return await ApiClient.CHAT_API.utility.delete(
        `user-session/${userSessionId}/attempt/${submitAttemptId}/media/${filename}`
      );
    },
  });

export const useDeleteAsset = () =>
  useMutation({
    mutationFn: async ({ userSessionId, submitAttemptId }: { userSessionId: string; submitAttemptId: string }) => {
      return await ApiClient.CHAT_API.utility.delete(`user-session/${userSessionId}/attempt/${submitAttemptId}/asset`);
    },
  });
