import tw from 'twin.macro';

type ChoiceButtonListProps = {
  choiceButtons: ChoiceButtonProps[];
};

const ButtonListWrapper = tw.div`flex flex-col gap-2 px-5`;

export const ChoiceButtonList = ({ choiceButtons = [] }: ChoiceButtonListProps) => {
  return (
    <ButtonListWrapper>
      {choiceButtons.map(({ id, displayText, onButtonSelection }) => {
        return (
          <ChoiceButton
            key={`${id}-${displayText}`}
            id={id.toString()}
            onButtonSelection={onButtonSelection}
            displayText={String(displayText)}
          />
        );
      })}
    </ButtonListWrapper>
  );
};

type ChoiceButtonCallback = (() => void) | ((id: string, text: string) => void);

export type ChoiceButtonProps = {
  displayText: string;
  id: string;
  onButtonSelection: ChoiceButtonCallback;
};

const ButtonWrapper = tw.button`px-2 py-4 bg-white border rounded-xl text-text-light border-text-light active:bg-primary active:text-white active:border-primary hover:bg-primary hover:text-white hover:border-primary`;

export const ChoiceButton = ({ displayText, id, onButtonSelection }: ChoiceButtonProps) => {
  const onPress = () => onButtonSelection(id, displayText);
  return <ButtonWrapper onClick={onPress}>{displayText}</ButtonWrapper>;
};
