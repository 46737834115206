import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import { KEYCODES } from '../types';

const ListWrapper = tw.div`overflow-hidden border-2 rounded-xl border-light-grey`;
const StyledList = tw.ul`p-0 m-0 list-none`;
const StyledListItem = tw.li`m-0 border-light-grey not-first:border-t-2`;

const StyledLink = tw.a`flex items-center justify-between px-4 py-8 font-bold no-underline transition-all cursor-pointer text-primary hover:bg-primary hover:text-white active:bg-primary active:text-white`;
const StyledIcon = tw(FontAwesomeIcon)`fill-current`;

const RightSection = tw.div`flex justify-between gap-4 align-middle`;

const StyledPill = styled.div(() => [
  tw`relative px-2`,
  css`
    &::before {
      ${tw`rounded-full bg-primary`}
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      opacity: 0.2;
      position: absolute;
      left: 0;
      right: 0;
    }
  `,
]);

type LinkListProps = {
  links: {
    linkText: string;
    handleClick: VoidFunction;
    tag?: string | number;
  }[];
};

export const LinkList = ({ links }: LinkListProps) => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.keyCode === KEYCODES.enter && e.shiftKey === false) {
      e.currentTarget.click();
    }
  };
  return (
    <ListWrapper>
      <StyledList>
        {links.length ? (
          links.map(({ handleClick, linkText, tag }) => (
            <StyledListItem key={linkText}>
              <StyledLink onClick={handleClick} onKeyDown={handleKeyPress} role="link" tabIndex={0}>
                <span>{linkText}</span>
                <RightSection>
                  {tag && <StyledPill>{tag}</StyledPill>}
                  <StyledIcon icon={faArrowRight} size="lg" />
                </RightSection>
              </StyledLink>
            </StyledListItem>
          ))
        ) : (
          <StyledListItem key="linkText">
            <StyledLink role="link">
              <span></span>
            </StyledLink>
          </StyledListItem>
        )}
      </StyledList>
    </ListWrapper>
  );
};
