import tw from 'twin.macro';

const Checkbox = tw.input``;
const CheckboxAcknowledgeWrapper = tw.label`flex flex-row items-center justify-start gap-2`;
const CheckboxAcknowledgeText = tw.span``;

type CheckboxWithTextProps = {
  value: boolean;
  onChange: VoidFunction;
  text: string;
  name: string;
};

export const CheckboxWithText: React.FC<CheckboxWithTextProps> = ({ value, onChange, text, name }) => {
  return (
    <CheckboxAcknowledgeWrapper>
      <Checkbox type="checkbox" name={name} id={name} onChange={onChange} checked={value} />
      <CheckboxAcknowledgeText>{text}</CheckboxAcknowledgeText>
    </CheckboxAcknowledgeWrapper>
  );
};
