import { ResidentFeedbackDto } from '@deprecated/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useMutation } from '@tanstack/react-query';

export const useCreateServiceRequestFromAttempt = () =>
  useMutation({
    mutationFn: async () => await ApiClient.CHAT_API.utility.post('/service-requests/submit-attempt'),
  });

export const useUpdateServiceRequestFeedback = () =>
  useMutation({
    mutationFn: (update: { id: string; payload: ResidentFeedbackDto }) =>
      ApiClient.CHAT_API.utility.patch(`/service-requests/${update.id}/feedback`, update.payload),
  });
