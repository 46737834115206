import { MediaFileInfoDto } from '@deprecated/dtos';
import { formatJSDate } from '@deprecated/shared/formatters';
import { ChatItem, ChatResponseDto, Sender, SubmitAttemptMessagesDto } from '@mezo/common/dtos';
import { getLanguage, Language } from '@mezo/common/utils';
import { getMediaTypeFromExtension } from '@mezo/web/utils';
import parse from 'html-react-parser';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import { CustomerLogo, Gallery } from '../../components';
import { BOT, DisplayNode, MessageAuthor, MEZO_LOGO_URL, NODE_TYPES } from '../../types';
import { IntelliflowMessageSequencer } from './intelliflow-message-sequencer';

const GridContainer = tw.div`flex justify-start gap-4`;
const LogoColumn = tw.div``;
const MessagesColumn = tw.div`flex flex-col gap-2 max-w-[80%]`;
const StyledCustomerLogo = tw(CustomerLogo)`max-w-12 max-h-12`;

const RECEIVER = 'receiver';

const MessageImage = tw.img`block w-auto h-auto rounded-lg max-h-64`;
const MessageVideo = tw.video`block w-full h-auto rounded-lg max-h-64`;

const StyledMedia = styled.div<{ author: string }>(({ author }) => [
  author === RECEIVER ? tw`self-start max-w-[80%]` : tw`self-end max-w-3/4`,
]);

const StyledMessage = styled.div<{ author: string }>(({ author }) => [
  tw`px-5 py-3`,
  author === RECEIVER
    ? tw`self-start max-w-full text-white rounded-tl-none rounded-tr-xl rounded-b-xl bg-primary`
    : tw`self-end text-black rounded-br-none rounded-bl-xl rounded-t-xl bg-light-grey max-w-3/4`,
]);

const StyledText = tw.span`block`;

interface Props {
  messages: SubmitAttemptMessagesDto;
  customerIconUrl?: string;
  onBotResponseCompletion?: VoidFunction;
  onNodeDisplay?: VoidFunction;
  staggerNodes?: boolean;
}

export interface MessageProps {
  author: MessageAuthor;
  node: DisplayNode;
  onClick?: VoidFunction;
  parseContent?: boolean;
  onLoad?: VoidFunction;
}

interface BotMessageProps {
  nodes: DisplayNode[];
  onClick?: VoidFunction;
  customerIconUrl: string;
  onLoad?: VoidFunction;
}

type BotMessageContainerProps = {
  customerIconUrl: string;
  children?: React.ReactNode;
};

export const BotMessageContainer: React.FC<BotMessageContainerProps> = ({ customerIconUrl, children }) => (
  <GridContainer>
    <LogoColumn>
      <StyledCustomerLogo src={customerIconUrl ?? MEZO_LOGO_URL} />
    </LogoColumn>
    <MessagesColumn>{children}</MessagesColumn>
  </GridContainer>
);

const renderMedia = (
  author: MessageAuthor,
  media: MediaFileInfoDto | null,
  onClick?: VoidFunction,
  onLoad?: VoidFunction,
) => {
  if (!media) {
    return undefined;
  }

  const type = getMediaTypeFromExtension(media);

  return type === 'video' ? (
    <StyledMedia author={author} onClick={onClick} data-cy="chat-node">
      <MessageVideo src={media.url} controls onClick={(e) => e.stopPropagation()} onLoad={onLoad} />
    </StyledMedia>
  ) : (
    <StyledMedia author={author} onClick={onClick} data-cy="chat-node">
      <MessageImage alt={media.filename} src={media.url} onLoad={onLoad} />
    </StyledMedia>
  );
};

export const MessageNode: React.FC<MessageProps> = ({ node, onClick, author, parseContent = false, onLoad }) => (
  <Fragment>
    {node.type === NODE_TYPES.media && renderMedia(author, node.media, onClick, onLoad)}
    {node.type === NODE_TYPES.text && node.msg != null && (
      <StyledMessage author={author} data-cy="chat-node">
        <StyledText title={formatJSDate(node.timestamp as unknown as Date)}>
          {parseContent ? parse(node.msg) : node.msg}
        </StyledText>
      </StyledMessage>
    )}
  </Fragment>
);

export const BotMessageNode: React.FC<BotMessageProps> = ({ nodes, onClick, customerIconUrl, onLoad }) => (
  <BotMessageContainer customerIconUrl={customerIconUrl}>
    {nodes.map((node, index) => {
      return (
        node && (
          <MessageNode
            key={`${node?.type}-${index}-${node?.timestamp}`}
            author={RECEIVER}
            node={node}
            onClick={onClick}
            parseContent={true}
            onLoad={onLoad}
          />
        )
      );
    })}
  </BotMessageContainer>
);

const Container = tw.div`flex flex-col self-end w-full gap-6`;

const mapDisplayNodes = (message: ChatItem, language: Language) => {
  const currLangMessages = message.messages[language];
  return (
    currLangMessages?.map((val) => ({
      type: NODE_TYPES.text,
      msg: val,
      media: null,
      timestamp: message.createdAt,
      senderId: message.sender,
    })) ?? []
  );
};

export const IntelliflowMessageList: React.FC<Props> = ({
  messages,
  customerIconUrl = MEZO_LOGO_URL,
  onBotResponseCompletion,
  onNodeDisplay,
  staggerNodes = true,
}) => {
  const lastMessageIndex = messages ? messages.length - 1 : 0;
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const language = getLanguage(locale);
  return (
    <Container>
      {messages.map((message, index) => {
        const media: { url: string }[] = [];
        if (message.sender === Sender.BOT) {
          const response: ChatResponseDto = message as ChatResponseDto;
          if (response?.media?.url)
            media.push({
              url: response.media.url,
            });
          const cardMedia =
            response?.cards?.map((card) => ({
              url: card.imageUrl,
            })) || [];

          media.push(...cardMedia);
        }

        const messageNodeKey = `${message.createdAt}-${index}`;

        const shouldStaggerDisplayNodes = lastMessageIndex === index && message.sender === BOT && staggerNodes;

        const GalleryView =
          media?.length > 0 ? (
            <Gallery
              key={`${message.createdAt}-${index}-gallery`}
              galleryId={'galleryId'}
              isStatic={false}
              onImageLoad={onNodeDisplay}
              images={media}
            />
          ) : null;

        if (shouldStaggerDisplayNodes) {
          const mappedMessage = mapDisplayNodes(message, language);
          return (
            <Fragment key={messageNodeKey}>
              <IntelliflowMessageSequencer
                key={`${message.createdAt}-${index}`}
                customerIconUrl={customerIconUrl}
                messageNodes={mappedMessage}
                shouldStaggerDisplayNodes={true}
                onNodeDisplay={onNodeDisplay}
                onBotResponseCompletion={onBotResponseCompletion}
              >
                {GalleryView}
              </IntelliflowMessageSequencer>
            </Fragment>
          );
        } else if (message.sender === BOT) {
          const mappedMessage = mapDisplayNodes(message, language);
          return (
            <Fragment key={messageNodeKey}>
              <BotMessageNode
                key={messageNodeKey}
                nodes={mappedMessage}
                customerIconUrl={customerIconUrl}
                onLoad={onNodeDisplay}
              />
              {GalleryView}
            </Fragment>
          );
        } else {
          const mappedMessage = mapDisplayNodes(message, language);
          return (
            <Fragment key={messageNodeKey}>
              {mappedMessage.map((node, index) => (
                <MessageNode
                  key={`${node?.type}-${node?.timestamp}-${index}`}
                  author={'sender'}
                  node={node}
                  onLoad={onNodeDisplay}
                />
              ))}
            </Fragment>
          );
        }
      })}
    </Container>
  );
};

export default IntelliflowMessageList;
