import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThumbnailType, getThumbnailTypeFromContentType } from '@mezo/web/utils';
import { useCallback } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export interface ThumbnailProps {
  url: string;
  contentType: string;
  fileName: string;
  onSelect?: VoidFunction;
}

const StyledThumbnail = tw.img`w-full h-auto rounded-lg max-h-16 max-w-16 bg-text`;
const StyledVideo = tw.video`w-16 h-16 rounded-lg bg-text`;
const Container = styled.div<{ onClick?: VoidFunction }>((onClick) => [tw`relative`, onClick && tw`cursor-pointer`]);
const VidContainer = tw.div`relative rounded-lg before:top-0 before:left-0 before:w-full before:h-full before:opacity-50 before:absolute before:bg-text before:rounded-lg`;
const PlayIcon = tw(FontAwesomeIcon)`absolute text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2`;

export const Thumbnail = ({ url, contentType, fileName, onSelect }: ThumbnailProps) => {
  const type = getThumbnailTypeFromContentType(contentType);
  const renderThumbnail = useCallback(() => {
    if (type === ThumbnailType.FILE) {
      return (
        <VidContainer>
          <StyledVideo src={url} controls={false} onClick={(e) => e.stopPropagation()} />
          <PlayIcon icon={faPlayCircle} size="2x" />
        </VidContainer>
      );
    } else {
      return <StyledThumbnail src={url} aria-label={fileName} alt={fileName} />;
    }
  }, [type, url, fileName]);

  return <Container onClick={onSelect}>{renderThumbnail()}</Container>;
};

export default Thumbnail;
