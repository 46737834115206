import { captureException } from '@sentry/react';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { noop } from 'lodash';
import { toast } from 'react-toastify';
import { isProduction } from '../cypress';
import { getEnvVar } from '../env-vars';
import { Api } from './api';
const WEB_API_VERSION = 'v1';

const envJson: AppConfig = {
  WEB_FIREBASE_API_KEY: getEnvVar('WEB_FIREBASE_API_KEY'),
  WEB_FIREBASE_AUTH_DOMAIN: getEnvVar('WEB_FIREBASE_AUTH_DOMAIN'),
  WEB_FIREBASE_PROJECT_ID: getEnvVar('WEB_FIREBASE_PROJECT_ID'),
  WEB_FIREBASE_STORAGE_BUCKET: getEnvVar('WEB_FIREBASE_STORAGE_BUCKET'),
  WEB_FIREBASE_MESSAGING_SENDER_ID: getEnvVar('WEB_FIREBASE_MESSAGING_SENDER_ID'),
  WEB_FIREBASE_APP_ID: getEnvVar('WEB_FIREBASE_APP_ID'),
  WEB_FIREBASE_MEASUREMENT_ID: getEnvVar('WEB_FIREBASE_MEASUREMENT_ID'),
  WEB_API_BASE_URL: getEnvVar('WEB_API_BASE_URL'),
  WEB_BASE_URL: getEnvVar('WEB_BASE_URL'),
};

export interface AppConfig {
  WEB_FIREBASE_API_KEY: string;
  WEB_FIREBASE_AUTH_DOMAIN: string;
  WEB_FIREBASE_PROJECT_ID: string;
  WEB_FIREBASE_STORAGE_BUCKET: string;
  WEB_FIREBASE_MESSAGING_SENDER_ID: string;
  WEB_FIREBASE_APP_ID: string;
  WEB_FIREBASE_MEASUREMENT_ID: string;
  WEB_API_BASE_URL: string;
  WEB_BASE_URL: string;
}

const nonChatInterceptors = {
  request: [
    {
      onFulfilled: (_config: AxiosRequestConfig) => {
        return _config;
      },
      onRejected: (err: AxiosError) => {
        noop();
      },
    },
  ],
  response: [
    {
      onFulFilled: (response: AxiosResponse) => {
        return response;
      },
      onRejected: (err: AxiosError) => {
        toast(err.message, { toastId: err.code });
        if (isProduction()) {
          captureException(err);
        }
        throw err;
      },
    },
  ],
};

const chatInterceptors = {
  request: [
    {
      onFulfilled: (_config: AxiosRequestConfig) => {
        return _config;
      },
      onRejected: (err: AxiosError) => {
        if (isProduction()) {
          captureException(err, {
            tags: {
              requestUrl: err.config?.url,
              baseUrl: err.config?.baseURL,
              method: err.config?.method,
              status: err.response?.status,
              code: err.code,
              message: err.message,
            },
            extra: {
              data: err.config?.data,
            },
          });
        }
        noop();
      },
    },
  ],
  response: [
    {
      onFulFilled: (response: AxiosResponse) => {
        return response;
      },
      onRejected: (err: AxiosError) => {
        throw err;
      },
    },
  ],
};

export class ApiClient {
  static APP_CONFIG: AppConfig = envJson;

  static NON_CHAT_API: Api = new Api(
    {
      baseURL: `${ApiClient.APP_CONFIG.WEB_API_BASE_URL}/${WEB_API_VERSION}`,
    },
    nonChatInterceptors,
    false
  );

  static CHAT_API: Api = new Api(
    {
      baseURL: `${ApiClient.APP_CONFIG.WEB_API_BASE_URL}/${WEB_API_VERSION}`,
    },
    chatInterceptors,
    true
  );
}
