import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren } from 'react';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export const Section = tw.section`relative flex flex-col w-full gap-2 px-6 py-5 break-words md:px-8 rounded-2xl bg-background-secondary`;
export const SectionHeader = tw.h4`text-sm font-medium tracking-wider uppercase text-text-light`;
const SectionHeaderEdit = styled(SectionHeader)<{ isEditable: boolean }>(({ isEditable }) => [
  isEditable && tw`cursor-pointer`,
]);
const StyledIcon = tw(FontAwesomeIcon)`invisible ml-2 fill-current text-text-light group-hocus:visible`;

export const HeaderDetails = styled.span(() => [
  tw`block text-sm font-normal tracking-normal capitalize text-text-medium-dark md:inline-block`,
  css`
    @media (min-width: 768px) {
      & + & {
        ${tw`ml-3`};
        &::before {
          content: '•';
          ${tw`mr-3`};
        }
      }
    }
  `,
]);

interface SectionHeaderWithEditProps extends PropsWithChildren {
  onClick?: VoidFunction;
  isEditable: boolean;
  'data-testid'?: string;
  role?: string;
}
export const SectionHeaderWithEdit = ({ children, onClick, isEditable, ...rest }: SectionHeaderWithEditProps) => (
  <SectionHeaderEdit
    role={isEditable ? 'button' : ''}
    data-testid={rest['data-testid']}
    className="group"
    isEditable={isEditable}
    onClick={isEditable ? onClick : undefined}
  >
    {children}
    {isEditable && <StyledIcon icon={faPencil} size="1x" />}
  </SectionHeaderEdit>
);

export const FieldLabel = tw.label`mt-4`;
export const FormContainer = tw.div`flex flex-col px-8 mt-4`;
export const WrappedSelect = tw(Select)`w-full`;
export const PageContent = tw.div`flex flex-col w-full h-full mx-auto lg:max-w-xl bg-background-light`;
export const PageContainer = tw.div`flex flex-col justify-between w-full h-full bg-background rounded-xl`;

export const SectionWithLightPrimaryBackground = tw.div`relative p-4 before:w-full before:h-full before:opacity-10 before:bg-primary before:rounded-xl before:absolute before:left-0 before:top-0`;
