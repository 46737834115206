import tw from 'twin.macro';
import { MEZO_LOGO_URL } from '../types/constants';
import { CustomerLogo } from './customer-logo';
import { MessageLoaderBubble } from './message-loader-bubble';

const LoadingContainer = tw.div`flex self-end justify-start w-full gap-4`;
const LogoColumn = tw.div``;
const LoadingBubbleColumn = tw.div`flex flex-col self-start`;
const StyledCustomerLogo = tw(CustomerLogo)`max-w-12 max-h-12`;

interface Props {
  customerIconUrl?: string;
}

export const MessageLoader: React.FC<Props> = ({ customerIconUrl = MEZO_LOGO_URL }) => (
  <LoadingContainer>
    <LogoColumn>
      <StyledCustomerLogo src={customerIconUrl} />
    </LogoColumn>
    <LoadingBubbleColumn>
      <MessageLoaderBubble />
    </LoadingBubbleColumn>
  </LoadingContainer>
);
