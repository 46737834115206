import { useEffect, useState } from 'react';

export const useStaggerDisplay = <T>(
  components: T[],
  delay: ((component: T) => number) | number,
  onNodeDisplay?: VoidFunction,
  onCompletion?: VoidFunction
): [T[], boolean] => {
  const [visibleDisplayNodes, setVisibleDisplayNodes] = useState([components[0]]);
  const [showIndicator, setShowIndicator] = useState<boolean>(components.length > 1);

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];
    if (components.length > 1) {
      // first component is displayed immediately
      let acc = 0;
      const lastDisplayNodeIndex = components.length - 1;
      components.forEach((component, index) => {
        const delayTime = typeof delay === 'number' ? delay : delay(component);

        const timeout = setTimeout(() => {
          const newVisibleDisplayNodes = components.slice(0, index + 1);
          setVisibleDisplayNodes(newVisibleDisplayNodes);
          onNodeDisplay?.();
          if (index === lastDisplayNodeIndex) {
            // hide indicator when we show last display node
            setShowIndicator(false);
            onCompletion?.();
          }
        }, acc);
        if (index !== lastDisplayNodeIndex) {
          // delay the visibility of the next display node by the time it takes to read the current display node
          // first display node shows immediately
          acc += delayTime;
        }
        timeouts.push(timeout);
      });
    } else {
      onNodeDisplay?.();
      onCompletion?.();
    }
    // clear timeouts to prevent mem leak
    return () => timeouts.forEach((timeout) => clearTimeout(timeout));
  }, [components, delay, onNodeDisplay, onCompletion]);

  return [visibleDisplayNodes, showIndicator];
};
