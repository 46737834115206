import { FlowNode, Locale, Page, UserSessionStatusType } from '@mezo/common/utils';
import { ResolutionType } from '../chat';
import { EmergencyConfigDto, UnitSchedulingConfiguration } from '../configuration';
import { EmergencyItem } from '../emergencies';
import { CommonProblem } from '../units';
import { MediaFileDto } from './media-file.dto';
import { SubmitAttemptMessagesDto } from './user-session-submit-attempt.dto';

export enum STEP {
  ADDITIONAL_DETAILS = 'ADDITIONAL_DETAILS',
}

export interface UserSessionDto {
  id: string;
  createdAt: number;
  user: {
    locale: Locale;
    userAgent: string;
  };
  updatedAt?: number;
  customerId?: string;
  residentId?: string;
  unitId?: string;
  isGuest?: boolean;
  page?: Page;
  node?: FlowNode;
  currentDialogId?: string;
  currentSubmitAttempt?: {
    id: string;
    createdAt: number;
    updatedAt: number;
    userSessionId: string;
    isEmergency: boolean;
    status: UserSessionStatusType;
    taxonomy?: {
      isAsset?: boolean;
      itemId?: string;
      itemLabel?: string;
      symptomId?: string;
      locationId?: string;
      componentId?: string;
      resolutionType?: ResolutionType;
      nonMxProb?: number;
      isNonMaintenance?: boolean;
      isIntelliflowEnabled?: boolean;
      skipMediaCollection?: boolean;
    };
    emergencyDetails: {
      emergency: EmergencyItem;
    };
    problemStatement?: string;
    media: MediaFileDto[];
    asset?: MediaFileDto;
    additionalDetails?: string;
    scheduling: {
      startTimeUtc: string;
      endTimeUtc: string;
    }[];
    messages?: SubmitAttemptMessagesDto;
  };
  config: {
    hideSavedResidentBanner: boolean;
    hideSubmittedRequests: boolean;
    customExitText?: string;
    submittedRequestsHeaderText?: string;
    showSubmitV1Button: boolean;
    showSubmitV2Button: boolean;
    scheduling?: UnitSchedulingConfiguration;
    skipMediaCopy?: {
      bodyText?: string;
      bulletPoints?: string[];
    };
    askPermissionToEnter?: boolean;
    defaultPermissionToEnter?: boolean;
    askEntryNotes?: boolean;
    isEmbedEnabled: boolean;
    emergencies: EmergencyConfigDto;
    botNameOverride?: string;
    nonMx: {
      shouldWarnNonMx: boolean;
      nonMxCustomCopy: string;
      nonMxProbabilityThreshold: number;
    };
  };
  brandingConfig: {
    primaryColor?: string;
    name?: string;
    logo?: string;
    hideChatHeader?: boolean;
  };
  homeProfile: {
    commonProblems: CommonProblem[];
  };
}

export interface SubmitAttemptDto {
  id: string;
  createdAt: number;
  updatedAt: number;
  userSessionId: string;
  isEmergency: boolean;
  status: UserSessionStatusType;
}
