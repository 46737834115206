import { MediaFileInfo, MediaFileInfoDto } from '@deprecated/dtos';
import { UserSessionDto } from '@mezo/common/dtos';
import { useDeleteAsset, useDeleteMedia } from '@mezo/web/queries';
import { ApiClient } from '@mezo/web/utils';
import { useMutation } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';
import { useCallback, useState } from 'react';

export interface Upload extends MediaFileInfoDto {
  uploadProgress: number;
  uploadError?: boolean;
}

type UploadMap = { [key: string]: Upload };

// removes all characters that are not a-z, 0-9, or a period
const cleanFileName = (filename: string) => filename.replace(/[^a-z0-9.]/gi, '_').toLowerCase();

export const useUploadMedia = (
  uploadType: 'media' | 'asset',
  userSessionId?: string,
  submitAttemptId?: string,
  mediaFileInfos?: MediaFileInfo[]
) => {
  const existingUploadMap = (mediaFileInfos ?? []).reduce((map, mediaFileInfo) => {
    map[mediaFileInfo.filename] = { uploadProgress: 100, ...mediaFileInfo };
    return map;
  }, {} as UploadMap);
  const [uploads, setUploads] = useState<UploadMap>(existingUploadMap);
  const [isUploadingMedia, setIsUploadingMedia] = useState<boolean>(false);
  const isAssetUpload = uploadType === 'asset';

  const { mutateAsync: deleteMediaAsync } = useDeleteMedia();
  const { mutateAsync: deleteAssetAsync } = useDeleteAsset();

  const setProgressCompleted = useCallback((filename: string, progressValue: number) => {
    setUploads((previousUploads) => {
      const selectedUpload = previousUploads[filename];
      if (!selectedUpload) {
        return previousUploads;
      }
      const updatedUpload = {
        ...selectedUpload,
        uploadProgress: progressValue,
      };
      return {
        ...previousUploads,
        [filename]: updatedUpload,
      };
    });
  }, []);

  const deleteMedia = useCallback(
    async (filename: string) => {
      if (!userSessionId || !submitAttemptId) {
        return;
      }
      isAssetUpload
        ? await deleteAssetAsync({ userSessionId, submitAttemptId })
        : await deleteMediaAsync({ userSessionId, submitAttemptId, filename });
      setUploads((previousUploads) => {
        delete previousUploads[filename];
        return previousUploads;
      });
    },
    [userSessionId, submitAttemptId, isAssetUpload, deleteAssetAsync, deleteMediaAsync]
  );

  const clearMedia = useCallback(() => {
    Object.keys(uploads).forEach(async (filename) => {
      await deleteMedia(filename);
    });
    setUploads({});
  }, [deleteMedia, uploads]);

  const useUploadMediaMutation = (onError: (filename: string) => void) =>
    useMutation({
      mutationFn: async ({ files = [], skipStep }: { files?: File[]; skipStep?: boolean }) => {
        setIsUploadingMedia(true);
        const data = new FormData();
        if (skipStep) {
          data.append('skipStep', 'true');
        } else if (files) {
          for (const f of files) {
            const fileName = cleanFileName(f.name);
            isAssetUpload ? data.append('file', f, fileName) : data.append('files', f, fileName);
          }
        }

        const config: AxiosRequestConfig = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            files.forEach((file) => {
              const cleanName = cleanFileName(file.name);
              setProgressCompleted(cleanName, percentCompleted);
            });
          },
          headers: { contentType: 'multipart/form-data' },
        };
        const uploads = Object.values(files).reduce((acc, file) => {
          const fileName = cleanFileName(file.name);
          acc[fileName] = {
            url: '',
            filename: fileName,
            contentType: file.type,
            uploadProgress: 0,
            uploadError: false,
          };

          return acc;
        }, {} as UploadMap);

        setUploads((previousUploads) => {
          return {
            ...previousUploads,
            ...uploads,
          };
        });
        const url = isAssetUpload
          ? `user-session/${userSessionId}/attempt/${submitAttemptId}/asset`
          : `user-session/${userSessionId}/attempt/${submitAttemptId}/media`;
        const response = await ApiClient.CHAT_API.utility.patch<UserSessionDto>(url, data, config);

        if (isAssetUpload) {
          if (response?.data?.currentSubmitAttempt?.asset) {
            const uploadedFile = response?.data?.currentSubmitAttempt?.asset;
            const currUpload = uploads[uploadedFile.filename];
            const uploadEnd: Upload = {
              ...currUpload,
              uploadProgress: 100,
              url: uploadedFile.url,
            };
            setUploads(() => ({
              [uploadedFile.filename]: uploadEnd,
            }));
          }
        } else {
          if (response?.data?.currentSubmitAttempt?.media) {
            const updates: UploadMap = {};
            for (const file of files) {
              const fileName = cleanFileName(file.name);
              const uploadedFile = response?.data?.currentSubmitAttempt?.media.find((m) => m.filename === fileName);
              if (uploadedFile) {
                const uploadEnd: Upload = {
                  ...uploads[fileName],
                  uploadProgress: 100,
                  url: uploadedFile.url,
                };
                updates[fileName] = uploadEnd;
              }
            }
            setUploads((previousUploads) => {
              return {
                ...previousUploads,
                ...updates,
              };
            });
          }
        }
        setIsUploadingMedia(false);
      },
      onError: (error, { files }) => {
        setIsUploadingMedia(false);
        if (!files) return;
        files.forEach((file) => {
          const fileName = cleanFileName(file.name);
          onError(fileName);
          setUploads((previousUploads) => {
            const selectedUpload = previousUploads[fileName];
            if (!selectedUpload) {
              return previousUploads;
            }
            const updatedUpload = {
              ...selectedUpload,
              uploadError: true,
            };
            return {
              ...previousUploads,
              [fileName]: updatedUpload,
            };
          });
        });
      },
    });

  return {
    useUploadMediaMutation,
    uploads: Object.values(uploads),
    isUploadingMedia,
    deleteMedia,
    clearMedia,
  };
};
