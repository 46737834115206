import { ResidentSearchResultResponseDto, ServiceRequestResidentViewDto, TenantUserDto } from '@deprecated/dtos';
import { RegisterGuestResidentRequest, UnitDto } from '@mezo/common/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

export const mapTenantUserDtoToResidentSearchResultResponseDto = (
  tenantUser: TenantUserDto,
): ResidentSearchResultResponseDto => {
  return {
    sourceId: tenantUser.tenantData.sourceId,
    residentId: tenantUser.id,
    unitId: tenantUser.tenantData.primaryUnit.id,
    firstName: tenantUser.data.contact.firstName,
    lastName: tenantUser.data.contact.lastName,
    address1: tenantUser.tenantData.primaryUnit.address?.address1 ?? tenantUser.tenantData.guestAddress?.address1 ?? '',
    address2: tenantUser.tenantData.primaryUnit.address?.address2 ?? tenantUser.tenantData.guestAddress?.address2 ?? '',
    city: tenantUser.tenantData.primaryUnit.address?.city ?? tenantUser.tenantData.guestAddress?.city ?? '',
    state: tenantUser.tenantData.primaryUnit.address?.state ?? tenantUser.tenantData.guestAddress?.state ?? '',
    zip: tenantUser.tenantData.primaryUnit.address?.zip ?? tenantUser.tenantData.guestAddress?.zip ?? '',
    country: tenantUser.tenantData.primaryUnit.address?.country ?? tenantUser.tenantData.guestAddress?.country ?? '',
    propertyId: tenantUser.tenantData.primaryUnit.propertyId,
    propertyName: tenantUser.tenantData.primaryUnit.propertyName,
    customerName: tenantUser.tenantData.primaryUnit.customerName,
    customerIconUrl: tenantUser.tenantData.primaryUnit.customerIconUrl,
    deliveryIntegrationTypeOverride: tenantUser.tenantData.deliveryIntegrationTypeOverride,
    customerId: tenantUser.tenantData.primaryUnit.customerId,
    isGuest: tenantUser.tenantData.isGuest,
    createdAt: tenantUser.createdAt,
  };
};

export const useResident = (
  residentId: string | undefined,
  options?:
    | Omit<
        UseQueryOptions<TenantUserDto, unknown, ResidentSearchResultResponseDto, (string | undefined)[]>,
        'queryKey' | 'queryFn'
      >
    | undefined,
) =>
  useQuery({
    queryKey: ['resident', residentId],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get(`/residents/${residentId}`);
      return response?.data;
    },
    enabled: !!residentId,
    select: (resident) => mapTenantUserDtoToResidentSearchResultResponseDto(resident),
    ...options,
  });

export const useResidentUnits = (residentId: string | undefined) =>
  useQuery({
    queryKey: ['resident', residentId, 'units'],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get<UnitDto[]>(`residents/${residentId}/units`);
      return response?.data;
    },
    enabled: !!residentId,
  });

export const useGetServiceRequestsForResident = (residentId?: string) =>
  useQuery({
    queryKey: ['getServiceRequestsForResident', residentId],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get<ServiceRequestResidentViewDto[]>(
        `/residents/${residentId}/service-requests`,
      );
      return response?.data;
    },
    enabled: !!residentId,
  });

export const useCreateGuestResident = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ guestResident }: { guestResident: RegisterGuestResidentRequest }) => {
      guestResident.isGuest = true;
      const response = await ApiClient.CHAT_API.utility.post<TenantUserDto>(`/residents`, guestResident);
      return response?.data;
    },
    onSuccess: (data) => {
      queryClient.setQueriesData({ queryKey: ['guestResident'] }, data);
    },
  });
};
