import { Type } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsString, ValidateNested } from 'class-validator';

export enum ClickSource {
  EXIT_MODAL = 'Exit Modal',
  ERROR_PAGE = 'Error Page',
}

export enum IssueCode {
  I_STARTED_TO_SUBMIT_MY_REQUEST_BUT_COULD_NOT_COMPLETE_IT = 'I started to submit my request but could not complete it.',
  I_COULD_NOT_START_SUBMITTING_MY_REQUEST = 'I could not start submitting my request.',
  I_COULD_NOT_UPLOAD_PHOTOS_AND_OR_VIDEOS = 'I could not upload photos and/or videos.',
  MAX_IS_NOT_RESPONSIVE = 'Max is not responsive.',
  MAX_IS_RESETTING = 'Max is resetting.',
  MAX_DOESNT_UNDERSTAND_ME = "Max doesn't understand me.",
  I_COULD_NOT_SUBMIT_MY_REQUEST_FOR_ANOTHER_REASON = 'I could not submit my request for another reason.',
}

export class CreateSupportTicketCustomFieldsDto {
  @IsString()
  customerId?: string;

  @IsString()
  residentId?: string;

  @IsString()
  sessionId?: string;

  @IsEnum(IssueCode)
  @IsNotEmpty()
  issueCode?: IssueCode;

  @IsEnum(ClickSource)
  @IsNotEmpty()
  clickSource?: ClickSource;
}

export class CreateSupportTicketDto {
  @IsNotEmpty()
  @IsString()
  description: string;

  @IsNotEmpty()
  @IsString()
  email: string;

  @ValidateNested()
  @Type(() => CreateSupportTicketCustomFieldsDto)
  customFields: CreateSupportTicketCustomFieldsDto;
}
