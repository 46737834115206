import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export interface ModalProps {
  dataTestId?: string;
  isOpen: boolean;
  children?: React.ReactNode;
}

const StyledModal = styled('div')<ModalProps>(({ isOpen }) => [
  tw`fixed top-0 left-0 z-50 hidden w-full h-full bg-opacity-50 bg-text`,
  css`
    background-color: rgba(23, 23, 37, 0.25);
  `,
  isOpen && tw`block`,
]);
const StyledContent = tw.div`flex items-center justify-center w-full h-full max-w-full max-h-full rounded-xl`;

export const Modal: React.FC<ModalProps> = ({ children, dataTestId, isOpen }) => (
  <StyledModal isOpen={isOpen} data-testid={dataTestId || 'modal'}>
    <StyledContent>{children}</StyledContent>
  </StyledModal>
);

export default Modal;
