import { CustomerDto } from '@deprecated/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useQuery } from '@tanstack/react-query';

export const useCustomer = (customerId: string | undefined, options?: { enabled: boolean }) => {
  return useQuery({
    queryKey: ['customers', customerId],
    queryFn: async () => {
      const { data } = await ApiClient.NON_CHAT_API.utility.get<CustomerDto>(`customers/${customerId}`);
      return data;
    },
    enabled: !!customerId,
    ...options,
  });
};
