export type ExampleMediaData = {
  heroImage: string;
  images: string[];

  // i18nKeyAssetCopy is what's used to get the copy;
  // frontend use would be i18n.t(assetItems.i18nKeyAssetCopy)
  i18nKeyAssetCopy: string;
  i18nKeyItemName: string;
};

type ExampleMediaLookup = {
  [taxonomyID: string]: ExampleMediaData;
};

export const exampleMediaMap: ExampleMediaLookup = {
  'assetItems.airConditionerID': {
    heroImage: 'https://m.mezo.io/asset-media/primary-air-conditioner.png',
    i18nKeyAssetCopy: 'assetItems.airConditionerID.copy',
    images: ['https://m.mezo.io/asset-media/example-ac-01.png', 'https://m.mezo.io/asset-media/example-ac-02.png'],
    i18nKeyItemName: 'assetItems.airConditionerID.itemName',
  },
  'assetItems.clothesDryerID': {
    heroImage: 'https://m.mezo.io/asset-media/primary-dryer.png',
    i18nKeyAssetCopy: 'assetItems.clothesDryerID.copy',
    images: [
      'https://m.mezo.io/asset-media/example-dryer-01.png',
      'https://m.mezo.io/asset-media/example-dryer-02.png',
    ],
    i18nKeyItemName: 'assetItems.clothesDryerID.itemName',
  },
  'assetItems.cooktopID': {
    heroImage: 'https://m.mezo.io/asset-media/primary-cooktop.png',
    i18nKeyAssetCopy: 'assetItems.cooktopID.copy',
    images: [
      'https://m.mezo.io/asset-media/example-cooktop-01.png',
      'https://m.mezo.io/asset-media/example-cooktop-02.png',
    ],
    i18nKeyItemName: 'assetItems.cooktopID.itemName',
  },
  'assetItems.dishwasherID': {
    heroImage: 'https://m.mezo.io/asset-media/primary-dishwasher.png',
    i18nKeyAssetCopy: 'assetItems.dishwasherID.copy',
    images: [
      'https://m.mezo.io/asset-media/example-dishwasher-01.png',
      'https://m.mezo.io/asset-media/example-dishwasher-02.png',
    ],
    i18nKeyItemName: 'assetItems.dishwasherID.itemName',
  },
  'assetItems.freezerID': {
    heroImage: 'https://m.mezo.io/asset-media/primary-fridge.png',
    i18nKeyAssetCopy: 'assetItems.freezerID.copy',
    images: ['https://m.mezo.io/asset-media/example-fridge-01.png'],
    i18nKeyItemName: 'assetItems.freezerID.itemName',
  },
  'assetItems.heatingSystemID': {
    heroImage: 'https://m.mezo.io/asset-media/primary-heatingsystem.png',
    i18nKeyAssetCopy: 'assetItems.furnaceID.copy',
    images: [
      'https://m.mezo.io/asset-media/example-heatingsystem-01.png',
      'https://m.mezo.io/asset-media/example-heatingsystem-02.png',
    ],
    i18nKeyItemName: 'assetItems.furnaceID.itemName',
  },
  'assetItems.microwaveID': {
    heroImage: 'https://m.mezo.io/asset-media/primary-microwave.png',
    i18nKeyAssetCopy: 'assetItems.microwaveID.copy',
    images: [
      'https://m.mezo.io/asset-media/example-microwave-01.png',
      'https://m.mezo.io/asset-media/example-microwave-02.png',
    ],
    i18nKeyItemName: 'assetItems.microwaveID.itemName',
  },
  'assetItems.ovenID': {
    heroImage: 'https://m.mezo.io/asset-media/primary-oven.png',
    i18nKeyAssetCopy: 'assetItems.rangeStoveOvenID.copy',
    images: ['https://m.mezo.io/asset-media/example-oven-01.png', 'https://m.mezo.io/asset-media/example-oven-02.png'],
    i18nKeyItemName: 'assetItems.rangeStoveOvenID.itemName',
  },
  'assetItems.refrigeratorID': {
    heroImage: 'https://m.mezo.io/asset-media/primary-fridge.png',
    i18nKeyAssetCopy: 'assetItems.refrigeratorID.copy',
    images: ['https://m.mezo.io/asset-media/example-fridge-01.png'],
    i18nKeyItemName: 'assetItems.refrigeratorID.itemName',
  },
  'assetItems.washingMachineID': {
    heroImage: 'https://m.mezo.io/asset-media/primary-washer.png',
    i18nKeyAssetCopy: 'assetItems.washingMachineID.copy',
    images: ['https://m.mezo.io/asset-media/example-washer-01.png'],
    i18nKeyItemName: 'assetItems.washingMachineID.itemName',
  },
  'assetItems.waterHeaterID': {
    heroImage: 'https://m.mezo.io/asset-media/primary-waterheater.png',
    i18nKeyAssetCopy: 'assetItems.waterHeaterID.copy',
    images: ['https://m.mezo.io/asset-media/example-waterheater-01.png'],
    i18nKeyItemName: 'assetItems.waterHeaterID.itemName',
  },
  'mediaItems.defaultID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'media.instructions',
    i18nKeyItemName: 'mediaItems.defaultID.itemName',
  },
  'mediaItems.doorbellID': {
    heroImage: 'https://m.mezo.io/example_assets/doorbellID/doorbell.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.doorbellID.copy',
    i18nKeyItemName: 'mediaItems.doorbellID.itemName',
  },
  'mediaItems.toiletPaperHolderID': {
    heroImage: 'https://m.mezo.io/example_assets/toiletPaperHolderID/toiletpaperholder.png',
    images: ['https://m.mezo.io/example_assets/toiletPaperHolderID/Toilet-paper-holder.png'],
    i18nKeyAssetCopy: 'mediaItems.toiletPaperHolderID.copy',
    i18nKeyItemName: 'mediaItems.toiletPaperHolderID.itemName',
  },
  'mediaItems.equipmentID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.equipmentID.copy',
    i18nKeyItemName: 'mediaItems.equipmentID.itemName',
  },
  'mediaItems.waterChillerID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.waterChillerID.copy',
    i18nKeyItemName: 'mediaItems.waterChillerID.itemName',
  },
  'mediaItems.ceilingID': {
    heroImage: 'https://m.mezo.io/example_assets/ceilingID/ceiling-1.png',
    images: ['https://m.mezo.io/example_assets/ceilingID/ceiling-2.png'],
    i18nKeyAssetCopy: 'mediaItems.ceilingID.copy',
    i18nKeyItemName: 'mediaItems.ceilingID.itemName',
  },
  'mediaItems.windowLockID': {
    heroImage: 'https://m.mezo.io/example_assets/windowLockID/windowlatch.png',
    images: ['https://m.mezo.io/example_assets/windowLockID/shutterstock_1222793332.jpg'],
    i18nKeyAssetCopy: 'mediaItems.windowLockID.copy',
    i18nKeyItemName: 'mediaItems.windowLockID.itemName',
  },
  'mediaItems.thermostatID': {
    heroImage: 'https://m.mezo.io/example_assets/thermostatID/shutterstock_207407488.jpg',
    images: [
      'https://m.mezo.io/example_assets/thermostatID/thermostat2.png',
      'https://m.mezo.io/example_assets/thermostatID/thermostat3.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.thermostatID.copy',
    i18nKeyItemName: 'mediaItems.thermostatID.itemName',
  },
  'mediaItems.leaseID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.leaseID.copy',
    i18nKeyItemName: 'mediaItems.leaseID.itemName',
  },
  'mediaItems.sewerID': {
    heroImage: 'https://m.mezo.io/example_assets/sewerID/sewer-1.png',
    images: ['https://m.mezo.io/example_assets/sewerID/sewer2.png'],
    i18nKeyAssetCopy: 'mediaItems.sewerID.copy',
    i18nKeyItemName: 'mediaItems.sewerID.itemName',
  },
  'mediaItems.stoveID': {
    heroImage: 'https://m.mezo.io/example_assets/stoveID/Stove-full.png',
    images: [
      'https://m.mezo.io/example_assets/stoveID/Stove-side.png',
      'https://m.mezo.io/example_assets/stoveID/Stove-Burner.png',
      'https://m.mezo.io/example_assets/stoveID/Stove-controls.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.stoveID.copy',
    i18nKeyItemName: 'mediaItems.stoveID.itemName',
  },
  'mediaItems.structuralID': {
    heroImage: 'https://m.mezo.io/example_assets/structuralID/exterior.png',
    images: ['https://m.mezo.io/example_assets/structuralID/sidewalk2.png'],
    i18nKeyAssetCopy: 'mediaItems.structuralID.copy',
    i18nKeyItemName: 'mediaItems.structuralID.itemName',
  },
  'mediaItems.largeAnimalsID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.largeAnimalsID.copy',
    i18nKeyItemName: 'mediaItems.largeAnimalsID.itemName',
  },
  'mediaItems.hoaID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.hoaID.copy',
    i18nKeyItemName: 'mediaItems.hoaID.itemName',
  },
  'mediaItems.blindsAndShadesID': {
    heroImage: 'https://m.mezo.io/example_assets/blindsAndShadesID/windows-2.png',
    images: ['https://m.mezo.io/example_assets/blindsAndShadesID/windows-1.png'],
    i18nKeyAssetCopy: 'mediaItems.blindsAndShadesID.copy',
    i18nKeyItemName: 'mediaItems.blindsAndShadesID.itemName',
  },
  'mediaItems.microhoodID': {
    heroImage: 'https://m.mezo.io/example_assets/microhoodID/Hood-3.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.microhoodID.copy',
    i18nKeyItemName: 'mediaItems.microhoodID.itemName',
  },
  'mediaItems.deliveryID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.deliveryID.copy',
    i18nKeyItemName: 'mediaItems.deliveryID.itemName',
  },
  'mediaItems.floorID': {
    heroImage: 'https://m.mezo.io/example_assets/floorID/floors-1.png',
    images: [
      'https://m.mezo.io/example_assets/floorID/floor-2.png',
      'https://m.mezo.io/example_assets/floorID/floor-3.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.floorID.copy',
    i18nKeyItemName: 'mediaItems.floorID.itemName',
  },
  'mediaItems.septicSystemID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.septicSystemID.copy',
    i18nKeyItemName: 'mediaItems.septicSystemID.itemName',
  },
  'mediaItems.cleaningID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.cleaningID.copy',
    i18nKeyItemName: 'mediaItems.cleaningID.itemName',
  },
  'mediaItems.internetID': {
    heroImage: 'https://m.mezo.io/example_assets/internetID/IMG_7686.png',
    images: ['https://m.mezo.io/example_assets/internetID/IMG_7687.png'],
    i18nKeyAssetCopy: 'mediaItems.internetID.copy',
    i18nKeyItemName: 'mediaItems.internetID.itemName',
  },
  'mediaItems.ovenID': {
    heroImage: 'https://m.mezo.io/example_assets/ovenID/oven-1.png',
    images: ['https://m.mezo.io/example_assets/ovenID/oven-2.jpg'],
    i18nKeyAssetCopy: 'mediaItems.ovenID.copy',
    i18nKeyItemName: 'mediaItems.ovenID.itemName',
  },
  'mediaItems.interiorWallsID': {
    heroImage: 'https://m.mezo.io/example_assets/interiorWallsID/walls-1.png',
    images: ['https://m.mezo.io/example_assets/interiorWallsID/walls-2.png'],
    i18nKeyAssetCopy: 'mediaItems.interiorWallsID.copy',
    i18nKeyItemName: 'mediaItems.interiorWallsID.itemName',
  },
  'mediaItems.waterPurifierID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.waterPurifierID.copy',
    i18nKeyItemName: 'mediaItems.waterPurifierID.itemName',
  },
  'mediaItems.closetOrPantryID': {
    heroImage: 'https://m.mezo.io/example_assets/closetOrPantryID/cabinet-1.png',
    images: ['https://m.mezo.io/example_assets/closetOrPantryID/cabinethinge.png'],
    i18nKeyAssetCopy: 'mediaItems.closetOrPantryID.copy',
    i18nKeyItemName: 'mediaItems.closetOrPantryID.itemName',
  },
  'mediaItems.stairsID': {
    heroImage: 'https://m.mezo.io/example_assets/stairsID/stairs-1.png',
    images: ['https://m.mezo.io/example_assets/stairsID/stairs-2.png'],
    i18nKeyAssetCopy: 'mediaItems.stairsID.copy',
    i18nKeyItemName: 'mediaItems.stairsID.itemName',
  },
  'mediaItems.dishwasherID': {
    heroImage: 'https://m.mezo.io/example_assets/dishwasherID/dishwasher-3.jpg',
    images: [
      'https://m.mezo.io/example_assets/dishwasherID/dishwasher-1.jpg',
      'https://m.mezo.io/example_assets/dishwasherID/dishwasher-2.jpg',
      'https://m.mezo.io/example_assets/dishwasherID/dishwasher-4.jpg',
    ],
    i18nKeyAssetCopy: 'mediaItems.dishwasherID.copy',
    i18nKeyItemName: 'mediaItems.dishwasherID.itemName',
  },
  'mediaItems.parkingAreaID': {
    heroImage: 'https://m.mezo.io/example_assets/parkingAreaID/parking.png',
    images: ['https://m.mezo.io/example_assets/parkingAreaID/Parking.png'],
    i18nKeyAssetCopy: 'mediaItems.parkingAreaID.copy',
    i18nKeyItemName: 'mediaItems.parkingAreaID.itemName',
  },
  'mediaItems.electronicOpenerID': {
    heroImage: 'https://m.mezo.io/example_assets/electronicOpenerID/electronic-opener-3.png',
    images: [
      'https://m.mezo.io/example_assets/electronicOpenerID/electronic-opener-2.png',
      'https://m.mezo.io/example_assets/electronicOpenerID/electronic-opener.jpg',
    ],
    i18nKeyAssetCopy: 'mediaItems.electronicOpenerID.copy',
    i18nKeyItemName: 'mediaItems.electronicOpenerID.itemName',
  },
  'mediaItems.insectsID': {
    heroImage: 'https://m.mezo.io/example_assets/insectsID/insects-1.png',
    images: [
      'https://m.mezo.io/example_assets/insectsID/insects-2.png',
      'https://m.mezo.io/example_assets/insectsID/pests-1.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.insectsID.copy',
    i18nKeyItemName: 'mediaItems.insectsID.itemName',
  },
  'mediaItems.tubOrShowerID': {
    heroImage: 'https://m.mezo.io/example_assets/tubOrShowerID/shower.png',
    images: [
      'https://m.mezo.io/example_assets/tubOrShowerID/tub-1.png',
      'https://m.mezo.io/example_assets/tubOrShowerID/shower-1.png',
      'https://m.mezo.io/example_assets/tubOrShowerID/tub-3.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.tubOrShowerID.copy',
    i18nKeyItemName: 'mediaItems.tubOrShowerID.itemName',
  },
  'mediaItems.lockAndKeysID': {
    heroImage: 'https://m.mezo.io/example_assets/lockAndKeysID/Lock.png',
    images: [
      'https://m.mezo.io/example_assets/lockAndKeysID/LockKeys.png',
      'https://m.mezo.io/example_assets/lockAndKeysID/key-1.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.lockAndKeysID.copy',
    i18nKeyItemName: 'mediaItems.lockAndKeysID.itemName',
  },
  'mediaItems.irrigationID': {
    heroImage: 'https://m.mezo.io/example_assets/irrigationID/irrigation-1.jpg',
    images: [
      'https://m.mezo.io/example_assets/irrigationID/irrigation-2.jpg',
      'https://m.mezo.io/example_assets/irrigationID/irrigation-3.jpg',
      'https://m.mezo.io/example_assets/irrigationID/irrigation-4.jpg',
    ],
    i18nKeyAssetCopy: 'mediaItems.irrigationID.copy',
    i18nKeyItemName: 'mediaItems.irrigationID.itemName',
  },
  'mediaItems.poolPumpID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.poolPumpID.copy',
    i18nKeyItemName: 'mediaItems.poolPumpID.itemName',
  },
  'mediaItems.bathroomFixturesID': {
    heroImage: 'https://m.mezo.io/example_assets/bathroomFixturesID/fixtures.jpg',
    images: ['https://m.mezo.io/example_assets/bathroomFixturesID/vanity.png'],
    i18nKeyAssetCopy: 'mediaItems.bathroomFixturesID.copy',
    i18nKeyItemName: 'mediaItems.bathroomFixturesID.itemName',
  },
  'mediaItems.amenityID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.amenityID.copy',
    i18nKeyItemName: 'mediaItems.amenityID.itemName',
  },
  'mediaItems.alarmSystemID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.alarmSystemID.copy',
    i18nKeyItemName: 'mediaItems.alarmSystemID.itemName',
  },
  'mediaItems.airConditionerID': {
    heroImage: 'https://m.mezo.io/example_assets/airConditionerID/AC-3.jpg',
    images: [
      'https://m.mezo.io/example_assets/airConditionerID/AC-2.jpg',
      'https://m.mezo.io/example_assets/airConditionerID/AC-1.jpg',
    ],
    i18nKeyAssetCopy: 'mediaItems.airConditionerID.copy',
    i18nKeyItemName: 'mediaItems.airConditionerID.itemName',
  },
  'mediaItems.balconyID': {
    heroImage: 'https://m.mezo.io/example_assets/balconyID/balcony.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.balconyID.copy',
    i18nKeyItemName: 'mediaItems.balconyID.itemName',
  },
  'mediaItems.waterSystemID': {
    heroImage: 'https://m.mezo.io/example_assets/waterSystemID/water-damage-3.png',
    images: [
      'https://m.mezo.io/example_assets/waterSystemID/Water-damage.png',
      'https://m.mezo.io/example_assets/waterSystemID/water-system-1.png',
      'https://m.mezo.io/example_assets/waterSystemID/water-system-3.png',
      'https://m.mezo.io/example_assets/waterSystemID/water-system-2.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.waterSystemID.copy',
    i18nKeyItemName: 'mediaItems.waterSystemID.itemName',
  },
  'mediaItems.smokeAlarmID': {
    heroImage: 'https://m.mezo.io/example_assets/smokeAlarmID/smoke-alarm.png',
    images: [
      'https://m.mezo.io/example_assets/smokeAlarmID/firealarm.png',
      'https://m.mezo.io/example_assets/smokeAlarmID/firealarm2.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.smokeAlarmID.copy',
    i18nKeyItemName: 'mediaItems.smokeAlarmID.itemName',
  },
  'mediaItems.carpetID': {
    heroImage: 'https://m.mezo.io/example_assets/carpetID/carpet2.png',
    images: ['https://m.mezo.io/example_assets/carpetID/carpet-1.png'],
    i18nKeyAssetCopy: 'mediaItems.carpetID.copy',
    i18nKeyItemName: 'mediaItems.carpetID.itemName',
  },
  'mediaItems.stairHandrailID': {
    heroImage: 'https://m.mezo.io/example_assets/stairHandrailID/stairs-2.png',
    images: ['https://m.mezo.io/example_assets/stairHandrailID/handrail.png'],
    i18nKeyAssetCopy: 'mediaItems.stairHandrailID.copy',
    i18nKeyItemName: 'mediaItems.stairHandrailID.itemName',
  },
  'mediaItems.toiletID': {
    heroImage: 'https://m.mezo.io/example_assets/toiletID/vanity.png',
    images: [
      'https://m.mezo.io/example_assets/toiletID/toilet-1.png',
      'https://m.mezo.io/example_assets/toiletID/toilet-2.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.toiletID.copy',
    i18nKeyItemName: 'mediaItems.toiletID.itemName',
  },
  'mediaItems.shuttersID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.shuttersID.copy',
    i18nKeyItemName: 'mediaItems.shuttersID.itemName',
  },
  'mediaItems.buildingSidingID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.buildingSidingID.copy',
    i18nKeyItemName: 'mediaItems.buildingSidingID.itemName',
  },
  'mediaItems.porchID': {
    heroImage: 'https://m.mezo.io/example_assets/porchID/balcony.png',
    images: [
      'https://m.mezo.io/example_assets/porchID/porch-2.png',
      'https://m.mezo.io/example_assets/porchID/porch-1.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.porchID.copy',
    i18nKeyItemName: 'mediaItems.porchID.itemName',
  },
  'mediaItems.ventilationFanID': {
    heroImage: 'https://m.mezo.io/example_assets/ventilationFanID/Hood-3.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.ventilationFanID.copy',
    i18nKeyItemName: 'mediaItems.ventilationFanID.itemName',
  },
  'mediaItems.comboWasherAndDryerID': {
    heroImage: 'https://m.mezo.io/example_assets/comboWasherAndDryerID/dryer.png',
    images: [
      'https://m.mezo.io/example_assets/comboWasherAndDryerID/machines.png',
      'https://m.mezo.io/example_assets/comboWasherAndDryerID/washer-3.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.comboWasherAndDryerID.copy',
    i18nKeyItemName: 'mediaItems.comboWasherAndDryerID.itemName',
  },
  'mediaItems.waterHeaterID': {
    heroImage: 'https://m.mezo.io/example_assets/waterHeaterID/waterheater.jpg',
    images: ['https://m.mezo.io/example_assets/waterHeaterID/water-system-1.png'],
    i18nKeyAssetCopy: 'mediaItems.waterHeaterID.copy',
    i18nKeyItemName: 'mediaItems.waterHeaterID.itemName',
  },
  'mediaItems.fireExtinguisherID': {
    heroImage: 'https://m.mezo.io/example_assets/fireExtinguisherID/extinguisher-1.png',
    images: ['https://m.mezo.io/example_assets/fireExtinguisherID/extinguisher2.png'],
    i18nKeyAssetCopy: 'mediaItems.fireExtinguisherID.copy',
    i18nKeyItemName: 'mediaItems.fireExtinguisherID.itemName',
  },
  'mediaItems.wiringID': {
    heroImage: 'https://m.mezo.io/example_assets/wiringID/wiring.png',
    images: [
      'https://m.mezo.io/example_assets/wiringID/Electrical-3.png',
      'https://m.mezo.io/example_assets/wiringID/electrical-2.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.wiringID.copy',
    i18nKeyItemName: 'mediaItems.wiringID.itemName',
  },
  'mediaItems.wallSafeID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.wallSafeID.copy',
    i18nKeyItemName: 'mediaItems.wallSafeID.itemName',
  },
  'mediaItems.poolDeckID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.poolDeckID.copy',
    i18nKeyItemName: 'mediaItems.poolDeckID.itemName',
  },
  'mediaItems.clothesDryerID': {
    heroImage: 'https://m.mezo.io/example_assets/clothesDryerID/machines.png',
    images: ['https://m.mezo.io/example_assets/clothesDryerID/dryer.png'],
    i18nKeyAssetCopy: 'mediaItems.clothesDryerID.copy',
    i18nKeyItemName: 'mediaItems.clothesDryerID.itemName',
  },
  'mediaItems.lightingID': {
    heroImage: 'https://m.mezo.io/example_assets/lightingID/lighting-1.png',
    images: [
      'https://m.mezo.io/example_assets/lightingID/lighting.png',
      'https://m.mezo.io/example_assets/lightingID/lighting-3.png',
      'https://m.mezo.io/example_assets/lightingID/lighting-2.png',
      'https://m.mezo.io/example_assets/lightingID/lighswitch.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.lightingID.copy',
    i18nKeyItemName: 'mediaItems.lightingID.itemName',
  },
  'mediaItems.neighborID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.neighborID.copy',
    i18nKeyItemName: 'mediaItems.neighborID.itemName',
  },
  'mediaItems.poolFurnitureID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.poolFurnitureID.copy',
    i18nKeyItemName: 'mediaItems.poolFurnitureID.itemName',
  },
  'mediaItems.countertopID': {
    heroImage: 'https://m.mezo.io/example_assets/countertopID/kitchen01.png',
    images: ['https://m.mezo.io/example_assets/countertopID/countertop2.png'],
    i18nKeyAssetCopy: 'mediaItems.countertopID.copy',
    i18nKeyItemName: 'mediaItems.countertopID.itemName',
  },
  'mediaItems.termitesID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.termitesID.copy',
    i18nKeyItemName: 'mediaItems.termitesID.itemName',
  },
  'mediaItems.gateID': {
    heroImage: 'https://m.mezo.io/example_assets/gateID/electronic-opener 1.png',
    images: ['https://m.mezo.io/example_assets/gateID/opener.png'],
    i18nKeyAssetCopy: 'mediaItems.gateID.copy',
    i18nKeyItemName: 'mediaItems.gateID.itemName',
  },
  'mediaItems.gutterID': {
    heroImage: 'https://m.mezo.io/example_assets/gutterID/gutters-1.png',
    images: ['https://m.mezo.io/example_assets/gutterID/gutters-2.png'],
    i18nKeyAssetCopy: 'mediaItems.gutterID.copy',
    i18nKeyItemName: 'mediaItems.gutterID.itemName',
  },
  'mediaItems.microwaveID': {
    heroImage: 'https://m.mezo.io/example_assets/microwaveID/microwave-3.png',
    images: [
      'https://m.mezo.io/example_assets/microwaveID/microwave-1.png',
      'https://m.mezo.io/example_assets/microwaveID/microwave-2.jpg',
    ],
    i18nKeyAssetCopy: 'mediaItems.microwaveID.copy',
    i18nKeyItemName: 'mediaItems.microwaveID.itemName',
  },
  'mediaItems.otherID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.otherID.copy',
    i18nKeyItemName: 'mediaItems.otherID.itemName',
  },
  'mediaItems.signageID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.signageID.copy',
    i18nKeyItemName: 'mediaItems.signageID.itemName',
  },
  'mediaItems.cabinetID': {
    heroImage: 'https://m.mezo.io/example_assets/cabinetID/cabinet-1.png',
    images: ['https://m.mezo.io/example_assets/cabinetID/cabinethinge.png'],
    i18nKeyAssetCopy: 'mediaItems.cabinetID.copy',
    i18nKeyItemName: 'mediaItems.cabinetID.itemName',
  },
  'mediaItems.sidewalkID': {
    heroImage: 'https://m.mezo.io/example_assets/sidewalkID/sidewalk.png',
    images: ['https://m.mezo.io/example_assets/sidewalkID/sidewalk2.png'],
    i18nKeyAssetCopy: 'mediaItems.sidewalkID.copy',
    i18nKeyItemName: 'mediaItems.sidewalkID.itemName',
  },
  'mediaItems.waterSoftenerID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.waterSoftenerID.copy',
    i18nKeyItemName: 'mediaItems.waterSoftenerID.itemName',
  },
  'mediaItems.garageDoorID': {
    heroImage: 'https://m.mezo.io/example_assets/garageDoorID/garage-1.png',
    images: [
      'https://m.mezo.io/example_assets/garageDoorID/garage-2.png',
      'https://m.mezo.io/example_assets/garageDoorID/garage-3.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.garageDoorID.copy',
    i18nKeyItemName: 'mediaItems.garageDoorID.itemName',
  },
  'mediaItems.freezerID': {
    heroImage: 'https://m.mezo.io/example_assets/freezerID/fridge-1.jpg',
    images: [
      'https://m.mezo.io/example_assets/freezerID/Freezer-2.png',
      'https://m.mezo.io/example_assets/freezerID/Fridge-Freezer.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.freezerID.copy',
    i18nKeyItemName: 'mediaItems.freezerID.itemName',
  },
  'mediaItems.garbageDisposalID': {
    heroImage: 'https://m.mezo.io/example_assets/garbageDisposalID/IMG_7217 1.png',
    images: ['https://m.mezo.io/example_assets/garbageDisposalID/disposal2.png'],
    i18nKeyAssetCopy: 'mediaItems.garbageDisposalID.copy',
    i18nKeyItemName: 'mediaItems.garbageDisposalID.itemName',
  },
  'mediaItems.poolScreensID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.poolScreensID.copy',
    i18nKeyItemName: 'mediaItems.poolScreensID.itemName',
  },
  'mediaItems.toasterID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.toasterID.copy',
    i18nKeyItemName: 'mediaItems.toasterID.itemName',
  },
  'mediaItems.furnitureID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.furnitureID.copy',
    i18nKeyItemName: 'mediaItems.furnitureID.itemName',
  },
  'mediaItems.fireplaceID': {
    heroImage: 'https://m.mezo.io/example_assets/fireplaceID/fireplace.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.fireplaceID.copy',
    i18nKeyItemName: 'mediaItems.fireplaceID.itemName',
  },
  'mediaItems.vanityID': {
    heroImage: 'https://m.mezo.io/example_assets/vanityID/vanity-1.png',
    images: [
      'https://m.mezo.io/example_assets/vanityID/vanity-2.png',
      'https://m.mezo.io/example_assets/vanityID/vanity-3.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.vanityID.copy',
    i18nKeyItemName: 'mediaItems.vanityID.itemName',
  },
  'mediaItems.interiorBaseboardID': {
    heroImage: 'https://m.mezo.io/example_assets/interiorBaseboardID/baseboard.png',
    images: ['https://m.mezo.io/example_assets/interiorBaseboardID/baseboard2.png'],
    i18nKeyAssetCopy: 'mediaItems.interiorBaseboardID.copy',
    i18nKeyItemName: 'mediaItems.interiorBaseboardID.itemName',
  },
  'mediaItems.exhaustFanID': {
    heroImage: 'https://m.mezo.io/example_assets/exhaustFanID/vents-2.png',
    images: ['https://m.mezo.io/example_assets/exhaustFanID/Range-2.png'],
    i18nKeyAssetCopy: 'mediaItems.exhaustFanID.copy',
    i18nKeyItemName: 'mediaItems.exhaustFanID.itemName',
  },
  'mediaItems.roofID': {
    heroImage: 'https://m.mezo.io/example_assets/roofID/gutters-1.png',
    images: ['https://m.mezo.io/example_assets/roofID/gutters-2.png'],
    i18nKeyAssetCopy: 'mediaItems.roofID.copy',
    i18nKeyItemName: 'mediaItems.roofID.itemName',
  },
  'mediaItems.lockoutID': {
    heroImage: 'https://m.mezo.io/example_assets/lockoutID/LockKeys.png',
    images: ['https://m.mezo.io/example_assets/lockoutID/key-1.png'],
    i18nKeyAssetCopy: 'mediaItems.lockoutID.copy',
    i18nKeyItemName: 'mediaItems.lockoutID.itemName',
  },
  'mediaItems.windowID': {
    heroImage: 'https://m.mezo.io/example_assets/windowID/windows-3.png',
    images: [
      'https://m.mezo.io/example_assets/windowID/windows-1.png',
      'https://m.mezo.io/example_assets/windowID/windowlatch.png',
      'https://m.mezo.io/example_assets/windowID/windows-4.png',
      'https://m.mezo.io/example_assets/windowID/windows-6.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.windowID.copy',
    i18nKeyItemName: 'mediaItems.windowID.itemName',
  },
  'mediaItems.hubID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.hubID.copy',
    i18nKeyItemName: 'mediaItems.hubID.itemName',
  },
  'mediaItems.washingMachineID': {
    heroImage: 'https://m.mezo.io/example_assets/washingMachineID/washer-1.png',
    images: [
      'https://m.mezo.io/example_assets/washingMachineID/washer-2.png',
      'https://m.mezo.io/example_assets/washingMachineID/washer-3.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.washingMachineID.copy',
    i18nKeyItemName: 'mediaItems.washingMachineID.itemName',
  },
  'mediaItems.switchID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.switchID.copy',
    i18nKeyItemName: 'mediaItems.switchID.itemName',
  },
  'mediaItems.elevatorID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.elevatorID.copy',
    i18nKeyItemName: 'mediaItems.elevatorID.itemName',
  },
  'mediaItems.ceilingFanID': {
    heroImage: 'https://m.mezo.io/example_assets/ceilingFanID/ceiling-fan-1.png',
    images: [
      'https://m.mezo.io/example_assets/ceilingFanID/ceiling-fan-2.jpg',
      'https://m.mezo.io/example_assets/ceilingFanID/ceiling-fan-3.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.ceilingFanID.copy',
    i18nKeyItemName: 'mediaItems.ceilingFanID.itemName',
  },
  'mediaItems.patioID': {
    heroImage: 'https://m.mezo.io/example_assets/patioID/patio.png',
    images: [
      'https://m.mezo.io/example_assets/patioID/sidewalk2.png',
      'https://m.mezo.io/example_assets/patioID/porch-1.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.patioID.copy',
    i18nKeyItemName: 'mediaItems.patioID.itemName',
  },
  'mediaItems.landscapingID': {
    heroImage: 'https://m.mezo.io/example_assets/landscapingID/landscaping-1.jpg',
    images: [
      'https://m.mezo.io/example_assets/landscapingID/landscaping-2.png',
      'https://m.mezo.io/example_assets/landscapingID/landscaping-3.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.landscapingID.copy',
    i18nKeyItemName: 'mediaItems.landscapingID.itemName',
  },
  'mediaItems.rentID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.rentID.copy',
    i18nKeyItemName: 'mediaItems.rentID.itemName',
  },
  'mediaItems.outdoorGrillID': {
    heroImage: 'https://m.mezo.io/example_assets/outdoorGrillID/grill-1.png',
    images: ['https://m.mezo.io/example_assets/outdoorGrillID/Grill-2.png'],
    i18nKeyAssetCopy: 'mediaItems.outdoorGrillID.copy',
    i18nKeyItemName: 'mediaItems.outdoorGrillID.itemName',
  },
  'mediaItems.gasLinesID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.gasLinesID.copy',
    i18nKeyItemName: 'mediaItems.gasLinesID.itemName',
  },
  'mediaItems.moveInID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.moveInID.copy',
    i18nKeyItemName: 'mediaItems.moveInID.itemName',
  },
  'mediaItems.ventHoodID': {
    heroImage: 'https://m.mezo.io/example_assets/ventHoodID/Hood-3.png',
    images: ['https://m.mezo.io/example_assets/ventHoodID/Range-2.png'],
    i18nKeyAssetCopy: 'mediaItems.ventHoodID.copy',
    i18nKeyItemName: 'mediaItems.ventHoodID.itemName',
  },
  'mediaItems.bedBugsID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.bedBugsID.copy',
    i18nKeyItemName: 'mediaItems.bedBugsID.itemName',
  },
  'mediaItems.staffID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.staffID.copy',
    i18nKeyItemName: 'mediaItems.staffID.itemName',
  },
  'mediaItems.ventsOrDuctsID': {
    heroImage: 'https://m.mezo.io/example_assets/ventsOrDuctsID/29.png',
    images: ['https://m.mezo.io/example_assets/ventsOrDuctsID/vents-2.png'],
    i18nKeyAssetCopy: 'mediaItems.ventsOrDuctsID.copy',
    i18nKeyItemName: 'mediaItems.ventsOrDuctsID.itemName',
  },
  'mediaItems.backflowTestID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.backflowTestID.copy',
    i18nKeyItemName: 'mediaItems.backflowTestID.itemName',
  },
  'mediaItems.solarPanelsID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.solarPanelsID.copy',
    i18nKeyItemName: 'mediaItems.solarPanelsID.itemName',
  },
  'mediaItems.trashID': {
    heroImage: 'https://m.mezo.io/example_assets/trashID/trash-1.png',
    images: [
      'https://m.mezo.io/example_assets/trashID/trash-2.png',
      'https://m.mezo.io/example_assets/trashID/trash-1-907b599e-97d2-4632-941c-a22ea2d9cbe0.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.trashID.copy',
    i18nKeyItemName: 'mediaItems.trashID.itemName',
  },
  'mediaItems.towelRackID': {
    heroImage: 'https://m.mezo.io/example_assets/towelRackID/vanity-1.png',
    images: ['https://m.mezo.io/example_assets/towelRackID/vanity.png'],
    i18nKeyAssetCopy: 'mediaItems.towelRackID.copy',
    i18nKeyItemName: 'mediaItems.towelRackID.itemName',
  },
  'mediaItems.evictionID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.evictionID.copy',
    i18nKeyItemName: 'mediaItems.evictionID.itemName',
  },
  'mediaItems.doorID': {
    heroImage: 'https://m.mezo.io/example_assets/doorID/porch-2.png',
    images: [
      'https://m.mezo.io/example_assets/doorID/door-1.png',
      'https://m.mezo.io/example_assets/doorID/LockKeys.png',
      'https://m.mezo.io/example_assets/doorID/Lock.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.doorID.copy',
    i18nKeyItemName: 'mediaItems.doorID.itemName',
  },
  'mediaItems.toasterOvenID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.toasterOvenID.copy',
    i18nKeyItemName: 'mediaItems.toasterOvenID.itemName',
  },
  'mediaItems.trashCompactorID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.trashCompactorID.copy',
    i18nKeyItemName: 'mediaItems.trashCompactorID.itemName',
  },
  'mediaItems.phoneAndCableID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.phoneAndCableID.copy',
    i18nKeyItemName: 'mediaItems.phoneAndCableID.itemName',
  },
  'mediaItems.rodentsOrBirdsID': {
    heroImage: 'https://m.mezo.io/example_assets/rodentsOrBirdsID/pest-2.png',
    images: ['https://m.mezo.io/example_assets/rodentsOrBirdsID/birdnest.png'],
    i18nKeyAssetCopy: 'mediaItems.rodentsOrBirdsID.copy',
    i18nKeyItemName: 'mediaItems.rodentsOrBirdsID.itemName',
  },
  'mediaItems.drawerID': {
    heroImage: 'https://m.mezo.io/example_assets/drawerID/drawer-1.png',
    images: ['https://m.mezo.io/example_assets/drawerID/Drawer-2.png'],
    i18nKeyAssetCopy: 'mediaItems.drawerID.copy',
    i18nKeyItemName: 'mediaItems.drawerID.itemName',
  },
  'mediaItems.powerID': {
    heroImage: 'https://m.mezo.io/example_assets/powerID/electrical.png',
    images: ['https://m.mezo.io/example_assets/powerID/outlet-1.png'],
    i18nKeyAssetCopy: 'mediaItems.powerID.copy',
    i18nKeyItemName: 'mediaItems.powerID.itemName',
  },
  'mediaItems.unknownSourceID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.unknownSourceID.copy',
    i18nKeyItemName: 'mediaItems.unknownSourceID.itemName',
  },
  'mediaItems.refrigeratorID': {
    heroImage: 'https://m.mezo.io/example_assets/refrigeratorID/fridge-1.jpg',
    images: ['https://m.mezo.io/example_assets/refrigeratorID/Fridge-Freezer.png'],
    i18nKeyAssetCopy: 'mediaItems.refrigeratorID.copy',
    i18nKeyItemName: 'mediaItems.refrigeratorID.itemName',
  },
  'mediaItems.storageLockerID': {
    heroImage: 'https://m.mezo.io/example_assets/storageLockerID/Storage.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.storageLockerID.copy',
    i18nKeyItemName: 'mediaItems.storageLockerID.itemName',
  },
  'mediaItems.buildingExteriorID': {
    heroImage: 'https://m.mezo.io/example_assets/buildingExteriorID/exterior-1.png',
    images: [
      'https://m.mezo.io/example_assets/buildingExteriorID/exterior-2.png',
      'https://m.mezo.io/example_assets/buildingExteriorID/exterior-3.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.buildingExteriorID.copy',
    i18nKeyItemName: 'mediaItems.buildingExteriorID.itemName',
  },
  'mediaItems.sinkID': {
    heroImage: 'https://m.mezo.io/example_assets/sinkID/Faucet.png',
    images: [
      'https://m.mezo.io/example_assets/sinkID/sink-3.png',
      'https://m.mezo.io/example_assets/sinkID/sink-1.png',
      'https://m.mezo.io/example_assets/sinkID/sink-2.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.sinkID.copy',
    i18nKeyItemName: 'mediaItems.sinkID.itemName',
  },
  'mediaItems.mailboxID': {
    heroImage: 'https://m.mezo.io/example_assets/mailboxID/maiilbox-1.png',
    images: ['https://m.mezo.io/example_assets/mailboxID/mailbox-2.png'],
    i18nKeyAssetCopy: 'mediaItems.mailboxID.copy',
    i18nKeyItemName: 'mediaItems.mailboxID.itemName',
  },
  'mediaItems.outletID': {
    heroImage: 'https://m.mezo.io/example_assets/outletID/outlet-1.png',
    images: ['https://m.mezo.io/example_assets/outletID/outlet2.png'],
    i18nKeyAssetCopy: 'mediaItems.outletID.copy',
    i18nKeyItemName: 'mediaItems.outletID.itemName',
  },
  'mediaItems.fleasID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.fleasID.copy',
    i18nKeyItemName: 'mediaItems.fleasID.itemName',
  },
  'mediaItems.recurringMaintenanceID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.recurringMaintenanceID.copy',
    i18nKeyItemName: 'mediaItems.recurringMaintenanceID.itemName',
  },
  'mediaItems.heatingSystemID': {
    heroImage: 'https://m.mezo.io/example_assets/heatingSystemID/heating-1.png',
    images: ['https://m.mezo.io/example_assets/heatingSystemID/thermostat3.png'],
    i18nKeyAssetCopy: 'mediaItems.heatingSystemID.copy',
    i18nKeyItemName: 'mediaItems.heatingSystemID.itemName',
  },
  'mediaItems.cooktopID': {
    heroImage: 'https://m.mezo.io/example_assets/cooktopID/cooktop-1.jpg',
    images: ['https://m.mezo.io/example_assets/cooktopID/cooktop-2.jpg'],
    i18nKeyAssetCopy: 'mediaItems.cooktopID.copy',
    i18nKeyItemName: 'mediaItems.cooktopID.itemName',
  },
  'mediaItems.waterIntrusionID': {
    heroImage: 'https://m.mezo.io/example_assets/waterIntrusionID/water-intrusion-1.png',
    images: [
      'https://m.mezo.io/example_assets/waterIntrusionID/water-damage-3.png',
      'https://m.mezo.io/example_assets/waterIntrusionID/Water-damage.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.waterIntrusionID.copy',
    i18nKeyItemName: 'mediaItems.waterIntrusionID.itemName',
  },
  'mediaItems.pestControlID': {
    heroImage: 'https://m.mezo.io/example_assets/pestControlID/pest-2.png',
    images: ['https://m.mezo.io/example_assets/pestControlID/pests-1.png'],
    i18nKeyAssetCopy: 'mediaItems.pestControlID.copy',
    i18nKeyItemName: 'mediaItems.pestControlID.itemName',
  },
  'mediaItems.waspsOrBeesID': {
    heroImage: 'https://m.mezo.io/example_assets/waspsOrBeesID/insects-1.png',
    images: ['https://m.mezo.io/example_assets/waspsOrBeesID/insects-2.png'],
    i18nKeyAssetCopy: 'mediaItems.waspsOrBeesID.copy',
    i18nKeyItemName: 'mediaItems.waspsOrBeesID.itemName',
  },
  'mediaItems.miscElectricalID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.miscElectricalID.copy',
    i18nKeyItemName: 'mediaItems.miscElectricalID.itemName',
  },
  'mediaItems.garageID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.garageID.copy',
    i18nKeyItemName: 'mediaItems.garageID.itemName',
  },
  'mediaItems.coDetectorID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.coDetectorID.copy',
    i18nKeyItemName: 'mediaItems.coDetectorID.itemName',
  },
  'mediaItems.utilitiesID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.utilitiesID.copy',
    i18nKeyItemName: 'mediaItems.utilitiesID.itemName',
  },
  'mediaItems.sumpPumpID': {
    heroImage: 'https://m.mezo.io/example_assets/sumpPumpID/water-intrusion-1.png',
    images: ['https://m.mezo.io/example_assets/sumpPumpID/sumppump2.png'],
    i18nKeyAssetCopy: 'mediaItems.sumpPumpID.copy',
    i18nKeyItemName: 'mediaItems.sumpPumpID.itemName',
  },
  'mediaItems.swampCoolerID': {
    heroImage: 'https://m.mezo.io/example_assets/example-1.png',
    images: ['https://m.mezo.io/example_assets/example-2.gif', 'https://m.mezo.io/example_assets/example-3.png'],
    i18nKeyAssetCopy: 'mediaItems.swampCoolerID.copy',
    i18nKeyItemName: 'mediaItems.swampCoolerID.itemName',
  },
  'mediaItems.faucetID': {
    heroImage: 'https://m.mezo.io/example_assets/faucetID/shutterstock_2136182015.jpg',
    images: ['https://m.mezo.io/example_assets/faucetID/Faucet.png'],
    i18nKeyAssetCopy: 'mediaItems.faucetID.copy',
    i18nKeyItemName: 'mediaItems.faucetID.itemName',
  },
  'mediaItems.shelvingID': {
    heroImage: 'https://m.mezo.io/example_assets/shelvingID/shelving.png',
    images: ['https://m.mezo.io/example_assets/shelvingID/686x466-shelving.jpg'],
    i18nKeyAssetCopy: 'mediaItems.shelvingID.copy',
    i18nKeyItemName: 'mediaItems.shelvingID.itemName',
  },
  'mediaItems.fenceID': {
    heroImage: 'https://m.mezo.io/example_assets/fenceID/gate-1.png',
    images: [
      'https://m.mezo.io/example_assets/fenceID/Fence.png',
      'https://m.mezo.io/example_assets/fenceID/Fence-2.png',
    ],
    i18nKeyAssetCopy: 'mediaItems.fenceID.copy',
    i18nKeyItemName: 'mediaItems.fenceID.itemName',
  },
  'mediaItems.poolID': {
    heroImage: 'https://m.mezo.io/example_assets/poolID/pool-1.png',
    images: ['https://m.mezo.io/example_assets/poolID/Pool.png'],
    i18nKeyAssetCopy: 'mediaItems.poolID.copy',
    i18nKeyItemName: 'mediaItems.poolID.itemName',
  },
};

export default exampleMediaMap;
