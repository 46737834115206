import { useState } from 'react';

/**
 *
 * @param func - the function to throttle
 * @param delay - the delay in millis
 * @param isThrottling - the shared state of the throttle. use this if you have different functions you'd like to throttle together
 * @param setIsThrottling - the setter for the shared state of the throttle
 * @returns
 */
export const useThrottle = (
  func: () => void,
  delay = 500,
  isThrottling?: boolean,
  setIsThrottling?: (isThrottling: boolean) => void,
) => {
  const [throttle, setThrottle] = useState<boolean>(false);

  const isThrottlingState = isThrottling ?? throttle;
  const setIsThrottlingState = setIsThrottling ?? setThrottle;

  return () => {
    if (!isThrottlingState) {
      setIsThrottlingState(true);
      func();
      const timeoutId = setTimeout(() => {
        setIsThrottlingState(false);
      }, delay);
      return () => {
        clearTimeout(timeoutId);
      };
    }
    return;
  };
};
