import { ComponentId, ItemId, LocationId, SymptomId } from '@mezo/shared/common';
import { UnitDto } from '../customer-integrations';

export type DiagnosisId = string;
export enum ResolutionType {
  DIY_SUCCESS = 'diySuccess',
}

export interface ChatInitState {
  unitId?: UnitDto['id'];
  itemId: ItemId;
  symptomId?: SymptomId;
  componentId?: ComponentId;
  locationId?: LocationId;
  platformUrl: string;
  modelEventUrl: string;
  platformApiKey: string;
  modelEventApiKey: string;
  attemptId?: string;
  sessionId?: string;
  typeId?: string;
  typeLabel?: string;
  isAccessible?: string;
}

export interface ChatExitState {
  resolutionType: ResolutionType;
  diagnosisId: DiagnosisId;
  itemId: ItemId;
  symptomId?: SymptomId;
  componentId?: ComponentId;
  locationId?: LocationId;
}
