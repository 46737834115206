import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrandingConfiguration } from '@mezo/web/hooks';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { MEZO_LOGO_URL } from '../../types/constants';
import { CustomerLogo } from '../customer-logo';

export const HEADER_HEIGHT = 56;

const FlexContainer = tw.div`relative z-10 flex items-center gap-4 px-4 py-2 shadow-lg h-14`;
const StyledLogo = tw(CustomerLogo)`w-10 h-10 border-2`;

const HeaderText = tw.h2`items-center justify-center flex-1 m-0 text-sm tracking-wide text-center`;
const RightPadding = tw.div`w-6`;
const BackButtonContainer = tw.div`flex items-center justify-center w-10 h-10`;
const BackButton = tw.button`bg-transparent border-none text-primary`;

interface Props {
  config: Pick<BrandingConfiguration, 'logo' | 'name' | 'hideChatHeader'>;
  onBackClicked?: () => void;
  headerTitle?: string;
}

export const ChatHeader: React.FC<Props> = ({ config, onBackClicked, headerTitle }) => {
  const { t } = useTranslation();
  const { logo, name, hideChatHeader = false } = config;

  return !hideChatHeader ? (
    <FlexContainer>
      {onBackClicked ? (
        <BackButtonContainer>
          <BackButton onClick={onBackClicked} aria-label={'Back'}>
            <FontAwesomeIcon icon={faArrowLeft} size="xl" />
          </BackButton>
        </BackButtonContainer>
      ) : (
        <StyledLogo src={logo ?? MEZO_LOGO_URL} alt={`${name || 'Mezo'} Logo`} />
      )}
      <HeaderText>{!headerTitle ? t('chat.residentHeader', { customerName: name || 'Mezo' }) : headerTitle}</HeaderText>
      <RightPadding />
    </FlexContainer>
  ) : null;
};

export default ChatHeader;
