import { OperationConfigDto } from '@mezo/common/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useQuery } from '@tanstack/react-query';

export const getBrandingConfigForCustomerForChat = async (customerId: string) => {
  const response = await ApiClient.CHAT_API.utility.get(`/customers/${customerId}/configuration/branding`);
  return response?.data;
};

export const getBrandingConfigForUnitForChat = async (unitId: string) => {
  const response = await ApiClient.CHAT_API.utility.get(`/unit/${unitId}/config/branding`);
  return response?.data;
};

export const getBrandingConfigurationForChat = async (configurationId: string) => {
  const response = await ApiClient.CHAT_API.utility.get(`/configuration/${configurationId}/branding`);
  return response?.data;
};

export const usePlatformConfigForChat = () =>
  useQuery({
    queryKey: ['platformOperationConfig'],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get<OperationConfigDto>(`/configuration/operation`);
      return response?.data;
    },
    enabled: true,
  });
