import { faCheck, faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import tw from 'twin.macro';

const IconButton = tw.button`mr-1`;
const StarsContainer = tw.div``;
const StarsRatingText = tw.div`flex flex-row h-5 mt-2 text-sm text-disabled`;

const StyledIcon = styled(FontAwesomeIcon)(() => [tw`mr-1 fill-current text-primary`]);
const StyledCheckIcon = styled(FontAwesomeIcon)(() => [tw`fill-current text-primary`]);
const StyledCheckIconWrapper = tw.div`ml-2`;

const NO_STARS = 0;
const STAR_POSITIONS = [1, 2, 3, 4, 5];

type StarRatingProps = {
  rating?: number;
  showRatingLabelCheck: boolean;
  isEditable: boolean;
  onRatingClicked?: (rating: number) => void;
};

export const StarRating: React.FC<StarRatingProps> = ({
  rating,
  onRatingClicked,
  showRatingLabelCheck,
  isEditable,
}) => {
  const [hoverRating, setHoverRating] = useState<number>(NO_STARS);
  const { t } = useTranslation();

  const textForRating = useCallback(
    (rating: number) => {
      if (rating === 0) {
        return;
      }

      return t(`chat.feedback.ratings.${rating}star`);
    },
    [t]
  );

  const onHover = useCallback((stars: number) => {
    setHoverRating(stars);
  }, []);

  const currentRating = rating || hoverRating;
  const ratingsText = textForRating(currentRating) || '';

  return (
    <>
      <StarsContainer>
        {STAR_POSITIONS.map((position) => (
          <IconButton
            key={position}
            disabled={!isEditable}
            aria-label={textForRating(position)}
            onClick={() => onRatingClicked?.(position)}
            onMouseEnter={() => onHover(position)}
            onMouseLeave={() => onHover(NO_STARS)}
          >
            <StyledIcon icon={position <= currentRating ? faSolidStar : faStar} size="2xl" />
          </IconButton>
        ))}
      </StarsContainer>
      <StarsRatingText>
        {ratingsText}
        {showRatingLabelCheck && (
          <StyledCheckIconWrapper>
            <StyledCheckIcon icon={faCheck} size="sm" />
          </StyledCheckIconWrapper>
        )}
      </StarsRatingText>
    </>
  );
};
