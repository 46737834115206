import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceRequestSearchResultResponseDto } from '@deprecated/dtos';

const initialState: SliceState = {
  isLoading: false,
  error: false,
  serviceRequests: [],
  nextPage: null,
};

type SliceState = {
  isLoading: boolean;
  error: boolean;
  serviceRequests: ServiceRequestSearchResultResponseDto[];
  nextPage: number | null;
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    dashboardApiCallStart: (state) => {
      state.isLoading = true;
    },
    dashboardApiCallSuccess: (state) => {
      state.isLoading = false;
    },
    dashboardApiCallError: (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.error = true;
    },
    updateServiceRequests: (
      state,
      action: PayloadAction<{
        serviceRequests: ServiceRequestSearchResultResponseDto[];
        nextPage: number | null;
      }>
    ) => {
      state.isLoading = false;
      state.serviceRequests = action.payload.serviceRequests;
      state.nextPage = action.payload.nextPage;
    },
  },
});

export default dashboardSlice;
