import { Locale } from '@mezo/common/utils';
import { isAfter, isSameDay } from 'date-fns';
import { format, formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { enUS, es } from 'date-fns/locale';

export enum DATE_STRINGS {
  dayWithMonth = 'MMMM do, yyyy', // August 1st, 2023
  dayWithMonthNoOrdinal = 'MMMM d, yyyy', // August 1, 2023
  dateTimeWithMonth = 'EEEE, LLLL d, yyyy h:mmaa', // Tuesday, August 1, 2023 8:00AM
  dateTimeWithMonthAndTimezone = 'EEEE, LLLL d, yyyy h:mmaa z', // Tuesday, August 1, 2023 8:00AM
  dateWthDayOfWeek = 'EEE, MMM d, h:mmaa', // Mon, Jul 31, 1:15PM
  timeWithTimezone = 'h:mmaa z', // 12:00PM CDT
  time = 'haaa', // 8am
}

// NOTE: This helper function is based on JS date from stubbed WO data. Once real WO data is being returned, we will need to revisit this function to adjust for WO timestamp formatting
export const formatJSDate = (date: Date): string => {
  if (date) {
    return format(date, DATE_STRINGS.dateWthDayOfWeek);
  } else {
    return 'Invalid Date';
  }
};
/**
 *
 * @param start '2023-03-08T11:00:00Z'
 * @param end '2023-03-08T11:00:00Z'
 * @param timeZoneId IANA - 'America/Chicago'
 * @returns
 */
export const formatInterval = (start: string, end: string, timeZoneId = 'Etc/GMT'): string | undefined => {
  if (timeZoneId === '') {
    timeZoneId = 'Etc/GMT';
  }
  const startAsDateUTC = new Date(Date.parse(start));
  const endAsDateUTC = new Date(Date.parse(end));

  if (isAfter(startAsDateUTC, endAsDateUTC)) {
    return;
  }

  if (isSameDay(startAsDateUTC, endAsDateUTC)) {
    return `${formatInTimeZone(startAsDateUTC, timeZoneId, DATE_STRINGS.dateWthDayOfWeek)} - ${formatInTimeZone(
      endAsDateUTC,
      timeZoneId,
      DATE_STRINGS.timeWithTimezone
    )}`;
  }
  return `${formatInTimeZone(startAsDateUTC, timeZoneId, DATE_STRINGS.dateWthDayOfWeek)} - ${formatInTimeZone(
    endAsDateUTC,
    timeZoneId,
    DATE_STRINGS.dateWthDayOfWeek
  )}`;
};

export const formatDateToTimezone = (
  date: Date,
  timeZoneId = 'Etc/GMT',
  locale = Locale.EN_US as string,
  formatString = DATE_STRINGS.dayWithMonth
): string => {
  return format(date, formatString, {
    timeZone: timeZoneId,
    locale: getLocale(locale),
  });
};

export const minutesToMillis = (minutes: number) => minutes * 60 * 1000;

export const formatAvailabilityInterval = (
  start: string | number,
  end: string | number,
  timeZoneId = 'Etc/GMT',
  locale = Locale.EN_US as string
) => {
  const startAsDate = utcToZonedTime(new Date(start), timeZoneId);
  const endAsDate = utcToZonedTime(new Date(end), timeZoneId);
  return `${format(startAsDate, DATE_STRINGS.time, { timeZone: timeZoneId, locale: getLocale(locale) })} - ${format(
    endAsDate,
    DATE_STRINGS.time,
    {
      timeZone: timeZoneId,
      locale: getLocale(locale),
    }
  )}`;
};

export const getLocale = (locale: string) => (new Intl.Locale(locale).language.includes('es') ? es : enUS);
