import { Type } from 'class-transformer';
import { ValidateNested } from 'class-validator';
import { ItemResponseDto } from './item-response.dto';
import { TaxonomyEntityDto } from './taxonomy-entity-response.dto';

export class TaxonomyResponseDto {
  @ValidateNested()
  @Type(() => ItemResponseDto)
  items: ItemResponseDto[];
  @ValidateNested()
  @Type(() => TaxonomyEntityDto)
  components: TaxonomyEntityDto[];
  @ValidateNested()
  @Type(() => TaxonomyEntityDto)
  locations: TaxonomyEntityDto[];
  @ValidateNested()
  @Type(() => TaxonomyEntityDto)
  symptoms: TaxonomyEntityDto[];
}
