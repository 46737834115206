import { t } from 'i18next';
import { useState } from 'react';
import Select, { SingleValue } from 'react-select';
import styled from 'styled-components';
import tw, { theme } from 'twin.macro';
import { ButtonWithSpinner } from '../../components/button.v2';
import { CheckboxWithText } from '../../components/checkbox-with-text';
import { OptionType } from '../../components/styled-select';
import { countries, states } from './states';

export type UnitAddress = {
  address1: string;
  address2: string;
  city: string;
  country: OptionType;
  state: OptionType;
  zipCode: string;
};

export type FormErrors = {
  address1: boolean;
  city: boolean;
  state: boolean;
  zipCode: boolean;
  firstName: boolean;
  lastName: boolean;
  emailAddress: boolean;
  confirmEmailAddress: boolean;
};

export type ContactInfo = {
  firstName: string;
  lastName: string;
  emailAddress: string;
  confirmEmailAddress: string;
};

const CANADA = 'CA';

const selectStyles = {
  borderRadius: '0.5rem',
  height: '3.125rem',
};

const ErrorMessage = tw.span`text-sm text-error`;
const CenterErrorMessage = tw(ErrorMessage)`text-center`;

const FlexLabel = tw.label`flex flex-col gap-2`;
const FlexWrapper = tw.div`flex flex-col gap-4`;
const InputRow = tw.div`flex flex-row gap-4`;
const InputRowLabel = tw(FlexLabel)`flex-grow-2 basis-0`;
const Container = tw(FlexWrapper)`gap-8 p-4 bg-background-light`;

const Input = styled.input<{ hasError: boolean }>(({ hasError }) => [
  tw`w-full p-3 border rounded-lg border-text-grey`,
  hasError && tw`border-error`,
]);

const LabelText = tw.span``;
const Subtitle = tw.span`text-sm`;

const Title = tw.h4`text-2xl font-medium`;

type GuestResidentAuthFormProps = {
  address1: string;
  address2: string;
  city: string;
  country: OptionType;
  state: OptionType;
  zipCode: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  confirmEmailAddress: string;
  errors: FormErrors;
  onChangeSelect: (fieldName: keyof UnitAddress, value: SingleValue<OptionType>) => void;
  onChangeAddressField: (fieldName: keyof UnitAddress, value: string) => void;
  onChangeContactField: (fieldName: keyof ContactInfo, value: string) => void;
  onBlurField: (fieldName: keyof UnitAddress | keyof ContactInfo) => void;
  onSubmit: VoidFunction;
  submissionError: boolean;
  isLoading: boolean;
};

export const GuestResidentAuthForm = ({
  address1,
  address2,
  city,
  country,
  state,
  zipCode,
  firstName,
  lastName,
  emailAddress,
  confirmEmailAddress,
  errors,
  onChangeSelect,
  onChangeAddressField,
  onChangeContactField,
  onBlurField,
  onSubmit,
  submissionError,
  isLoading,
}: GuestResidentAuthFormProps) => {
  const [acknowledgement, setAcknowledgement] = useState(false);

  const isCanada = country?.value === CANADA;

  const createErrorMessage = (missingField: string) => {
    return <ErrorMessage>{t('guestResidentAuth.fieldIsRequired', { missingField })}</ErrorMessage>;
  };

  return (
    <Container>
      <FlexWrapper>
        <FlexLabel>
          <Title>{t('guestResidentAuth.enterYourAddress')}</Title>
          <Subtitle>{t('guestResidentAuth.enterLeaseAddress')}</Subtitle>
        </FlexLabel>
        <FlexLabel>
          <LabelText>{t('guestResidentAuth.country')}</LabelText>
          <Select
            aria-labelledby="country"
            id="country"
            data-hj-allow
            inputId="country"
            isLoading={false}
            onChange={(newValue) => onChangeSelect('country', newValue)}
            options={countries}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                ...selectStyles,
              }),
            }}
            value={country}
            onBlur={() => onBlurField('country')}
          />
        </FlexLabel>
        <FlexLabel>
          <LabelText>{t('guestResidentAuth.address1')}*</LabelText>
          <Input
            data-hj-allow
            hasError={errors.address1}
            onChange={(e) => onChangeAddressField('address1', e.target.value)}
            value={address1}
            onBlur={() => onBlurField('address1')}
          />
          {errors.address1 && createErrorMessage(t('guestResidentAuth.address1'))}
        </FlexLabel>
        <FlexLabel>
          <LabelText>{t('guestResidentAuth.address2')}</LabelText>
          <Input
            data-hj-allow
            hasError={false}
            onChange={(e) => onChangeAddressField('address2', e.target.value)}
            value={address2}
          />
        </FlexLabel>
        <FlexLabel>
          <LabelText>{t('guestResidentAuth.city')}*</LabelText>
          <Input
            data-hj-allow
            hasError={errors.city}
            onChange={(e) => onChangeAddressField('city', e.target.value)}
            value={city}
            onBlur={() => onBlurField('city')}
          />
          {errors.city && createErrorMessage(t('guestResidentAuth.city'))}
        </FlexLabel>
        <FlexLabel>
          <InputRow>
            <InputRowLabel htmlFor="state">
              <LabelText>{isCanada ? t('guestResidentAuth.province') : t('guestResidentAuth.state')}*</LabelText>
              <Select
                inputId="state"
                data-hj-allow
                isLoading={false}
                onChange={(newValue) => onChangeSelect('state', newValue)}
                options={isCanada ? states.CA : states.US}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    ...selectStyles,
                    borderColor: errors.state && !state.value ? theme`colors.error` : theme`colors.text-grey`,
                  }),
                }}
                value={state}
                onBlur={() => onBlurField('state')}
              />
              {errors.state &&
                createErrorMessage(isCanada ? t('guestResidentAuth.province') : t('guestResidentAuth.state'))}
            </InputRowLabel>
            <InputRowLabel>
              <LabelText>{isCanada ? t('guestResidentAuth.postalCode') : t('guestResidentAuth.zipCode')}*</LabelText>
              <Input
                data-hj-allow
                hasError={errors.zipCode}
                value={zipCode}
                onChange={(e) => onChangeAddressField('zipCode', e.target.value)}
                onBlur={() => onBlurField('zipCode')}
              />
              {errors.zipCode &&
                createErrorMessage(isCanada ? t('guestResidentAuth.postalCode') : t('guestResidentAuth.zipCode'))}
            </InputRowLabel>
          </InputRow>
        </FlexLabel>
      </FlexWrapper>
      <FlexWrapper>
        <FlexLabel>
          <Title>{t('guestResidentAuth.yourContactInfo')}</Title>
          <Subtitle>{t('guestResidentAuth.followUp')}</Subtitle>
        </FlexLabel>
        <InputRow>
          <InputRowLabel>
            <LabelText>{t('guestResidentAuth.firstName')}*</LabelText>
            <Input
              data-hj-allow
              hasError={errors.firstName}
              onChange={(e) => onChangeContactField('firstName', e.target.value)}
              value={firstName}
              onBlur={() => onBlurField('firstName')}
            />
            {errors.firstName && createErrorMessage(t('guestResidentAuth.firstName'))}
          </InputRowLabel>
          <InputRowLabel>
            <LabelText>{t('guestResidentAuth.lastName')}*</LabelText>
            <Input
              data-hj-allow
              hasError={errors.lastName}
              onChange={(e) => onChangeContactField('lastName', e.target.value)}
              value={lastName}
              onBlur={() => onBlurField('lastName')}
            />
            {errors.lastName && createErrorMessage(t('guestResidentAuth.lastName'))}
          </InputRowLabel>
        </InputRow>
        <FlexLabel>
          <LabelText>{t('guestResidentAuth.yourEmail')}*</LabelText>
          <Input
            data-hj-allow
            hasError={errors.emailAddress}
            onChange={(e) => onChangeContactField('emailAddress', e.target.value)}
            value={emailAddress}
            onBlur={() => onBlurField('emailAddress')}
          />
          {errors.emailAddress && <ErrorMessage>{t('guestResidentAuth.emailInvalid')}</ErrorMessage>}
        </FlexLabel>
        <FlexLabel>
          <LabelText>{t('guestResidentAuth.confirmEmail')}*</LabelText>
          <Input
            data-hj-allow
            hasError={errors.confirmEmailAddress}
            onChange={(e) => onChangeContactField('confirmEmailAddress', e.target.value)}
            onBlur={() => onBlurField('confirmEmailAddress')}
            value={confirmEmailAddress}
          />
          {errors.confirmEmailAddress && <ErrorMessage>{t('guestResidentAuth.emailNoMatch')}</ErrorMessage>}
        </FlexLabel>
        <CheckboxWithText
          data-hj-allow
          onChange={() => setAcknowledgement(!acknowledgement)}
          value={acknowledgement}
          text={t('guestResidentAuth.acknowledgement')}
          name={'acknowledgement'}
        />
        <FlexWrapper>
          {submissionError && <CenterErrorMessage>{t('guestResidentAuth.requiredFieldsMissing')}</CenterErrorMessage>}
          <ButtonWithSpinner
            primary={true}
            disabled={!acknowledgement || isLoading}
            onClick={onSubmit}
            isLoading={isLoading}
          >
            {t('guestResidentAuth.submitButtonText')}
          </ButtonWithSpinner>
        </FlexWrapper>
      </FlexWrapper>
    </Container>
  );
};
