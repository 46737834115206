import { Page, UserSessionStatusType } from '@mezo/common/utils';
import { ChatAgent, EmergencyScreeningType, StepStatus } from '@mezo/shared/common';
import { IsBoolean, IsEnum, IsObject, IsOptional } from 'class-validator';

export interface ServiceRequestAttemptTaxonomyMap {
  itemId: string;
  symptomId: string;
  componentId: string;
  locationId: string;
  diagnosisId: string;
  isNonMaintenance: boolean;
  isAsset: boolean;
  resolutionType: string;
}

export class CreateServiceRequestAttemptDto {
  @IsOptional()
  dialogId?: string;

  @IsOptional()
  taxonomy?: Partial<ServiceRequestAttemptTaxonomyMap>;

  @IsBoolean()
  isEmergency?: boolean;

  @IsOptional()
  @IsEnum(Page)
  page?: Page;

  @IsOptional()
  @IsBoolean()
  howItWorksConfirmation?: boolean;
}

export class UpdateServiceRequestAttemptDto {
  @IsOptional()
  @IsEnum(Page)
  page?: Page;

  @IsOptional()
  dialogId?: string;

  @IsOptional()
  status?: UserSessionStatusType;

  @IsOptional()
  @IsEnum(ChatAgent)
  chatAgent?: ChatAgent;

  @IsOptional()
  serviceRequestId?: string;

  @IsOptional()
  @IsObject()
  taxonomy?: Partial<ServiceRequestAttemptTaxonomyMap>;

  @IsOptional()
  @IsBoolean()
  isEmergency?: boolean;

  @IsOptional()
  emergencyDetails?: {
    screeningDetails?: {
      screeningType: EmergencyScreeningType;
      isDeflected?: boolean;
    };
    emergency: {
      id: string;
      label: string;
      itemId: string;
      symptomId: string;
      is911: boolean;
    };
  };

  @IsOptional()
  additionalDetails?: {
    requestStatus: StepStatus;
    residentInfo: string;
  };

  @IsOptional()
  captureProblem?: {
    requestStatus: StepStatus;
    itemdescription?: string;
    issuedescription?: string;
    locationdescription?: string;
    problemstatement?: string;
  };
  @IsOptional()
  hasAsset?: boolean;
  // These are step based.  If we reached the step, they are required
  @IsOptional()
  media?: {
    requestStatus: StepStatus;
  };
  // scheduling?: SubmitAttemptScheduling;
  // entryNotes?: SubmitAttemptEntryNotes;
  // permissionToEnter?: SubmitAttemptPermissionToEnter;
}
