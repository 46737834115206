import { ServiceRequestSearchResultResponseDto } from '@deprecated/dtos';
import { ApiClient } from '@mezo/web/utils';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select } from 'redux-saga/effects';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { selectDashboardNextPage, selectServiceRequests } from '../selectors';
import dashboardSlice from '../slices/dashboard.slice';

const {
  actions: { dashboardApiCallError, dashboardApiCallStart, dashboardApiCallSuccess, updateServiceRequests },
} = dashboardSlice;

const RESULTS_PER_PAGE = 16;
const ENDPOINTS = {
  getAllServiceRequests: '/service-requests/search',
};

const sagaSlice = createSliceSaga({
  name: 'dashboard',
  sagaType: SagaType.TakeLatest,
  caseSagas: {
    getServiceRequests: {
      *fn(action: PayloadAction<boolean>) {
        yield put(dashboardApiCallStart());
        let params;
        try {
          const refresh = action.payload;
          // if payload is true we refresh the entire list
          const currRequests: ServiceRequestSearchResultResponseDto[] = yield select(selectServiceRequests);
          if (refresh) {
            const numRecords = currRequests.length;
            params = {
              resultsPerPage: numRecords !== 0 ? numRecords + 1 : RESULTS_PER_PAGE, // Magic number since we want the newest service request and however many records we currently had loaded
              nextPage: null,
            };
          } else {
            const pageStart = yield select(selectDashboardNextPage);
            params = {
              resultsPerPage: RESULTS_PER_PAGE,
              nextPage: pageStart,
            };
          }
          const result: any = yield call(ApiClient.NON_CHAT_API.utility.post, ENDPOINTS.getAllServiceRequests, params);
          const serviceRequests: ServiceRequestSearchResultResponseDto[] = result.data.docs;
          const nextPage = result.data?.nextPage; // if there's no nextPage we've reached the end of the list
          if (refresh) {
            yield put(
              updateServiceRequests({
                serviceRequests,
                nextPage,
              })
            );
          } else if (nextPage && serviceRequests.length > 0) {
            yield put(
              updateServiceRequests({
                serviceRequests: [...currRequests, ...serviceRequests],
                nextPage,
              })
            );
          }
          yield put(dashboardApiCallSuccess());
        } catch (err: any) {
          yield put(dashboardApiCallError(err.toString()));
        }
      },
      sagaType: SagaType.TakeLatest,
    },
  },
});

export const dashboardSaga = sagaSlice.saga;
export const { getServiceRequests } = sagaSlice.actions;
