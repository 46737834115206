import { IsString } from 'class-validator';

export class KeyValuePairDto {
  @IsString()
  id: string;
  @IsString()
  label: string;
}

export class KeyValuePairPlusSymptomsDto extends KeyValuePairDto {
  symptoms: { [key: string]: string[] };
}

export function isKeyValuePairPlusSymptoms(
  kvp: KeyValuePairDto | KeyValuePairPlusSymptomsDto
): kvp is KeyValuePairPlusSymptomsDto {
  return kvp && Object.prototype.hasOwnProperty.call(kvp, 'symptoms');
}
