import { TaxonomyEntityOptionDto, TaxonomyItemOptionDto, UserConfirmationDto } from '@mezo/common/dtos';
import { Locale } from '@mezo/common/utils';
import { useSearchItemComponent } from '@mezo/web/queries';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { TypeAheadAsyncDropDown } from '../../components/type-ahead-async-dropdown';
import { TypeAheadDropDown } from '../../components/type-ahead-dropdown';

const UserConfirmationPageContainer = tw.div``;
const Container = tw.div`flex flex-col gap-4 p-4`;
const Header = tw.div`mb-2 font-bold`;
const ProblemStatement = tw.div`p-4 bg-light-grey`;
const Subheader = tw.span`text-left`;
const OrderedList = tw.ol`list-decimal list-inside`;
const ListItem = tw.li`mb-4`;
const DropDownContainer = tw.div`mt-2`;
const DummyComponent = tw.div`h-[17vh] w-full bg-transparent`;

export type UserConfirmationPageProps = {
  problemStatement: string;
  userConfirmationChoices: UserConfirmationDto;
  enableSubmit: (enabled: boolean) => void;
  handleItemChange: (item?: TaxonomyItemOptionDto) => void;
  handleSymptomChange: (symptom?: TaxonomyEntityOptionDto) => void;
  handleLocationChange: (location?: TaxonomyEntityOptionDto) => void;
};

export const SELECT_OTHER_ID = 'selectOtherID';

export const UserConfirmationPage: React.FC<UserConfirmationPageProps> = ({
  problemStatement,
  userConfirmationChoices,
  enableSubmit,
  handleItemChange,
  handleLocationChange,
  handleSymptomChange,
}) => {
  const [item, setItem] = useState<TaxonomyItemOptionDto | undefined>(undefined);
  const [symptom, setSymptom] = useState<TaxonomyEntityOptionDto | undefined>(undefined);
  const [location, setLocation] = useState<TaxonomyEntityOptionDto | undefined>(undefined);
  const { searchItem } = useSearchItemComponent;
  const initSelectOptions = () => {
    const findSelected = (items: (TaxonomyEntityOptionDto | TaxonomyItemOptionDto)[]) =>
      items.find((item) => item?.isSelected);
    const item = findSelected(userConfirmationChoices.items);
    const symptom = findSelected(userConfirmationChoices.symptoms);
    const location = findSelected(userConfirmationChoices.locations);
    console.log('updated taxonomy', item, symptom, location);
    setItem(item as TaxonomyItemOptionDto);
    setSymptom(symptom);
    setLocation(location);
  };

  useEffect(initSelectOptions, [userConfirmationChoices]);

  useEffect(() => {
    const needsLocation = !!item?.needLocation;
    const enableSubmitButton =
      !!item && !!symptom && (!!location || !needsLocation || userConfirmationChoices.locations.length === 0);
    enableSubmit(enableSubmitButton);
  }, [item, symptom, location, enableSubmit, userConfirmationChoices.locations]);

  const updateItemComponent = (item?: TaxonomyItemOptionDto) => {
    setItem(item);
    handleItemChange(item);
  };
  const updateSymptom = (symptom?: TaxonomyEntityOptionDto) => {
    setSymptom(symptom);
    handleSymptomChange(symptom);
  };
  const updateLocation = (location?: TaxonomyEntityOptionDto) => {
    setLocation(location);
    handleLocationChange(location);
  };

  return (
    <UserConfirmationPageContainer>
      <ProblemStatement>{problemStatement}</ProblemStatement>
      <Container>
        <Header>{t('userConfirmation.header')}</Header>

        <OrderedList>
          <ListItem>
            <Subheader>{t('userConfirmation.itemSubheader')}</Subheader>
            <DropDownContainer>
              <TypeAheadAsyncDropDown
                placeholder={t('userConfirmation.placeholder')}
                selected={item}
                createPrefix={t('userConfirmation.add')}
                handleTypeAheadChange={updateItemComponent}
                typeAheadResults={userConfirmationChoices.items}
                asyncSearchFunc={async (inputValue: string) => {
                  return searchItem({
                    text: inputValue,
                    locale: Locale.EN_US,
                    userSessionId: 'userSessionId',
                    submitAttemptId: 'submitAttemptId',
                  });
                }}
              />
            </DropDownContainer>
          </ListItem>
          <ListItem>
            <Subheader>{t('userConfirmation.symptomSubheader')}</Subheader>
            <DropDownContainer>
              <TypeAheadDropDown
                placeholder={t('userConfirmation.placeholder')}
                selected={symptom}
                handleTypeAheadChange={updateSymptom}
                typeAheadResults={userConfirmationChoices.symptoms}
                createPrefix={t('userConfirmation.add')}
              />
            </DropDownContainer>
          </ListItem>

          {userConfirmationChoices.locations.length > 0 && (
            <ListItem>
              <Subheader>{t('userConfirmation.locationSubheader')}</Subheader>
              <DropDownContainer>
                <TypeAheadDropDown
                  placeholder={t('userConfirmation.placeholder')}
                  selected={location}
                  handleTypeAheadChange={updateLocation}
                  typeAheadResults={userConfirmationChoices.locations}
                  createPrefix={t('userConfirmation.add')}
                />
              </DropDownContainer>
            </ListItem>
          )}
          <DummyComponent />
        </OrderedList>
      </Container>
    </UserConfirmationPageContainer>
  );
};
