export enum StepStatus {
  NOT_REQUESTED_CONFIG = 'NOT_REQUESTED_CONFIG',
  NOT_REQUESTED_FLOW = 'NOT_REQUESTED_FLOW',
  USER_COMPLETED = 'USER_COMPLETED',
  USER_SKIPPED = 'USER_SKIPPED',
  REQUESTED = 'REQUESTED',
}

export enum SubmitAttemptStep {
  PROBLEM_CAPTURE = 'problem-capture',
  USER_CONFIRMATION = 'user-confirmation',
  TRANSITION = 'transition',
  INTELLIFLOW = 'intelliflow',
  MEDIA = 'media',
  ASSET = 'asset',
  ADDITIONAL_DETAILS = 'additional-details',
  SCHEDULING = 'scheduling',
  FEEDBACK = 'feedback',
  ENTRY_NOTES = 'entry-notes',
}

export enum EmergencyScreeningType {
  SUBMIT_EMERGENCY = 'SUBMIT_EMERGENCY',
  SUBMIT_EMERGENCY_CTA = 'SUBMIT_EMERGENCY_CTA',
  ESCALATE_EMERGENCY = 'ESCALATE_EMERGENCY',
}

export enum FirstAvailable {
  SAME_DAY_NEXT_AVAILABLE = 'SAME_DAY_NEXT_AVAILABLE',
  NEXT_BUSINESS_DAY = 'NEXT_BUSINESS_DAY',
  TWO_BUSINESS_DAYS_OUT = 'TWO_BUSINESS_DAYS_OUT',
}

export enum ServiceRequestPriority {
  NORMAL = 'NORMAL',
  EMERGENCY = 'EMERGENCY',
}

export enum ConfigurationType {
  integration = 'integration',
  branding = 'branding',
  operation = 'operation',
}

export enum Role {
  Resident = 'resident',
  Admin = 'admin',
  Technician = 'technician',
}

export enum ChatAgent {
  DIALOGFLOW = 'dialogflow',
  VOICEFLOW = 'voiceflow',
}

export type ItemId = string;
export type SymptomId = string;
export type ComponentId = string;
export type LocationId = string;
export type DiagnosisId = string;

export const OTHER_ID = 'otherID';

export const OTHER_ITEM = {
  id: OTHER_ID,
  label: 'Other',
};

export const OTHER_SYMPTOM = {
  id: OTHER_ID,
  label: 'Other',
};
