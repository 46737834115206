import styled from 'styled-components';
import tw from 'twin.macro';

export type TagType =
  | 'default'
  | 'delivered'
  | 'diySuccess'
  | 'emergency'
  | 'nonMaintenance'
  | 'pending'
  | 'processing';

export interface TagProps {
  type?: TagType;
  children?: React.ReactNode;
}

const StyledTag = styled.span<Omit<TagProps, 'content'>>(({ type }) => [
  tw`flex items-center gap-1 px-2 py-1 text-sm text-center rounded-lg bg-background-secondary max-w-max flex-nowrap whitespace-nowrap`,
  type === 'default' && tw`bg-background-secondary text-text-medium-dark`,
  type === 'delivered' && tw`bg-success-light text-success`,
  type === 'diySuccess' && `bg-background-secondary text-text-medium-dark`,
  type === 'emergency' && tw`bg-background-secondary text-text-medium-dark`,
  type === 'nonMaintenance' && tw`bg-background-secondary text-text-medium-dark`,
  type === 'pending' && tw`bg-pending-light text-pending`,
  type === 'processing' && tw`bg-processing-light text-processing`,
]);

export const Tag: React.FC<TagProps> = ({ type = 'default', children }) => {
  return <StyledTag type={type}>{children}</StyledTag>;
};

export default Tag;
