import tw from 'twin.macro';

const LinkContainer = tw.span``;
const LinkText = tw.span`text-base`;
const Link = tw.a`underline cursor-pointer text-primary`;

type LinkWithTextAtBeginningAndEndProps = {
  preText: string;
  postText: string;
  linkText: string;
  onClick: VoidFunction;
};

export const LinkWithTextAtBeginningAndEnd = ({
  preText,
  postText,
  linkText,
  onClick,
}: LinkWithTextAtBeginningAndEndProps) => (
  <LinkContainer>
    <LinkText>
      <span>
        {preText}
        {<Link onClick={onClick}>{linkText}</Link>}
        {postText}
      </span>
    </LinkText>
  </LinkContainer>
);
