import { IssueCode } from '@mezo/common/dtos';
import { i18n } from '@mezo/web/i18n';

export const getIssues = () => {
  return (Object.keys(IssueCode) as Array<keyof typeof IssueCode>).map((enumKeyName) => {
    const translatedString = i18n.t(`supportForm.form.issue.options.${enumKeyName}`);

    return { label: translatedString, value: IssueCode[enumKeyName] };
  });
};
