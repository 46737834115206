import { faAngleLeft, faAngleRight, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import galleries from './galleries';
import { IndicatorGroup } from './indicators';

const Container = tw.div`flex flex-col items-center gap-2`;

const TitleBar = tw.div`relative flex flex-row justify-center w-full min-h-4`;

const TitleContainer = tw.div`flex flex-col justify-center h-auto`;
const Title = tw.div`font-semibold text-center`;
const SubTitle = tw.div`text-center`;
const HideButton = tw.button`absolute top-0 right-0 w-4 h-4 px-4`;

const CarouselWrapper = tw.div`relative flex items-center justify-evenly`;
const Stage = styled.div(() => [
  tw`flex gap-5 overflow-x-hidden scroll-smooth snap-x`,
  css`
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
]);

const Scene = tw.div`relative basis-4/5 shrink-0 snap-center`;
const InactiveSceneCurtain = tw.div`w-full max-w-12 shrink-0`;
const SlideImage = styled.img<{ isInactive: boolean }>(({ isInactive }) => [
  tw`w-full rounded-3xl`,
  isInactive && tw`opacity-50`,
]);
const ControlBtn = styled.button(() => [
  tw`absolute z-10 flex items-center justify-center w-12 h-12 text-2xl text-white transition-all border-none rounded-full outline-none appearance-none left-[5%] top-1/2 shrink-0 bg-primary font-bold`,
  css`
    transform: translateY(-50%);
  `,
]);
const RightControlBtn = tw(ControlBtn)`left-auto right-[5%]`;

type GalleryProps = {
  galleryId: string;
  images?: { url: string }[];
  isStatic: boolean;
  shouldDisplayTitle?: boolean;
  isHidable?: boolean;
  onImageLoad?: VoidFunction;
  onHide?: VoidFunction;
};

const getSlideKey = (index: number) => `carousel_slide_${index}`;

const START_INDEX = 0;

export const Gallery = ({
  galleryId,
  isStatic,
  shouldDisplayTitle,
  isHidable,
  onImageLoad,
  onHide,
  images: galleryImages,
}: GalleryProps) => {
  const { t } = useTranslation();

  const [activeScene, setActiveScene] = useState(START_INDEX);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const sceneMap = useRef<Record<string, HTMLDivElement | null>>({});

  const { images } = galleryImages ? { images: galleryImages } : galleries[galleryId] || { images: [] };

  const scrollToScene = useCallback((index: number) => {
    if (scrollContainerRef.current) {
      const el = scrollContainerRef.current;
      const slide = sceneMap.current[getSlideKey(index)];
      const pos = slide?.offsetLeft;
      window.requestAnimationFrame(() => el.scrollTo({ left: pos, behavior: 'instant' as unknown as 'auto' }));
    }
  }, []);

  useEffect(() => {
    // We have an invisible element (that takes up space) at the front of the Stage.
    // so we must scroll to the the actual first element on mount
    scrollToScene(START_INDEX);
  }, [scrollToScene]);

  const updateSceneAndScroll = useCallback(
    (index: number) => {
      setActiveScene(index);
      scrollToScene(index);
    },
    [scrollToScene],
  );

  const maxSlide = images?.length - 1;

  const nextSlide = useCallback(() => {
    activeScene === maxSlide ? updateSceneAndScroll(START_INDEX) : updateSceneAndScroll(activeScene + 1);
  }, [activeScene, maxSlide, updateSceneAndScroll]);

  const prevSlide = useCallback(() => {
    activeScene === START_INDEX ? updateSceneAndScroll(maxSlide) : updateSceneAndScroll(activeScene - 1);
  }, [activeScene, maxSlide, updateSceneAndScroll]);

  const hasMoreThanOneSlide = images?.length > 1;
  const showControls = hasMoreThanOneSlide && !isStatic;

  const visibleImages = useMemo(() => (isStatic ? images.slice(0, 1) : images), [images, isStatic]);

  if (!visibleImages) {
    return null;
  }

  const titlePath = `galleries.${galleryId}.title`;
  const subTitlePath = `galleries.${galleryId}.subTitle`;

  let title = '';
  if (t(titlePath) !== titlePath) {
    title = t(titlePath);
  }

  let subTitle = undefined;
  if (t(subTitlePath) !== subTitlePath) {
    subTitle = t(subTitlePath);
  }

  const imageTitlePath = `galleries.${galleryId}.images.${activeScene}.title`;
  const imageSubTitlePath = `galleries.${galleryId}.images.${activeScene}.subTitle`;

  let titleToDisplay = title;
  if (t(imageTitlePath) !== imageTitlePath) {
    titleToDisplay = t(imageTitlePath);
  }

  let subTitleToDisplay = subTitle;
  if (t(imageSubTitlePath) !== imageSubTitlePath) {
    subTitleToDisplay = t(imageSubTitlePath);
  }

  return (
    <Container>
      {shouldDisplayTitle || isHidable ? (
        <TitleBar>
          {shouldDisplayTitle ? (
            <TitleContainer>
              <Title>{titleToDisplay}</Title>
              {subTitleToDisplay ? <SubTitle>{subTitleToDisplay}</SubTitle> : undefined}
            </TitleContainer>
          ) : undefined}
          {isHidable ? (
            <HideButton onClick={onHide}>
              <FontAwesomeIcon icon={faXmark} size="1x" />
            </HideButton>
          ) : undefined}
        </TitleBar>
      ) : undefined}
      <CarouselWrapper role="region" aria-roledescription="carousel" aria-label={title}>
        {showControls && (
          <ControlBtn onClick={prevSlide} value="previous" aria-label="Previous Slide">
            <FontAwesomeIcon icon={faAngleLeft} size="1x" />
          </ControlBtn>
        )}
        <Stage ref={scrollContainerRef} aria-atomic="false" aria-live="polite">
          <InactiveSceneCurtain aria-hidden={true} />
          {visibleImages.map((image, index) => {
            const altTextPath = `galleries.${galleryId}.images.${index}.alt`;
            let altText = '';
            if (t(altTextPath) !== altTextPath) {
              altText = t(altTextPath);
            }

            return (
              <Scene
                key={`${image.url}-${index}`}
                ref={(r) => (sceneMap.current[getSlideKey(index)] = r)}
                aria-roledescription="slide"
              >
                <SlideImage onLoad={onImageLoad} isInactive={index !== activeScene} src={image.url} alt={altText} />
              </Scene>
            );
          })}
          <InactiveSceneCurtain aria-hidden={true} />
        </Stage>
        {showControls && (
          <RightControlBtn onClick={nextSlide} value="next" aria-label="Next Slide">
            <FontAwesomeIcon fontWeight={900} icon={faAngleRight} size="1x" />
          </RightControlBtn>
        )}
      </CarouselWrapper>
      {hasMoreThanOneSlide && <IndicatorGroup sceneLength={images.length - 1} activeScene={activeScene} />}
    </Container>
  );
};

export default Gallery;
