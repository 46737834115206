import { BRANDING_ID_KEY, CUSTOMER_ID_KEY, RouteBuilder } from '@mezo/web/utils';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

export const DeprecatedChatUrlRedirect = () => {
  const { customerId, residentId, unitId } = useParams();
  const [searchParams] = useSearchParams();

  let newUrl = new RouteBuilder().buildAddressesRoute().prependPath(RouteBuilder.RESIDENT).build();

  if (customerId && residentId && unitId) {
    newUrl = new RouteBuilder()
      .buildResidentHomeRoute(customerId, residentId, unitId)
      .prependPath(RouteBuilder.RESIDENT)
      .build();
  }

  const searchParamsString = searchParams.toString();

  if (searchParamsString.length > 0) {
    const newUrlWithSearchParams = `${newUrl}?${searchParams.toString()}`;

    return <Navigate to={newUrlWithSearchParams} />;
  }

  return <Navigate to={newUrl} />;
};

export const buildDeprecatedChatRoute = (residentHomeUrl: string) => `chat/${residentHomeUrl}/*`;

export const DeprecatedServiceRequestRedirect = () => {
  const { serviceRequestId } = useParams();

  if (!serviceRequestId) {
    return <Navigate to={`/${RouteBuilder.DASHBOARD}`} />;
  }

  const newUrl = new RouteBuilder(`/${RouteBuilder.DASHBOARD}/`).buildServiceRequestRoute(serviceRequestId).build();

  return <Navigate to={newUrl} />;
};

export const DeprecatedAddressesRedirect = () => {
  const [searchParams] = useSearchParams();

  const brandingId = searchParams.get(BRANDING_ID_KEY) ?? undefined;
  const customerId = searchParams.get(CUSTOMER_ID_KEY) ?? undefined;

  const addressesUrl = new RouteBuilder()
    .buildAddressesRoute(brandingId, customerId)
    .prependPath(RouteBuilder.RESIDENT)
    .build();

  return <Navigate to={addressesUrl} />;
};
