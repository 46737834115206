import { ResidentSearchResultResponseDto } from '@deprecated/dtos';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { BrandingConfiguration } from '@mezo/web/hooks';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { ChatBanner, ChatHeader, LinkList } from '../../components';
const Content = tw.div`flex flex-col gap-4 p-4 flex-grow-2`;
const Title = tw.h1`text-base leading-6`;
const Container = tw.div`relative flex flex-col w-full h-full mx-auto lg:max-w-xl bg-background-light`;

type ResidentLandingProps = {
  links: {
    linkText: string;
    handleClick: VoidFunction;
  }[];
  resident: ResidentSearchResultResponseDto | undefined;
  brandingConfiguration: BrandingConfiguration | undefined;
  clearResidentAndNavigateToStart: VoidFunction;
  shouldHideSavedResidentBanner: boolean;
};

export const ResidentLanding = ({
  links,
  resident,
  brandingConfiguration,
  clearResidentAndNavigateToStart,
  shouldHideSavedResidentBanner,
}: ResidentLandingProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      {!brandingConfiguration?.hideChatHeader && <ChatHeader config={brandingConfiguration || {}} />}
      <Content>
        <Title>{t('landing.introText', { firstName: resident?.firstName })}</Title>
        {resident?.isGuest && <ChatBanner icon={faInfoCircle} lineOne={t('landing.guestIntroText')} />}
        <LinkList links={links} />
        {!shouldHideSavedResidentBanner && (
          <ChatBanner
            onBannerClick={clearResidentAndNavigateToStart}
            lineOne={`${resident?.address1} ${resident?.address2}`}
            lineTwo={resident?.isGuest ? t('chat.clickHereToLogOut') : t('chat.notYou')}
          />
        )}
      </Content>
    </Container>
  );
};
