import { CalendarDate } from '@internationalized/date';
import { AriaCalendarCellProps, useCalendarCell } from '@react-aria/calendar';
import { useFocusRing } from '@react-aria/focus';
import { CalendarState } from '@react-stately/calendar';
import { formatInTimeZone } from 'date-fns-tz';
import { KeyboardEventHandler, memo, MouseEventHandler, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import { KEYCODES } from '../../types';

const Cell = styled('span')<{
  isSelected: boolean;
  isFocusVisible: boolean;
  isDisabled: boolean;
  hasEvent: boolean;
  isCurrentDay: boolean;
}>(({ isSelected, isFocusVisible, isDisabled, hasEvent, isCurrentDay }) => [
  tw`relative flex items-center justify-center w-8 h-8 m-auto transition-all rounded-full outline-none appearance-none after:transition-all outline-dotted outline-1 focus:outline-dotted focus:outline-1`,
  isCurrentDay && tw`border border-primary after:bg-primary`,
  isSelected && tw`text-white bg-primary after:bg-white outline-dotted focus:outline-dotted focus:outline-1`,
  !isDisabled && tw`cursor-pointer hover:bg-primary hover:text-white hover:after:bg-white`,
  isDisabled && tw`!cursor-default text-disabled opacity-70`,
  isFocusVisible && `outline-dotted outline-1`,
  hasEvent && tw`after:block after:w-1 after:h-1 after:absolute after:bottom-1 after:rounded-full after:bg-primary`,
  hasEvent && isCurrentDay && tw`after:bg-primary`,
  hasEvent && isSelected && tw`after:bg-white`,
]);

type CalendarCellProps = AriaCalendarCellProps & {
  state: CalendarState;
  isDisabled: boolean;
  isSelected: boolean;
  isCurrentDay: boolean;
  onSelect: (day: CalendarDate) => void;
  timezoneId: string;
  date: CalendarDate;
  hasEvent: boolean;
};

export const CalendarCell = (props: CalendarCellProps) => {
  const ref = useRef(null);
  const { cellProps, buttonProps } = useCalendarCell(props, props.state, ref);

  const { date, isDisabled, isCurrentDay, onSelect, isSelected, hasEvent, timezoneId } = props;

  const handleDayClick: MouseEventHandler<HTMLSpanElement> = useCallback(
    (e) => {
      !isDisabled && onSelect(date);
    },
    [isDisabled, onSelect, date]
  );
  const handleDayPress: KeyboardEventHandler<HTMLSpanElement> = useCallback(
    (e) => {
      if (e.keyCode === KEYCODES.enter) {
        e.preventDefault();
        !isDisabled && onSelect(date);
      }
    },
    [isDisabled, onSelect, date]
  );

  const { isFocusVisible } = useFocusRing();

  const day = useMemo(() => formatInTimeZone(date.toDate(timezoneId), timezoneId, 'd'), [date, timezoneId]);

  return (
    <div {...cellProps}>
      <Cell
        ref={ref}
        {...buttonProps}
        isSelected={isSelected}
        isFocusVisible={isFocusVisible}
        isDisabled={isDisabled}
        hasEvent={hasEvent}
        isCurrentDay={isCurrentDay}
        onClick={handleDayClick}
        onKeyDown={handleDayPress}
      >
        {day}
      </Cell>
    </div>
  );
};

export default memo(CalendarCell);
