import { RecentResidentProfileDto, ResidentSearchResponseDto, ResidentSearchResultResponseDto } from '@deprecated/dtos';
import { SearchTextRequestDto } from '@mezo/common/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useGetRecentResidents = (uid: string) =>
  useQuery({
    queryKey: ['recentResidents', uid],
    queryFn: async () => {
      const { data } = await ApiClient.NON_CHAT_API.utility.get<ResidentSearchResultResponseDto[]>(
        `/users/${uid}/recent-resident-profiles`,
      );
      return data;
    },
    enabled: !!uid,
  });

export const useSearchTestResidents = (searchText: string, tags?: string[]) => {
  return useQuery({
    queryKey: ['searchTestResidents', searchText + tags?.toString()],
    queryFn: async () => {
      const payload: SearchTextRequestDto = {
        searchText: searchText,
        filter: 'tenantData.isGuest!=1',
        resultsPerPage: 20,
        pageNumber: 0,
        isTestUser: true,
        tags: tags ?? [],
      };
      const { data } = await ApiClient.NON_CHAT_API.utility.post<ResidentSearchResponseDto>(
        `/residents/search`,
        payload,
      );
      return data.docs;
    },
    enabled: !!searchText.length,
  });
};

export const useSearchResidents = (searchText: string) =>
  useQuery({
    queryKey: ['searchResidents', searchText],
    queryFn: async () => {
      const payload: SearchTextRequestDto = {
        searchText: searchText,
        filter: 'tenantData.isGuest!=1',
        resultsPerPage: 8,
        pageNumber: 0,
        isTestUser: false,
      };
      const { data } = await ApiClient.NON_CHAT_API.utility.post<ResidentSearchResponseDto>(
        `/residents/search`,
        payload,
      );
      return data.docs;
    },
    enabled: !!searchText.length,
  });

export const useAddRecentResident = (uid: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ resident }: { resident: RecentResidentProfileDto }) => {
      const { data } = await ApiClient.NON_CHAT_API.utility.post(`/users/${uid}/recent-resident-profiles`, resident);
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueriesData({ queryKey: ['recentResidents', uid] }, data);
    },
  });
};
