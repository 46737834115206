import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Modal from 'styled-react-modal';
import tw from 'twin.macro';

const IconButton = tw.button`p-0 border-0 cursor-pointer bg-none`;
const Title = tw.h5`m-0 text-lg font-medium leading-7 text-left`;
const CloseIcon = tw(FontAwesomeIcon)`fill-current text-disabled`;
const ModalContainer = tw.div`fixed flex flex-col justify-between w-[calc(100%-2rem)] max-w-md gap-4 p-4 overflow-y-scroll transform -translate-x-1/2 bg-background-light rounded-3xl top-4 left-1/2 max-h-[475px]`;
const FlexContainer = tw.div`flex items-start justify-between gap-4`;
const VerticalFlexContainer = tw(FlexContainer)`flex-col items-stretch`;
const ExampleImage = tw.img`w-full rounded-lg`;

type ExampleImageModalProps = {
  isVisible: boolean;
  onClose: VoidFunction;
  exampleProblemStatement: string;
  exampleImageUrls: string[];
};

export const ExampleImageModal = ({
  isVisible,
  onClose,
  exampleImageUrls,
  exampleProblemStatement,
}: ExampleImageModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isVisible} onBackgroundClick={onClose} onEscapeKeydown={onClose}>
      <ModalContainer>
        <FlexContainer>
          <Title>{t('media.exampleModal.header', { problemStatement: exampleProblemStatement })}</Title>
          <IconButton onClick={onClose} aria-label={t('media.confirmModal.cancel')}>
            <CloseIcon icon={faXmark} size="lg" />
          </IconButton>
        </FlexContainer>
        <VerticalFlexContainer>
          {exampleImageUrls.map((url, index) => (
            <ExampleImage key={index} src={url} alt={t('media.exampleModal.exampleAlt')} />
          ))}
        </VerticalFlexContainer>
      </ModalContainer>
    </Modal>
  );
};
