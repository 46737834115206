import { ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Loading from '../loading';
import { MemoizedPickListCell } from './pick-list-cell';
import { SELECT_OTHER_ID } from '../../pages';

export type PickListItem = {
  id: string | number;
  label: string;
};

export type PickListProps<T> = {
  items: T[];
  selectedItem?: T | undefined;
  highlightColor?: string;
  loading?: boolean;
  renderItem?: (item: T) => ReactNode;
  onItemSelected?: (item: T | undefined) => void;
  numColumns?: number;
};

const PickListContainer = styled.div<{ fullWidthColumns: boolean }>(({ fullWidthColumns }) => [
  tw`grid w-full grid-flow-row grid-cols-1 gap-2`,
  !fullWidthColumns && tw`grid-cols-2`,
]);

export const PickList = <T extends PickListItem>({
  items,
  selectedItem,
  loading,
  onItemSelected,
  highlightColor,
  renderItem,
  numColumns = 1,
}: PickListProps<T>) => {
  const handleItemPicked = useCallback(
    (item: T) => {
      const newSelectedItem = item.id === selectedItem?.id ? undefined : item;
      onItemSelected?.(newSelectedItem);
    },
    [onItemSelected, selectedItem?.id]
  );

  const defaultRenderItem = useCallback(
    (item: T) => (
      <MemoizedPickListCell
        key={item.id}
        item={item}
        onClick={handleItemPicked}
        selected={item.id === selectedItem?.id}
        showRadio={item.id !== SELECT_OTHER_ID}
        highlightColor={highlightColor}
      >
        {item.label}
      </MemoizedPickListCell>
    ),
    [handleItemPicked, selectedItem?.id, highlightColor]
  );

  const renderMethod = renderItem || defaultRenderItem;
  return loading ? (
    <Loading />
  ) : (
    <PickListContainer fullWidthColumns={numColumns === 1}>{items.map((item) => renderMethod(item))}</PickListContainer>
  );
};
