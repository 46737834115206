import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';

const getInitialTheme = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const storedPrefs = window.localStorage.getItem('color-theme');
    if (typeof storedPrefs === 'string') {
      return storedPrefs;
    }

    const userMedia = window.matchMedia('(prefers-color-scheme: override)');
    if (userMedia.matches) {
      return 'override';
    }
  }

  return 'mezo';
};

const TwThemeContext = createContext<{ theme: string; setTheme: Dispatch<SetStateAction<string>> }>({
  theme: 'mezo',
  setTheme: () => {
    /* */
  },
});

const TwThemeProvider = ({ initialTheme, children }: { initialTheme: string; children: ReactNode }) => {
  const [theme, setTheme] = useState<string>(getInitialTheme);

  const rawSetTheme = (theme: string) => {
    const root = window.document.documentElement;
    const isMezo = theme === 'mezo';

    root.classList.remove(isMezo ? 'override' : 'mezo');
    root.classList.add(theme);

    localStorage.setItem('color-theme', theme);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  return <TwThemeContext.Provider value={{ theme, setTheme }}>{children}</TwThemeContext.Provider>;
};

export { TwThemeContext, TwThemeProvider };
