import { memo, ReactNode, useCallback } from 'react';
import styled, { css, CSSProperties } from 'styled-components';
import tw, { theme } from 'twin.macro';
import { PickListItem } from './pick-list';

type HighlightColorProps = {
  highlightColor?: string;
  selected: boolean;
};

type PickListItemProps<T> = {
  item: T;
  selected: boolean;
  showRadio: boolean;
  style?: CSSProperties | undefined;
  onClick: (item: T) => void;
  children?: ReactNode;
};

const SelectedCircleOuter = styled.div<HighlightColorProps>(({ highlightColor, selected }) => [
  tw`flex items-center justify-center border-2 w-[18px] h-[18px] rounded-[80px]`,
  selected &&
    css({
      'border-color': highlightColor,
    }),
]);
const SelectedCircleInner = styled.div<Pick<HighlightColorProps, 'highlightColor'>>(({ highlightColor }) => [
  tw`w-[10px] h-[10px] rounded-[10px]`,
  css({
    'background-color': highlightColor,
  }),
]);

const PickListItemButton = styled.button<HighlightColorProps>(({ selected, highlightColor }) => [
  tw`relative flex items-center justify-between gap-2 p-4 text-sm border grow bg-background-light text-text-light rounded-[10px]`,
  selected &&
    css({
      color: highlightColor,
      'border-color': highlightColor,
    }),
]);

export const PickListCell = <T extends PickListItem>({
  onClick,
  item,
  selected,
  showRadio,
  highlightColor = theme`colors.primary`,
  style,
  children,
}: PickListItemProps<T> & HighlightColorProps) => {
  const handleOnClick = useCallback(() => onClick(item), [item, onClick]);

  return (
    <PickListItemButton
      onClick={handleOnClick}
      selected={selected}
      highlightColor={highlightColor}
      style={style}
      aria-pressed={selected}
    >
      {children}
      {showRadio && (
        <SelectedCircleOuter highlightColor={highlightColor} selected={selected}>
          {selected && <SelectedCircleInner highlightColor={highlightColor} />}
        </SelectedCircleOuter>
      )}
    </PickListItemButton>
  );
};

export const MemoizedPickListCell = memo(PickListCell) as typeof PickListCell;
