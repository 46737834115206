import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChatAction, ChatResponseDto, Sender } from '@mezo/common/dtos';
import { useUserSessionContext } from '@mezo/web/contexts';
import { LocalStorageAndroid, MEZO_ANDROID_STORAGE_KEY, useLocalStorage } from '@mezo/web/hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import tw from 'twin.macro';
import { ChoiceButtonList } from '../../components';
import { MessageLoader } from '../../components/message-loader';
import { IntelliflowMessageList } from './intelliflow-message-list';

const ChatConversationContainer = tw.div`flex flex-col gap-6 p-5 overflow-y-auto flex-grow-2`;
const SpacerDiv = tw.div`flex-grow`;

const ChatButtonContainer = tw.div`relative`;

const UploadErrorContainer = tw.div`flex flex-row px-2 py-4 border border-alert rounded-xl`;
const UploadErrorText = tw.div`text-alert`;
const UploadErrorIconContainer = tw.div`flex flex-col justify-center pr-2 align-middle`;
const UploadErrorIcon = tw(FontAwesomeIcon)`fill-current text-alert`;

const AndroidUploadError = () => {
  const { t } = useTranslation();

  return (
    <UploadErrorContainer>
      <UploadErrorIconContainer>
        <UploadErrorIcon icon={faCircleExclamation} />
      </UploadErrorIconContainer>
      <UploadErrorText> {t('upload.androidError')}</UploadErrorText>
    </UploadErrorContainer>
  );
};

type IntelliflowPageProps = {
  onChatComplete: () => void;
};

const FIVE_MINUTES_AS_MS = 300000;

export const IntelliflowPage: React.FC<IntelliflowPageProps> = ({ onChatComplete }) => {
  const [shouldDisplayButtons, setShouldDisplayButtons] = useState<boolean>(false);
  const [isNormalUpload, setNormalUpload] = useState<boolean>(false);
  const [isLoadingResponse, setIsLoadingResponse] = useState<boolean>(false);
  const { value: localStorageAndroid } = useLocalStorage<LocalStorageAndroid>(MEZO_ANDROID_STORAGE_KEY);

  const chatRef = useRef<HTMLDivElement>(null);

  const { getUserSession, sendMessage, startSession } = useUserSessionContext();
  const userSession = getUserSession();

  const brandingConfiguration = userSession?.brandingConfig;
  const messages = userSession?.currentSubmitAttempt?.messages || [];

  const lastMessage = messages.length > 0 ? messages[messages.length - 1] : undefined;

  const isLastMessageBotResponse =
    lastMessage?.sender === Sender.BOT && Object.prototype.hasOwnProperty.call(lastMessage, 'choices');

  const chatComplete =
    isLastMessageBotResponse && (lastMessage as ChatResponseDto).chatAction === ChatAction.CHAT_COMPLETE;

  useEffect(() => {
    if (chatComplete) onChatComplete();
  }, [chatComplete, onChatComplete]);

  useEffect(() => {
    if (messages.length === 0) {
      setIsLoadingResponse(true);
      startSession().finally(() => {
        setIsLoadingResponse(false);
      });
    }
  }, [messages.length]);

  const now = Date.now();
  const wasMediaRequestedAndNotCleanedUp =
    localStorageAndroid?.mediaRequested && now - localStorageAndroid.mediaRequested < FIVE_MINUTES_AS_MS;

  const choiceButtons = useMemo(() => {
    const choices = isLastMessageBotResponse ? (lastMessage as ChatResponseDto).choices : [];
    if (!choices?.length) {
      return [];
    }

    return choices.map((button) => {
      return {
        id: button.id,
        displayText: button.label,
        onButtonSelection: (id: string, text: string) => {
          setIsLoadingResponse(true);
          setShouldDisplayButtons(false);
          sendMessage({ id, label: text }).finally(() => {
            setIsLoadingResponse(false);
          });
        },
      };
    });
  }, [isLastMessageBotResponse, lastMessage, sendMessage]);

  const scrollToBottom = useCallback(() => {
    if (chatRef.current) {
      const el = chatRef.current;
      if (el?.scrollTo) {
        window.requestAnimationFrame(() => el.scrollTo({ top: el.scrollHeight, behavior: 'smooth' }));
      }
    }
  }, []);

  const showButtons = useCallback(() => {
    choiceButtons?.length > 0 && setShouldDisplayButtons(true);
  }, [setShouldDisplayButtons, choiceButtons?.length]);

  useEffect(() => {
    if (!choiceButtons.length && shouldDisplayButtons) {
      setShouldDisplayButtons(false);
    }
  }, [choiceButtons, shouldDisplayButtons]);

  useEffect(() => {
    scrollToBottom();
  }, [messages.length, shouldDisplayButtons, scrollToBottom]);

  useEffect(() => {
    return () => setNormalUpload(false);
  }, [isNormalUpload]);

  return (
    <ChatConversationContainer ref={chatRef}>
      <SpacerDiv />
      {isAndroid && wasMediaRequestedAndNotCleanedUp ? <AndroidUploadError /> : undefined}
      {messages.length > 0 && (
        <IntelliflowMessageList
          customerIconUrl={brandingConfiguration?.logo}
          messages={messages}
          onNodeDisplay={scrollToBottom}
          onBotResponseCompletion={showButtons}
          staggerNodes={!shouldDisplayButtons}
          data-cy="chat-message-list"
        />
      )}
      {isLoadingResponse && <MessageLoader customerIconUrl={brandingConfiguration?.logo} />}
      {shouldDisplayButtons && (
        <ChatButtonContainer>
          <ChoiceButtonList choiceButtons={choiceButtons} />
        </ChatButtonContainer>
      )}
    </ChatConversationContainer>
  );
};
