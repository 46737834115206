import { ServiceRequestResidentView } from '@deprecated/dtos';
import { t } from 'i18next';
import React from 'react';
import tw from 'twin.macro';
import { ServiceRequestItem } from '../../components';

const TextWrapper = tw.div`flex flex-col gap-4 py-4`;
const CustomTextWrapper = tw.div`py-4`;
const StyledDarkText = tw.p`px-4`;
const StyledGrayText = tw.p`px-4 mb-4 text-xs text-disabled`;
const ServiceRequestsContainer = tw.div`mb-4`;

type SubmittedRequestsPageProps = {
  customText?: string;
  serviceRequests?: Array<ServiceRequestResidentView>;
};

export const SubmittedRequestsPage: React.FC<SubmittedRequestsPageProps> = ({ customText, serviceRequests }) => {
  if (!serviceRequests) {
    return (
      <TextWrapper>
        <StyledDarkText>{t('chat.submittedRequests.noRequests')}</StyledDarkText>
        <StyledGrayText>{t('chat.submittedRequests.showMaxRequestsOnly')}</StyledGrayText>
      </TextWrapper>
    );
  } else {
    return (
      <React.Fragment>
        <ServiceRequestsContainer>
          <CustomTextWrapper>
            <StyledDarkText>{customText || t('chat.submittedRequests.workingOnRequests')}</StyledDarkText>
          </CustomTextWrapper>
          {serviceRequests.map((sr) => (
            <ServiceRequestItem
              key={`${sr.sourceId}-${sr.item}-${sr.createdAt}`}
              createdAt={sr.createdAt}
              item={sr.item}
              lastUpdatedAt={sr.sourceLastUpdated}
              status={sr.sourceStatus}
              sourceId={sr.sourceId}
              showWorkOrderNumber={sr.showWorkOrderNumber}
            />
          ))}
        </ServiceRequestsContainer>
        <StyledGrayText>{t('chat.submittedRequests.showMaxRequestsOnly')}</StyledGrayText>
        <StyledGrayText>{t('chat.submittedRequests.makeChangesToRequests')}</StyledGrayText>
      </React.Fragment>
    );
  }
};
