import { TaxonomyMapDto } from '@deprecated/dtos';
import { TFunction } from 'i18next';
import { RouteBuilder } from './routes';
const UNKNOWN_LABEL = 'unknown';
export const formatStatus = (input: string) => input.replace('_', ' ').split(' ').map(capitalize).join(' ');
const capitalize = (word: string) => word[0].toUpperCase() + word.substr(1);

export const formatServiceRequestTitle = (taxonomyMap: TaxonomyMapDto, t: TFunction) => {
  const componentLabel =
    taxonomyMap?.mezoComponent?.label && taxonomyMap?.mezoComponent?.label !== UNKNOWN_LABEL
      ? taxonomyMap?.mezoComponent?.label
      : '';
  return `${taxonomyMap?.mezoItem?.label || t('serviceRequestDetails.unknown')} / ${
    (componentLabel || '') + (componentLabel ? ' /' : '')
  } ${taxonomyMap?.mezoSymptom?.label || t('serviceRequestDetails.other')}`;
};

export const formatServiceRequestSubTitle = (taxonomyMap: TaxonomyMapDto) =>
  `${taxonomyMap?.mezoLocation?.label ? taxonomyMap?.mezoLocation?.label + ' / ' : ''}${
    taxonomyMap?.mezoCategory?.label
  }`;

export enum ThumbnailType {
  FILE = 'file',
  URL = 'url',
}
export const getThumbnailTypeFromContentType = (contentType: string): ThumbnailType =>
  contentType.split('/')[0].includes('video') ? ThumbnailType.FILE : ThumbnailType.URL;

export const DEFAULT_BOT_NAME = 'Max';

export const generateResidentRedirectUrl = (customerId?: string, unitId?: string, residentId?: string) => {
  if (!customerId || !residentId || !unitId) {
    const residentAddressUrl = new RouteBuilder().buildAddressesRoute().prependPath(RouteBuilder.RESIDENT).build();
    return residentAddressUrl;
  }
  const residentHomeUrl = new RouteBuilder()
    .buildResidentHomeRoute(customerId, residentId, unitId)
    .prependPath(RouteBuilder.RESIDENT)
    .build();
  return residentHomeUrl;
};
