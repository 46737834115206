import { IsArray, IsNumber, IsString, ValidateNested } from 'class-validator';

export class TaxonomyEntityResponseDto {
  @IsString()
  id: string;
  @IsString()
  label: string;
  @IsNumber()
  createdAt: number;
  @IsString()
  entityId: string;
  @IsArray()
  @ValidateNested()
  synonyms: string[];
  @IsNumber()
  updatedAt: number;
  @IsString()
  updatedBy: string;
  @IsString()
  version: string;
}

export class TaxonomyEntityDto {
  @IsString()
  id: string;
  @IsString()
  label: string;
  @IsString()
  isSelected?: boolean;
}
