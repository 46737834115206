import React, { createContext, useContext } from 'react';

interface NavigationContextValue {
  navigateToPage: (pageName: string) => void;
  navigateToNextPage: VoidFunction;
  resetNavigationStack: VoidFunction;
}

const NavigationContext = createContext<NavigationContextValue>({
  navigateToPage: () => {
    /** */
  },
  navigateToNextPage: () => {
    /** */
  },
  resetNavigationStack: () => {
    /** */
  },
});

export const useNavigationContext = () => useContext(NavigationContext);

type NavigationContextProviderProps = React.PropsWithChildren & {
  onPageChange: (pageName: string) => void;
  onNavigateToNextPage: VoidFunction;
  resetNavigationStack: VoidFunction;
};

export const NavigationContextProvider: React.FC<NavigationContextProviderProps> = ({
  children,
  onPageChange,
  onNavigateToNextPage,
  resetNavigationStack,
}) => {
  const contextValue: NavigationContextValue = {
    navigateToPage: onPageChange,
    navigateToNextPage: onNavigateToNextPage,
    resetNavigationStack: resetNavigationStack,
  };
  return <NavigationContext.Provider value={contextValue}>{children}</NavigationContext.Provider>;
};
