export class AssetInfoDto {
  id: string;
  applianceUid?: string;
  lookupDate?: number;
  dateManufactured?: number;
  brandName?: string;
  modelNumber?: string;
  serialNumber?: string;
  remainingLife?: number;
  expectedLife?: number;
  recallInfo?: RecallInformationV3Dto;
  applianceFailureRiskScore?: number;
}

export class RecallInformationV3Dto {
  /** Recall information code:
   * (0) No recalls returned - there are no recalls for this product
   * (1) One or more recalls were returned as possible matches for the submitted product. Brand, model number, and/or serial number was not included in the request or are not defined in the CPSC recall, therefore the system could not confirm the recall as a positive match for the product. The returned recall(s) should be manually reviewed to determine if they apply to the product.
   * (2) The returned recall(s) applies to the submitted product. */
  recallCode: RecallCode;
  recalls?: RecallV3Dto[];
}

export enum RecallCode {
  NO_RECALLS = 0,
  ONE_OR_MORE_POSSIBLE = 1,
  RECALLS_APPLICABLE = 2,
}

export type RecallV3Dto = {
  /** Date recall added to Source7 database */
  dateAdded: number;
  /** CPSC Recall Number */
  recallNumber: string;
  /** Hazard that caused the recall */
  hazard: string;
  /** The specified fix for the recall */
  fix: string;
  /** Short name hazard that caused the recall */
  dueTo: string;
  /** CPSC URL For the recall */
  recallUrl: string;
  /**  Date of first sale of recalled product */
  dateStartedBeingSold: number;
  /** Date of last sale of recalled product */
  dateStoppedBeingSold: number;
  /** Date product was recalled */
  dateRecalled: number;
};
