import { IsNotEmpty, IsOptional, IsString, IsUrl } from 'class-validator';

export class AliasDto {
  @IsUrl()
  @IsNotEmpty()
  target: string;

  @IsString()
  @IsNotEmpty()
  vanity: string;
}

export class AliasUpdateDto {
  @IsUrl()
  @IsOptional()
  target: string;

  @IsString()
  @IsOptional()
  vanity: string;
}
