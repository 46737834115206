import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrandingConfiguration } from '@mezo/web/hooks';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import { MEZO_LOGO_URL } from '../../types';
import { CustomerLogo } from '../customer-logo';

type ChatStackHeaderProps = {
  config?: Pick<BrandingConfiguration, 'logo' | 'name'>;
  showBackButton: boolean;
  currentTitle?: string;
  onBackClicked?: VoidFunction;
  hasSubmittedRequestsStyling?: boolean;
};
const StyledLogo = tw(CustomerLogo)`w-10 h-10 border-2`;

const BackButton = styled.button<{ isVisible: boolean }>(({ isVisible }) => [
  tw`flex-grow-0 flex-shrink-0 transition-opacity duration-300 bg-transparent border-none opacity-0 fill-current text-primary `,
  isVisible && tw`opacity-100`,
]);

const ChatStackHeaderWrapper = styled.div<{ hasSubmittedRequestsStyling?: boolean }>(
  ({ hasSubmittedRequestsStyling }) => [
    tw`flex items-center justify-center flex-shrink-0 px-4 text-center border-b border-b-border-grey h-14`,
    hasSubmittedRequestsStyling &&
      css`
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
      `,
  ]
);

const PageTitle = tw.div`self-center flex-1 text-sm`;
const RightPadding = tw.div`w-6`;
export const ChatStackHeader: React.FC<ChatStackHeaderProps> = ({
  config,
  showBackButton,
  onBackClicked,
  currentTitle,
  hasSubmittedRequestsStyling,
}) => {
  return (
    <ChatStackHeaderWrapper hasSubmittedRequestsStyling={hasSubmittedRequestsStyling}>
      {showBackButton ? (
        <BackButton disabled={!showBackButton} isVisible={showBackButton} onClick={onBackClicked} aria-label={'Back'}>
          <FontAwesomeIcon icon={faArrowLeft} size="xl" />
        </BackButton>
      ) : (
        <StyledLogo src={config?.logo ?? MEZO_LOGO_URL} alt={`${config?.name} Logo`} />
      )}
      <PageTitle>{currentTitle}</PageTitle>
      <RightPadding />
    </ChatStackHeaderWrapper>
  );
};
