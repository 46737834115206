import { LinkWithTextAtBeginningAndEnd } from '@mezo/web/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import Logo from '../assets/Logo.svg';

const Container = tw.div`flex flex-col items-center h-full px-8 py-4 mx-auto bg-background-light`;
const Content = tw.div``;
const StyledLogo = tw.img`my-8`;

export const ExpiredLink: React.FC = () => {
  const { t } = useTranslation();

  const supportEmailAddress = 'support@mezo.io';
  const href = `mailto: ${supportEmailAddress}`;
  const onClick = useCallback(() => window.open(href), [href]);
  // the spacing is added here
  const formattedPreLink = `${t('expired.preLink')} `;
  const formattedPostLink = ` ${t('expired.postLink')}`;

  return (
    <Container>
      <StyledLogo src={Logo} alt="Mezo Logo" />
      <Content>{t('expired.pageHasExpired')}</Content>
      <Content>
        <LinkWithTextAtBeginningAndEnd
          preText={formattedPreLink}
          linkText={supportEmailAddress}
          postText={formattedPostLink}
          onClick={onClick}
        />
      </Content>
    </Container>
  );
};
