import { faCamcorder, faCamera, faImage, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalStorageAndroid, MEZO_ANDROID_STORAGE_KEY, useLocalStorage } from '@mezo/web/hooks';
import { ChangeEvent, HTMLProps, MouseEvent, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { MediaUploadButton } from './media-upload-button';

const FileInputLabel = tw.label`relative p-2 hover:cursor-pointer`;
const Input = tw.input`hidden`;
const StyledIcon = tw(FontAwesomeIcon)`fill-current hover:text-primary text-text`;

export interface FileInputProps {
  handleOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

type UploadButtonProps = {
  children?: React.ReactNode;
  icon?: IconDefinition;
  onChange: FileInputProps['handleOnChange'];
  initiateUpload?: boolean;
  inputProps?: HTMLProps<HTMLInputElement>;
};

const ButtonListWrapper = tw.div`flex flex-col gap-2 px-5`;

export const ImageUpload: React.FC<UploadButtonProps> = ({ onChange, icon, initiateUpload, inputProps }) => {
  const { t } = useTranslation();

  const resetInput = useCallback((e: MouseEvent) => {
    (e.currentTarget as HTMLInputElement).value = '';
  }, []);

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initiateUpload) {
      ref.current?.click();
    }
  }, [initiateUpload]);

  return (
    <FileInputLabel>
      {icon ? (
        <StyledIcon icon={icon} size="lg" />
      ) : (
        <ButtonListWrapper>
          <MediaUploadButton
            id="promptMediaUpload"
            onMediaUploadSelection={onChange}
            displayText={t('chat.mediaUploadButtonLabel')}
          />
        </ButtonListWrapper>
      )}
      <Input
        ref={ref}
        type="file"
        name="files"
        aria-label={t('chat.fileUpload')}
        id="files"
        data-cy="file-upload"
        multiple
        // NOTE: video/* does not work in Safari and must include /mp4 explicitly
        accept="image/*,video/mp4,video/x-m4v,video/*"
        onClick={resetInput}
        onChange={onChange}
        {...inputProps}
      />
    </FileInputLabel>
  );
};

export type AndroidUploadProps = FileInputProps;

const AndroidContainer = tw.div`flex flex-col w-full rounded-lg bg-gray-50`;
const AndroidItem = tw.label`flex items-center self-stretch justify-between pl-3 border-t-2 border-b-0 border-solid border-background-light first:border-0 border-x-0`;
const AndroidText = tw.span``;

export const AndroidUpload: React.FC<AndroidUploadProps> = ({ handleOnChange }) => {
  const { t } = useTranslation();
  const { setLocalStorage: setLocalStorageAndroid } = useLocalStorage<LocalStorageAndroid>(MEZO_ANDROID_STORAGE_KEY);

  useEffect(() => {
    setLocalStorageAndroid({
      mediaRequested: Date.now(),
    });
  }, [setLocalStorageAndroid]);

  return (
    <AndroidContainer>
      <AndroidItem>
        <AndroidText>{t('upload.gallery')}</AndroidText>
        <ImageUpload icon={faImage} onChange={handleOnChange} />
      </AndroidItem>
      <AndroidItem>
        <AndroidText>{t('upload.photo')}</AndroidText>
        <ImageUpload
          icon={faCamera}
          onChange={handleOnChange}
          inputProps={{ capture: 'environment', accept: 'image/*' }}
        />
      </AndroidItem>
      <AndroidItem>
        <AndroidText>{t('upload.video')}</AndroidText>
        <ImageUpload
          icon={faCamcorder}
          onChange={handleOnChange}
          inputProps={{ capture: 'environment', accept: 'video/*' }}
        />
      </AndroidItem>
    </AndroidContainer>
  );
};
