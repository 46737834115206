import { Link } from 'react-router-dom';
import tw from 'twin.macro';

const MezoLink = tw.a`underline cursor-pointer text-primary`;

const SpanLink = tw.span`underline cursor-pointer text-primary`;

type StyledLinkProps = {
  linkText: string;
  onClick?: VoidFunction;
  to?: string;
  href?: string;
};

export const StyledLink = ({ linkText, to, onClick, href }: StyledLinkProps) => {
  if (to) {
    return (
      <Link to={to}>
        <SpanLink> {linkText} </SpanLink>
      </Link>
    );
  } else if (onClick) {
    return <MezoLink onClick={onClick}>{linkText}</MezoLink>;
  } else {
    return <MezoLink href={href}>{linkText}</MezoLink>;
  }
};

export default StyledLink;
