import { OTHER_ID } from '@mezo/common/constants';
import { TaxonomyItemOptionDto } from '@mezo/common/dtos';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GetOptionValue, Options, StylesConfig } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import tw from 'twin.macro';

const CreatePrefixSpan = tw.span`text-gray-500`;
const CreateLabelSpan = tw.span``;
export const createCreateLabel = (prefix: string, inputValue: string) => (
  <CreateLabelSpan>
    <CreatePrefixSpan>{prefix}</CreatePrefixSpan> "{inputValue}"
  </CreateLabelSpan>
);

// TYPE AHEAD
const TypeAheadDropdownDiv = tw.div`w-full`;
const customStyles: StylesConfig = {
  input: (base) => ({
    ...base,
    "input[type='text']:focus": { boxShadow: 'none' },
    height: '48px',
  }),
};

type TypeAheadAsyncDropDownProps = {
  selected: TaxonomyItemOptionDto | undefined;
  typeAheadResults?: (TaxonomyItemOptionDto & { elem?: JSX.Element })[];
  handleTypeAheadChange: (result: TaxonomyItemOptionDto) => void;
  asyncSearchFunc?: (inputValue: string) => Promise<Options<TaxonomyItemOptionDto>>;
  placeholder: string | undefined;
  createPrefix?: string;
};

export const TypeAheadAsyncDropDown: React.FC<TypeAheadAsyncDropDownProps> = ({
  selected,
  typeAheadResults,
  handleTypeAheadChange,
  asyncSearchFunc,
  placeholder,
  createPrefix,
}) => {
  const typeAheadInputRef = useRef<HTMLTextAreaElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const createPrefixLabel = createPrefix ?? 'Create';

  const loadOptions = useCallback(
    async (inputValue: string) => {
      if (inputValue) {
        setIsLoading(true);
        const results = await asyncSearchFunc?.(inputValue);
        setIsLoading(false);
        return results ?? [];
      }
      return [];
    },
    [asyncSearchFunc],
  );

  useEffect(() => {
    typeAheadInputRef.current?.focus();
  }, []);

  const selectedValue = useMemo(() => {
    return selected;
  }, [selected]);

  const memoizedOptions = useMemo(() => {
    return typeAheadResults ?? [];
  }, [typeAheadResults]);

  return (
    <TypeAheadDropdownDiv>
      <AsyncCreatableSelect
        menuPortalTarget={document.body}
        menuShouldBlockScroll={true}
        menuShouldScrollIntoView={true}
        placeholder={placeholder}
        cacheOptions={true}
        defaultOptions={memoizedOptions}
        loadOptions={loadOptions}
        isLoading={isLoading}
        value={selectedValue}
        onChange={(result) => handleTypeAheadChange(result as TaxonomyItemOptionDto)}
        getOptionValue={((option) => option) as GetOptionValue<unknown>}
        styles={customStyles}
        formatCreateLabel={(inputValue) => createCreateLabel(createPrefixLabel, inputValue)}
        onCreateOption={(inputValue) =>
          handleTypeAheadChange({
            id: OTHER_ID,
            label: inputValue,
            itemId: OTHER_ID,
            itemLabel: inputValue,
            isSelected: true,
          } as TaxonomyItemOptionDto)
        }
      />
    </TypeAheadDropdownDiv>
  );
};
