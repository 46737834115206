export enum ModelNames {
  Any,
  Address,
  BotContext,
  Contact,
  Customer,
  CustomerIssueMap,
  Item,
  Media,
  Message,
  PropertyGroup,
  RecentResidentProfile,
  Resident,
  Role,
  ServiceRequest,
  ServiceRequestUsers,
  Taxonomy,
  Unit,
  User,
}
