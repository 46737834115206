import { onlineManager } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

export function useOnlineStatus() {
  const [online, setOnline] = useState(window.navigator.onLine);
  useEffect(() => {
    const unsubscribe = onlineManager.subscribe((isOnline) => {
      setOnline(isOnline);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return online;
}
