import { AddressDto } from './address.dto';

export class UnitDto {
  id: string;
  sourceId: string;
  customerId: string;
  customerName: string;
  customerIconUrl: string;
  propertyId: string;
  propertyName: string;
  address: AddressDto;
  yearBuilt: string;
  type: string;
  squareFootage: number;
  bedroomsNumber: number;
  fullBathroomsNumber: number;
  halfBathroomsNumber: number;
  floor: number;
  floors: number;
  createdAt: number;
  updatedAt: number;
}
