import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Modal from 'styled-react-modal';
import tw from 'twin.macro';
import { ButtonV2, StyledLink } from '../../components';

const IconButton = tw.button`p-0 border-0 cursor-pointer bg-none`;
const Title = tw.h5`m-0 text-lg font-medium leading-7 text-left`;
const StyledIcon = tw(FontAwesomeIcon)`fill-current text-disabled`;
const ModalContainer = tw.div`fixed flex flex-col justify-between w-full max-w-md gap-4 p-4 bg-background-light rounded-3xl top-4`;
const FlexContainer = tw.div`flex items-start justify-between gap-4`;
const ButtonContainer = tw.div`w-full`;
const LinkContainer = tw.div`flex justify-center`;

type NonMxModalProps = {
  isVisible: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  customBodyCopy?: string;
  maintenanceManagerName?: string;
};

export const NonMxModal = ({
  isVisible,
  onClose,
  onConfirm,
  customBodyCopy,
  maintenanceManagerName,
}: NonMxModalProps) => {
  const { t } = useTranslation();
  const bodyCopy =
    customBodyCopy ||
    t('problemCapture.nonMxModal.bodyCopy', { name: maintenanceManagerName ?? 'property management' });
  return (
    <Modal isOpen={isVisible} onBackgroundClick={onClose} onEscapeKeydown={onClose}>
      <ModalContainer>
        <FlexContainer>
          <Title>{t('problemCapture.nonMxModal.header')}</Title>
          <IconButton onClick={onClose} aria-label={t('problemCapture.nonMxModal.cancel')}>
            <StyledIcon icon={faXmark} size="lg" />
          </IconButton>
        </FlexContainer>
        <div>{bodyCopy}</div>
        <div>{t('problemCapture.nonMxModal.bodyCopy2')}</div>

        <ButtonContainer>
          <ButtonV2 primary={true} onClick={onConfirm}>
            {t('problemCapture.nonMxModal.continue')}
          </ButtonV2>
        </ButtonContainer>
        <LinkContainer>
          <StyledLink onClick={onClose} linkText={t('problemCapture.nonMxModal.cancel')}></StyledLink>
        </LinkContainer>
      </ModalContainer>
    </Modal>
  );
};
