import { MediaFileInfoDto } from '@deprecated/dtos';
type MediaType = 'image' | 'video' | 'unknown';

export const getMediaTypeFromExtension = (mediaFileInfo: MediaFileInfoDto): MediaType => {
  if (mediaFileInfo.contentType) return mediaFileInfo.contentType.split('/')[0] as MediaType;
  const ext = mediaFileInfo?.filename?.split('.').pop();
  if (!ext) {
    return 'unknown';
  }

  const imageExtensions = ['png', 'jpg', 'gif', 'jpeg', 'svg'];
  const videoExtensions = ['mp4', 'mov'];

  // Extract jpg from .jpg
  const strippedExt = ext.slice(1, ext.length);

  if (imageExtensions.includes(strippedExt)) {
    return 'image';
  } else if (videoExtensions.includes(strippedExt)) {
    return 'video';
  } else {
    return 'unknown';
  }
};

export const ensureFilesHaveUniqueNames = (files: File[]): File[] => {
  const result: File[] = [];

  const found = new Map<string, number>();
  for (const file of files) {
    if (!found.has(file.name)) {
      found.set(file.name, 0);
      result.push(file);
    } else {
      const previousCount = found.get(file.name) ?? 0;

      result.push(new File([file], `${previousCount}-${file.name}`, { type: file.type }));

      found.set(file.name, previousCount + 1);
    }
  }

  return result;
};
