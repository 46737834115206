import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import ButtonV2 from '../../components/button.v2';
import { ResizeableTextArea } from '../../components/textarea';
import ToggleSwitch from '../../components/toggle-switch';

const Intro = tw.div`mb-8 text-base font-bold`;
const Subtitle = tw.label`inline-block mb-2 text-base font-normal`;
const Spacer = tw.div`h-8`;
const TextAreaWrapper = styled.div<{ isFocused: boolean }>(({ isFocused }) => [
  tw`flex px-3 border border-background`,
  css({ 'border-radius': '10px' }),
  isFocused && tw`border-disabled`,
]);

const SecondaryText = styled.p<{ hasError: boolean }>(({ hasError }) => [
  tw`py-2 text-xs text-center`,
  hasError && tw`text-error`,
]);

const ButtonContainer = tw.div`flex flex-col items-center justify-center mt-2`;

type EntryDetailsPageProps = {
  showPermissionToEnter?: boolean;
  showEntryNotes?: boolean;
  onPermissionToEnterChanged: (permissionToEnter: boolean) => void;
  onEntryNotesChanged: (entryNotes: string) => void;
  permissionToEnter?: boolean;
  entryNotes: string;
  helperText?: string;
  submitButtonlabel: string;
  onSubmit: VoidFunction;
  showSpinner?: boolean;
  showFooter?: boolean;
};

export const EntryDetailsPage: React.FC<EntryDetailsPageProps> = ({
  showPermissionToEnter,
  showEntryNotes,
  onPermissionToEnterChanged,
  onEntryNotesChanged,
  permissionToEnter,
  entryNotes,
  helperText,
  onSubmit,
  submitButtonlabel,
  showSpinner,
  showFooter = true,
}) => {
  const { t } = useTranslation();

  const [entryNotesFocused, setEntryNotesFocused] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const handleEntryNotesChanged = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onEntryNotesChanged(e.target.value);
    },
    [onEntryNotesChanged]
  );

  const handleEntryNotesBlurred = useCallback(() => {
    setEntryNotesFocused(false);
  }, []);

  const handleEntryNotesFocused = useCallback(() => {
    setEntryNotesFocused(true);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!hasError) {
      onSubmit();
    }
  }, [hasError, onSubmit]);

  useEffect(() => {
    if (entryNotes.length > 0 && showPermissionToEnter && permissionToEnter === undefined) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [entryNotes.length, permissionToEnter, showPermissionToEnter]);

  const isActive = showPermissionToEnter && permissionToEnter === undefined ? false : true;
  const showHelperText = isActive && !hasError;

  return (
    <>
      <Intro>{t('chat.preferences.intro')}</Intro>
      {showPermissionToEnter && (
        <>
          <Subtitle>{t('chat.preferences.permissionToEnter')}</Subtitle>
          <ToggleSwitch
            onValueChanged={onPermissionToEnterChanged}
            initialValue={permissionToEnter}
            yesTextOverride={t('chat.preferences.permissionToEnterYes')}
            noTextOverride={t('chat.preferences.permissionToEnterNo')}
          />
          {hasError && <SecondaryText hasError={true}>{t('chat.preferences.permissionToEnterRequired')}</SecondaryText>}
        </>
      )}
      {showPermissionToEnter && showEntryNotes && <Spacer />}
      {showEntryNotes && (
        <>
          <Subtitle htmlFor="entryNotes">{t('chat.preferences.entryNotes')}</Subtitle>
          <TextAreaWrapper isFocused={entryNotesFocused}>
            <ResizeableTextArea
              id="entryNotes"
              name="entryNotes"
              minRows={2}
              maxRows={2}
              value={entryNotes}
              onChange={handleEntryNotesChanged}
              onFocus={handleEntryNotesFocused}
              onBlur={handleEntryNotesBlurred}
            />
          </TextAreaWrapper>
        </>
      )}
      {showFooter && (
        <ButtonContainer>
          <ButtonV2 primary={true} disabled={!isActive} onClick={handleSubmit} isLoading={showSpinner}>
            {submitButtonlabel}
          </ButtonV2>
          {showHelperText && <SecondaryText hasError={false}>{helperText}</SecondaryText>}
        </ButtonContainer>
      )}
    </>
  );
};
