import {
  ChatMessage,
  KeyValuePairDto,
  ProblemCaptureSelectionType,
  Sender,
  ServiceRequestAttemptTaxonomyMap,
  TranslatedText,
  TypeAheadResponseItemDto,
  UpdateServiceRequestAttemptDto,
  UserConfirmationDto,
  UserConfirmationRequestDto,
  UserSessionDto,
} from '@mezo/common/dtos';
import { AbandonReason, getLanguage, Page, UserSessionStatusType } from '@mezo/common/utils';
import { LocalStorageResident, MEZO_RESIDENT_STORAGE_KEY, useLocalStorage } from '@mezo/web/hooks';
import {
  useCloseSubmitAttemptForUserSession,
  useCreateServiceRequestAttemptForUserSession,
  useCreateUserSession,
  useProblemCapture,
  useSendMessageForAttempt,
  useStartIntelliflowSessionForAttempt,
  useUpdateItem,
  useUpdateServiceRequestAttemptForUserSession,
  useUpdateUserSessionForResidentInput,
  useUpdateUserSessionPage,
  useUpdateUserSessionResident,
  useUserConfirmation,
} from '@mezo/web/queries';
import { ApiClient } from '@mezo/web/utils';
import { setTags } from '@sentry/react';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

type CreateSubmitAttemptPayload = {
  isEmergency: boolean;
  page?: Page;
  taxonomy?: Partial<ServiceRequestAttemptTaxonomyMap>;
  howItWorksConfirmation?: boolean;
};
type ConfirmTypeAheadResponse = { userSessionDto: UserSessionDto; userConfirmationDto: UserConfirmationDto };

const DEFAULT_PROMISE = new Promise<UserSessionDto>((resolve) => {
  resolve({} as UserSessionDto);
});

export interface UserSessionContextValue {
  userSessionId?: string;
  submitAttemptId?: string;
  getUserSession: () => UserSessionDto | undefined;
  customerId?: string;
  residentId?: string;
  unitId?: string;
  config?: UserSessionDto['config'];
  setCustomerResidentUnit: (customerId: string, residentId: string, unitId: string) => void;
  updateUserSessionServiceRequestAttempt: (dto: UpdateServiceRequestAttemptDto) => void;
  closeServiceRequestAttemptAndMarkAbandoned: (message?: string) => void;
  closeServiceRequestAttemptAndMarkRedirected: () => void;
  updateUserSessionPage: (page: Page, node?: string) => void;
  closeServiceRequestAttempt: (type: UserSessionStatusType, reason?: AbandonReason) => void;
  createUserSessionServiceRequestAttempt: (payload: CreateSubmitAttemptPayload) => void;
  getUpdatedUserSession: () => void;
  submitProblemCapture: (
    payload: TypeAheadResponseItemDto,
    typeAheadSelectionType: ProblemCaptureSelectionType,
  ) => Promise<{ userSessionDto: UserSessionDto; userConfirmationDto: UserConfirmationDto }>;
  updateItem: (payload: {
    itemId: string;
    itemLabel: string;
    symptomId: string;
    locationId: string;
    componentId: string;
  }) => Promise<UserConfirmationDto>;
  submitUserConfirmation: (payload: UserConfirmationRequestDto) => Promise<UserSessionDto>;
  howItWorksConfirmation?: boolean;
  setHowItWorksConfirmation: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  sendMessage: (payload: KeyValuePairDto) => Promise<UserSessionDto>;
  startSession: () => Promise<UserSessionDto>;
  updateAdditionalDetails: (residentInput: string, skipStep: boolean) => Promise<UserSessionDto>;
}

export const UserSessionContext = createContext<UserSessionContextValue>({
  setCustomerResidentUnit: (customerId: string, residentId: string, unitId: string) => {
    /* */
  },
  updateUserSessionServiceRequestAttempt: (dto: UpdateServiceRequestAttemptDto) => {
    /* */
  },
  closeServiceRequestAttemptAndMarkRedirected: () => {
    /* */
  },
  closeServiceRequestAttemptAndMarkAbandoned: (message?: string) => {
    /* */
  },
  updateUserSessionPage: (page: Page, node?: string) => {
    /** */
  },
  closeServiceRequestAttempt: (type: UserSessionStatusType, reason?: AbandonReason) => {
    /* */
  },
  createUserSessionServiceRequestAttempt: (payload: CreateSubmitAttemptPayload) => {
    return;
  },
  getUpdatedUserSession: () => {
    return;
  },
  getUserSession: function (): UserSessionDto | undefined {
    return;
  },
  setHowItWorksConfirmation: () => {
    /** */
  },
  submitProblemCapture: (payload: TypeAheadResponseItemDto, typeAheadSelectionType: ProblemCaptureSelectionType) => {
    /* */
    return Promise.resolve({} as { userSessionDto: UserSessionDto; userConfirmationDto: UserConfirmationDto });
  },
  updateItem: (payload: {
    itemId: string;
    itemLabel: string;
    symptomId: string;
    locationId: string;
    componentId: string;
  }) => {
    return Promise.resolve({} as UserConfirmationDto);
  },

  submitUserConfirmation: (payload: UserConfirmationRequestDto) => {
    /* */
    return Promise.resolve({} as UserSessionDto);
  },
  sendMessage: (payload: KeyValuePairDto) => DEFAULT_PROMISE,
  startSession: () => DEFAULT_PROMISE,
  updateAdditionalDetails: (residentInput: string, skipStep: boolean) => DEFAULT_PROMISE,
});

export const useUserSessionContext = () => useContext(UserSessionContext);

export const UserSessionContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const initialized = useRef(false);

  const [userSession, setUserSession] = useState<UserSessionDto | undefined>(undefined);

  const [howItWorksConfirmation, setHowItWorksConfirmation] = useState<boolean | undefined>(undefined);

  const { updateLocalStorage: updateLocalStorageResident } =
    useLocalStorage<LocalStorageResident>(MEZO_RESIDENT_STORAGE_KEY);

  const { mutateAsync: createUserSessionAsync } = useCreateUserSession();
  const { mutateAsync: updateUserSessionResidentAsync } = useUpdateUserSessionResident();
  const { mutateAsync: updateUserSessionPageAsync } = useUpdateUserSessionPage();

  const { mutateAsync: createServiceRequestAttemptAsync } = useCreateServiceRequestAttemptForUserSession();
  const { mutateAsync: updateServiceRequestAttemptAsync } = useUpdateServiceRequestAttemptForUserSession();
  const { mutateAsync: closeServiceRequestAttemptAsync } = useCloseSubmitAttemptForUserSession();
  const { mutateAsync: submitProblemCaptureStep } = useProblemCapture();
  const { mutateAsync: updateItemConfirmationOptions } = useUpdateItem();
  const { mutateAsync: submitUserConfirmationStep } = useUserConfirmation();

  const { mutateAsync: sendMessageAsync } = useSendMessageForAttempt();
  const { mutateAsync: startIntelliflowSessionAsync } = useStartIntelliflowSessionForAttempt();
  const { mutateAsync: updateSubmitAttemptResidentInput } = useUpdateUserSessionForResidentInput();

  useEffect(() => {
    async function createUserSession() {
      const response = await createUserSessionAsync({});
      if (response) {
        setUserSession(response);
        updateLocalStorageResident({ currentUserSessionId: response.id });
        setTags({
          userSessionId: response.id,
        });
      }
    }

    if (!initialized.current) {
      initialized.current = true;
      if (!userSession?.id) {
        createUserSession();
      }
    }
  }, [createUserSessionAsync, updateLocalStorageResident, userSession?.id]);

  const setCustomerResidentUnit = useCallback(
    async (customerId: string, residentId: string, unitId: string) => {
      if (!userSession?.id) {
        return;
      }
      setTags({
        residentId,
        customerId,
        unitId,
      });
      const session = await updateUserSessionResidentAsync({
        userSessionId: userSession.id,
        request: {
          customerId,
          residentId,
          unitId,
        },
      });
      if (session) {
        updateLocalStorageResident({ currentUserSessionId: session.id });
        setUserSession(session);
      }
    },
    [updateLocalStorageResident, updateUserSessionResidentAsync, userSession?.id],
  );

  const closeServiceRequestAttempt = useCallback(
    async (type: UserSessionStatusType, reason?: AbandonReason) => {
      if (!userSession?.currentSubmitAttempt?.id) {
        return;
      }
      const updateRequest = await closeServiceRequestAttemptAsync({
        userSessionId: userSession.id,
        request: {
          status: type,
          abandonReason: reason,
        },
      });
      setUserSession(updateRequest);
    },
    [userSession?.currentSubmitAttempt?.id, userSession?.id, closeServiceRequestAttemptAsync],
  );

  const updateUserSessionPage = useCallback(
    async (page: Page, node?: string) => {
      if (!userSession?.id) {
        return;
      }
      const session = await updateUserSessionPageAsync({
        userSessionId: userSession.id,
        request: {
          page,
          node,
        },
      });
      setTags({
        page,
        node,
      });
      if (session) {
        setUserSession(session);
      }
    },
    [updateUserSessionPageAsync, userSession?.id],
  );

  const updateUserSessionServiceRequestAttempt = useCallback(
    async (dto: UpdateServiceRequestAttemptDto) => {
      if (!userSession?.currentSubmitAttempt?.id) {
        return;
      }
      const session = await updateServiceRequestAttemptAsync({
        userSessionId: userSession.id,
        attemptId: userSession.currentSubmitAttempt.id,
        request: {
          taxonomy: dto.taxonomy,
          emergencyDetails: dto.emergencyDetails,
          additionalDetails: dto.additionalDetails,
          isEmergency: dto.isEmergency,
        },
      });
      setTags({
        attemptId: userSession.currentSubmitAttempt.id,
        isEmergency: userSession.currentSubmitAttempt.isEmergency,
        item: dto.taxonomy?.itemId,
      });
      if (session) {
        setUserSession(session);
      }
    },
    [
      userSession?.currentSubmitAttempt?.id,
      userSession?.currentSubmitAttempt?.isEmergency,
      userSession?.id,
      updateServiceRequestAttemptAsync,
    ],
  );

  const getUpdatedUserSession = useCallback(async () => {
    const session = await ApiClient.CHAT_API.utility.get(`/user-session/${userSession?.id}`);
    if (session) {
      setUserSession(session?.data);
    }
  }, [userSession?.id]);

  const createUserSessionServiceRequestAttempt = useCallback(
    async (payload: CreateSubmitAttemptPayload) => {
      if (!userSession?.id) {
        return;
      }
      const { isEmergency, page, taxonomy, howItWorksConfirmation } = payload;
      const session: UserSessionDto = await createServiceRequestAttemptAsync({
        userSessionId: userSession?.id,
        request: {
          isEmergency,
          page,
          taxonomy,
          howItWorksConfirmation,
        },
      });
      setTags({
        submitAttemptId: session?.currentSubmitAttempt?.id,
        isEmergency,
      });
      if (session) {
        setUserSession(session);
      }
      if (session?.currentSubmitAttempt?.id) {
        updateLocalStorageResident({ currentAttemptId: session.currentSubmitAttempt.id });
      }
    },
    [createServiceRequestAttemptAsync, updateLocalStorageResident, userSession?.id],
  );

  const closeServiceRequestAttemptAndMarkAbandoned = useCallback(
    async (message?: string) => {
      if (!userSession?.id) {
        return;
      }
      if (!userSession.currentSubmitAttempt?.id) {
        return;
      }
      const result = await closeServiceRequestAttemptAsync({
        userSessionId: userSession.id,
        request: {
          status: UserSessionStatusType.ABANDONED,
          abandonReason: AbandonReason.USER_EXIT,
          abandonErrorMessage: message,
        },
      });

      if (result) {
        setUserSession(result);
      }
    },
    [closeServiceRequestAttemptAsync, userSession?.currentSubmitAttempt?.id, userSession?.id],
  );
  const closeServiceRequestAttemptAndMarkRedirected = useCallback(async () => {
    if (!userSession?.id) {
      return;
    }
    if (!userSession.currentSubmitAttempt?.id) {
      return;
    }
    const result = await closeServiceRequestAttemptAsync({
      userSessionId: userSession.id,
      request: {
        status: UserSessionStatusType.REDIRECT,
      },
    });

    if (result) {
      setUserSession(result);
    }
  }, [closeServiceRequestAttemptAsync, userSession?.currentSubmitAttempt?.id, userSession?.id]);

  const getUserSession = useCallback(() => userSession, [userSession]);

  const submitProblemCapture = useCallback(
    async (payload: TypeAheadResponseItemDto, problemCaptureSelectionType: ProblemCaptureSelectionType) => {
      if (!userSession?.id || !userSession?.currentSubmitAttempt?.id)
        throw new Error('User session or attempt not found');
      const response: ConfirmTypeAheadResponse = await submitProblemCaptureStep({
        userSessionId: userSession?.id,
        submitAttemptId: userSession?.currentSubmitAttempt?.id,
        data: payload,
        locale: userSession.user.locale,
        problemCaptureSelectionType,
      });
      setUserSession(response.userSessionDto);
      return response;
    },
    [submitProblemCaptureStep, userSession?.currentSubmitAttempt?.id, userSession?.id, userSession?.user.locale],
  );
  const updateItem = useCallback(
    async (payload: {
      itemId: string;
      itemLabel: string;
      symptomId: string;
      locationId: string;
      componentId: string;
    }) => {
      if (!userSession?.id || !userSession?.currentSubmitAttempt?.id)
        throw new Error('User session or attempt not found');
      return await updateItemConfirmationOptions({
        ...payload,
        userSessionId: userSession?.id,
        submitAttemptId: userSession?.currentSubmitAttempt?.id,
        locale: userSession.user.locale,
      });
    },
    [updateItemConfirmationOptions, userSession?.currentSubmitAttempt?.id, userSession?.id, userSession?.user.locale],
  );
  const submitUserConfirmation = useCallback(
    async (payload: UserConfirmationRequestDto) => {
      if (!userSession?.id || !userSession?.currentSubmitAttempt?.id)
        throw new Error('User session or attempt not found');
      const response: UserSessionDto = await submitUserConfirmationStep({
        userSessionId: userSession?.id,
        submitAttemptId: userSession?.currentSubmitAttempt?.id,
        data: payload,
      });
      setUserSession(response);
      return response;
    },
    [submitUserConfirmationStep, userSession?.currentSubmitAttempt?.id, userSession?.id, userSession?.user.locale],
  );

  const sendMessage = useCallback(
    async (payload: KeyValuePairDto) => {
      return new Promise<UserSessionDto>((resolve, reject) => {
        if (!userSession?.id || !userSession?.currentSubmitAttempt?.id) {
          reject('User session or attempt not found');
          return;
        }
        const language = getLanguage(userSession.user.locale);
        const translatedText = {
          [language]: [payload.label],
        } as TranslatedText;
        const chatMessage: ChatMessage = {
          sender: Sender.RESIDENT,
          messages: translatedText,
          createdAt: Date.now(),
        };
        const messages = userSession.currentSubmitAttempt.messages || [];
        messages.push(chatMessage);
        setUserSession({
          ...userSession,
          currentSubmitAttempt: {
            ...userSession.currentSubmitAttempt,
            messages,
          },
        });
        sendMessageAsync({
          userSessionId: userSession?.id,
          submitAttemptId: userSession?.currentSubmitAttempt?.id,
          payload,
        })
          .then((response) => {
            setUserSession(response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [sendMessageAsync, userSession],
  );

  const startSession = useCallback(async () => {
    return new Promise<UserSessionDto>((resolve, reject) => {
      if (!userSession?.id || !userSession?.currentSubmitAttempt?.id) {
        reject('User session or attempt not found');
        return;
      }
      startIntelliflowSessionAsync({
        userSessionId: userSession?.id,
        submitAttemptId: userSession?.currentSubmitAttempt?.id,
      })
        .then((response) => {
          setUserSession(response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, [startIntelliflowSessionAsync, userSession?.currentSubmitAttempt?.id, userSession?.id]);

  const updateAdditionalDetails = useCallback(
    async (residentInput: string, skipStep: boolean) => {
      return new Promise<UserSessionDto>((resolve, reject) => {
        if (!userSession?.id || !userSession?.currentSubmitAttempt?.id) {
          reject('User session or attempt not found');
          return;
        }
        updateSubmitAttemptResidentInput({
          userSessionId: userSession?.id,
          attemptId: userSession?.currentSubmitAttempt?.id,
          residentInput,
          skipStep,
        })
          .then((response) => {
            setUserSession(response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [updateServiceRequestAttemptAsync, userSession?.currentSubmitAttempt?.id, userSession?.id],
  );

  const contextValue: UserSessionContextValue = {
    userSessionId: userSession?.id,
    submitAttemptId: userSession?.currentSubmitAttempt?.id,
    getUserSession,
    customerId: userSession?.customerId,
    residentId: userSession?.residentId,
    unitId: userSession?.unitId,
    config: userSession?.config,
    closeServiceRequestAttemptAndMarkRedirected,
    setCustomerResidentUnit,
    updateUserSessionServiceRequestAttempt,
    getUpdatedUserSession,
    closeServiceRequestAttemptAndMarkAbandoned,
    updateUserSessionPage,
    closeServiceRequestAttempt,
    createUserSessionServiceRequestAttempt,
    howItWorksConfirmation,
    setHowItWorksConfirmation,
    submitProblemCapture,
    updateItem,
    submitUserConfirmation,
    sendMessage,
    startSession,
    updateAdditionalDetails,
  };

  return <UserSessionContext.Provider value={contextValue}>{children}</UserSessionContext.Provider>;
};
