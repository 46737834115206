import { RouteBuilder } from '@mezo/web/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalStorageResident, MEZO_RESIDENT_STORAGE_KEY, useLocalStorage } from './useLocalStorage';

export const useResidentHomeRedirect = () => {
  const navigate = useNavigate();
  const { value: localStorageResident } = useLocalStorage<LocalStorageResident>(MEZO_RESIDENT_STORAGE_KEY);

  useEffect(() => {
    // if theres resident info in localstorage redirect to resident home to attempt authenticating with those stored credentials
    if (localStorageResident?.customerId && localStorageResident?.residentId && localStorageResident?.unitId) {
      const residentHomeUrl = new RouteBuilder()
        .buildResidentHomeRoute(
          localStorageResident.customerId,
          localStorageResident.residentId,
          localStorageResident.unitId
        )
        .prependPath(RouteBuilder.RESIDENT)
        .build();
      navigate(residentHomeUrl);
    }
  }, [localStorageResident?.customerId, localStorageResident?.residentId, localStorageResident?.unitId, navigate]);
};
