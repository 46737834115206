import { ApiClient } from '@mezo/web/utils';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';

export const useResidentAuth = (
  customerId: string | undefined,
  residentId: string | undefined,
  unitId: string | undefined
) => {
  const [chatToken, setChatToken] = useState<string | undefined>(undefined);
  const [tokenError, setTokenError] = useState<boolean>(false);

  const fetchChatToken = useCallback(async (customerId: string, unitId: string, residentId: string) => {
    try {
      const response = await ApiClient.CHAT_API.utility.post('/residents/token', {
        customerId,
        unitId,
        residentId,
      });

      return response?.data;
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(
          `Unable to fetch token: ${err.response?.status} error for customer ${customerId}, resident ${residentId}, and unit ${unitId}`
        );
      }

      setTokenError(true);
    }
    return;
  }, []);

  useEffect(() => {
    async function getIdToken() {
      if (unitId && residentId && customerId && !chatToken) {
        const token = await fetchChatToken(customerId, unitId, residentId);
        if (token) {
          setChatToken(token);
          setTokenError(false);
          ApiClient.CHAT_API.setAuthToken(`Bearer ${token}`);
        }
      }
    }
    getIdToken();
  }, [customerId, unitId, residentId, fetchChatToken, chatToken]);

  return [chatToken, tokenError];
};
