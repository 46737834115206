import { AddIntelliflowVersionDto, IntelliflowVersionDto } from '@mezo/common/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useCreateIntelliflowVersion = () => {
  return useMutation({
    mutationFn: async ({ versionData }: { versionData: AddIntelliflowVersionDto }) => {
      const response = await ApiClient.CHAT_API.utility.post<AddIntelliflowVersionDto>(
        '/intelliflow-version',
        versionData
      );

      return response?.data;
    },
  });
};

export const useSetIntelliflowVersion = () => {
  return useMutation({ mutationFn: (id: string) => ApiClient.CHAT_API.utility.patch('/intelliflow-version', { id }) });
};

type TransformDataFn = (versions: IntelliflowVersionDto[]) => {
  label: string;
  value: string;
  id: string;
  version: string;
  enabledItemIds: string[];
  releaseNotes: string;
  isPlatformVersion: boolean;
}[];

export const useGetIntelliflowVersions = (transformData: TransformDataFn) => {
  return useQuery({
    queryKey: ['getIntelliflowVersions'],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get<IntelliflowVersionDto[]>('/intelliflow-version');
      return response?.data;
    },
    select: transformData,
  });
};
