import { EmergencyConfigDto, EmergencyItem } from '@mezo/common/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useQuery } from '@tanstack/react-query';

export const useEmergencyList = (unitId: string | undefined) => {
  return useQuery({
    queryKey: ['emergencies', { unitId }],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get<EmergencyItem[]>(`/emergencies?unitId=${unitId}`);
      return response?.data;
    },
    enabled: !!unitId,
  });
};

export const useEmergency = (emergencyId: string | undefined, unitId: string | undefined) => {
  return useQuery({
    queryKey: ['emergencies', emergencyId, { unitId }],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get<EmergencyItem[]>(`/emergencies?unitId=${unitId}`);
      return response?.data.find(({ id }) => id === emergencyId);
    },
    enabled: !!unitId && !!emergencyId,
  });
};

export const useEmergencyConfig = (unitId: string | undefined) => {
  return useQuery({
    queryKey: ['emergencies', unitId, 'config'],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get<EmergencyConfigDto>(`/emergencies/config?unitId=${unitId}`);
      return response?.data;
    },
    enabled: !!unitId,
    refetchOnWindowFocus: false,
  });
};
