import styled from 'styled-components';
import tw from 'twin.macro';

export const Indicators = tw.div`flex items-center gap-2 mx-auto`;
export const Indicator = styled.div<{ isActive: boolean }>(({ isActive }) => [
  tw`rounded-full w-[10px] h-[10px] bg-background`,
  isActive && tw`bg-primary`,
]);

type IndicatorGroupProps = {
  sceneLength: number;
  activeScene: number;
};
export const IndicatorGroup: React.FC<IndicatorGroupProps> = ({ sceneLength, activeScene }) => {
  const generateDots = () => {
    const elements = [];
    for (let i = 0; i <= sceneLength; i++) {
      elements.push(<Indicator key={`scene-${i}`} isActive={i === activeScene} tabIndex={-1} />);
    }
    return elements;
  };
  return (
    <Indicators role="group" aria-roledescription="carousel indicators">
      {generateDots()}
    </Indicators>
  );
};
