import { Locale, Page } from '@mezo/common/utils';
import { DEFAULT_BOT_NAME } from '@mezo/web/utils';
import { useLocale } from '@react-aria/i18n';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { IndicatorGroup } from '../../components/gallery';
import { MezoImage, MezoImageMap } from '../../components/mezo-image';
import { PageWrapper } from '../../components/page-wrapper';
import StaticImgPath from './before-starting-EN.png';
import StaticImgPathES from './before-starting-ES.png';

const VerticalFlexContainer = tw.div`flex flex-col gap-4 p-4`;
const ListItem = tw.li`text-2xl font-medium`;
const BeforeStartingImg = tw.img`w-full max-w-sm m-auto `;
const Text = tw.p`text-sm leading-6`;

const IMAGE_MAP: MezoImageMap = {
  [Locale.EN_US]: StaticImgPath,
  [Locale.ES_US]: StaticImgPathES,
  default: StaticImgPath,
};

type HowItWorksProps = {
  botNameOverride?: string;
  handleNavigate: () => void;
};

const OrderedList = tw.ol`list-decimal list-inside`;

export const HowItWorks: React.FC<HowItWorksProps> = ({ botNameOverride, handleNavigate }) => {
  const { locale } = useLocale();
  const { t } = useTranslation();

  return (
    <PageWrapper
      title={t('landing.howItWorks.title')}
      pageName={Page.SUBMIT_REQUEST_HOW_IT_WORKS}
      onButtonClick={handleNavigate}
      isButtonDisabled={false}
      buttonLabel={t('landing.howItWorks.proceed')}
    >
      <VerticalFlexContainer>
        <OrderedList>
          <ListItem>{t('landing.howItWorks.item1', { name: botNameOverride || DEFAULT_BOT_NAME })}</ListItem>
          <ListItem>{t('landing.howItWorks.item2')}</ListItem>
          <ListItem>{t('landing.howItWorks.item3')}</ListItem>
        </OrderedList>
        <MezoImage imageMap={IMAGE_MAP} locale={locale} StyledComponent={BeforeStartingImg} />
        <Text>
          <strong>{t('landing.howItWorks.note')}:</strong> {t('landing.howItWorks.request')}
        </Text>
        <IndicatorGroup sceneLength={1} activeScene={0} />
      </VerticalFlexContainer>
    </PageWrapper>
  );
};
