import { UserSessionStatusType } from '@mezo/common/utils';
import { useGetSubmitAttempt } from '@mezo/web/queries';
import { RouteBuilder } from '@mezo/web/utils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const THIRTY_SECONDS = 1000 * 30;

export const useRedirectForAbandonedAttempts = (
  customerId?: string,
  residentId?: string,
  unitId?: string,
  userSessionId?: string,
  attemptId?: string
) => {
  const navigate = useNavigate();
  const { data: submitAttempt, refetch } = useGetSubmitAttempt(userSessionId, attemptId);
  // setup the interval
  useEffect(() => {
    if (!customerId || !residentId || !unitId || !userSessionId || !attemptId) return;
    const intervalId = setInterval(async () => {
      if (submitAttempt?.id) await refetch();
    }, THIRTY_SECONDS);
    return () => clearInterval(intervalId);
  }, [attemptId, customerId, refetch, residentId, submitAttempt?.id, unitId, userSessionId]);

  // check for values and redirect
  useEffect(() => {
    if (!customerId || !residentId || !unitId || !userSessionId || !attemptId) return;
    if (submitAttempt?.status === UserSessionStatusType.ABANDONED) {
      const abandonedUrl = new RouteBuilder()
        .buildAbandonedRoute()
        .prependResidentHomeRoute(customerId, residentId, unitId)
        .build();
      navigate(abandonedUrl);
    }
  }, [attemptId, customerId, navigate, residentId, submitAttempt?.status, unitId, userSessionId]);
};
