import { useEffect, useState } from 'react';

export const useInfiniteScroll = ({
  elemId = 'body',
  fetching: fetchingInit = false,
  hasMore: hasMoreInit = false,
  threshold = 200,
}) => {
  const [fetching, setFetching] = useState(fetchingInit);
  const [hasMore, setHasMore] = useState(hasMoreInit);

  function handleScroll() {
    const elem = document.getElementById(elemId);
    if (!elem) return;
    let shouldScroll = false;
    if (elem.scrollTop === elem.scrollHeight - elem.offsetHeight) {
      shouldScroll = true;
    }
    if (!hasMore || fetching || !shouldScroll) return;
    setFetching(true);
  }

  useEffect(() => {
    if (!elemId) return;
    const elem = document.getElementById(elemId);
    if (!elem) {
      console.warn(`Element with id ${elemId} not found`);
      return;
    }
    elem.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return [fetching, setFetching, setHasMore];
};
