import { IsOptional, IsString } from 'class-validator';

export class FieldDto {
  @IsString()
  @IsOptional()
  readonly field: string;
  @IsString()
  @IsOptional()
  readonly value: string | boolean;
}
