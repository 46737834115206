const getItem = <T>(key: string): T | undefined => {
  try {
    const item = window.localStorage.getItem(key);
    if (item === null) {
      return;
    }
    return parseJSON(item) as T;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
    return;
  }
};

const setItem = <T>(key: string, value: T): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  try {
    window.localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    console.warn(`Error reading localStorage key “${key}”:`, error);
    return false;
  }
};

const removeItem = (key: string): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  try {
    window.localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.warn(`Error clearing localStorage key “${key}”:`, error);
    return false;
  }
};

const parseJSON = <T>(value: string | null): T | undefined => {
  try {
    return value === undefined ? undefined : JSON.parse(value ?? '');
  } catch {
    console.warn('parsing error on', { value });
    return undefined;
  }
};

export const storage = {
  getItem,
  setItem,
  removeItem,
};
