import { IsEnum, IsOptional, IsString, IsUrl, ValidateIf } from 'class-validator';

export interface SecretPayload {
  username: string;
  password: string;
  url: string;
  serverName?: string;
  database?: string;
}

export class CreateCustomerImplDto {
  @IsString()
  customerId: string;
  @IsString()
  username: string;
  @IsString()
  password: string;
  @IsUrl()
  url: string;
  @IsString()
  @IsOptional()
  taxonomyMapKey: string;
  @IsEnum(['Yardi', 'Appfolio', 'Entrata'])
  integrationSystem: 'Yardi' | 'Appfolio' | 'Entrata';
  @ValidateIf((o) => o.integrationSystem === 'Yardi')
  @IsString()
  serverName?: string;
  @ValidateIf((o) => o.integrationSystem === 'Yardi')
  @IsString()
  database?: string;

  static getSecretPayload(dto: CreateCustomerImplDto) {
    const secret: SecretPayload = {
      username: dto.username,
      password: dto.password,
      url: dto.url,
    };
    if (dto.integrationSystem === 'Yardi') {
      secret.serverName = dto.serverName;
      secret.database = dto.database;
    }
    return secret;
  }
}
