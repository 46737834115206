import { IsOptional } from 'class-validator';
import { EntryNotes, PermissionToEnter } from './submit-attempt-submit-steps.dto';

export class SubmitAttemptEntryNotesDto {
  @IsOptional()
  entryNotes?: EntryNotes;

  @IsOptional()
  permissionToEnter?: PermissionToEnter;
}
