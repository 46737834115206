import { useUserSessionContext } from '@mezo/web/contexts';
import { MetricsResult, useUploadMedia } from '@mezo/web/hooks';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { MediaFileInfo } from '@deprecated/model';
import exampleMediaMap, { ExampleMediaData } from '../../components/asset-media/asset-media';
import { ALL_UPLOAD_EXTENSIONS, IMAGE_UPLOAD_EXTENSIONS } from '../../types/constants';
import { ConfirmDeleteModal } from './confirm-delete-modal';
import { ExampleImageModal } from './example-images-modal';
import MediaUpload from './media-upload';
import { SkipModal } from './skip-modal';
import { useMediaUploadMetrics } from './tracking';

const Container = tw.div`flex flex-col flex-grow-2`;

const MAX_MB_MEDIA = 30;
const MAX_MB_ASSET = 10;
const BYTES_PER_MB = 1000000;

export enum MediaPageType {
  MEDIA = 'media',
  ASSET = 'asset',
}

type MediaPageProps = {
  customerId?: string;
  residentId?: string;
  unitId?: string;
  handleNavigate: VoidFunction;
  showSubmissionLoader?: boolean;
  screenType: MediaPageType;
  skipBodyText?: string;
  skipBulletPoints?: string[];
};
const DEFAULT = 'mediaItems.defaultID';

const LIMITED_ACCESS_ITEMS = ['airConditionerID', 'waterHeaterID', 'ovenID', 'heatingSystemID'];

const getExampleImages = (taxonomyId: string, screenType: MediaPageType): ExampleMediaData => {
  const lookupKey = screenType === MediaPageType.ASSET ? `assetItems.${taxonomyId}` : `mediaItems.${taxonomyId}`;

  const assetMediaData = exampleMediaMap[lookupKey] || exampleMediaMap[DEFAULT];
  return assetMediaData;
};

export const MediaPage = ({
  customerId,
  residentId,
  unitId,
  handleNavigate,
  showSubmissionLoader = false,
  screenType,
  skipBodyText,
  skipBulletPoints,
}: MediaPageProps) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [currentlyDeleting, setCurrentlyDeleting] = useState<string>('');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);
  const [isSkipModalVisible, setIsSkipModalVisible] = useState<boolean>(false);
  const [isExampleImagesModalVisible, setIsExampleImagesModalVisible] = useState<boolean>(false);

  const isAsset = screenType === MediaPageType.ASSET;

  // media asset vars
  const allowedFileExtensions = useMemo(() => (isAsset ? IMAGE_UPLOAD_EXTENSIONS : ALL_UPLOAD_EXTENSIONS), [isAsset]);
  const errorSizeKey = `${screenType}.errorSize`;
  const fileTypeOverrides = allowedFileExtensions.join(', ');

  const { t } = useTranslation();

  const { userSessionId, submitAttemptId, getUserSession } = useUserSessionContext();

  const userSession = getUserSession();

  const currentItem = userSession?.currentSubmitAttempt?.taxonomy?.itemId || '';

  const shouldAllowSkip = LIMITED_ACCESS_ITEMS.includes(currentItem) && isAsset;

  const mediaAssetData = getExampleImages(currentItem, screenType);

  const { trackRemoved, trackSkipped, trackUploadStarted, trackUploadSuccess, trackUploadFail } =
    useMediaUploadMetrics();

  const mediaFileInfos: MediaFileInfo[] =
    screenType === MediaPageType.ASSET
      ? userSession?.currentSubmitAttempt?.asset
        ? [userSession?.currentSubmitAttempt?.asset]
        : []
      : userSession?.currentSubmitAttempt?.media ?? [];

  const { useUploadMediaMutation, uploads, deleteMedia, isUploadingMedia } = useUploadMedia(
    screenType,
    userSessionId,
    submitAttemptId,
    mediaFileInfos,
  );

  const handleUploadError = useCallback(
    (fileName: string) =>
      setErrors((previousErrors) => [...previousErrors, t('media.errorUpload', { name: fileName })]),
    [t],
  );

  const { mutateAsync: uploadMedia } = useUploadMediaMutation(handleUploadError);

  const onDeleteMedia = useCallback(() => {
    deleteMedia(currentlyDeleting);
    trackRemoved({
      customerId,
      residentId,
      unitId,
      userSessionId,
      submitAttemptId,
      fileName: currentlyDeleting,
    });
    setCurrentlyDeleting('');
    setIsDeleteModalVisible(false);
  }, [deleteMedia, currentlyDeleting, trackRemoved, customerId, residentId, unitId, userSessionId, submitAttemptId]);

  const onDisplayDeleteModal = useCallback((name: string) => {
    setCurrentlyDeleting(name);
    setIsDeleteModalVisible(true);
  }, []);

  const onDeleteModalClose = useCallback(() => {
    setCurrentlyDeleting('');
    setIsDeleteModalVisible(false);
  }, []);

  const validateFiles = useCallback(
    (files: File[]): { validFiles: File[]; errorMessages: string[] } => {
      const validFiles: File[] = [];
      const errorMessages: string[] = [];
      const MAX_MB = isAsset ? MAX_MB_ASSET : MAX_MB_MEDIA;

      files.forEach((file) => {
        if (file.size > MAX_MB * BYTES_PER_MB) {
          errorMessages.push(t(errorSizeKey, { name: file.name }));
        } else if (!allowedFileExtensions.some((ext) => file.name.toUpperCase().includes(ext.toUpperCase()))) {
          errorMessages.push(t('media.errorType', { name: file.name }));
        } else {
          validFiles.push(file);
        }
      });
      return { validFiles, errorMessages };
    },
    [allowedFileExtensions, errorSizeKey, isAsset, t],
  );

  const onUploadMedia = useCallback(
    async (files: File[]) => {
      const { validFiles, errorMessages } = validateFiles(files);
      setErrors(errorMessages);

      trackUploadStarted({
        customerId,
        residentId,
        unitId,
        userSessionId,
        submitAttemptId,
      });
      try {
        await uploadMedia({ files: validFiles });
        trackUploadSuccess({ customerId, residentId, unitId, userSessionId, submitAttemptId }, MetricsResult.SUCCESS);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        trackUploadFail(
          { customerId, residentId, unitId, userSessionId, submitAttemptId, errorMessage: err.message },
          MetricsResult.FAIL,
        );
      }
    },
    [
      customerId,
      residentId,
      submitAttemptId,
      trackUploadFail,
      trackUploadStarted,
      trackUploadSuccess,
      unitId,
      uploadMedia,
      userSessionId,
      validateFiles,
    ],
  );

  const handleShowSkipModal = useCallback(() => setIsSkipModalVisible(true), []);
  const handleHideSkipModal = useCallback(() => setIsSkipModalVisible(false), []);
  const handleShowExampleImages = useCallback(() => setIsExampleImagesModalVisible(true), []);
  const handleHideExampleImages = useCallback(() => setIsExampleImagesModalVisible(false), []);

  const handleSkip = useCallback(async () => {
    if (shouldAllowSkip) {
      handleNavigate();
    } else {
      handleShowSkipModal();
    }
  }, [handleNavigate, handleShowSkipModal, shouldAllowSkip]);

  const onMediaSkipped = useCallback(async () => {
    trackSkipped({
      customerId,
      residentId,
      unitId,
      userSessionId,
      submitAttemptId,
    });
    handleHideSkipModal();

    await uploadMedia({ skipStep: true });

    handleNavigate();
  }, [
    customerId,
    handleHideSkipModal,
    handleNavigate,
    residentId,
    submitAttemptId,
    trackSkipped,
    unitId,
    uploadMedia,
    userSessionId,
  ]);

  return (
    <Container>
      <MediaUpload
        handleUploadMedia={onUploadMedia}
        uploads={uploads}
        handleDeleteMedia={onDisplayDeleteModal}
        errorMessages={errors}
        handleShowExamples={handleShowExampleImages}
        handleMediaSkipped={handleSkip}
        handleSubmitMedia={handleNavigate}
        isProcessingSubmission={showSubmissionLoader}
        isUploadingMedia={isUploadingMedia}
        allowMultipleUploads={!isAsset}
        exampleImageUrl={mediaAssetData.heroImage}
        shouldAllowSkip={shouldAllowSkip}
        customInstructions={t(mediaAssetData.i18nKeyAssetCopy)}
        customFooterText={screenType === MediaPageType.ASSET ? t('asset.footerText') : undefined}
        uploadButtonLabel={t(`${screenType}.buttonLabel`)}
        disclaimerText={t(`${screenType}.disclaimer`)}
        customSubmitText={screenType === MediaPageType.ASSET ? t('asset.submit') : undefined}
        fileTypeOverrides={fileTypeOverrides}
      />
      <ConfirmDeleteModal
        onConfirm={onDeleteMedia}
        onClose={onDeleteModalClose}
        isVisible={isDeleteModalVisible}
        name={currentlyDeleting}
      />
      <SkipModal
        isVisible={isSkipModalVisible}
        onClose={handleHideSkipModal}
        onSkip={onMediaSkipped}
        customBodyText={skipBodyText}
        customBulletPoints={skipBulletPoints}
      />
      <ExampleImageModal
        isVisible={isExampleImagesModalVisible}
        onClose={handleHideExampleImages}
        exampleProblemStatement={t(mediaAssetData.i18nKeyItemName)}
        exampleImageUrls={mediaAssetData.images}
      />
    </Container>
  );
};
