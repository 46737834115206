import { IsArray, IsBoolean, IsString } from 'class-validator';

export class EmergencyItem {
  @IsString()
  readonly id: string;
  @IsString()
  readonly label: string;
  @IsArray()
  readonly itemId: string;
  @IsArray()
  readonly symptomId: string;
  @IsBoolean()
  readonly is911: boolean;
}
