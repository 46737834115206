export interface DescriptionFormatterDto {
  translations?: {
    id: string;
    locale: string;
    [key: string]: string;
  };
  preferences: {
    includeEntryNotes: boolean;
    includeAvailability: boolean;
    contentType: DescriptionContentType;
    includeChatTranscript: boolean;
  };
  isEmergency: boolean;
  isPerceivedEmergency: boolean;
  problemStatement: string;
  additionalDetails: string;
  diagnosisDescription?: string;
  attachments: {
    photoCount: number;
    videoCount: number;
    chatTranscript: boolean;
    mediaRequested: boolean;
  };
  entryNotes: {
    notes?: string;
    notesRequested: boolean;
  };
  resiAvailability: {
    availabilityRequested: boolean;
    availabilityWindows: {
      startTimeUtc: string;
      endTimeUtc: string;
    }[];
    timezoneId: string;
  };
  recommendedFix: string;
  partsAndTools: string;
  recommendedSkillset: string;
  productInfo: {
    productInfoRequested: boolean;
    manufacturer?: string;
    model?: string;
    serialNumber?: string;
    lifeRemaining?: string;
  };
  taxonomy: {
    category: string;
    item: string;
    symptom: string;
    location: string;
    component: string;
  };
  technicianUrl: string;
}

export enum DescriptionContentType {
  TEXT = 'text/plain',
  HTML = 'text/html',
}
