import { Locale } from '@mezo/common/utils';

export enum ProblemCaptureSelectionType {
  COMMON_PROBLEM_CLICK = 'COMMON_PROBLEM_CLICK', // they selected a common problem directly without type ahead followup
  TYPE_AHEAD_RESULT_CLICK = 'TYPE_AHEAD_RESULT_CLICK', // selected a type ahead option, excluding the first one
  TYPE_AHEAD_ENTER_PRESS = 'TYPE_AHEAD_ENTER_PRESS', // entered their issue via text and selected with enter/continue/go
  TYPE_AHEAD_ENTRY_CLICK = 'TYPE_AHEAD_ENTRY_CLICK', // selected the first type ahead option
  EMERGENCY_CLICK = 'EMERGENCY_CLICK', // selected an emergency option
}

export type ProblemCaptureRequestDto = {
  text: string;
  taxonomy: {
    itemId?: string;
    symptomId?: string;
    componentId?: string;
    locationId?: string;
  };
  sessionContext: {
    locale: Locale;
  };
  problemCaptureSelectionType: ProblemCaptureSelectionType;
};
