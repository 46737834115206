/* eslint @typescript-eslint/no-explicit-any: 0 */
import { MetricsResult, useMetrics } from '@mezo/web/hooks';
import { useCallback } from 'react';

const EVENT_NAME = 'media-upload';

export function useMediaUploadMetrics() {
  const { trackEvent } = useMetrics();

  const trackSkipped = useCallback(
    (payload: Record<string, any>) => {
      trackEvent({
        ...payload,
        label: EVENT_NAME,
        action: 'skipped',
      });
    },
    [trackEvent],
  );

  const trackRemoved = useCallback(
    (payload: Record<string, any>) => {
      trackEvent({
        ...payload,
        label: EVENT_NAME,
        action: 'removed',
      });
    },
    [trackEvent],
  );

  const trackUploadStarted = useCallback(
    (payload: Record<string, any>) => {
      trackEvent({
        ...payload,
        label: EVENT_NAME,
        action: 'upload.started',
      });
    },
    [trackEvent],
  );

  const trackUploadSuccess = useCallback(
    (payload: Record<string, any>, result: MetricsResult) => {
      trackEvent({
        ...payload,
        label: EVENT_NAME,
        action: 'upload.success',
        data1: result,
      });
    },
    [trackEvent],
  );

  const trackUploadFail = useCallback(
    (payload: Record<string, any>, result: MetricsResult) => {
      trackEvent({
        ...payload,
        label: EVENT_NAME,
        action: 'upload.fail',
        data1: result,
      });
    },
    [trackEvent],
  );

  const trackSubmitStarted = useCallback(
    (payload: Record<string, any>) => {
      trackEvent({
        ...payload,
        label: EVENT_NAME,
        action: 'submit.started',
      });
    },
    [trackEvent],
  );

  const trackSubmitSuccess = useCallback(
    (payload: Record<string, any>, result: MetricsResult) => {
      trackEvent({
        ...payload,
        label: EVENT_NAME,
        action: 'submit.success',
        data1: result,
      });
    },
    [trackEvent],
  );

  const trackSubmitFail = useCallback(
    (payload: Record<string, any>, result: MetricsResult) => {
      trackEvent({
        ...payload,
        label: EVENT_NAME,
        action: 'submit.fail',
        data1: result,
      });
    },
    [trackEvent],
  );

  return {
    trackRemoved,
    trackSkipped,
    trackUploadStarted,
    trackUploadSuccess,
    trackUploadFail,
    trackSubmitStarted,
    trackSubmitSuccess,
    trackSubmitFail,
  };
}
