import { KeyValuePairDto } from '@deprecated/dtos';
import { TaxonomyItemOptionDto, TypeAheadRequest, TypeAheadResponse } from '@mezo/common/dtos';
import { Locale } from '@mezo/common/utils';
import { ApiClient } from '@mezo/web/utils';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { Options } from 'react-select';

export const useSymptomsForItem = (itemId: string | undefined, isPrime = true) =>
  useQuery({
    queryKey: ['symptoms', { itemId, isPrime }],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get<KeyValuePairDto[]>(
        `/items/${itemId}/symptoms?isPrime=${isPrime}`,
      );
      return response.data;
    },
    enabled: !!itemId,
  });

export const useTypeAhead = ({
  text,
  userSessionId,
  submitAttemptId,
  enabled = true,
  locale,
}: {
  text: string;
  userSessionId?: string;
  submitAttemptId?: string;
  enabled?: boolean;
  locale: Locale;
}): UseQueryResult<TypeAheadResponse, Error> =>
  useQuery({
    queryKey: ['typeAhead', { text }],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.post<TypeAheadResponse>(`/taxonomy/type-ahead`, {
        text,
        userSessionId,
        submitAttemptId,
        locale,
      } as TypeAheadRequest);
      return response?.data;
    },
    enabled: !!text && !!userSessionId && !!submitAttemptId && !!enabled,
  });

export const useSearchItemComponent = {
  searchItem: async ({
    text,
    userSessionId,
    submitAttemptId,
    locale,
  }: {
    text: string;
    userSessionId?: string;
    submitAttemptId?: string;
    enabled?: boolean;
    locale: Locale;
  }): Promise<Options<TaxonomyItemOptionDto>> => {
    const response = await ApiClient.CHAT_API.utility.post<TaxonomyItemOptionDto[]>(`/taxonomy/search-item-component`, {
      text,
      userSessionId,
      submitAttemptId,
      locale,
    } as TypeAheadRequest);
    const results: Options<TaxonomyItemOptionDto> = response?.data.map((item) => {
      return { ...item, value: item.id };
    });
    return results;
  },
};
