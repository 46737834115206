import { IsBoolean, IsNumber, IsObject, IsOptional, IsString } from 'class-validator';
import { TaxonomyEntityResponseDto } from './taxonomy-entity-response.dto';

export abstract class BaseEntityDto {
  id: string;
  label: string;
}

export class BaseEntityProbabilityDto extends BaseEntityDto {
  probability: number;
}

abstract class BaseEntityFrequencyDto extends BaseEntityDto {
  frequency: number;
}

export class ItemSymptomDto extends BaseEntityFrequencyDto {
  canPlug?: boolean;
  isPrime?: boolean;
  skipMediaCollection?: boolean;
}

export class ItemComponentDto extends BaseEntityFrequencyDto {}

export class ItemLocationDto extends BaseEntityFrequencyDto {}

export class ItemResponseDto extends TaxonomyEntityResponseDto {
  @IsString()
  categoryId: string;

  @IsObject()
  components: {
    [key: string]: ItemComponentDto;
  };
  @IsObject()
  locations: {
    [key: string]: ItemLocationDto;
  };

  @IsObject()
  symptoms: {
    [key: string]: ItemSymptomDto;
  };

  @IsBoolean()
  isAsset: boolean;

  @IsBoolean()
  needLocation: boolean;

  @IsNumber()
  @IsOptional()
  source7ProductId?: number;
}
