import { isProduction } from '@mezo/web/utils';
import { ErrorBoundary as SentryBoundary } from '@sentry/react';
import { ErrorBoundary as Boundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { Section } from './generics';

interface ErrorBoundaryProps {
  errorCallback?: (error: Error) => void;
  children?: React.ReactNode;
}
const ErrorMessage = tw.h4`text-lg text-center capitalize`;

const ErrorFallback = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <ErrorMessage>{t('error.customer')}</ErrorMessage>
    </Section>
  );
};

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children, errorCallback }) => (
  <Boundary onError={errorCallback} FallbackComponent={ErrorFallback}>
    {children}
  </Boundary>
);

const SentryErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children, errorCallback }) => (
  <SentryBoundary onError={errorCallback} fallback={ErrorFallback}>
    {children}
  </SentryBoundary>
);

const DefaultBoundary: React.FC<ErrorBoundaryProps> = ({ children, errorCallback }) => {
  return isProduction() ? (
    <SentryErrorBoundary children={children} errorCallback={errorCallback} />
  ) : (
    <ErrorBoundary children={children} errorCallback={errorCallback} />
  );
};

export default DefaultBoundary;
