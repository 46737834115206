import { KeyValuePairDto } from '../common/key-value-pair.dto';
import { TranslatedText } from '../user-session';

export enum ChatAction {
  COLLECT_CHOICE_RESPONSE = 'COLLECT_CHOICE_RESPONSE',
  CHAT_COMPLETE = 'CHAT_COMPLETE',
  // these are deprecated and should be removed after destroying old chat
  MOVE_TO_MEDIA = 'MOVE_TO_MEDIA',
  MOVE_TO_FINAL_SCREEN = 'MOVE_TO_FINAL_SCREEN',
  MOVE_TO_DETAILS = 'MOVE_TO_DETAILS',
}

export enum Sender {
  RESIDENT = 'RESIDENT',
  BOT = 'BOT',
}

export interface ChatMessage {
  sender: Sender;
  createdAt: number;
  messages: TranslatedText;
}

export class ChatResponseCard {
  id: string;
  title: string;
  imageUrl: string;
  choices: KeyValuePairDto[];
}

export class ChatResponseMedia {
  type: string;
  url: string;
  dimensions: {
    width: number;
    height: number;
  };
  canvasVisibility: string;
}

export class ChatResponseDto implements ChatMessage {
  createdAt: number;
  chatAction: ChatAction;
  choices?: KeyValuePairDto[];
  cards?: ChatResponseCard[];
  media?: ChatResponseMedia;
  messages: TranslatedText;
  sender: Sender.BOT;
  constructor(
    chatAction: ChatAction,
    messages: TranslatedText,
    choices?: KeyValuePairDto[],
    cards?: ChatResponseCard[],
    media?: ChatResponseMedia,
  ) {
    this.choices = choices;
    this.chatAction = chatAction;
    this.createdAt = Date.now();
    this.cards = cards;
    this.media = media;
    this.messages = messages;
    this.sender = Sender.BOT;
  }
}
