import { CommonProblem, ProblemCaptureSelectionType, TypeAheadResponseItemDto } from '@mezo/common/dtos';
import { Dispatch, SetStateAction } from 'react';
import { ProblemCapture } from './problem-capture';

export type ProblemCapturePageProps = {
  onSubmit: (
    item?: TypeAheadResponseItemDto,
    problemCaptureSelectionType?: ProblemCaptureSelectionType,
  ) => Promise<void>;
  commonProblems?: CommonProblem[];
  handleTypeAheadChange: (text: string) => void;
  showTypeAhead: boolean;
  setShowTypeAhead: Dispatch<SetStateAction<boolean>>;
};

export const ProblemCapturePage: React.FC<ProblemCapturePageProps> = ({
  onSubmit,
  commonProblems,
  setShowTypeAhead,
}) => {
  const handlePromptClick = () => {
    setShowTypeAhead(true);
  };

  const handleSubmit = async (
    item?: TypeAheadResponseItemDto,
    problemCaptureSelectionType?: ProblemCaptureSelectionType,
  ) => {
    await onSubmit(item, problemCaptureSelectionType);
  };

  return (
    <ProblemCapture onSubmit={handleSubmit} handlePromptClick={handlePromptClick} commonProblems={commonProblems} />
  );
};
