import { OTHER_ID } from '@mezo/common/constants';
import { TaxonomyEntityOptionDto } from '@mezo/common/dtos';
import { useEffect, useMemo, useRef } from 'react';
import { GetOptionValue, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import tw from 'twin.macro';
import { createCreateLabel } from './type-ahead-async-dropdown';

// TYPE AHEAD
const TypeAheadDropdownDiv = tw.div`w-full`;

const customStyles: StylesConfig = {
  input: (base) => ({
    ...base,
    "input[type='text']:focus": { boxShadow: 'none' },
    height: '48px',
  }),
};

type TypeAheadDropDownProps = {
  placeholder: string | undefined;
  selected: TaxonomyEntityOptionDto | undefined;
  typeAheadResults?: (TaxonomyEntityOptionDto & { elem?: JSX.Element })[];
  handleTypeAheadChange: (result: TaxonomyEntityOptionDto) => void;
  createPrefix?: string;
};

export const TypeAheadDropDown: React.FC<TypeAheadDropDownProps> = ({
  placeholder,
  selected,
  typeAheadResults,
  handleTypeAheadChange,
  createPrefix,
}) => {
  const typeAheadInputRef = useRef<HTMLTextAreaElement>(null);
  const createPrefixLabel = createPrefix ?? 'Create';
  useEffect(() => {
    typeAheadInputRef.current?.focus();
  }, []);

  const memoizedOptions = useMemo(() => typeAheadResults ?? [], [typeAheadResults]);

  return (
    <TypeAheadDropdownDiv>
      <CreatableSelect
        menuPortalTarget={document.body}
        menuShouldBlockScroll={false}
        menuShouldScrollIntoView={true}
        placeholder={placeholder}
        options={memoizedOptions}
        isLoading={false}
        value={selected}
        onChange={(result) => handleTypeAheadChange(result as TaxonomyEntityOptionDto)}
        getOptionValue={((option) => option) as GetOptionValue<unknown>}
        styles={customStyles}
        formatCreateLabel={(inputValue) => createCreateLabel(createPrefixLabel, inputValue)}
        onCreateOption={(inputValue) =>
          handleTypeAheadChange({
            id: OTHER_ID,
            label: inputValue,
            isSelected: true,
          } as TaxonomyEntityOptionDto)
        }
      />
    </TypeAheadDropdownDiv>
  );
};
