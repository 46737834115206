import { ServiceRequestResidentView } from '@deprecated/dtos';
import { getLocale } from '@deprecated/shared/formatters';
import { SOURCE_ID_PLACEHOLDER } from '@mezo/common/utils';
import { useLocale } from '@react-aria/i18n';
import { formatDistanceToNow } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import { NavigationStack, NavigationStackPage } from '../navigation-stack';

type ServiceRequestItemProps = {
  createdAt: number;
  item: string;
  lastUpdatedAt: number;
  status: string;
  sourceId?: string;
  showWorkOrderNumber: boolean;
};

type SubmittedRequestsProps = {
  customText?: string;
  serviceRequests?: Array<ServiceRequestResidentView>;
  onNavigateBack: VoidFunction;
};

const STATUS_CHAR_LIMIT = 17;

const TextWrapper = tw.div`flex flex-col gap-4 py-4`;
const CustomTextWrapper = tw.div`py-4`;
const ItemText = tw.span`text-sm capitalize`;
const StyledTimeDataText = tw.span`text-xs text-disabled`;
const StyledWordOrderNumberText = tw.span`text-sm text-disabled`;
const StyledDarkText = tw.p`px-4`;
const StyledGrayText = tw.p`px-4 mb-4 text-xs text-disabled`;

const StyledPill = styled.div(() => [
  tw`relative`,
  css`
    &::before {
      ${tw`bg-primary`}
      ${tw`rounded`}
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      opacity: 0.2;
      position: absolute;
      left: 0;
      right: 0;
    }
  `,
]);

const StyledStatusText = tw.span`px-2 py-1 text-sm text-primary`;

const ServiceRequestItemWrapper = tw.div`flex flex-col gap-2 p-4 border-t-2 last:border-b-2 border-light-grey`;
const MaterialData = tw.div`flex flex-row justify-between`;
const TimeData = tw.div`flex flex-row justify-between`;
const WorkOrderNumberData = tw.div`flex flex-row justify-between`;
const ServiceRequestsWrapper = tw.div`mb-4`;

export const ServiceRequestItem: React.FC<ServiceRequestItemProps> = ({
  createdAt,
  item,
  status,
  sourceId,
  lastUpdatedAt,
  showWorkOrderNumber,
}) => {
  const { t } = useTranslation();

  const { locale } = useLocale();

  const displayedDates = useMemo(() => {
    const dateObject = new Date(createdAt);
    const month = dateObject.toLocaleString('default', { month: 'short' });
    const date = dateObject.getDate();

    const timeSince = formatDistanceToNow(lastUpdatedAt, {
      locale: getLocale(locale),
    });

    const submittedOn = t('chat.submittedRequests.submittedOnDate', { date: `${month} ${date}` });
    const updatedAt = t('chat.submittedRequests.updatedTime', { updatedTime: `${timeSince}` });

    return { submittedOn, updatedAt };
  }, [createdAt, lastUpdatedAt, locale, t]);

  const formattedStatus = status[0].charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  const truncatedStatus =
    status.length > STATUS_CHAR_LIMIT ? `${status.substring(0, STATUS_CHAR_LIMIT)}...` : formattedStatus;
  const workOrderNumberText = `${t('chat.submittedRequests.workOrderNumberPreText')}${sourceId}`;
  const workOrderNumber =
    sourceId !== SOURCE_ID_PLACEHOLDER ? workOrderNumberText : t('chat.submittedRequests.workOrderNumberText');

  return (
    <ServiceRequestItemWrapper>
      <MaterialData>
        <WorkOrderNumberData>
          {showWorkOrderNumber && <StyledWordOrderNumberText>{workOrderNumber}</StyledWordOrderNumberText>}
        </WorkOrderNumberData>
        <StyledPill>
          <StyledStatusText>{truncatedStatus}</StyledStatusText>
        </StyledPill>
      </MaterialData>
      <ItemText>{item}</ItemText>
      <TimeData>
        <StyledTimeDataText>{displayedDates.submittedOn}</StyledTimeDataText>
        <StyledTimeDataText>{displayedDates.updatedAt}</StyledTimeDataText>
      </TimeData>
    </ServiceRequestItemWrapper>
  );
};

export const SubmittedRequests: React.FC<SubmittedRequestsProps> = ({
  customText,
  serviceRequests,
  onNavigateBack,
}: SubmittedRequestsProps) => {
  const { t } = useTranslation();

  return (
    <NavigationStack showRootBackButton={true} onRootBackClicked={onNavigateBack} hasSubmittedRequestsStyling={true}>
      <NavigationStackPage
        title={t('chat.submittedRequests.title')}
        key={t('chat.submittedRequests.title')}
        isParentVisible={true}
      >
        {serviceRequests?.length ? (
          <React.Fragment>
            <ServiceRequestsWrapper>
              <CustomTextWrapper>
                <StyledDarkText>{customText || t('chat.submittedRequests.workingOnRequests')}</StyledDarkText>
              </CustomTextWrapper>
              {serviceRequests.map((sr) => (
                <ServiceRequestItem
                  key={`${sr.sourceId}-${sr.item}-${sr.createdAt}`}
                  createdAt={sr.createdAt}
                  item={sr.item}
                  lastUpdatedAt={sr.sourceLastUpdated}
                  status={sr.sourceStatus}
                  sourceId={sr.sourceId}
                  showWorkOrderNumber={sr.showWorkOrderNumber}
                />
              ))}
            </ServiceRequestsWrapper>
            <StyledGrayText>{t('chat.submittedRequests.showMaxRequestsOnly')}</StyledGrayText>
            <StyledGrayText>{t('chat.submittedRequests.makeChangesToRequests')}</StyledGrayText>
          </React.Fragment>
        ) : (
          <TextWrapper>
            <StyledDarkText>{t('chat.submittedRequests.noRequests')}</StyledDarkText>
            <StyledGrayText>{t('chat.submittedRequests.showMaxRequestsOnly')}</StyledGrayText>
          </TextWrapper>
        )}
      </NavigationStackPage>
    </NavigationStack>
  );
};
