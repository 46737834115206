import { IsOptional, IsString } from 'class-validator';

export class PropertyBrandingDto {
  @IsString()
  customerId: string;
  @IsString()
  alias: string;
  @IsString()
  propertyId: string;
  @IsString()
  propertyName: string;
  @IsString()
  primaryColor: string;
  @IsString()
  @IsOptional()
  secondaryColor: string;
}

export class CustomerBrandingDto {
  @IsString()
  customerId: string;
  @IsString()
  customerName: string;
  @IsString()
  alias: string;
  @IsString()
  primaryColor: string;
  @IsString()
  @IsOptional()
  secondaryColor: string;
}
