import { IssueCode } from '@mezo/common/dtos';
import { t } from 'i18next';
import Select from 'react-select';
import styled from 'styled-components';
import tw, { theme } from 'twin.macro';
import { ButtonWithSpinner } from '../button.v2';
import PageHeaderSubHeader from '../page-header-subheader';
import { OptionType } from '../styled-select';
import { getIssues } from './issues';

export type SupportFormErrors = {
  issue: boolean;
  details: boolean;
  emailAddress: boolean;
  confirmEmailAddress: boolean;
};

export type SupportFormFields = {
  issue: OptionType | { label: string; value: IssueCode };
  details: string;
  emailAddress: string;
  confirmEmailAddress: string;
};

const selectStyles = {
  borderRadius: '0.5rem',
  height: '3.125rem',
};

const issues = getIssues();

const ErrorMessage = tw.span`text-sm text-error`;
const CenterErrorMessage = tw(ErrorMessage)`text-center`;

const FlexItem = tw.div`flex flex-col gap-2`;
const FlexWrapper = tw.div`flex flex-col gap-4`;

const Input = styled.input<{ hasError: boolean }>(({ hasError }) => [
  tw`w-full p-3 border rounded-lg border-text-grey`,
  hasError && tw`border-error`,
]);

const TextArea = styled.textarea<{ hasError: boolean }>(({ hasError }) => [
  tw`w-full p-3 border rounded-lg border-text-grey`,
  hasError && tw`border-error`,
]);

const LabelText = tw.label``;
const PlaceholderText = tw.span`text-text-light`;

type SupportFormProps = {
  issue: OptionType;
  details: string;
  emailAddress: string;
  confirmEmailAddress: string;
  errors: SupportFormErrors;
  onChange: (fieldName: keyof SupportFormFields, value: unknown) => void;
  onSubmit: VoidFunction;
  onBlur: (fieldName: keyof SupportFormFields) => void;
  submissionError: boolean;
  isLoading: boolean;
};

export const SupportForm = ({
  issue,
  details,
  emailAddress,
  confirmEmailAddress,
  errors,
  onChange,
  onSubmit,
  onBlur,
  submissionError,
  isLoading,
}: SupportFormProps) => {
  return (
    <PageHeaderSubHeader
      translatedHeaderText={t('supportForm.title')}
      translatedSubHeaderText={t('supportForm.subtitle')}
    >
      <FlexWrapper>
        <FlexItem>
          <LabelText htmlFor="email-address">{t('supportForm.form.emailAddress.label')}</LabelText>
          <Input
            id="email-address"
            hasError={errors.emailAddress}
            value={emailAddress}
            onChange={(e) => onChange('emailAddress', e.target.value)}
            onBlur={() => onBlur('emailAddress')}
          />
          {errors.emailAddress && <ErrorMessage>{t('supportForm.form.emailAddress.error')}</ErrorMessage>}
          <PlaceholderText>{t('supportForm.form.emailAddress.placeholder')}</PlaceholderText>
        </FlexItem>
        <FlexItem>
          <LabelText htmlFor="confirm-email-address">{t('supportForm.form.confirmEmailAddress.label')}</LabelText>
          <Input
            id="confirm-email-address"
            hasError={errors.confirmEmailAddress}
            value={confirmEmailAddress}
            onChange={(e) => onChange('confirmEmailAddress', e.target.value)}
            onBlur={() => onBlur('confirmEmailAddress')}
          />
          {errors.confirmEmailAddress && <ErrorMessage>{t('supportForm.form.confirmEmailAddress.error')}</ErrorMessage>}
        </FlexItem>
        <FlexItem>
          <LabelText htmlFor="issue">{t('supportForm.form.issue.label')}</LabelText>
          <Select
            inputId="issue"
            isLoading={false}
            onChange={(newValue) => onChange('issue', newValue)}
            options={issues}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                ...selectStyles,
                borderColor: errors.issue && !issue.value ? theme`colors.error` : theme`colors.text-grey`,
              }),
            }}
            value={issue}
            onBlur={() => onBlur('issue')}
          />
        </FlexItem>
        <FlexItem>
          <LabelText htmlFor="details">{t('supportForm.form.details.label')}</LabelText>
          <TextArea
            id="details"
            hasError={errors.details}
            value={details}
            placeholder={t('supportForm.form.details.placeholder')}
            rows={4}
            onChange={(e) => onChange('details', e.target.value)}
            onBlur={() => onBlur('details')}
          />
        </FlexItem>

        <FlexWrapper>
          {submissionError && <CenterErrorMessage>{t('guestResidentAuth.requiredFieldsMissing')}</CenterErrorMessage>}
          <ButtonWithSpinner primary={true} onClick={onSubmit} isLoading={isLoading}>
            {t('supportForm.form.submit.label')}
          </ButtonWithSpinner>
        </FlexWrapper>
      </FlexWrapper>
    </PageHeaderSubHeader>
  );
};
