export enum Locale {
  EN_US = 'en-US',
  ES_US = 'es-US',
}

export enum Language {
  EN = 'en',
  ES = 'es',
}

export function isTranslationSupported(language: Language) {
  if (language === Language.ES) {
    return true;
  }

  return false;
}

export function toLocale(localeString: string): Locale {
  let locale: Intl.Locale;
  try {
    locale = new Intl.Locale(localeString);
  } catch (e) {
    locale = new Intl.Locale('en-US');
  }

  return locale.toString() as Locale;
}

export function parseLocale(headers: string[] | string | undefined): Locale {
  let locale: Locale;

  if (Array.isArray(headers) && headers.length > 0) {
    locale = (headers as string[])[0] as Locale;
  } else if (typeof headers === 'string') {
    locale = headers as Locale;
  } else {
    locale = Locale.EN_US;
  }

  return locale;
}

export function getLanguage(locale: Locale | string | undefined | null): Language {
  if (!locale) {
    return Language.EN;
  }

  try {
    const intlLocale = new Intl.Locale(locale);

    return intlLocale.language as Language;
  } catch (err) {
    console.error(`getLanguage: ${locale}`, err);
    return Language.EN;
  }
}
