import PulseLoader from 'react-spinners/PulseLoader';
import tw from 'twin.macro';

const LoadingBubble = tw.div`flex justify-start p-4 rounded-tl-none text-text bg-primary rounded-tr-xl rounded-b-xl w-[70px]`;
const HelperText = tw.span`absolute invisible -top-8 -left-8`;

export const MessageLoaderBubble = () => {
  return (
    <LoadingBubble data-cy="chat-loader">
      <PulseLoader color={'#ffffff'} size={6} />
      <HelperText>Loading...</HelperText>
    </LoadingBubble>
  );
};
