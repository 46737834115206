import { CreateSupportTicketDto } from '@mezo/common/dtos';
import { ApiClient } from '@mezo/web/utils';

import { useMutation } from '@tanstack/react-query';

export const useCreateSupportTicket = () =>
  useMutation({
    mutationFn: async ({ supportTicket }: { supportTicket: CreateSupportTicketDto }) =>
      await ApiClient.CHAT_API.utility.post<CreateSupportTicketDto>(`/support`, supportTicket),
  });
