import { Upload } from '@mezo/web/hooks';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { ChatScreenFooter } from '../../components/chat';
import { KEYCODES } from '../../types';
import FileUpload from './file-upload';

const Container = tw.div`flex flex-col gap-4 flex-grow-2`;
const PaddingContainer = tw.div`flex flex-col gap-4 p-4 flex-grow-2`;
const ExampleImage = tw.img`w-full rounded-lg`;
const Link = tw.a`underline cursor-pointer text-primary`;
const SkipContainer = tw.div`flex flex-col items-center gap-2 text-center`;
const ChatFooterContainer = tw.div`sticky bottom-0`;
const Instructions = tw.p``;

type MediaUploadProps = {
  allowMultipleUploads: boolean;
  customInstructions?: string;
  customSubmitText?: string;
  uploadButtonLabel: string;
  customFooterText?: string;
  disclaimerText?: string;
  errorMessages: string[];
  exampleImageUrl?: string;
  handleDeleteMedia: (name: string) => void;
  handleMediaSkipped: VoidFunction;
  handleShowExamples: VoidFunction;
  handleSubmitMedia: VoidFunction;
  handleUploadMedia: (files: File[]) => void;
  isProcessingSubmission: boolean;
  isUploadingMedia: boolean;
  shouldAllowSkip: boolean;
  uploads: Upload[];
  fileTypeOverrides?: string;
};

export const MediaUpload = ({
  allowMultipleUploads,
  customInstructions,
  customSubmitText,
  uploadButtonLabel,
  customFooterText,
  disclaimerText,
  errorMessages,
  exampleImageUrl,
  handleDeleteMedia,
  handleMediaSkipped,
  handleShowExamples,
  handleSubmitMedia,
  handleUploadMedia,
  isProcessingSubmission,
  isUploadingMedia,
  shouldAllowSkip,
  uploads,
  fileTypeOverrides,
}: MediaUploadProps) => {
  const { t } = useTranslation();

  const successfulUploads = uploads.filter((upload) => !upload.uploadError);
  const hasSuccessfulUploads = successfulUploads.length > 0;

  const shouldDisplayUploadInput = allowMultipleUploads || (!allowMultipleUploads && !hasSuccessfulUploads);

  const handleEnterPress = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.keyCode === KEYCODES.enter && e.shiftKey === false) {
      e.currentTarget.click();
    }
  };

  return (
    <Container>
      <PaddingContainer>
        {exampleImageUrl && <ExampleImage src={exampleImageUrl} />}
        <Instructions>
          {customInstructions || t('media.instructions')}{' '}
          <Link onClick={handleShowExamples}>{t('media.showExamples')}</Link>
        </Instructions>
        <FileUpload
          files={successfulUploads}
          onMediaSelection={handleUploadMedia}
          onMediaDeletion={handleDeleteMedia}
          errors={errorMessages}
          allowMultiple={allowMultipleUploads}
          uploadText={uploadButtonLabel}
          disclaimerText={disclaimerText}
          showInput={shouldDisplayUploadInput}
          fileTypes={fileTypeOverrides}
        />
        {!hasSuccessfulUploads && (
          <SkipContainer>
            <Link onClick={handleMediaSkipped} tabIndex={0} onKeyUp={handleEnterPress}>
              {shouldAllowSkip ? t('media.limitedAccess') : t('media.skip')}
            </Link>
          </SkipContainer>
        )}
      </PaddingContainer>
      {hasSuccessfulUploads && (
        <ChatFooterContainer>
          <ChatScreenFooter
            isActive={hasSuccessfulUploads && !isUploadingMedia}
            label={customSubmitText || t('media.submit', { count: successfulUploads.length })}
            onClick={handleSubmitMedia}
            showButtonLoader={isProcessingSubmission}
            textAbove={customFooterText}
          />
        </ChatFooterContainer>
      )}
    </Container>
  );
};

export default MediaUpload;
