import { isProduction } from '@mezo/web/utils';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './sagas';
import { dashboardSlice, sessionSlice } from './slices';

const sagaMiddleware = createSagaMiddleware({
  onError: (err: Error) => {
    throw err; //bubble up to ErrorBoundary
  },
});
const sentryReduxEnhancer = createReduxEnhancer({
  // Optionally pass options listed below
});
export const store = configureStore({
  reducer: {
    session: sessionSlice.reducer,
    dashboard: dashboardSlice.reducer,
  },
  middleware: [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: { ignoredActions: ['chat/sendMessage'] } }),
    sagaMiddleware,
  ],
  enhancers: isProduction() ? [sentryReduxEnhancer] : [],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);
