import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import parse from 'html-react-parser';
import { useMemo } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const bgStyles = `w-full p-4 text-center bg-light-grey rounded-lg`;

const BannerContainer = tw.div`${bgStyles}`;
const BannerButton = tw.button`${bgStyles}`;
const BannerText = tw.span`block text-sm text-text-medium-dark`;
const BannerTextUnderline = tw(BannerText)`underline`;
const BannerRow = styled.div<{ hasIcon: boolean; lineTwo?: string }>(({ hasIcon, lineTwo }) => [
  tw`grid justify-center gap-0`,
  hasIcon && tw`justify-start`,
  lineTwo && tw`grid-rows-2 gap-0`,
  !lineTwo && tw`grid-rows-1 gap-2`,
]);

const TextContainer = styled.span<{ hasIcon: boolean }>(({ hasIcon }) => [hasIcon && tw`text-left`]);

type ChatBannerProps = {
  icon?: IconDefinition;
  lineOne: string;
  lineTwo?: string;
  onBannerClick?: VoidFunction;
  parseHtml?: boolean;
};

export const ChatBanner: React.FC<ChatBannerProps> = ({ icon, onBannerClick, lineOne, lineTwo, parseHtml }) => {
  const hasIcon = !!icon;
  const Wrapper = useMemo(() => (onBannerClick ? BannerButton : BannerContainer), [onBannerClick]);
  return (
    <Wrapper onClick={onBannerClick || undefined}>
      <BannerRow hasIcon={hasIcon} lineTwo={lineTwo}>
        <div>
          <TextContainer hasIcon={hasIcon}>
            <BannerText>
              {hasIcon && <FontAwesomeIcon icon={icon} />} {parseHtml ? parse(lineOne) : lineOne}
            </BannerText>
          </TextContainer>
        </div>
        {lineTwo && (
          <div>
            <BannerTextUnderline>{parseHtml ? parse(lineTwo) : lineTwo}</BannerTextUnderline>
          </div>
        )}
      </BannerRow>
    </Wrapper>
  );
};
