import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  QuerySnapshot,
  startAfter,
  where,
} from 'firebase/firestore';
import { app } from './firebase.app.service';

export const firestore = getFirestore(app);
export class FirebaseFirestoreService {
  static buildServiceRequestQuery(statuses: string[], pageSize = 10, startAtP?: number): QueryConstraint[] {
    const constraints: QueryConstraint[] = [];
    if (statuses.length > 0) constraints.push(where('status', 'in', statuses));
    constraints.push(orderBy('updatedAt', 'desc'));
    if (startAtP) {
      constraints.push(startAfter(startAtP));
    }
    constraints.push(limit(pageSize));
    return constraints;
  }

  static watchCollectionForChanges(
    collectionName: string,
    queryClauses: QueryConstraint[],
    callback: (doc: QuerySnapshot<DocumentData>) => void,
  ) {
    console.log('adding watch ');
    const q = query(collection(firestore, collectionName), ...queryClauses);
    const unsubscribe = onSnapshot(q, (doc) => {
      callback(doc);
    });
    return () => {
      console.log('unsubscribed watch');
      unsubscribe();
    };
  }

  static watchCollectionItemForChanges(
    collectionName: string,
    id: string,
    callback: (doc: DocumentSnapshot<DocumentData>) => void,
  ) {
    const unsubscribe = onSnapshot(doc(collection(firestore, collectionName), id), (doc) => {
      callback(doc);
    });
    return unsubscribe;
  }
}
