import { MediaFileInfoDto } from '@deprecated/dtos';
import { getMediaTypeFromExtension } from '@mezo/web/utils';
import { useCallback, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import tw from 'twin.macro';
import { DownloadLink } from './download-link';
import Thumbnail from './thumbnail';

const MessageImage = tw.img`w-full max-w-full max-h-[60vh]`;
const MessageVideo = tw.video`w-full max-w-full max-h-[60vh]`;

interface ResidentMediaGallerProps {
  mediaFileInfos: MediaFileInfoDto[];
  startIndex: number | null;
}

const MediaContainer = tw.div`flex flex-col justify-center gap-2`;
const ThumbnailContainer = tw.div`flex justify-center gap-2 align-middle flex-grow-2`;
const Scene = tw.div`flex w-full gap-2`;

type LargeMediaProps = {
  media: MediaFileInfoDto;
};

const LargeMediaContainer = tw.div`max-w-full mx-auto`;

const LargeMedia = ({ media }: LargeMediaProps) => {
  return (
    <LargeMediaContainer key={media.url}>
      {getMediaTypeFromExtension(media).includes('video') ? (
        <MessageVideo src={media.url} controls data-cy="service-request-media" />
      ) : (
        <MessageImage
          key={media.url}
          alt={media.filename}
          title={media.filename}
          src={media.url}
          data-cy="service-request-media"
        />
      )}
      <DownloadLink mediaFileInfo={media} />
    </LargeMediaContainer>
  );
};

const START_INDEX = 0;

export const ResidentMediaGallery = ({ mediaFileInfos, startIndex }: ResidentMediaGallerProps) => {
  const [activeMediaIndex, setActiveMediaIndex] = useState(startIndex || START_INDEX);

  const updateActiveMediaIndex = useCallback((index: number) => {
    setActiveMediaIndex(index);
  }, []);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => prevSlide(),
    onSwipedRight: () => nextSlide(),
    trackMouse: true,
  });

  const maxSlide = mediaFileInfos?.length - 1;

  const nextSlide = useCallback(() => {
    activeMediaIndex === maxSlide ? updateActiveMediaIndex(START_INDEX) : updateActiveMediaIndex(activeMediaIndex + 1);
  }, [activeMediaIndex, maxSlide, updateActiveMediaIndex]);

  const prevSlide = useCallback(() => {
    activeMediaIndex === START_INDEX ? updateActiveMediaIndex(maxSlide) : updateActiveMediaIndex(activeMediaIndex - 1);
  }, [activeMediaIndex, maxSlide, updateActiveMediaIndex]);

  const isSingleSlide = mediaFileInfos.length === 1;

  const activeMedia = mediaFileInfos[activeMediaIndex];

  return (
    <MediaContainer>
      <Scene {...swipeHandlers}>
        <LargeMedia key={activeMedia.url} media={activeMedia} />
      </Scene>
      {!isSingleSlide && (
        <ThumbnailContainer>
          {mediaFileInfos.map((media, index) => (
            <Thumbnail
              key={media.url}
              url={media.url}
              fileName={media.filename}
              contentType={media.contentType}
              onSelect={() => updateActiveMediaIndex(index)}
            />
          ))}
        </ThumbnailContainer>
      )}
    </MediaContainer>
  );
};
