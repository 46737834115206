import AliasRedirect from '@mezo/web/alias';

import '@mezo/web/i18n';
import { ChatErrorBoundary, Loading } from '@mezo/web/ui';
import { getEnvVar, isTesting, RouteBuilder } from '@mezo/web/utils';

import { ExtraErrorData } from '@sentry/integrations';
import {
  BrowserTracing,
  init,
  reactRouterV6Instrumentation,
  Replay,
  withSentryReactRouterV6Routing,
} from '@sentry/react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { lazy, ReactElement, Suspense, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  createRoutesFromChildren,
  matchRoutes,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import tw from 'twin.macro';
import Capability from './capability';
import Embed from './embed/embed';
import { ExpiredLink } from './expired-link';

import { auth } from '@mezo/web/services';
import { sessionSlice } from '@mezo/web/store';
import { useDispatch } from 'react-redux';
import {
  buildDeprecatedChatRoute,
  DeprecatedAddressesRedirect,
  DeprecatedChatUrlRedirect,
  DeprecatedServiceRequestRedirect,
} from './url-redirects';

const Login = lazy(() => import('./auth/login'));
const Logout = lazy(() => import('./auth/logout'));
const DashboardEntry = lazy(() => import('@mezo/web/dashboard'));
const CustomerEntry = lazy(() => import('@mezo/web/customer'));
const ResidentEntry = lazy(() => import('@mezo/web/resident'));

const AppContainer = tw.div`flex flex-col h-full`;

const nonChatQueryClient = new QueryClient();

const projectEnvMap: Record<string, string> = {
  'reshub-dev': 'dev',
  'reshub-qa': 'qa',
  'mezo-staging': 'staging',
  'mezo-prod': 'prod',
};

const RequireAuth = ({ children }: { children?: ReactElement }) => {
  const [user] = useAuthState(auth);
  const location = useLocation();

  return !user ? (
    <Navigate to={`/${RouteBuilder.LOGIN}`} replace state={{ path: location.pathname }} />
  ) : (
    children ?? null
  );
};

const residentHomeUrl = new RouteBuilder().buildResidentHomeRoute(':customerId', ':residentId', ':unitId').build();
const residentLogin = new RouteBuilder(RouteBuilder.LOGIN).prependPath(RouteBuilder.RESIDENT).build();

const AppFactory = (monitor: boolean) => {
  if (monitor) {
    init({
      dsn: 'https://ce6186f210d04e139b92f999c3c26112@o1167515.ingest.sentry.io/6258650',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
        new Replay(),
        new ExtraErrorData(),
      ],
      environment: projectEnvMap[getEnvVar('WEB_FIREBASE_PROJECT_ID')],
      replaysSessionSampleRate: 0.5,
      replaysOnErrorSampleRate: 1.0,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
      normalizeDepth: 10,
    });
  }
  return App;
};

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const App = () => {
  const dispatch = useDispatch();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    if (user) {
      const parsedUser = JSON.parse(JSON.stringify(user));
      dispatch(
        sessionSlice.actions.authenticateUserSuccess({
          uid: parsedUser.uid,
          email: parsedUser.email,
        }),
      );
    }
  }, [user, dispatch]);

  if (loading) {
    return (
      <AppContainer>
        <Loading />
      </AppContainer>
    );
  }

  const RouteWrapper = isTesting() ? Routes : SentryRoutes;
  const expiredCapabilityUrl = new RouteBuilder().buildExpiredCapabilityRoute(':entity', ':capabilityId').build();
  const capabilityUrl = new RouteBuilder().buildCapabilityRoute(':entity', ':capabilityId').build();

  const customerUrl = new RouteBuilder().buildCustomersRoute(':customerId').build();

  const vanityUrl = new RouteBuilder().buildVanityRoute().build();

  return (
    <AppContainer>
      <RouteWrapper>
        <Route index element={<Navigate to={residentLogin} />} />
        <Route
          path={expiredCapabilityUrl}
          element={
            <QueryClientProvider client={nonChatQueryClient}>
              <ExpiredLink />
            </QueryClientProvider>
          }
        />
        <Route
          path={vanityUrl}
          element={
            <QueryClientProvider client={nonChatQueryClient}>
              <AliasRedirect />
            </QueryClientProvider>
          }
        />
        <Route
          path={`${RouteBuilder.RESIDENT}/*`}
          element={
            <ChatErrorBoundary>
              <ResidentEntry />
            </ChatErrorBoundary>
          }
        />
        <Route path={RouteBuilder.ROOT} element={<Navigate to={`/${RouteBuilder.DASHBOARD}`} />} />
        <Route
          path={`${RouteBuilder.SERVICE_REQUEST}/:serviceRequestId`}
          element={<DeprecatedServiceRequestRedirect />}
        />
        <Route
          path={`${RouteBuilder.DASHBOARD}/*`}
          element={
            <RequireAuth>
              <Suspense fallback={<Loading />}>
                <QueryClientProvider client={nonChatQueryClient}>
                  <DashboardEntry />
                </QueryClientProvider>
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path={`${customerUrl}/*`}
          element={
            <Suspense fallback={<Loading />}>
              <QueryClientProvider client={nonChatQueryClient}>
                <CustomerEntry />
              </QueryClientProvider>
            </Suspense>
          }
        />
        <Route
          path={capabilityUrl}
          element={
            <QueryClientProvider client={nonChatQueryClient}>
              <Capability />
            </QueryClientProvider>
          }
        />
        <Route
          path={RouteBuilder.LOGIN}
          element={
            <Suspense fallback={<Loading />}>
              <Login />
            </Suspense>
          }
        />
        <Route
          path={`/embed/:customerId/:residentId`}
          element={
            <RequireAuth>
              <Embed />
            </RequireAuth>
          }
        />

        <Route path={RouteBuilder.LOGOUT} element={<Logout />} />
        <Route path="*" element={<Navigate to={residentLogin} />} />
        {/* Handles redirecting for old chat link with /chat prefix */}
        <Route path={buildDeprecatedChatRoute(residentHomeUrl)} element={<DeprecatedChatUrlRedirect />} />
        <Route path={buildDeprecatedChatRoute(RouteBuilder.ADDRESSES)} element={<DeprecatedAddressesRedirect />} />
        {/* Handles redirecting for old chat intro url w/o prefix */}
        <Route path={`${residentHomeUrl}/*`} element={<DeprecatedChatUrlRedirect />} />
      </RouteWrapper>
      <ToastContainer />
    </AppContainer>
  );
};

export default AppFactory;
