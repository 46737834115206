import { Page } from '@mezo/common/utils';
import { useUserSessionContext } from '@mezo/web/contexts';
import { useEffect } from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

export type NavigationStackPageProps = React.PropsWithChildren & {
  title: string;
  pageName?: Page;
  isVisible?: boolean;
  isActive?: boolean;
  onActive?: VoidFunction;
  isParentVisible: boolean;
};

const NavigationStackPageWrapper = styled.div<{ isVisible: boolean; isActive: boolean }>(({ isVisible, isActive }) => [
  tw`absolute top-0 bottom-0 z-20 flex flex-col invisible w-full overflow-y-auto transition-all duration-500 opacity-0 left-full`,
  isVisible && tw`left-0 opacity-100`,
  isActive && tw`visible`,
]);

export const NavigationStackPage: React.FC<NavigationStackPageProps> = ({
  isVisible = false,
  isActive = false,
  children,
  onActive,
  pageName,
  isParentVisible,
}) => {
  const { updateUserSessionPage } = useUserSessionContext();

  const isDisplayedToUser = isVisible && isActive && isParentVisible;

  useEffect(() => {
    if (isDisplayedToUser) {
      if (pageName) {
        updateUserSessionPage(pageName);
      }
      if (onActive) {
        onActive?.();
      }
    }
  }, [onActive, updateUserSessionPage, pageName, isDisplayedToUser]);
  return (
    <NavigationStackPageWrapper isVisible={isVisible} isActive={isActive}>
      {children}
    </NavigationStackPageWrapper>
  );
};
