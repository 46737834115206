export enum NODE_TYPES {
  text,
  media,
}

export const MEZO_LOGO_URL = 'https://m.mezo.io/mezo.png';

export const BOT = 'BOT';

export enum KEYCODES {
  enter = 13,
  space = 32,
  backspace = 8,
}
export const ALL_UPLOAD_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.mp4', '.mov'];
export const IMAGE_UPLOAD_EXTENSIONS = ['.jpg', '.jpeg', '.png'];
