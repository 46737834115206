import {
  AvailabilityWindowDto,
  ChatMessage,
  CloseSubmitAttemptDto,
  CreateServiceRequestAttemptDto,
  KeyValuePairDto,
  ProblemCaptureRequestDto,
  ProblemCaptureSelectionType,
  Sender,
  SubmitAttemptDto,
  SubmitAttemptEntryNotesDto,
  TranslatedText,
  TypeAheadResponseItemDto,
  UpdateItemRequestDto,
  UpdateServiceRequestAttemptDto,
  UpdateUserSessionDto,
  UpdateUserSessionPageDto,
  UpdateUserSessionResidentDto,
  UserConfirmationDto,
  UserConfirmationRequestDto,
  UserSessionDto,
} from '@mezo/common/dtos';
import { getLanguage, Locale } from '@mezo/common/utils';

import { StepStatus } from '@mezo/shared/common';
import { ApiClient } from '@mezo/web/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useCreateUserSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ request }: { request?: UpdateUserSessionDto }) => {
      const response = await ApiClient.CHAT_API.utility.post<UserSessionDto>(`/user-session`, request);
      ApiClient.CHAT_API.setUserSession(response?.data.id);
      return response?.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['userSession', data?.id], data);
    },
  });
};

export const useGetUserSession = (userSessionId: string) =>
  useQuery({
    queryKey: ['userSession', userSessionId],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get(`/user-session/${userSessionId}`);
      ApiClient.CHAT_API.setUserSession(response?.data.id);
      return response?.data;
    },
    enabled: !!userSessionId,
  });
export const useGetSubmitAttempt = (userSessionId?: string, attemptId?: string) =>
  useQuery({
    queryKey: ['submitAttempt', attemptId],
    queryFn: async () => {
      const response = await ApiClient.CHAT_API.utility.get<SubmitAttemptDto>(
        `/user-session/${userSessionId}/attempt/${attemptId}`,
      );
      return response?.data;
    },
    enabled: !!userSessionId && !!attemptId,
  });

export const useUpdateUserSessionResident = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      userSessionId,
      request,
    }: {
      userSessionId: string;
      request: UpdateUserSessionResidentDto;
    }) => {
      const response = await ApiClient.CHAT_API.utility.put<UserSessionDto>(
        `/user-session/${userSessionId}/resident`,
        request,
      );
      return response?.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['userSession', data?.id], data);
    },
  });
};
export const useUpdateUserSessionPage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ userSessionId, request }: { userSessionId: string; request: UpdateUserSessionPageDto }) => {
      const response = await ApiClient.CHAT_API.utility.put<UserSessionDto>(
        `/user-session/${userSessionId}/page`,
        request,
      );
      return response?.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['userSession', data?.id], data);
    },
  });
};

export const useCreateServiceRequestAttemptForUserSession = () =>
  useMutation({
    mutationFn: async ({
      userSessionId,
      request,
    }: {
      userSessionId: string;
      request: CreateServiceRequestAttemptDto;
    }) => {
      const response = await ApiClient.CHAT_API.utility.post<UserSessionDto>(
        `/user-session/${userSessionId}/attempt`,
        request,
      );
      return response?.data;
    },
  });

export const useUpdateServiceRequestAttemptForUserSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      userSessionId,
      attemptId,
      request,
    }: {
      userSessionId: string;
      attemptId: string;
      request: UpdateServiceRequestAttemptDto;
    }) => {
      const response = await ApiClient.CHAT_API.utility.put<UserSessionDto>(
        `/user-session/${userSessionId}/attempt/${attemptId}`,
        request,
      );
      return response?.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['userSession', data?.id], data);
    },
  });
};

export const useCloseSubmitAttemptForUserSession = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ userSessionId, request }: { userSessionId: string; request: CloseSubmitAttemptDto }) => {
      const response = await ApiClient.CHAT_API.utility.post<UserSessionDto>(
        `/user-session/${userSessionId}/close`,
        request,
      );
      return response?.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['userSession', data?.id], data);
    },
  });
};

export const useUpdateUserSessionForEntryNotes = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      userSessionId,
      attemptId,
      entryNotes,
      permissionToEnter,
    }: {
      userSessionId: string;
      attemptId: string;
      entryNotes?: string;
      permissionToEnter?: boolean;
    }) => {
      const body: SubmitAttemptEntryNotesDto = {
        entryNotes: {
          requestStatus: entryNotes !== undefined ? StepStatus.USER_COMPLETED : StepStatus.NOT_REQUESTED_CONFIG,
          entryNotes,
        },
        permissionToEnter: {
          requestStatus: permissionToEnter !== undefined ? StepStatus.USER_COMPLETED : StepStatus.NOT_REQUESTED_CONFIG,
          permissionToEnter: permissionToEnter,
        },
      };
      const response = await ApiClient.CHAT_API.utility.patch<UserSessionDto>(
        `/user-session/${userSessionId}/attempt/${attemptId}/entry-notes`,
        body,
      );
      return response?.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['userSession', data?.id], data);
    },
  });
};

type UpdateResidentInputDto = {
  attemptId: string;
  userSessionId: string;
  residentInput?: string;
  skipStep?: boolean;
};

export const useUpdateUserSessionForResidentInput = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ attemptId, userSessionId, residentInput, skipStep }: UpdateResidentInputDto) => {
      const response = await ApiClient.CHAT_API.utility.patch<UserSessionDto>(
        `/user-session/${userSessionId}/attempt/${attemptId}/resident-input`,
        { residentInput, skipStep },
      );

      return response?.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['userSession', data?.id], data);
    },
  });
};

export const useProblemCapture = () =>
  useMutation({
    mutationFn: async ({
      data,
      userSessionId,
      submitAttemptId,
      locale,
      problemCaptureSelectionType,
    }: SubmitProblemCaptureParams) => {
      const confirmRequestDto: ProblemCaptureRequestDto = {
        ...data,
        sessionContext: { locale },
        problemCaptureSelectionType,
      };
      const response = await ApiClient.CHAT_API.utility.post<{
        userSessionDto: UserSessionDto;
        userConfirmationDto: UserConfirmationDto;
      }>(`/user-session/${userSessionId}/attempt/${submitAttemptId}/problem-capture`, confirmRequestDto);
      return response?.data;
    },
  });
export const useUpdateItem = () =>
  useMutation({
    mutationFn: async (request: UpdateItemRequestDto) => {
      const response = await ApiClient.CHAT_API.utility.post<UserConfirmationDto>(
        `/user-session/${request.userSessionId}/attempt/${request.submitAttemptId}/update-item`,
        request,
      );
      return response?.data;
    },
  });
export const useUserConfirmation = () =>
  useMutation({
    mutationFn: async ({ data, userSessionId, submitAttemptId }: SubmitUserConfirmationParams) => {
      const confirmRequestDto: UserConfirmationRequestDto = {
        ...data,
      };
      const response = await ApiClient.CHAT_API.utility.post<UserSessionDto>(
        `/user-session/${userSessionId}/attempt/${submitAttemptId}/user-confirmation`,
        confirmRequestDto,
      );
      return response?.data;
    },
  });

export type SubmitProblemCaptureParams = {
  data: TypeAheadResponseItemDto;
  userSessionId: string;
  submitAttemptId: string;
  locale: Locale;
  problemCaptureSelectionType: ProblemCaptureSelectionType;
};
export type SubmitUserConfirmationParams = {
  data: UserConfirmationRequestDto;
  userSessionId: string;
  submitAttemptId: string;
};
export const useSetAvailabilitiesForSubmitAttempt = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      userSessionId,
      submitAttemptId,
      availabilityWindows,
      timezoneId,
    }: {
      userSessionId: string;
      submitAttemptId: string;
      availabilityWindows: AvailabilityWindowDto[];
      timezoneId: string;
    }) => {
      const { data } = await ApiClient.CHAT_API.utility.post<UserSessionDto>(
        `/user-session/${userSessionId}/attempt/${submitAttemptId}/scheduling`,
        {
          availabilityWindows,
          timezoneId,
        },
      );
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['userSession', data?.id], data);
    },
  });
};

export const useSendMessageForAttempt = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      userSessionId,
      submitAttemptId,
      payload,
    }: {
      userSessionId: string;
      submitAttemptId: string;
      payload: KeyValuePairDto;
    }) => {
      const { data } = await ApiClient.CHAT_API.utility.patch<UserSessionDto>(
        `/user-session/${userSessionId}/attempt/${submitAttemptId}/message`,
        payload,
      );
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['userSession', data?.id], data);
    },
    onMutate: async ({ userSessionId, submitAttemptId, payload }) => {
      await queryClient.cancelQueries({ queryKey: ['userSession', userSessionId] });

      const previousData = queryClient.getQueryData<UserSessionDto>(['userSession', userSessionId]);

      queryClient.setQueryData(['userSession', userSessionId], (prev: UserSessionDto) => {
        if (!prev?.currentSubmitAttempt?.messages) {
          return { previousData };
        }
        const language = getLanguage(prev.user.locale);
        const translatedText = {
          [language]: [payload.label],
        } as TranslatedText;
        const chatMessage: ChatMessage = {
          sender: Sender.RESIDENT,
          messages: translatedText,
          createdAt: Date.now(),
        };
        return {
          ...prev,
          currentSubmitAttempt: {
            ...prev.currentSubmitAttempt,
            messages: [...prev.currentSubmitAttempt.messages, chatMessage],
          },
        };
      });
      return { previousData };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(['userSession', context?.previousData], context?.previousData);
    },
  });
};

export const useStartIntelliflowSessionForAttempt = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ userSessionId, submitAttemptId }: { userSessionId: string; submitAttemptId: string }) => {
      const { data } = await ApiClient.CHAT_API.utility.post<UserSessionDto>(
        `/user-session/${userSessionId}/attempt/${submitAttemptId}/message`,
      );
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['userSession', data?.id], data);
    },
  });
};
