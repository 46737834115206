import { ChangeEvent } from 'react';
import tw from 'twin.macro';

type UploadMediaButtonProps = {
  displayText: string;
  id: string;
  onMediaUploadSelection: (e: ChangeEvent<HTMLInputElement>) => void;
};

const ButtonWrapper = tw.button`px-2 py-4 bg-white border rounded-xl text-text-light border-text-light active:bg-primary active:text-white active:border-primary hover:bg-primary hover:text-white hover:border-primary`;

export const MediaUploadButton = ({ displayText, onMediaUploadSelection }: UploadMediaButtonProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPress = (e: any) => onMediaUploadSelection(e);
  return <ButtonWrapper onClick={onPress}>{displayText}</ButtonWrapper>;
};
