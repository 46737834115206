import styled from 'styled-components';
import tw from 'twin.macro';
import StyledLink from './styled-link';

const Container = styled.span<{ textAlign: 'center' | 'left' }>(({ textAlign }) => [
  textAlign === 'center' && tw`text-center`,
]);
const Text = tw.span`text-sm`;

type TextWithLinkAtEndProps = {
  plainText: string;
  linkText: string;
  onClick: VoidFunction;
  textAlign?: 'center' | 'left';
};

export const TextWithLinkAtEnd = ({ plainText, linkText, onClick, textAlign = 'left' }: TextWithLinkAtEndProps) => {
  return (
    <Container textAlign={textAlign}>
      <Text>
        {plainText} <StyledLink onClick={onClick} linkText={linkText} />
      </Text>
    </Container>
  );
};

export default TextWithLinkAtEnd;
