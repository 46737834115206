import { OperationConfigDto } from '@mezo/common/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useQuery } from '@tanstack/react-query';

export const getBrandingConfigForCustomer = async (customerId: string) => {
  const { data } = await ApiClient.NON_CHAT_API.utility.get(`/customers/${customerId}/configuration/branding`);
  return data;
};

export const getBrandingConfigForUnit = async (unitId: string) => {
  const { data } = await ApiClient.NON_CHAT_API.utility.get(`/unit/${unitId}/config/branding`);
  return data;
};

export const getBrandingConfiguration = async (configurationId: string) => {
  const { data } = await ApiClient.NON_CHAT_API.utility.get(`/configuration/${configurationId}/branding`);
  return data;
};

export const usePlatformConfig = () => {
  return useQuery({
    queryKey: ['platformOperationConfig'],
    queryFn: async () => {
      const { data } = await ApiClient.NON_CHAT_API.utility.get<OperationConfigDto>(`/configuration/operation`);
      return data;
    },
    enabled: true,
  });
};
