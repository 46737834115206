import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEventHandler,
  Ref,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import tw, { css, styled } from 'twin.macro';

export interface ResizeableTextAreaProps {
  disabled?: boolean;
  id?: string;
  name: string;
  placeholder?: string;
  minRows: number;
  maxRows: number;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  value: string;
  customStyles?: string;
}

const CustomTextArea = styled.textarea<{ customStyles?: string }>(({ customStyles }) => [
  tw`flex-1 py-3 leading-6 bg-transparent border-none resize-none px-1.5 focus:outline-none active:outline-none focus:ring-[0px] placeholder-text-light`,
  customStyles &&
    css`
      ${customStyles}
    `,
]);

export const ResizeableTextArea = forwardRef(
  (
    {
      disabled,
      id,
      name,
      placeholder,
      minRows,
      maxRows,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      value,
      customStyles,
    }: ResizeableTextAreaProps,
    ref: Ref<{
      focus: VoidFunction;
      blur: VoidFunction;
    }>
  ) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(ref, () => ({
      focus() {
        textAreaRef.current?.focus();
      },
      blur() {
        textAreaRef.current?.blur();
      },
    }));

    const [rows, setRows] = useState<number>(minRows);
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const textareaLineHeight = 24;
      const textareaPadding = 24;

      const previousRows = event.target.rows;
      event.target.rows = minRows;

      const currentRows = ~~((event.target.scrollHeight - textareaPadding) / textareaLineHeight);

      if (currentRows === previousRows) {
        event.target.rows = currentRows;
      }

      if (currentRows >= maxRows) {
        event.target.rows = maxRows;
        event.target.scrollTop = event.target.scrollHeight;
      }
      setRows(currentRows < maxRows ? currentRows : maxRows);
      onChange?.(event);
    };

    return (
      <CustomTextArea
        aria-label={name}
        data-hj-allow
        disabled={disabled}
        id={id}
        placeholder={placeholder}
        ref={textAreaRef}
        rows={rows}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        customStyles={customStyles}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        enterKeyHint="Go"
      />
    );
  }
);

export default ResizeableTextArea;
