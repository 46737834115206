import { NavLink as Link, useMatch, useResolvedPath } from 'react-router-dom';
import tw from 'twin.macro';
import { IconName } from '../icon';
import { MenuItem } from '../menu-item';

interface BreadCrumbProps {
  to: string;
  icon: IconName;
  label: string;
  showOnPageMatch: boolean;
}

const BreadCrumbContainer = tw.div`relative`;

export const BreadCrumb: React.FC<BreadCrumbProps> = ({ to, icon, label, showOnPageMatch }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const isMatch = to === match?.pattern.path;

  const generateMenuItem = (isMatch: boolean) =>
    isMatch ? (
      <BreadCrumbContainer>
        <MenuItem icon={icon} label={label} isActive={isMatch} />
      </BreadCrumbContainer>
    ) : (
      <BreadCrumbContainer>
        <Link to={to}>
          <MenuItem icon={icon} label={label} isActive={isMatch} />
        </Link>
      </BreadCrumbContainer>
    );

  if (showOnPageMatch) {
    return isMatch ? generateMenuItem(isMatch) : null;
  }
  return generateMenuItem(isMatch);
};
