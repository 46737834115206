import 'normalize.css';
import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';

// TODO: Update all of the styled components using the old theme to use tailwind
// when complete we can remove the old theme entirely
const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html {
    ${tw`h-full`}
  }
  body {
    ${tw`h-full m-0 text-text bg-background`}
  }
  #root {
    ${tw`h-full`}
  }
  a {
    text-decoration: none;
  }

  p {
    ${tw`m-0 text-base`}
  }
`;

export default GlobalStyle;
