import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw from 'twin.macro';

const LoaderContainer = tw.div`flex items-center justify-center h-full`;
const LoadingIcon = tw(FontAwesomeIcon)`transition-transform text-primary animate-spin`;
const HelperText = tw.span`absolute invisible -top-8 -left-8`;
export const Loading = () => {
  return (
    <LoaderContainer>
      <LoadingIcon icon={faSpinner} size="3x" />
      <HelperText>Loading...</HelperText>
    </LoaderContainer>
  );
};

export default Loading;
