import { IntelliflowVersionDto } from '@mezo/common/dtos';
import { useCreateIntelliflowVersion, useGetIntelliflowVersions, useSetIntelliflowVersion } from '@mezo/web/queries';
import { HttpStatus } from '@nestjs/common';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ResizeableTextArea } from '../../components';
import ButtonWithSpinner from '../../components/button.v2';

const selectStyles = {
  borderRadius: '0.5rem',
  height: '3.25rem',
  width: '12rem',
};

const ErrorMessage = tw.span`text-sm text-error`;
const FlexLabel = tw.label`flex flex-col gap-2`;

const FlexWrapper = styled.div<{ isRightJustified?: boolean }>(({ isRightJustified }) => [
  tw`flex flex-col gap-4`,
  isRightJustified && tw`items-end `,
]);

const FormContainer = tw.div`flex flex-col gap-4 p-4 border rounded-lg max-w-[1920px] border-text-grey bg-background-light`;
const FormsContainer = tw.div`container flex flex-col gap-4 p-4 mx-auto`;

const Input = styled.input<{ hasError?: boolean }>(({ hasError }) => [
  tw`w-full p-3 border rounded-lg border-text-grey`,
  hasError && tw`border-error`,
]);

const InputRow = tw.div`flex flex-wrap w-full gap-4`;
const InputRowLabel = tw(FlexLabel)`flex-grow-2 basis-0`;
const LabelText = styled.div<{ isBold?: boolean }>(({ isBold }) => [isBold && tw`font-medium`]);
const MenuRow = tw.div`flex flex-wrap items-center justify-end gap-2`;
const StyledButton = tw.div`flex w-32`;

const StyledButtonContainer = styled.div<{ isRightJustified?: boolean }>(({ isRightJustified }) => [
  tw`flex flex-row gap-4`,
  isRightJustified && tw`items-end `,
]);

const StyledError = tw.div`flex flex-col my-auto`;

const TextAreaWrapper = styled.div<{ hasError?: boolean }>(({ hasError }) => [
  tw`flex w-full p-3 border rounded-lg border-text-grey`,
  hasError && tw`border-error`,
]);

const TextBold = tw.span`font-medium`;

const Title = tw.h4`pt-2 text-2xl font-medium`;

type FormErrors = {
  apiKey: boolean;
  enabledItemIds: boolean;
  projectId: boolean;
  releaseNotes: boolean;
  version: boolean;
};

const defaultErrorState: FormErrors = {
  apiKey: false,
  enabledItemIds: false,
  projectId: false,
  releaseNotes: false,
  version: false,
};

type VersionDataUI = {
  apiKey: string;
  enabledItemIds: string;
  projectId: string;
  releaseNotes: string;
  version: string;
};

const defaultVersion: VersionDataUI = {
  apiKey: '',
  enabledItemIds: '',
  projectId: '',
  releaseNotes: '',
  version: '',
};

type IntelliflowVersionUI = {
  enabledItemIds: string[];
  id: string;
  isPlatformVersion: boolean;
  label: string;
  releaseNotes: string;
  version: string;
  value: string;
};

const defaultIntelliflowVersionUI: IntelliflowVersionUI = {
  enabledItemIds: [''],
  id: '',
  isPlatformVersion: false,
  label: '',
  releaseNotes: '',
  version: '',
  value: '',
};

export const IntelliflowVersion = () => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [versionData, setVersionData] = useState(defaultVersion);
  const [formErrors, setFormErrors] = useState(defaultErrorState);

  const transformData = useCallback((versions: IntelliflowVersionDto[]) => {
    return versions.map((versionDto) => ({
      ...versionDto,
      label: versionDto.version,
      value: versionDto.version,
    }));
  }, []);

  const { data: intelliflowVersions = [defaultIntelliflowVersionUI], refetch: fetchUpdatedIntelliflowVersions } =
    useGetIntelliflowVersions(transformData);

  // what comes back from system as active
  const [activeVersion] = intelliflowVersions.filter((v) => v.isPlatformVersion);
  const [selectedVersion, setSelectedVersion] = useState(activeVersion || defaultIntelliflowVersionUI);

  useEffect(() => {
    setSelectedVersion(activeVersion);
  }, [activeVersion]);

  const { mutateAsync: createIntelliflowVersion, isPending: isLoadingCreate } = useCreateIntelliflowVersion();
  const { mutateAsync: setIntelliflowVersion, isPending: isLoadingSet } = useSetIntelliflowVersion();

  const handleSetSelectedVersionNumber = useCallback(
    (optionValue: string) => {
      const [newSelectedVersion] = intelliflowVersions.filter((version) => version.value === optionValue);
      setSelectedVersion(newSelectedVersion);
    },
    [intelliflowVersions]
  );

  const handleFieldChange = useCallback((fieldName: keyof VersionDataUI, fieldValue: string) => {
    setVersionData((previousVersionData) => ({
      ...previousVersionData,
      [fieldName]: fieldValue,
    }));

    if (fieldValue) {
      setFormErrors((previousFormErrors) => ({
        ...previousFormErrors,
        [fieldName]: false,
      }));
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    for (const [key, value] of Object.entries(versionData)) {
      if (!value) {
        setFormErrors((previousFormErrors) => ({
          ...previousFormErrors,
          [key]: true,
        }));
      }
    }

    // check versionData values; formErrors won't have updated yet
    if (Object.values(versionData).some((value) => !value)) {
      return;
    }

    const versionDataDto = {
      ...versionData,
      enabledItemIds: versionData.enabledItemIds.split(','),
    };

    try {
      await createIntelliflowVersion(
        {
          versionData: versionDataDto,
        },
        {
          onError: (e) => {
            const error = e as AxiosError;

            const errorMessage =
              error.response?.status === HttpStatus.PRECONDITION_FAILED
                ? 'mxEngine.errors.preconditionFailError'
                : error.response?.status === HttpStatus.NOT_FOUND
                ? 'mxEngine.errors.projectNotFound'
                : error.response?.status === HttpStatus.UNAUTHORIZED
                ? 'mxEngine.errors.unauthorized'
                : '';

            toast(t(errorMessage) as string, {
              toastId: errorMessage,
            });
          },
          onSuccess: () => {
            toast(t('mxEngine.versionAdded') as string, {
              toastId: 'intelliflow-versionAdded',
            });

            setVersionData(defaultVersion);
            setFormErrors(defaultErrorState);
            fetchUpdatedIntelliflowVersions();
          },
        }
      );
    } catch (e) {
      // ignore - we handle errors in the mutation onError
      // this empty catch is here to prevent errors from
      // being thrown instead of handled in the e2e tests
    }
  }, [createIntelliflowVersion, fetchUpdatedIntelliflowVersions, versionData]);

  const handleSetIntelliflowVersion = useCallback(
    async (versionId: string) => {
      await setIntelliflowVersion(versionId, {
        onError: (e) => {
          const error = e as AxiosError;

          const errorMessage =
            error?.response?.status === HttpStatus.NOT_FOUND
              ? 'mxEngine.errors.versionNotFound'
              : 'mxEngine.errors.unknown';

          toast(t(errorMessage) as string, {
            toastId: errorMessage,
          });
        },
        onSuccess: () => {
          toast(t('mxEngine.activeVersionChanged') as string, {
            toastId: 'intelliflow-activeVersionChanged',
          });

          fetchUpdatedIntelliflowVersions();
        },
      });
    },
    [fetchUpdatedIntelliflowVersions, setIntelliflowVersion]
  );

  const hasFormErrors = Object.values(formErrors).some((hasError) => hasError);

  const createErrorMessage = useCallback(() => {
    return <ErrorMessage>{t('mxEngine.creationUnsuccessful')}</ErrorMessage>;
  }, []);

  return (
    <FormsContainer>
      <FormContainer>
        <FlexWrapper>
          <FlexLabel>
            <Title>{t('mxEngine.addNewVersion')}</Title>
          </FlexLabel>

          <InputRow>
            <InputRowLabel>
              <LabelText>{t('mxEngine.projectId')}</LabelText>
              <Input
                data-hj-allow
                hasError={formErrors.projectId}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFieldChange('projectId', e.target.value)}
                value={versionData.projectId}
              />
            </InputRowLabel>

            <InputRowLabel>
              <LabelText>{t('mxEngine.apiKey')}</LabelText>
              <Input
                data-hj-allow
                hasError={formErrors.apiKey}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFieldChange('apiKey', e.target.value)}
                value={versionData.apiKey}
              />
            </InputRowLabel>

            <InputRowLabel>
              <LabelText>{t('mxEngine.versionNumber')}</LabelText>
              <Input
                data-hj-allow
                hasError={formErrors.version}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleFieldChange('version', e.target.value)}
                value={versionData.version}
              />
            </InputRowLabel>
          </InputRow>
        </FlexWrapper>

        <FlexWrapper>
          <FlexLabel>
            <LabelText>{t('mxEngine.enabledItemIds')}</LabelText>
            <Input
              data-hj-allow
              hasError={formErrors.enabledItemIds}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleFieldChange('enabledItemIds', e.target.value)}
              value={versionData.enabledItemIds}
            />
          </FlexLabel>
        </FlexWrapper>

        <FlexWrapper>
          <FlexLabel>
            <LabelText>{t('mxEngine.releaseNotes')}</LabelText>
            <TextAreaWrapper hasError={formErrors.releaseNotes}>
              <ResizeableTextArea
                minRows={1}
                maxRows={3}
                name={t('mxEngine.releaseNotes')}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleFieldChange('releaseNotes', e.target.value)}
                ref={textAreaRef}
                value={versionData.releaseNotes}
              />
            </TextAreaWrapper>
          </FlexLabel>
        </FlexWrapper>

        <FlexWrapper isRightJustified={true}>
          <StyledButtonContainer isRightJustified={true}>
            <StyledError>{hasFormErrors && createErrorMessage()}</StyledError>
            <StyledButton>
              <ButtonWithSpinner primary={true} disabled={false} onClick={handleSubmit} isLoading={isLoadingCreate}>
                {t('mxEngine.addVersion')}
              </ButtonWithSpinner>
            </StyledButton>
          </StyledButtonContainer>
        </FlexWrapper>
      </FormContainer>

      <FormContainer>
        <FlexLabel>
          <LabelText>
            <TextBold>{t('mxEngine.currentVersion')}: </TextBold>
            {activeVersion?.label}
          </LabelText>
        </FlexLabel>

        <FlexLabel>
          <InputRow>
            <InputRowLabel>
              <Title>{t('mxEngine.setActiveVersion')}</Title>
            </InputRowLabel>

            <InputRowLabel>
              <MenuRow>
                <FlexLabel>
                  <LabelText>{t('mxEngine.version')}</LabelText>
                </FlexLabel>

                <Select
                  name={t('mxEngine.version')}
                  data-hj-allow
                  isLoading={false}
                  onChange={(option) => handleSetSelectedVersionNumber(option?.value || '')}
                  options={intelliflowVersions.map((v) => ({ label: v.label, value: v.value })) || []}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      ...selectStyles,
                    }),
                  }}
                  value={{
                    label: selectedVersion?.label || activeVersion?.label || '',
                    value: selectedVersion?.value || activeVersion?.id || '',
                  }}
                />
                <StyledButtonContainer>
                  <ButtonWithSpinner
                    disabled={false}
                    isLoading={isLoadingSet}
                    onClick={() => handleSetIntelliflowVersion(selectedVersion.id)}
                    primary={true}
                  >
                    {t('mxEngine.setAsActive')}
                  </ButtonWithSpinner>
                </StyledButtonContainer>
              </MenuRow>
            </InputRowLabel>
          </InputRow>
        </FlexLabel>

        <FlexWrapper>
          <FlexLabel>
            <LabelText isBold={true}>{t('mxEngine.versionDetails')}:</LabelText>
          </FlexLabel>
        </FlexWrapper>

        <FlexWrapper>
          <FlexLabel>
            <LabelText>{t('mxEngine.enabledItemIds')}</LabelText>
            <Input data-hj-allow value={selectedVersion?.enabledItemIds || ''} disabled={true} />
          </FlexLabel>
        </FlexWrapper>

        <FlexWrapper>
          <FlexLabel>
            <LabelText>{t('mxEngine.releaseNotes')}</LabelText>
            <TextAreaWrapper>
              <ResizeableTextArea
                disabled={true}
                minRows={1}
                maxRows={3}
                name={t('mxEngine.releaseNotes')}
                ref={textAreaRef}
                value={selectedVersion?.releaseNotes || ''}
              />
            </TextAreaWrapper>
          </FlexLabel>
        </FlexWrapper>
      </FormContainer>
    </FormsContainer>
  );
};
