import { faArrowRight, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProblemCaptureSelectionType, TypeAheadResponseItemDto } from '@mezo/common/dtos';
import { useThrottle } from '@mezo/web/hooks';
import parse from 'html-react-parser';
import { t } from 'i18next';
import { ChangeEvent, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import tw, { css, styled } from 'twin.macro';
import { ChatBanner, ResizeableTextArea } from '../../components';
import { ellipses } from './problem-capture';
const SPACE_KEY = ' ';
const ENTER_KEY = 'Enter';
const BACKSPACE_KEY = 'Backspace';

// TYPE AHEAD
const TypeAheadContainer = tw.div`flex items-start w-full p-2 bg-white shadow-md min-h-14`;
const Form = tw.form`flex items-center flex-1 text-sm tracking-wide text-center outline-none justify-items-stretch`;
const TypeAheadSkeletonContainer = tw.li`h-full pl-4 pr-4`;
const SkeletonWrap = tw.div`w-full mt-2 mb-4 leading-10 `;
const GoButton = tw.button`flex items-center justify-center w-10 h-10 text-white border-none rounded bg-primary`;
const TypeAheadResults = tw.ul``;
const TypeAheadResultContainer = tw.li`relative p-4 cursor-pointer before:absolute before:hover:bg-primary before:focus:bg-primary before:left-0 before:top-0 before:w-full before:h-full before:opacity-25`;
const Container = tw.div`px-4 py-2`;
const ResultsWrapper = styled.div(() => [
  css`
    & + & {
      border-top: 1px solid var(--light-grey);
    }
  `,
]);

const createOnClickHandler = (
  handleSubmit: (result: TypeAheadResponseItemDto, probCapType: ProblemCaptureSelectionType) => void,
  result: TypeAheadResponseItemDto,
  index: number,
) => {
  return () => {
    handleSubmit(
      result,
      index === 0
        ? ProblemCaptureSelectionType.TYPE_AHEAD_ENTRY_CLICK
        : ProblemCaptureSelectionType.TYPE_AHEAD_RESULT_CLICK,
    );
  };
};

type TypeAheadProps = {
  onSubmit: (
    item: TypeAheadResponseItemDto,
    isModalVisible: boolean,
    problemCaptureSelectionType: ProblemCaptureSelectionType,
  ) => Promise<void>;
  typeAheadText: string;
  typeAheadResults?: (TypeAheadResponseItemDto & { elem?: JSX.Element })[];
  handleTypeAheadChange: (text: string) => void;
  typeAheadLoading: boolean;
  taxonomyItemHasIntelliflow?: boolean;
  handleTrackTypeAheadInteraction: (data1: any) => void;
  handleTrackTypeAheadClear: (data1: any, data2?: any) => void;
  debouncedTypeAheadText: string;
};

const textAreaStyles = `
  font-size: 1rem /* 16px */;
  line-height: 1.25rem /* 20px */;
  padding: 0.5rem; /* 8px */;
  scrollbar-width: none;
`;

export const MAXIMUM_TEXT_LENGTH = 140;

const MAX_ROWS = 4;
const MIN_ROWS = 1;

const generateFormattedText = (searchText: string, result: TypeAheadResponseItemDto) => {
  const searchTextWords = searchText.toLowerCase().trim().split(' ');
  const completionTextWords = result.text.toLowerCase().trim().split(' ');
  const formattedText = [];

  for (const word of completionTextWords) {
    if (searchTextWords.includes(word)) {
      formattedText.push(`${word}`);
    } else {
      formattedText.push(`<strong>${word}</strong>`);
    }
  }
  if (result.taxonomy?.needItem || result.taxonomy?.needSymptom || result.taxonomy?.needLocation) {
    formattedText.push(ellipses);
  }
  return <span>{parse(formattedText.join(' '))}</span>;
};

export const TypeAhead: React.FC<TypeAheadProps> = ({
  onSubmit,
  typeAheadText,
  typeAheadResults,
  handleTypeAheadChange,
  typeAheadLoading,
  handleTrackTypeAheadClear,
  debouncedTypeAheadText,
}) => {
  const typeAheadInputRef = useRef<HTMLTextAreaElement>(null);
  const [isTypeAheadThrottling, setIsTypeAheadThrottling] = useState(false);

  useEffect(() => {
    typeAheadInputRef.current?.focus();
  }, []);

  const onTypeAheadSubmit = useCallback(
    async (result: TypeAheadResponseItemDto, selectionType: ProblemCaptureSelectionType) => {
      await onSubmit(result, false, selectionType);
      return;
    },
    [onSubmit],
  );

  const onGoClick = useCallback(() => {
    const firstResult = {
      text: typeAheadText,
      taxonomy: {},
    };
    if (typeAheadText) onTypeAheadSubmit(firstResult, ProblemCaptureSelectionType.TYPE_AHEAD_ENTER_PRESS);
  }, [typeAheadText, onTypeAheadSubmit]);

  const onTypeAheadChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      handleTypeAheadChange(e.target.value);
    },
    [handleTypeAheadChange],
  );

  const handleTextAreaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === ENTER_KEY && e.shiftKey === false && typeAheadText.length !== 0) {
      e.preventDefault();
      const firstResult = {
        text: typeAheadText,
        taxonomy: {},
      };
      onTypeAheadSubmit(firstResult, ProblemCaptureSelectionType.TYPE_AHEAD_ENTER_PRESS);
    } else if (e.key === BACKSPACE_KEY && typeAheadText.length === 0) {
      e.preventDefault();
      handleTrackTypeAheadClear(debouncedTypeAheadText, 'backspace');
    } else if (e.key === ENTER_KEY) {
      // dont add any newlines ever
      e.preventDefault();
    }
  };
  const handleTypeAheadOptionKeyDown = (e: React.KeyboardEvent, result: TypeAheadResponseItemDto) => {
    if ((e.key === ENTER_KEY || e.key === SPACE_KEY) && e.shiftKey === false && result.text) {
      e.preventDefault();
      onTypeAheadSubmit(result, ProblemCaptureSelectionType.TYPE_AHEAD_ENTER_PRESS);
    }
  };

  const shouldDisplaySuggestedLengthText = typeAheadText.length >= MAXIMUM_TEXT_LENGTH;

  const shouldDisplayTypeAheadResults =
    typeAheadResults && typeAheadResults.length > 0 && !shouldDisplaySuggestedLengthText;

  const SkeletonWrapper = ({ children }: PropsWithChildren<unknown>) => {
    return <SkeletonWrap>{children}</SkeletonWrap>;
  };

  return (
    <>
      <TypeAheadContainer>
        <Form action="#">
          <ResizeableTextArea
            ref={typeAheadInputRef}
            name={'type-ahead-input'}
            minRows={MIN_ROWS}
            maxRows={MAX_ROWS}
            value={typeAheadText}
            placeholder={t('problemCapture.placeholder')}
            onChange={onTypeAheadChange}
            onKeyDown={handleTextAreaKeyDown}
            data-hj-allow
            customStyles={textAreaStyles}
          />
        </Form>
        {typeAheadText.length > 0 && (
          <GoButton onClick={onGoClick} aria-label={'Go'}>
            <FontAwesomeIcon icon={faArrowRight} size="xl" />
          </GoButton>
        )}
      </TypeAheadContainer>

      <TypeAheadResults>
        <ResultsWrapper>
          {typeAheadLoading && (
            <TypeAheadSkeletonContainer>
              <SkeletonTheme borderRadius=".5rem" duration={0.5}>
                <SkeletonWrapper>
                  <Skeleton style={{ width: '40%', marginBottom: '1.75rem' }} />
                  <Skeleton style={{ width: '80%', marginBottom: '1.75rem' }} />
                  <Skeleton style={{ width: '90%', marginBottom: '1.75rem' }} />
                  <Skeleton style={{ width: '80%', marginBottom: '1.75rem' }} />
                </SkeletonWrapper>
              </SkeletonTheme>
            </TypeAheadSkeletonContainer>
          )}
          {shouldDisplayTypeAheadResults &&
            typeAheadResults?.map(
              (result, index) =>
                index > 0 && (
                  <TypeAheadResult
                    key={result.text}
                    result={result}
                    handleSubmit={onTypeAheadSubmit}
                    index={index}
                    typeAheadText={typeAheadText}
                    handleKeyDown={handleTypeAheadOptionKeyDown}
                    isTypeAheadThrottling={isTypeAheadThrottling}
                    setIsTypeAheadThrottling={setIsTypeAheadThrottling}
                  />
                ),
            )}
        </ResultsWrapper>
      </TypeAheadResults>
      {(shouldDisplayTypeAheadResults || shouldDisplaySuggestedLengthText) && (
        <Container>
          {typeAheadResults?.length !== 1 && !shouldDisplaySuggestedLengthText && (
            <ChatBanner icon={faInfoCircle} lineOne={t('problemCapture.typeAheadResultInstructions')} />
          )}
          {!typeAheadLoading && typeAheadResults?.length === 1 && !shouldDisplaySuggestedLengthText && (
            <ChatBanner icon={faInfoCircle} lineOne={t('problemCapture.continue')} />
          )}
          {shouldDisplaySuggestedLengthText && (
            <ChatBanner icon={faInfoCircle} lineOne={t('problemCapture.keepSimple')} parseHtml={true} />
          )}
        </Container>
      )}
    </>
  );
};

type TypeAheadResultProps = {
  handleSubmit: (result: TypeAheadResponseItemDto, selectionType: ProblemCaptureSelectionType) => Promise<void>;
  index: number;
  typeAheadText: string;
  handleKeyDown: (e: React.KeyboardEvent, result: TypeAheadResponseItemDto) => void;
  result: TypeAheadResponseItemDto;
  isTypeAheadThrottling: boolean;
  setIsTypeAheadThrottling: (isThrottling: boolean) => void;
};

const TypeAheadResult = ({
  result,
  handleSubmit,
  index,
  typeAheadText,
  handleKeyDown,
  isTypeAheadThrottling,
  setIsTypeAheadThrottling,
}: TypeAheadResultProps) => {
  const onClick = createOnClickHandler(handleSubmit, result, index);

  const throttledFunc = useThrottle(onClick, 500, isTypeAheadThrottling, setIsTypeAheadThrottling);
  return (
    <TypeAheadResultContainer tabIndex={0} onClick={throttledFunc} onKeyDown={(e) => handleKeyDown(e, result)}>
      {generateFormattedText(typeAheadText, result)}
    </TypeAheadResultContainer>
  );
};
