import { fetchAndActivate, getRemoteConfig, getValue, isSupported, RemoteConfig, Value } from '@firebase/remote-config';
import { hoursToMilliseconds } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { app } from './firebase.app.service';

export const enum RemoteConfiguration {
  USE_RESIDENT_IDENTIFICATION = 'useResidentIdentification',
  USE_EMBEDDED_RESIDENT_IDENTIFICATION = 'useEmbeddedResidentIdentification',
}

export const useFirebaseConfig = () => {
  const [configLoaded, setConfigLoaded] = useState<boolean>(false);
  const remoteConfig = useRef<RemoteConfig>();

  useEffect(() => {
    async function loadRemoteConfig() {
      const remoteConfigIsSupported = await isSupported();
      if (!remoteConfigIsSupported) {
        console.error('Remote config is not enabled in this environment');
        setConfigLoaded(true);
        return;
      }

      const config = getRemoteConfig(app);
      config.settings.minimumFetchIntervalMillis = hoursToMilliseconds(6);
      config.defaultConfig = { [RemoteConfiguration.USE_RESIDENT_IDENTIFICATION]: false };
      if (config.lastFetchStatus !== 'success') {
        await fetchAndActivate(config);
      }
      setConfigLoaded(config.lastFetchStatus === 'success');
      remoteConfig.current = config;
    }

    loadRemoteConfig();
  }, []);

  const getConfigValue = useCallback((configurationName: RemoteConfiguration): Value | undefined => {
    if (!remoteConfig.current) {
      return;
    }
    return getValue(remoteConfig.current, configurationName);
  }, []);

  const getBooleanValue = useCallback(
    (configurationName: RemoteConfiguration): boolean | undefined => getConfigValue(configurationName)?.asBoolean(),
    [getConfigValue]
  );

  return {
    configLoaded,
    getBooleanValue,
  };
};
