import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { faClock, faToolbox, faTruck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'styled-react-modal';
import tw from 'twin.macro';
import { ButtonV2 } from '../../components';
import { KEYCODES } from '../../types/constants';

const IconButton = tw.button`p-0 border-0 cursor-pointer bg-none`;
const Title = tw.h5`m-0 text-lg font-medium leading-7 text-left`;
const CloseIcon = tw(FontAwesomeIcon)`fill-current text-disabled`;
const StyledIcon = tw(FontAwesomeIcon)`w-6 fill-current text-primary`;
const ModalContainer = tw.div`fixed flex flex-col justify-between w-[calc(100%-2rem)] max-w-md gap-4 p-4 transform -translate-x-1/2 bg-background-light rounded-3xl top-4 left-1/2`;
const FlexContainer = tw.div`flex items-start justify-between gap-4`;
const VerticalFlexContainer = tw(FlexContainer)`flex-col items-stretch`;

const Link = tw.a`underline cursor-pointer text-primary`;

const List = tw.ul`flex flex-col gap-2`;
const Item = tw.li`flex items-start gap-2`;

const CenteredText = tw.p`text-center`;

type SkipModalProps = {
  isVisible: boolean;
  onClose: VoidFunction;
  onSkip: VoidFunction;
  customBodyText?: string;
  customBulletPoints?: string[];
};

const getStyledIcon = (i: number) => {
  switch (i) {
    case 0:
      return faClock;
    case 1:
      return faToolbox;
    case 2:
      return faTruck;
    default:
      return faToolbox;
  }
};

const ListItem = ({ text, index }: { text: string; index: number }) => {
  return (
    <Item>
      <StyledIcon icon={getStyledIcon(index)} size="lg" />
      {text}
    </Item>
  );
};

export const SkipModal = ({ isVisible, onClose, onSkip, customBodyText, customBulletPoints }: SkipModalProps) => {
  const { t } = useTranslation();
  const bulletPoints = customBulletPoints || [
    t('media.skipModal.item1'),
    t('media.skipModal.item2'),
    t('media.skipModal.item3'),
  ];
  const handleEnterPress = useCallback((e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.keyCode === KEYCODES.enter && e.shiftKey === false) {
      e.currentTarget.click();
    }
  }, []);
  return (
    <Modal isOpen={isVisible} onBackgroundClick={onClose} onEscapeKeydown={onClose}>
      <ModalContainer>
        <FlexContainer>
          <Title>{t('media.skipModal.header')}</Title>
          <IconButton onClick={onClose} aria-label={t('media.confirmModal.cancel')}>
            <CloseIcon icon={faXmark} size="lg" />
          </IconButton>
        </FlexContainer>
        <VerticalFlexContainer>
          <p>{customBodyText || t('media.skipModal.provideDetails')}</p>
          <List>
            {bulletPoints.map((bp, i) => (
              <ListItem key={bp} text={bp} index={i} />
            ))}
          </List>
          <ButtonV2 primary={true} onClick={onClose}>
            {t('media.skipModal.goBack')}
          </ButtonV2>
          <CenteredText>
            <Link onClick={onSkip} onKeyUp={handleEnterPress} tabIndex={0}>
              {t('media.skip')}
            </Link>
          </CenteredText>
        </VerticalFlexContainer>
      </ModalContainer>
    </Modal>
  );
};
