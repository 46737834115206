import { useEffect } from 'react';

export const useBeforeUnload = (isEnabled = true) => {
  useEffect(() => {
    const handleBeforeUnload = (ev: BeforeUnloadEvent) => {
      ev.preventDefault();
      ev.returnValue = '';
      return true;
    };
    if (isEnabled) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isEnabled]);
};
