import { CommonProblem, ProblemCaptureSelectionType, TypeAheadResponseItemDto } from '@mezo/common/dtos';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { ButtonV2 } from '../../components';
import { KEYCODES } from '../../types';

const Container = tw.div`p-4`;

const Prompt = tw.div`mb-2 font-bold`;
const PromptBox = tw.div`cursor-pointer min-h-28`;
const PlaceholderText = tw.span`text-sm text-text-light`;
const CommonIssueBox = tw.div``;
const CommonProblemContainer = tw.div`flex flex-col gap-2`;
const Label = tw.div`mb-4`;
const ButtonSmall = tw(ButtonV2)`min-h-[40px]`;

type ProblemCaptureProps = {
  onSubmit: (item: TypeAheadResponseItemDto, selectionType: ProblemCaptureSelectionType) => Promise<void>;
  commonProblems?: CommonProblem[];
  handlePromptClick: VoidFunction;
};

export const ellipses = '...';

const MAX_NUMBER_OF_COMMON_PROBLEMS = 5;

export const ProblemCapture: React.FC<ProblemCaptureProps> = ({ onSubmit, commonProblems, handlePromptClick }) => {
  const { t } = useTranslation();
  const [currentExample, setCurrentExample] = useState(0);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === KEYCODES.enter && e.shiftKey === false) {
      e.currentTarget.click();
    }
  };

  const exampleStatements = [
    t('problemCapture.placeholder'),
    t('problemCapture.placeholder1'),
    t('problemCapture.placeholder2'),
  ];

  useEffect(() => {
    const interval = setInterval(() => setCurrentExample((prev) => (prev + 1) % exampleStatements.length), 4000);
    return () => clearInterval(interval);
  });

  return (
    <Container>
      <Prompt>{t('problemCapture.prompt')}</Prompt>
      <PromptBox onClick={handlePromptClick} tabIndex={0} onKeyDown={handleKeyPress}>
        <PlaceholderText>{exampleStatements[currentExample]}</PlaceholderText>
      </PromptBox>
      {commonProblems && commonProblems.length > 0 && (
        <CommonIssueBox>
          <Label>{t('problemCapture.commonIssue')}</Label>
          <CommonProblemContainer>
            {commonProblems.map((problem, index) => {
              if (index >= MAX_NUMBER_OF_COMMON_PROBLEMS) return;
              else
                return (
                  <ButtonSmall
                    key={problem.text}
                    onClick={() =>
                      onSubmit(
                        {
                          text: problem.text,
                          taxonomy: problem.taxonomy as TypeAheadResponseItemDto['taxonomy'],
                        },
                        ProblemCaptureSelectionType.COMMON_PROBLEM_CLICK
                      )
                    }
                  >
                    {problem.text +
                      (problem.taxonomy.needItem || problem.taxonomy.needLocation || problem.taxonomy.needSymptom
                        ? ellipses
                        : '')}
                  </ButtonSmall>
                );
            })}
          </CommonProblemContainer>
        </CommonIssueBox>
      )}
    </Container>
  );
};
