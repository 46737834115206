import { useEffect, useRef } from 'react';
import { BrandingConfiguration } from './useBrandingConfiguration';

export const useBrandingColors = (
  customerId: string | undefined,
  brandingConfiguration: BrandingConfiguration | undefined
) => {
  const styleNode = useRef<HTMLStyleElement>();

  useEffect(() => {
    if (!customerId || !brandingConfiguration?.primaryColor) {
      return;
    }

    if (styleNode.current) {
      styleNode.current.remove();
    }

    const id = customerId.toUpperCase();
    const css = document.createElement('style');
    const style = `.${id} {
      --primary: ${brandingConfiguration.primaryColor} !important
    }`;
    css.append(style);
    document.head.appendChild(css);
    const root = document.querySelector(':root');
    root?.classList.add(`${id}`);
    styleNode.current = css;
  }, [customerId, brandingConfiguration?.primaryColor]);
};
