import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';
import { ButtonV2, SectionWithLightPrimaryBackground } from '../../components';

const Container = tw.div`flex flex-col items-center justify-center gap-4`;
const Text = tw.p`text-sm leading-6`;

const TransitionContainer = tw.div`flex flex-col items-center gap-4 p-4`;
const ProblemStatementContainer = tw.p`p-4 m-0 border-b border-light-grey`;

type TransitionPageProps = {
  brandingColor?: string;
  bodyText: string;
  onSubmit: VoidFunction;
  problemStatement: string;
};

export const TransitionPage: React.FC<TransitionPageProps> = ({
  brandingColor,
  bodyText,
  problemStatement,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <ProblemStatementContainer>{problemStatement}</ProblemStatementContainer>
      <TransitionContainer>
        <SectionWithLightPrimaryBackground>
          <Container>
            <IntelliflowSVG fillColor={brandingColor} />
            <Text>{bodyText}</Text>
          </Container>
        </SectionWithLightPrimaryBackground>
        <ButtonV2 onClick={onSubmit} primary={true}>
          {t('problemCapture.typeAheadActionButton')}
        </ButtonV2>
      </TransitionContainer>
    </>
  );
};

const IntelliflowSVG = ({ fillColor }: { fillColor?: string }) => {
  return (
    <svg width="311" height="89" viewBox="0 0 311 89" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_449_3275" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83 0C77.4772 0 73 4.47715 73 10V52C73 57.5228 77.4772 62 83 62H89L89 76L103 62H159C164.523 62 169 57.5228 169 52V10C169 4.47715 164.523 0 159 0H83Z"
        />
      </mask>
      <path
        d="M89 62H92V59H89V62ZM89 76H86L86 83.2426L91.1213 78.1213L89 76ZM103 62V59H101.757L100.879 59.8787L103 62ZM76 10C76 6.13401 79.134 3 83 3V-3C75.8203 -3 70 2.8203 70 10H76ZM76 52V10H70V52H76ZM83 59C79.134 59 76 55.866 76 52H70C70 59.1797 75.8203 65 83 65V59ZM89 59H83V65H89V59ZM92 76L92 62H86L86 76H92ZM100.879 59.8787L86.8787 73.8787L91.1213 78.1213L105.121 64.1213L100.879 59.8787ZM159 59H103V65H159V59ZM166 52C166 55.866 162.866 59 159 59V65C166.18 65 172 59.1797 172 52H166ZM166 10V52H172V10H166ZM159 3C162.866 3 166 6.13401 166 10H172C172 2.8203 166.18 -3 159 -3V3ZM83 3H159V-3H83V3Z"
        fill={fillColor || '#000'}
        mask="url(#path-1-inside-1_449_3275)"
      />
      <mask id="path-3-inside-2_449_3275" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M239 13C244.523 13 249 17.4772 249 23V65C249 70.5228 244.523 75 239 75H233L233 89L219 75H163C157.477 75 153 70.5228 153 65V23C153 17.4772 157.477 13 163 13H239Z"
        />
      </mask>
      <path
        d="M233 75H230V72H233V75ZM233 89H236L236 96.2426L230.879 91.1213L233 89ZM219 75V72H220.243L221.121 72.8787L219 75ZM246 23C246 19.134 242.866 16 239 16V10C246.18 10 252 15.8203 252 23H246ZM246 65V23H252V65H246ZM239 72C242.866 72 246 68.866 246 65H252C252 72.1797 246.18 78 239 78V72ZM233 72H239V78H233V72ZM230 89L230 75H236L236 89H230ZM221.121 72.8787L235.121 86.8787L230.879 91.1213L216.879 77.1213L221.121 72.8787ZM163 72H219V78H163V72ZM156 65C156 68.866 159.134 72 163 72V78C155.82 78 150 72.1797 150 65H156ZM156 23V65H150V23H156ZM163 16C159.134 16 156 19.134 156 23H150C150 15.8203 155.82 10 163 10V16ZM239 16H163V10H239V16Z"
        fill={fillColor || '#000'}
        mask="url(#path-3-inside-2_449_3275)"
      />
      <path
        d="M122.495 39.87C124.053 39.87 125.307 41.124 125.307 42.682C125.307 44.24 124.053 45.494 122.495 45.494C120.937 45.494 119.683 44.24 119.683 42.682C119.683 41.124 120.937 39.87 122.495 39.87ZM122.191 22.314C120.025 22.314 119.037 23.834 118.733 25.278L114.363 24.176C115.389 19.768 118.923 17.944 122.533 17.944C127.093 17.944 130.627 21.288 130.627 24.974C130.627 30.902 124.851 31.282 124.851 36.412H120.253C120.253 30.56 125.763 29.344 125.763 25.658C125.763 23.758 124.585 22.314 122.191 22.314Z"
        fill={fillColor || '#000'}
      />
      <path
        d="M194.889 34.4444H214.444C214.933 34.4444 215.333 34.8444 215.333 35.3333V47.7778C215.333 48.2667 214.933 48.6667 214.444 48.6667H213.267L207.561 39.9389C207.317 39.5611 206.894 39.3333 206.444 39.3333C205.994 39.3333 205.572 39.5611 205.328 39.9389L202.428 44.3722L201.739 43.4333C201.489 43.0889 201.089 42.8889 200.661 42.8889C200.233 42.8889 199.839 43.0889 199.583 43.4333L195.756 48.6667H194.889C194.4 48.6667 194 48.2667 194 47.7778V35.3333C194 34.8444 194.4 34.4444 194.889 34.4444ZM191.333 35.3333V47.7778C191.333 49.7389 192.928 51.3333 194.889 51.3333H214.444C216.406 51.3333 218 49.7389 218 47.7778V35.3333C218 33.3722 216.406 31.7778 214.444 31.7778H194.889C192.928 31.7778 191.333 33.3722 191.333 35.3333ZM188.667 36.6667C188.667 35.9278 188.072 35.3333 187.333 35.3333C186.594 35.3333 186 35.9278 186 36.6667V49.1111C186 53.2833 189.383 56.6667 193.556 56.6667H211.333C212.072 56.6667 212.667 56.0722 212.667 55.3333C212.667 54.5944 212.072 54 211.333 54H193.556C190.856 54 188.667 51.8111 188.667 49.1111V36.6667ZM200.222 38C200.222 37.5285 200.035 37.0763 199.702 36.7429C199.368 36.4095 198.916 36.2222 198.444 36.2222C197.973 36.2222 197.521 36.4095 197.187 36.7429C196.854 37.0763 196.667 37.5285 196.667 38C196.667 38.4715 196.854 38.9237 197.187 39.2571C197.521 39.5905 197.973 39.7778 198.444 39.7778C198.916 39.7778 199.368 39.5905 199.702 39.2571C200.035 38.9237 200.222 38.4715 200.222 38Z"
        fill={fillColor || '#000'}
      />
    </svg>
  );
};
