import { ClickSource, UserSessionDto, CloseSubmitAttemptDto } from '@mezo/common/dtos';
import { AbandonReason, Page, UserSessionStatusType } from '@mezo/common/utils';
import { useUserSessionContext } from '@mezo/web/contexts';
import { LocalStorageResident, MEZO_RESIDENT_STORAGE_KEY, useLocalStorage } from '@mezo/web/hooks';
import { ApiClient, RouteBuilder } from '@mezo/web/utils';
import { captureException } from '@sentry/react';
import { AxiosError } from 'axios';
import { useCallback, useEffect } from 'react';
import { ErrorBoundary as Boundary, useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import tw from 'twin.macro';
import TextWithLinkAtEnd from './text-with-link';

const ErrorIcon = () => {
  return (
    <svg width="144" height="123" viewBox="0 0 144 123" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 7C3 4.79086 4.79086 3 7 3H137C139.209 3 141 4.79086 141 7V19H3V7Z"
        fill="#CCCCCC"
        stroke="#CCCCCC"
        strokeWidth="6"
      />
      <circle cx="11" cy="11" r="3" fill="white" />
      <circle cx="21" cy="11" r="3" fill="white" />
      <circle cx="31" cy="11" r="3" fill="white" />
      <g clipPath="url(#clip0_1_3080)">
        <path
          d="M56.7547 48.4297C55.9383 47.7938 54.7781 47.8712 54.0391 48.6016L50.6016 52.0391C49.8711 52.7696 49.7937 53.9297 50.4211 54.7547L57.2961 63.6922C57.6828 64.1993 58.293 64.5001 58.9289 64.5001H63.5781L72.9453 73.8672C71.682 76.3594 72.0859 79.4876 74.1742 81.5672L83.7992 91.1922C84.8734 92.2665 86.618 92.2665 87.6922 91.1922L93.1922 85.6922C94.2664 84.618 94.2664 82.8735 93.1922 81.7993L83.5672 72.1743C81.4875 70.0946 78.3594 69.6821 75.8672 70.9454L66.5 61.5782V56.9376C66.5 56.293 66.1992 55.6915 65.6922 55.3047L56.7547 48.4297ZM51.9937 80.3813C50.7133 81.6618 50 83.3891 50 85.1938C50 88.9493 53.0508 92.0001 56.8062 92.0001C58.6109 92.0001 60.3383 91.2868 61.6187 90.0063L70.4961 81.129C69.7312 79.6165 69.3961 77.9407 69.4992 76.2907L58.7055 87.093C58.1984 87.5915 57.5195 87.8751 56.8062 87.8751C55.3281 87.8751 54.125 86.6719 54.125 85.1938C54.125 84.4805 54.4086 83.8016 54.907 83.2946L66.6977 71.504L63.7844 68.5907L51.9937 80.3813ZM89.8664 62.0938C89.7461 65.4368 87.9242 68.3501 85.243 69.9829C85.3375 70.0688 85.4234 70.1547 85.5094 70.2407L88.225 72.9563C91.7226 70.4641 94 66.3735 94 61.7587C94 59.6274 93.5102 57.6079 92.6508 55.8032C92.2727 55.0126 91.2328 54.9008 90.6141 55.5196L84.7789 61.3547C84.5211 61.6126 84.1687 61.7587 83.8078 61.7587H81.625C80.8687 61.7587 80.25 61.1399 80.25 60.3837V58.1922C80.25 57.8313 80.3961 57.479 80.6539 57.2212L86.4891 51.386C87.1078 50.7672 86.9961 49.7274 86.2055 49.3493C84.4008 48.4899 82.3812 48.0001 80.25 48.0001C75.3258 48.0001 71.0031 50.5868 68.5797 54.4797C69.0094 55.2188 69.25 56.061 69.25 56.9376V60.4438L70.625 61.8188V61.7501C70.625 56.5508 74.75 52.3055 79.9062 52.1336L77.732 54.3079C76.7008 55.3391 76.125 56.7399 76.125 58.2008V60.3751C76.125 63.4087 78.5914 65.8751 81.625 65.8751H83.8078C85.2687 65.8751 86.6695 65.2993 87.7008 64.268L89.8664 62.0938Z"
          fill="#CCCCCC"
        />
      </g>
      <path
        d="M3 19H141V116C141 118.209 139.209 120 137 120H7C4.79086 120 3 118.209 3 116V19Z"
        stroke="#CCCCCC"
        strokeWidth="6"
      />
      <defs>
        <clipPath id="clip0_1_3080">
          <rect width="44" height="44" fill="white" transform="translate(50 48)" />
        </clipPath>
      </defs>
    </svg>
  );
};
interface ErrorBoundaryProps {
  children?: React.ReactNode;
}

const Container = tw.div`h-full mx-auto rounded-xl bg-background-light`;
const Header = tw.section`flex flex-col items-center w-full px-6 py-5`;
const Body = tw.section`flex flex-col w-full px-6 py-2`;
const Footer = tw.section`flex flex-col w-full px-6 py-2`;
const ErrorMessage = tw.h4`text-lg font-bold`;
const BackToMainContainer = tw.div``;
const LetUsKnowContainer = tw.div`pt-4`;

const ErrorFallback = () => {
  const { t } = useTranslation();

  const params = useParams();
  const [searchParams] = useSearchParams();
  const { resetBoundary } = useErrorBoundary();

  const handleOnClickGoBack = useCallback(() => {
    resetBoundary();

    if (params['*']) {
      const pathname = `${params['*']}/intro`;
      const search = searchParams.toString();

      window.location.replace(`${pathname}?${search}`);
      return;
    }

    if (params['customerId'] && params['residentId'] && params['unitId']) {
      const residentHomeUrl = new RouteBuilder()
        .buildResidentHomeRoute(params['customerId'], params['residentId'], params['unitId'])
        .prependPath(RouteBuilder.RESIDENT)
        .build();
      const search = searchParams.toString();

      window.location.replace(`${residentHomeUrl}?${search}`);
      return;
    }

    const addressesUrl = new RouteBuilder().buildAddressesRoute().prependPath(RouteBuilder.RESIDENT).build();
    window.location.replace(addressesUrl);
  }, [params, searchParams, resetBoundary]);

  const handleOnClickLetUsKnow = useCallback(() => {
    const supportFormUrl = new RouteBuilder()
      .buildSupportFormRoute(ClickSource.ERROR_PAGE)
      .prependPath(RouteBuilder.RESIDENT)
      .build();
    window.location.replace(supportFormUrl);
  }, []);

  return (
    <Container>
      <Header>
        <ErrorIcon />
      </Header>
      <Body>
        <ErrorMessage>{t('chatError.error')}</ErrorMessage>
      </Body>
      <Footer>
        <BackToMainContainer>
          <TextWithLinkAtEnd
            plainText={t('chatError.weHadTrouble')}
            linkText={t('chatError.goingBackToTheMainScreen')}
            onClick={handleOnClickGoBack}
          />
        </BackToMainContainer>
        <LetUsKnowContainer>
          <TextWithLinkAtEnd
            plainText={t('chatError.ifTheIssuePersists')}
            linkText={t('chatError.letUsKnow')}
            onClick={handleOnClickLetUsKnow}
          />
        </LetUsKnowContainer>
      </Footer>
    </Container>
  );
};

export const ChatErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const { value } = useLocalStorage<LocalStorageResident>(MEZO_RESIDENT_STORAGE_KEY);

  const { userSessionId, updateUserSessionPage } = useUserSessionContext();

  useEffect(() => {
    if (userSessionId) {
      updateUserSessionPage(Page.ERROR_PAGE);
    }
  }, [updateUserSessionPage, userSessionId]);

  const handleOnError = useCallback(
    (err: Error | AxiosError) => {
      const e = err as AxiosError;
      const tags = {
        requestUrl: e.config?.url,
        status: e.response?.status,
        method: e.config?.method,
        message: e.message,
        code: e.code,
      };
      captureException(err, { tags });

      if (value?.currentUserSessionId && value?.currentAttemptId) {
        const request: CloseSubmitAttemptDto = {
          status: UserSessionStatusType.ABANDONED,
          abandonReason: AbandonReason.ERROR,
          abandonErrorMessage: err.message,
        };

        try {
          ApiClient.CHAT_API.utility.post<UserSessionDto>(`/user-session/${value.currentUserSessionId}/close`, request);
        } catch (e) {
          // do nothing
        }
      }
    },
    [value?.currentUserSessionId, value?.currentAttemptId]
  );

  return (
    <Boundary FallbackComponent={ErrorFallback} onError={handleOnError}>
      {children}
    </Boundary>
  );
};
