import { CalendarDate, getDayOfWeek, isSameDay } from '@internationalized/date';
import { FirstAvailable } from '@mezo/shared/common';
import { Locale } from '@mezo/common/utils';
import { add, isBefore } from 'date-fns';
import { AvailabilitySegment } from './scheduling.types';
const NUM_DAYS_IN_WEEK = 7;

export const generateAvailabilities = (
  firstBlockStartHour: number,
  blockCount: number,
  blockLengthMinutes: number,
  day: CalendarDate,
  timezoneId: string
): AvailabilitySegment[] => {
  const timeslots: AvailabilitySegment[] = [];
  const midnight = day.toDate(timezoneId);
  let start = add(midnight, { hours: firstBlockStartHour });
  for (let i = 0; i < blockCount; i++) {
    const end = add(start, { minutes: blockLengthMinutes });
    if (isBefore(new Date(), start)) {
      timeslots.push({
        id: `start-${start.getTime()}-end-${end.getTime()}`,
        startTimeUtc: start.toUTCString(),
        endTimeUtc: end.toUTCString(),
      });
    }
    start = end;
  }
  return timeslots;
};

export const determineFirstActiveDay = (
  firstAvailable: FirstAvailable,
  daysToSchedule: number[],
  startDate: CalendarDate
): CalendarDate => {
  switch (firstAvailable) {
    case FirstAvailable.SAME_DAY_NEXT_AVAILABLE:
      if (daysToSchedule.includes(getDayOfWeek(startDate, Locale.EN_US))) {
        return startDate;
      } else {
        return getNextScheduleDay(daysToSchedule, startDate);
      }
    case FirstAvailable.NEXT_BUSINESS_DAY:
      return getNextScheduleDay(daysToSchedule, startDate);
    case FirstAvailable.TWO_BUSINESS_DAYS_OUT: {
      const first = getNextScheduleDay(daysToSchedule, startDate);
      return getNextScheduleDay(daysToSchedule, first);
    }
    default:
      return getNextScheduleDay(daysToSchedule, startDate);
  }
};

export const getNextScheduleDay = (daysToSchedule: number[], day: CalendarDate): CalendarDate => {
  let isValid = false;
  let dayCount = 0;
  while (!isValid) {
    dayCount++;
    const next = day.add({ days: dayCount });
    if (daysToSchedule.includes(getDayOfWeek(next, Locale.EN_US))) {
      isValid = true;
      return next;
    }
    if (dayCount > NUM_DAYS_IN_WEEK) {
      // if we loop around to a new week then that means that daysToSchedule is empty and we should stop
      break;
    }
  }
  return day;
};

export const shouldDisableDate = (
  date: CalendarDate,
  daysToSchedule: number[],
  maxDaysOut: number,
  todayInUnitTimeZone: CalendarDate,
  firstAvailable: FirstAvailable
): boolean => {
  if (!daysToSchedule.includes(getDayOfWeek(date, Locale.EN_US))) {
    return true;
  }

  if (date.compare(todayInUnitTimeZone) < 0) {
    return true;
  }

  if (firstAvailable === FirstAvailable.NEXT_BUSINESS_DAY && isSameDay(todayInUnitTimeZone, date)) {
    return true;
  }

  const nextScheduleDay = getNextScheduleDay(daysToSchedule, todayInUnitTimeZone);

  if (firstAvailable === FirstAvailable.TWO_BUSINESS_DAYS_OUT && date.compare(nextScheduleDay) <= 0) {
    return true;
  }

  return date.compare(todayInUnitTimeZone) > maxDaysOut;
};
