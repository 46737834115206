import { Loading } from '@mezo/web/ui';
import { ApiClient, CHAT_HISTORY_KEY, RouteBuilder } from '@mezo/web/utils';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

const Capability = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const entity = params['entity'];
  const capabilityId = params['capabilityId'];
  const chatHistoryOnly = searchParams.get(CHAT_HISTORY_KEY);

  useEffect(() => {
    const lookup = async (entity: string, capabilityId: string) => {
      try {
        const capabilityResponse = await ApiClient.NON_CHAT_API.utility.get<{ redirect: string }>(
          `/${entity}/capability/${capabilityId}`
        );
        if (!capabilityResponse) {
          navigate(RouteBuilder.ROOT);
        }
        let target = capabilityResponse.data?.redirect ?? RouteBuilder.ROOT;
        if (chatHistoryOnly) {
          target = `${target}&${CHAT_HISTORY_KEY}=true`;
        }
        navigate(target);
      } catch {
        navigate(RouteBuilder.ROOT);
      }
    };

    if (entity === 'service-requests' && capabilityId) {
      //TODO: expand beyond SR capability lookup
      lookup(entity, capabilityId);
    } else {
      navigate(RouteBuilder.ROOT);
    }
  }, [navigate, entity, chatHistoryOnly, capabilityId]);

  return <Loading />;
};

export default Capability;
