import { IsArray, IsNotEmpty } from 'class-validator';

export class AvailabilityWindowDto {
  constructor(startTimeUtc: string, endTimeUtc: string) {
    this.startTimeUtc = startTimeUtc;
    this.endTimeUtc = endTimeUtc;
  }
  startTimeUtc: string;
  endTimeUtc: string;
}

export class AvailabilityWindowsDto {
  constructor(availabilityWindows: AvailabilityWindowDto[], timezoneId: string) {
    this.availabilityWindows = availabilityWindows;
    this.timezoneId = timezoneId;
  }
  @IsArray()
  availabilityWindows: AvailabilityWindowDto[];

  @IsNotEmpty()
  timezoneId: string;
}
