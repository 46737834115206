import { IsNotEmpty, IsString } from 'class-validator';

export class AddIntelliflowVersionDto {
  @IsString()
  @IsNotEmpty()
  version: string;

  @IsString()
  @IsNotEmpty()
  apiKey: string;

  @IsString({ each: true })
  @IsNotEmpty()
  enabledItemIds: string[];

  @IsString()
  @IsNotEmpty()
  releaseNotes: string;

  @IsString()
  @IsNotEmpty()
  projectId: string;
}
