import { Locale } from '@mezo/common/utils';
import { TwComponent } from 'twin.macro';

export type MezoImageMap = { [key in Locale | 'default']: string };

type MezoImageProps = {
  imageMap: MezoImageMap;
  locale: string;
  StyledComponent: TwComponent<'img'>;
};

// example usage and IMAGE_MAP creation in component

// import StaticImgPathES from './before-starting-ES.png';
// import StaticImgPath from './before-starting.png';

// const IMAGE_MAP: MezoImageMap = {
//   [Locale.EN_US]: StaticImgPath,
//   [Locale.ES_US]: StaticImgPathES,
//   default: StaticImgPath,
// };

export const MezoImage: React.FC<MezoImageProps> = ({ imageMap, locale, StyledComponent }) => {
  const imageSrc = imageMap[locale as Locale] || imageMap['default'];
  return <StyledComponent src={imageSrc} />;
};
