import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

type ToggleSwitchProps = {
  initialValue?: boolean | undefined;
  onValueChanged: (value: boolean) => void;
  yesTextOverride?: string;
  noTextOverride?: string;
};

const ToggleSwitchWrapper = styled.div(() => [
  tw`flex overflow-hidden border bg-background border-disabled h-14`,
  css({ 'border-radius': '10px' }),
]);
const ToggleButton = styled.button<{ isActive: boolean }>(({ isActive }) => [
  tw`flex-1 flex-shrink-0 text-center first:border-r text-disabled first:border-r-disabled`,
  isActive && tw`text-white bg-primary`,
]);

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  onValueChanged,
  initialValue,
  yesTextOverride,
  noTextOverride,
}) => {
  const [value, setValue] = useState<boolean | undefined>(initialValue);

  const { t } = useTranslation();

  useEffect(() => {
    if (value === undefined) {
      return;
    }
    onValueChanged(value);
  }, [onValueChanged, value]);

  const handleYesClicked = useCallback(() => {
    setValue(true);
  }, []);

  const handleNoClicked = useCallback(() => {
    setValue(false);
  }, []);

  return (
    <ToggleSwitchWrapper>
      <ToggleButton isActive={value === true} onClick={handleYesClicked}>
        {yesTextOverride || t('chat.buttons.yes')}
      </ToggleButton>
      <ToggleButton isActive={value === false} onClick={handleNoClicked}>
        {noTextOverride || t('chat.buttons.no')}
      </ToggleButton>
    </ToggleSwitchWrapper>
  );
};

export default ToggleSwitch;
