import { DiagnosisDto, KeyValuePairDto } from '@deprecated/dtos';
import { ApiClient } from '@mezo/web/utils';
import { useQuery } from '@tanstack/react-query';
import { ItemResponseDto } from '@mezo/common/dtos';

export const useGetDiagnoses = () =>
  useQuery({
    queryKey: ['diagnoses'],
    queryFn: async () => {
      const { data } = await ApiClient.NON_CHAT_API.utility.get<DiagnosisDto[]>('/taxonomy/diagnoses');
      return data;
    },
    refetchOnWindowFocus: false,
  });

type DiagnosisDependenciesPayload = {
  resolutionTypes: KeyValuePairDto[];
  skillLevels: KeyValuePairDto[];
};

export const useGetDiagnosisDependencies = () =>
  useQuery({
    queryKey: ['diagnosisDependencies'],
    queryFn: async () => {
      const payload = await ApiClient.NON_CHAT_API.utility.get<DiagnosisDependenciesPayload>(
        '/taxonomy/diagnosis-dependencies'
      );
      return payload.data;
    },
    refetchOnWindowFocus: false,
  });

type GetTaxonomiesPayload = {
  components: KeyValuePairDto[];
  items: ItemResponseDto[];
  locations: KeyValuePairDto[];
  symptoms: KeyValuePairDto[];
};

export const useGetTaxonomies = () =>
  useQuery({
    queryKey: ['taxonomies'],
    queryFn: async () => {
      const { data } = await ApiClient.NON_CHAT_API.utility.get<GetTaxonomiesPayload>('/taxonomy');
      return data;
    },
    refetchOnWindowFocus: false,
  });
