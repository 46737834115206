import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type User = {
  uid: string;
  email: string;
};

type SessionState = {
  authenticationError: string;
  user: User;
};

const initialState: SessionState = {
  authenticationError: '',
  user: {
    uid: '',
    email: '',
  },
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    authenticateUserSuccess: (state, action: PayloadAction<{ uid: string; email: string }>) => {
      state.user = {
        uid: action.payload.uid,
        email: action.payload.email,
      };
      state.authenticationError = '';
    },
    authenticateUserFailure: (state, action) => {
      const errorMessage = action.payload;
      state.authenticationError = errorMessage;
    },
    logOutUserSuccess: (state) => {
      state.user = initialState.user;
      state.authenticationError = '';
    },
  },
});
