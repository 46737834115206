import { IsOptional, IsString } from 'class-validator';

export class AddressDto {
  constructor(params: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
    country?: string;
  }) {
    this.address1 = params.address1;
    this.address2 = params.address2;
    this.city = params.city;
    this.state = params.state;
    this.zip = params.zip;
    this.country = params.country;
  }

  @IsString()
  address1: string;

  @IsOptional()
  @IsString()
  address2?: string;

  @IsString()
  city: string;

  @IsString()
  state: string;

  @IsString()
  zip: string;

  @IsString()
  country?: string;
}
