import { PlatformEventDto } from '@mezo/common/dtos';
import { usePostAnalyticEvent } from '@mezo/web/queries';
import { useCallback } from 'react';

export enum MetricsResult {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export type MetricUtils = {
  trackEvent: (payload: Omit<PlatformEventDto, 'timestamp'>) => void;
};

export function useMetrics(): MetricUtils {
  const { mutate: sendAnalyticEvent } = usePostAnalyticEvent();

  const trackEvent = useCallback(
    (payload: Omit<PlatformEventDto, 'timestamp'>) =>
      sendAnalyticEvent({
        timestamp: Date.now(),
        ...payload,
      }),
    [sendAnalyticEvent],
  );

  return {
    trackEvent,
  };
}
