import { DetailedHTMLProps, HTMLAttributes, LegacyRef } from 'react';
import IcoMoon from 'react-icomoon';
import iconSet from './selection.json';

// Deprecate this moving forward and replace usage
export type IconName =
  | 'chat'
  | 'service-request'
  | 'archive'
  | 'dashboard'
  | 'inbox'
  | 'chevron'
  | 'map'
  | 'drawer2'
  | 'pencil2'
  | 'notification'
  | 'clipboard';

export interface IconProps extends DetailedHTMLProps<Partial<HTMLAttributes<HTMLOrSVGElement>>, HTMLOrSVGElement> {
  icon: IconName;
  size?: string;
  color?: string;
  width?: string;
  height?: string;
  disableFill?: boolean;
  removeInlineStyle?: boolean;
  css?: string;
  ref?: LegacyRef<SVGElement> | undefined;
}

export const Icon: React.FC<IconProps> = ({ ...props }) => {
  // TODO: filter props passed to icon remove isActive
  return <IcoMoon iconSet={iconSet} {...props} width={props.width || '100%'} height={props.height || '100%'} />;
};

// TODO: remove this icon component, only used a couple of places

export default Icon;
