import { KeyValuePairDto, ServiceRequestResponseDto } from '@deprecated/dtos';
import { Locale } from '@mezo/common/utils';
import { ServiceRequestPriority } from '@mezo/shared/common';
import { ApiClient } from '@mezo/web/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export const useGetServiceRequest = (id?: string, locale?: Locale) => {
  return useQuery({
    queryKey: ['serviceRequest', { id }],
    queryFn: async () => {
      const headers: Record<string, string> = {};
      if (locale) {
        headers['Accept-Language'] = locale;
      }
      const response = await ApiClient.NON_CHAT_API.utility.get<ServiceRequestResponseDto>(`/service-requests/${id}`, {
        headers,
      });
      return response.data;
    },
    enabled: !!id,
  });
};

export const useCreateServiceRequestInCustomerSystem = (onError?: VoidFunction) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const response = await ApiClient.NON_CHAT_API.utility.post<ServiceRequestResponseDto>(
        `/service-requests/${id}/create`,
      );
      return response?.data;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['serviceRequest', { id: variables.id }], data);
    },
    onError: (error, variables) => {
      onError?.();
      console.error('Error creating service request in customer system', error);
    },
  });
};

export const useDeliverServiceRequest = (onError?: VoidFunction) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, workorderNumber }: { id: string; workorderNumber?: string }) => {
      const payload = workorderNumber ? { workorderNumber } : undefined;
      const response = await ApiClient.NON_CHAT_API.utility.post<ServiceRequestResponseDto>(
        `/service-requests/${id}/deliver`,
        payload,
      );
      return response?.data;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['serviceRequest', { id: variables.id }], data);
    },
    onError: (error, variables) => {
      onError?.();
      console.error('Error delivering service request', error);
    },
  });
};

export const useArchiveServiceRequest = (onError?: VoidFunction) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      id,
      archiveReason,
      archiveReasonDescription,
    }: {
      id: string;
      archiveReason: string;
      archiveReasonDescription?: string;
    }) => {
      const payload = {
        archiveReason,
        archiveReasonDescription,
      };
      const response = await ApiClient.NON_CHAT_API.utility.patch<ServiceRequestResponseDto>(
        `/service-requests/${id}/archive`,
        payload,
      );
      return response?.data;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['serviceRequest', { id: variables.id }], data);
    },
    onError: (error, variables) => {
      onError?.();
      console.error('Error archiving service request', error);
    },
  });
};

export type EditServiceRequestBody = {
  item?: KeyValuePairDto;
  component?: KeyValuePairDto;
  symptom?: KeyValuePairDto;
  location?: KeyValuePairDto;
  description?: string;
  diagnosis?: KeyValuePairDto;
  priority?: ServiceRequestPriority;
};
export const useEditServiceRequest = (onError?: VoidFunction) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, body }: { id: string; body: EditServiceRequestBody }) => {
      const response = await ApiClient.NON_CHAT_API.utility.patch<ServiceRequestResponseDto>(
        `/service-requests/${id}`,
        body,
      );
      return response?.data;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['serviceRequest', { id: variables.id }], data);
    },
    onError: (error, variables) => {
      onError?.();
      console.error('Error editing service request', error);
    },
  });
};

export const useDownloadMedia = (url: string) =>
  useQuery({
    queryKey: ['downloadMedia', url],
    queryFn: async () => {
      const download = await axios.get<Blob>(url, { responseType: 'blob' });
      return download.data;
    },
    enabled: !!url.length,
  });
