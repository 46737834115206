import { createContext, FC, useContext, useEffect, useState } from 'react';

/**
 * Screen provider and hook for Twin
 */
const defaultValue: { [key: string]: boolean } = {};

const ScreensContext = createContext(defaultValue);

const MinScreenProvider: FC<{
  screens: string;
  children?: React.ReactNode;
}> = ({ children, screens }) => {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    const mediaQueryLists: { [key: string]: MediaQueryList } = {};
    let isAttached = false;

    const mediaData = Object.entries(screens).map(([name, media]) => [name, `(min-width: ${media})`]);

    const handleQueryListener = () => {
      const updatedMatches = mediaData.reduce(
        (acc, [name]) => ({
          ...acc,
          [name]: Boolean(mediaQueryLists[name]?.matches),
        }),
        {}
      );
      setQueryMatch(updatedMatches);
    };

    if (window?.matchMedia) {
      const matches: { [key: string]: unknown } = {};

      mediaData.forEach(([name, media]) => {
        if (typeof media !== 'string') {
          matches[name] = false;
          return;
        }

        mediaQueryLists[name] = window.matchMedia(media);

        matches[name] = mediaQueryLists[name].matches;
      });

      setQueryMatch(matches);
      isAttached = true;

      mediaData.forEach(([name, media]) => {
        if (typeof media !== 'string') return;

        const mql = mediaQueryLists[name];

        if (mql.addEventListener) {
          mql.addEventListener('change', handleQueryListener);
        } else {
          mediaQueryLists[name].addListener(handleQueryListener);
        }
      });
    }

    return () => {
      if (!isAttached) return;
      mediaData.forEach(([name, media]) => {
        if (typeof media !== 'string') return;

        const mql = mediaQueryLists[name];

        if (mql.removeEventListener) {
          mql.removeEventListener('change', handleQueryListener);
        } else {
          mql.removeListener(handleQueryListener);
        }
      });
    };
  }, [screens]);

  return <ScreensContext.Provider value={queryMatch}>{children}</ScreensContext.Provider>;
};

const useMinScreen = () => {
  const context = useContext(ScreensContext);

  if (context === defaultValue) throw new Error('useMinScreen must be used within a MinScreenProvider');

  return {
    min: (size: TemplateStringsArray) => context[size[0]],
  };
};

export { useMinScreen, MinScreenProvider };
