import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';

const FIVE_MINUTES_MILLIS = 5 * 60 * 1000;

export interface ApiConfig {
  timeout?: number;
  headers?: ApiHeaders;
  validateStatus?: (status: number) => boolean;
  baseURL?: string;
}

export interface DecodedToken {
  customerId: string;
  exp: number;
  iat: number;
  uid: string;
  unitId: string;
}

export interface Interceptors {
  request: AxiosInterceptorConfig[];
  response: AxiosInterceptorConfig[];
}

export interface ApiHeaders extends AxiosRequestHeaders {
  'Content-Type': string;
  'Access-Control-Allow-Origin': string;
  Authorization: string;
  'x-mezo-medium': string;
  'Accept-Language': string;
}

export interface AxiosInterceptorConfig {
  onFulfilled?: (value: AxiosResponse | AxiosRequestConfig) => void;
  onRejected?: (error: AxiosError) => void;
}

export class Api {
  baseConfig: ApiConfig;
  baseHeaders: ApiHeaders;
  baseUrl: string;
  utility: AxiosInstance;
  requestInterceptorIds = [] as number[];
  responseInterceptorIds = [] as number[];
  isRefreshingToken: boolean;

  constructor(config?: ApiConfig, interceptors?: Interceptors, shouldRefreshToken?: boolean) {
    this.isRefreshingToken = false;
    this.baseHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      'x-mezo-medium': 'web',
      'Accept-Language': window.navigator.language,
    } as ApiHeaders;
    this.setBaseUrl(config?.baseURL ?? 'http://localhost:8080/v1');

    const defaultConfig: ApiConfig = {
      baseURL: this.baseUrl,
      timeout: 60000,
      headers: this.baseHeaders,
      validateStatus: (status: number) => {
        return (status >= 200 && status < 300) || status === 304;
      },
    };

    this.baseConfig = {
      ...defaultConfig,
      ...config,
    };

    this.utility = axios.create(this.baseConfig);

    if (interceptors?.request) {
      this.requestInterceptorIds = interceptors?.request.map((config: AxiosInterceptorConfig) =>
        this.utility.interceptors.request.use(config.onFulfilled, config.onRejected)
      );
    }

    if (interceptors?.response) {
      this.responseInterceptorIds = interceptors?.response.map((config: AxiosInterceptorConfig) =>
        this.utility.interceptors.response.use(config.onFulfilled, config.onRejected)
      );
    }

    if (shouldRefreshToken) {
      this.requestInterceptorIds.push(
        this.utility.interceptors.request.use(
          async (config) => {
            if (!this.isRefreshingToken) {
              const authToken = this.utility.defaults.headers.common['Authorization'] as string;
              if (authToken) {
                const { customerId, unitId, uid, exp } = jwtDecode<DecodedToken>(authToken);
                const fiveMinutesFromNow = Date.now() + FIVE_MINUTES_MILLIS;
                // expiration timestamp is in seconds not millis
                if (exp * 1000 < fiveMinutesFromNow) {
                  const token = await this.refreshToken(customerId, uid, unitId);
                  config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                  };
                }
              }
            }
            return config;
          },
          async (error: AxiosError) => Promise.reject(error)
        )
      );
    }
  }

  clearResponseInterceptors() {
    this.responseInterceptorIds.forEach((responseInterceptorId) => {
      this.utility.interceptors.response.eject(responseInterceptorId);
    });
  }

  setAuthToken(authToken: string) {
    this.utility.defaults.headers.common['Authorization'] = authToken;
  }

  clearAuthToken() {
    delete this.utility.defaults.headers.common['Authorization'];
  }

  hasAuthToken() {
    return this.utility.defaults.headers.common['Authorization'] != null;
  }

  setUserSession(userSessionId: string) {
    this.utility.defaults.headers.common['X-mezo-userSession'] = userSessionId;
  }

  getUserSessionId() {
    return this.utility.defaults.headers.common['X-mezo-userSession'];
  }

  clearUserSession() {
    delete this.utility.defaults.headers.common['X-mezo-userSession'];
  }

  hasUserSession() {
    return this.utility.defaults.headers.common['X-mezo-userSession'] != null;
  }

  setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }
  getBaseUrl() {
    return this.baseUrl;
  }

  refreshToken = async (customerId: string, residentId: string, unitId: string): Promise<string | undefined> => {
    this.isRefreshingToken = true;
    const { data: token } = await this.utility.post<string | undefined>('/residents/token', {
      customerId,
      residentId,
      unitId,
    });
    if (token) {
      this.setAuthToken(`Bearer ${token}`);
    }
    this.isRefreshingToken = false;
    return token;
  };
}
