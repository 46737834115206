import { Page } from '@mezo/common/utils';
import { SKIP_HOW_IT_WORKS_KEY } from '@mezo/web/utils';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import tw from 'twin.macro';
import { IndicatorGroup } from '../../components/gallery';
import { SectionWithLightPrimaryBackground } from '../../components/generics';
import { PageWrapper } from '../../components/page-wrapper';
import ToggleSwitch from '../../components/toggle-switch';

const VerticalFlexContainer = tw.div`flex flex-col gap-4 p-4`;

const Text = tw.p`text-sm leading-6`;
const Title = tw.h4`text-2xl font-medium`;

type BeforeStartingProps = {
  handleNavigate: (acknowledgement?: boolean) => void;
};

export const BeforeStarting: React.FC<BeforeStartingProps> = ({ handleNavigate }) => {
  const [acknowledgement, setAcknowledgement] = useState<boolean | undefined>(undefined);

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const didSkipHowItWorks = searchParams.get(SKIP_HOW_IT_WORKS_KEY) ?? undefined;

  const handleContinueClick = useCallback(() => {
    handleNavigate(acknowledgement);
  }, [acknowledgement, handleNavigate]);

  return (
    <PageWrapper
      title={t('landing.beforeStarting.title')}
      pageName={Page.SUBMIT_REQUEST_BEFORE_START}
      onButtonClick={handleContinueClick}
      isButtonDisabled={acknowledgement === undefined}
      buttonLabel={
        acknowledgement === false ? t('landing.beforeStarting.startAnyway') : t('landing.beforeStarting.startRequest')
      }
      buttonTextAbove={acknowledgement === false ? t('landing.beforeStarting.weRecommend') : undefined}
    >
      <VerticalFlexContainer>
        <Title>{t('landing.beforeStarting.header')}</Title>
        <Text>{t('landing.beforeStarting.question')}</Text>
        <ToggleSwitch initialValue={undefined} onValueChanged={(value) => setAcknowledgement(value)} />

        {acknowledgement === false && (
          <SectionWithLightPrimaryBackground>
            <Text>
              <strong>{t('landing.beforeStarting.weRecommend')}</strong> {t('landing.beforeStarting.provideDetails')}
            </Text>
          </SectionWithLightPrimaryBackground>
        )}
        {!didSkipHowItWorks && <IndicatorGroup sceneLength={1} activeScene={1} />}
      </VerticalFlexContainer>
    </PageWrapper>
  );
};
