import styled from 'styled-components';
import tw from 'twin.macro';
import Icon, { IconName } from './icon';

export interface MenuItemProps {
  className?: string;
  icon: IconName;
  isActive: boolean;
  label: string;
  onClick?: () => void;
  ['data-testid']?: string;
}

const StyledMenuItem = styled('button')<{ isActive: boolean }>(({ isActive }) => [
  tw`flex justify-center gap-2 p-3 text-sm border-none bg-none text-text-light md:text-base`,
  isActive && tw`font-medium text-text-medium-dark`,
]);

export const MenuItem: React.FC<MenuItemProps> = ({ className, label, icon, isActive, onClick, ...rest }) => (
  <StyledMenuItem
    className={className}
    isActive={isActive}
    onClick={onClick}
    data-testid={rest ? rest['data-testid'] : undefined}
  >
    <Icon icon={icon} size="20px" />
    <span>{label}</span>
  </StyledMenuItem>
);

export default MenuItem;
