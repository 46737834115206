import { FlowNode, Locale, Page } from '@mezo/common/utils';
import { IsBoolean, IsEnum, IsOptional, IsString } from 'class-validator';

// request payload for update and create user sessions
export class UpdateUserSessionDto {
  @IsString()
  @IsOptional()
  customerId?: string;
  @IsOptional()
  @IsString()
  residentId?: string;
  @IsString()
  @IsOptional()
  unitId?: string;
  @IsBoolean()
  @IsOptional()
  isGuest?: boolean;

  @IsOptional()
  page?: Page;

  @IsOptional()
  node?: FlowNode;
}

export class CreateUserSessionDto extends UpdateUserSessionDto {
  @IsString()
  userAgent: string;
  locale: Locale;
}

export class UpdateUserSessionPageDto {
  @IsEnum(Page)
  page: Page;

  @IsOptional()
  @IsString()
  node?: FlowNode;
}

export class UpdateUserSessionResidentDto {
  @IsString()
  customerId: string;
  @IsString()
  residentId: string;
  @IsString()
  unitId: string;
}
