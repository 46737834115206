import { AbandonReason, UserSessionStatusType } from '@mezo/common/utils';
import { IsEnum, IsOptional, IsString } from 'class-validator';

export class CloseSubmitAttemptDto {
  @IsEnum(UserSessionStatusType)
  status: UserSessionStatusType;
  @IsEnum(AbandonReason)
  @IsOptional()
  abandonReason?: AbandonReason;
  @IsString()
  @IsOptional()
  abandonErrorMessage?: string;
}
