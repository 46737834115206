import { Page } from '@mezo/common/utils';
import { useUserSessionContext } from '@mezo/web/contexts';
import { BrandingConfiguration } from '@mezo/web/hooks';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';
import { ChatScreenFooter as PageFooter, ChatStackHeader as PageHeader } from './chat';

const Content = styled.div(() => [
  tw`flex flex-col flex-1 overflow-y-scroll`,
  css`
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  `,
]);

const Container = tw.div`relative flex flex-col w-full h-full mx-auto grow-2 md:max-w-xl bg-background-light`;

type PageWrapperProps = {
  config?: Pick<BrandingConfiguration, 'logo' | 'name'>;
  title: string;
  pageName?: Page;
  onButtonClick: () => void;
  onBackClick?: VoidFunction;
  isButtonDisabled: boolean;
  buttonLabel: string;
  buttonTextAbove?: string;
  hideFooter?: boolean;
  showBackButton?: boolean;
};

export const PageWrapper: React.FC<PropsWithChildren<PageWrapperProps>> = ({
  config,
  children,
  title,
  pageName,
  onButtonClick,
  onBackClick,
  isButtonDisabled,
  buttonLabel,
  buttonTextAbove,
  hideFooter,
  showBackButton,
}) => {
  const navigate = useNavigate();
  const { updateUserSessionPage } = useUserSessionContext();
  useEffect(() => {
    pageName && updateUserSessionPage(pageName);
  }, [pageName, updateUserSessionPage]);

  const goBack = useCallback(() => navigate(-1), [navigate]);
  return (
    <Container>
      <PageHeader
        currentTitle={title}
        showBackButton={showBackButton ?? true}
        onBackClicked={onBackClick || goBack}
        config={config}
      />
      <Content>{children}</Content>
      {!hideFooter && (
        <PageFooter
          isActive={!isButtonDisabled}
          label={buttonLabel}
          onClick={onButtonClick}
          textAbove={buttonTextAbove}
        />
      )}
    </Container>
  );
};
