import { formatAvailabilityInterval } from '@deprecated/shared/formatters';
import { useCallback, useState } from 'react';
import tw from 'twin.macro';
import { AvailabilitySegment } from './scheduling.types';

const TimeIntervalWrapper = tw.label`flex items-center justify-between h-12 px-6 transition-all border-b cursor-pointer border-b-border-grey hover:bg-primary active:bg-primary hover:text-white active:text-white`;

type AvailabilityIntervalProps = AvailabilitySegment & {
  isSelected: boolean;
  onChange: (timeInterval: AvailabilitySegment, add: boolean) => void;
  timezoneId: string;
  currentLocale: string;
};

export const AvailabilityInterval = ({
  id,
  startTimeUtc,
  endTimeUtc,
  isSelected,
  onChange,
  timezoneId,
  currentLocale,
}: AvailabilityIntervalProps) => {
  const [checked, setChecked] = useState<boolean>(isSelected);

  const handleCheckChanged = useCallback(() => {
    onChange({ id, startTimeUtc, endTimeUtc }, checked ? false : true);
    setChecked(checked ? false : true);
  }, [checked, endTimeUtc, id, onChange, startTimeUtc]);

  return (
    <TimeIntervalWrapper htmlFor={id}>
      <span>{formatAvailabilityInterval(startTimeUtc, endTimeUtc, timezoneId, currentLocale)}</span>
      <input data-hj-allow type="checkbox" name={id} id={id} onChange={handleCheckChanged} checked={checked} />
    </TimeIntervalWrapper>
  );
};
