import { FindUnitsAddressQueryDto } from '@deprecated/dtos';
import { SubmitAttemptStep } from '@mezo/shared/common';

type PrependStrings = typeof RouteBuilder.RESIDENTS | typeof RouteBuilder.EMERGENCIES | typeof RouteBuilder.DASHBOARD;

export const BRANDING_ID_KEY = 'brandingId';
export const CUSTOMER_ID_KEY = 'customerId';
export const DISABLE_RESIDENT_STORAGE_KEY = 'disableResidentStorage';
export const CHAT_HISTORY_KEY = 'chatHistoryOnly';
export const AUTH_KEY = 'auth';
export const VANITY_KEY = 'vanityId';
export const DEFLECT_EMERGENCY_KEY = 'isDeflectedEmergency';
export const SKIP_HOW_IT_WORKS_KEY = 'skipHowItWorks';
export const PROBLEM_CAPTURE_PATH = '/resident/v2/problem-capture';

export class RouteBuilder {
  private currentPath: string;

  static ROOT = '/';
  static ADDRESSES = 'addresses';
  static ABANDONED = 'abandoned';
  static BEFORE_STARTING = 'before-starting';
  static CAPABILITY = 'cap';
  static CUSTOMER = 'customer';
  static CUSTOMERS = 'customers';
  static DASHBOARD = 'dashboard';
  static DETAILS = 'details';
  static EMERGENCIES = 'emergencies';
  static EMERGENCY_SUBMITTED = 'emergency-submitted';
  static HOME = 'home';
  static HOW_IT_WORKS = 'how-it-works';
  static INTELLIFLOW_VERSION = 'intelliflow-version';
  static LOGIN = 'login';
  static LOGOUT = 'logout';
  static MEDIA = 'media';
  static RESIDENT = 'resident';
  static RESIDENT_IDENTIFICATION = 'resident-identification';
  static RESIDENTS = 'residents';
  static SERVICE_REQUEST = 'service-request';
  static SUBMIT_REQUEST = 'submit-request';
  static SUBMITTED_REQUESTS = 'submitted-requests';
  static SUPPORT = 'support';
  static TEST_MESSAGE = 'test-message';
  static SUBMIT_REQUEST_V2 = 'v2';

  constructor(path = '') {
    this.currentPath = path;
    return this;
  }

  build() {
    return this.currentPath;
  }

  prependPath(path: PrependStrings) {
    this.currentPath = `/${path}/${this.currentPath}`;
    return this;
  }

  appendQueryParameters(parameters: Record<string, string | undefined>) {
    const query = new URLSearchParams();
    for (const key in parameters) {
      const value = parameters[key];
      if (value) {
        query.append(key, value);
      }
    }

    const queryString = query.toString();
    if (queryString.length > 0) {
      this.currentPath += `?${queryString}`;
    }
    return this;
  }

  appendBrandingId(brandingId: string | undefined) {
    brandingId && this.appendQueryParameters({ [BRANDING_ID_KEY]: brandingId });
    return this;
  }

  /**
    START Address, Resident, Unit identification routes
   */

  buildAddressesRoute(brandingId?: string | undefined, customerId?: string | undefined) {
    this.currentPath = RouteBuilder.ADDRESSES;
    const queryParams: Record<string, string> = {};
    if (brandingId) {
      queryParams[BRANDING_ID_KEY] = brandingId;
    }
    if (customerId) {
      queryParams[CUSTOMER_ID_KEY] = customerId;
    }
    this.appendQueryParameters(queryParams);
    return this;
  }

  buildResidentIdentificationRoute(
    unitAddressQuery: FindUnitsAddressQueryDto,
    brandingId: string | undefined,
    customerId?: string,
  ) {
    this.currentPath = RouteBuilder.RESIDENT_IDENTIFICATION;
    const queryParams = unitAddressQuery;
    if (customerId) {
      queryParams[CUSTOMER_ID_KEY] = customerId;
    }
    if (brandingId) {
      queryParams[BRANDING_ID_KEY] = brandingId;
    }
    this.appendQueryParameters(queryParams);
    return this;
  }

  buildSubmittedRequestsRoute() {
    this.currentPath = `${RouteBuilder.SUBMITTED_REQUESTS}`;
    return this.prependPath(RouteBuilder.RESIDENT);
  }

  buildSubmitRequestV2Route(step: SubmitAttemptStep) {
    this.currentPath = `${RouteBuilder.SUBMIT_REQUEST_V2}/${step}`;
    return this;
  }

  buildUnitIdentificationSubRoute(customerId: string, residentId: string, brandingId: string | undefined) {
    this.currentPath = `${RouteBuilder.CUSTOMERS}/${customerId}/residents/${residentId}/units`;
    this.appendBrandingId(brandingId);
    return this;
  }

  buildGuestResidentRegistration(customerId: string) {
    this.currentPath = `${RouteBuilder.CUSTOMERS}/${customerId}/${RouteBuilder.RESIDENTS}/register`;
    return this;
  }

  /**
    END Address, Resident, Unit identification routes
   */

  /**
    START Resident home routes
   */

  buildResidentLogin() {
    this.currentPath += `${RouteBuilder.RESIDENT}/${RouteBuilder.LOGIN}`;
    return this;
  }

  buildResidentHomeRoute(customerId: string, residentId: string, unitId: string, brandingId?: string | undefined) {
    this.currentPath = `${RouteBuilder.CUSTOMERS}/${customerId}/residents/${residentId}/units/${unitId}`;
    this.appendBrandingId(brandingId);
    return this;
  }

  prependResidentHomeRoute(customerId: string, residentId: string, unitId: string) {
    const prepend = `${RouteBuilder.RESIDENT}/${RouteBuilder.CUSTOMERS}/${customerId}/residents/${residentId}/units/${unitId}`;
    this.currentPath = `/${prepend}/${this.currentPath}`;
    return this;
  }

  buildAbandonedRoute() {
    this.currentPath += RouteBuilder.ABANDONED;
    return this;
  }

  buildSupportFormRoute(clickSource: string) {
    this.currentPath += `${RouteBuilder.SUPPORT}/${clickSource}`;
    return this;
  }

  buildHowItWorksRoute(customerId: string, residentId: string, unitId: string) {
    this.buildResidentHomeRoute(customerId, residentId, unitId);
    this.currentPath += `/${RouteBuilder.HOW_IT_WORKS}`;
    return this;
  }

  buildBeforeStartingRoute(customerId: string, residentId: string, unitId: string) {
    this.buildResidentHomeRoute(customerId, residentId, unitId);
    this.currentPath += `/${RouteBuilder.BEFORE_STARTING}`;
    return this;
  }

  /**
    END Resident home routes
   */

  /**
    START Emergency routes
   */

  buildEmergencyListRoute(customerId: string, residentId: string, unitId: string) {
    this.buildResidentHomeRoute(customerId, residentId, unitId);
    this.currentPath += `/${RouteBuilder.EMERGENCIES}`;
    return this;
  }

  prependEmergenciesRoute() {
    this.currentPath = `${RouteBuilder.EMERGENCIES}/${this.currentPath}`;
    return this;
  }

  buildEmergencyDetailsRoute = () => {
    this.currentPath += `/${RouteBuilder.DETAILS}`;
    return this;
  };

  buildEmergencyMediaRoute = () => {
    this.currentPath += RouteBuilder.MEDIA;
    return this;
  };

  buildEmergencySubmittedRoute() {
    this.currentPath += `/${RouteBuilder.EMERGENCY_SUBMITTED}`;
    return this;
  }

  buildEmbedRoute(customerId: string, residentId: string) {
    // Our embed code expects the customer's resident id, which is just our resident ID without the customerId prefix
    const sourceResidentId = residentId.replace(customerId, '');
    this.currentPath = `embed/${customerId}/${sourceResidentId}`;
    return this;
  }

  /**
    END Emergency home routes
   */

  /**
    START Dashboard routes
   */

  buildServiceRequestRoute(serviceRequestId: string) {
    this.currentPath += `${RouteBuilder.SERVICE_REQUEST}/${serviceRequestId}`;
    return this;
  }

  buildExpiredCapabilityRoute(entity: string, id: string) {
    this.currentPath += `${RouteBuilder.CAPABILITY}/${entity}/expired`;
    return this;
  }

  buildCapabilityRoute(entity: string, id: string) {
    this.currentPath += `${RouteBuilder.CAPABILITY}/${entity}/${id}`;
    return this;
  }

  buildCustomersRoute(customerId: string) {
    this.currentPath += `${RouteBuilder.CUSTOMER}/${customerId}`;
    return this;
  }

  prependCustomersRoute(customerId: string) {
    this.currentPath = `/${RouteBuilder.CUSTOMER}/${customerId}/${this.currentPath}`;
    return this;
  }

  buildVanityRoute() {
    this.currentPath += `r/:${VANITY_KEY}`;
    return this;
  }
}
