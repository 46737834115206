export enum Topics {
  CUSTOMER_FILE = 'sftp-topic',
  CUSTOMER_FILE_DEAD = 'sftp-dead-topic',

  CUSTOMER_DEAD = 'customer-dead-topic',

  CUSTOMER = 'customer-topic',

  SERVICE_REQUEST = 'service-request',
  SERVICE_REQUEST_DEAD = 'service-request-dead',
}

export enum Topics_v2 {
  // DOMAIN TOPICS
  ANALYTICS = 'analytics-topic',
  ASSET = 'asset-topic',

  //Integrations Topics
  INTEGRATION = 'integration-topic',

  UNIT = 'unit-topic',
  RESIDENT = 'resident-topic',
  WORK_ORDER = 'work-order-topic',

  CUSTOMER = 'customer-topic',

  // FUNCTIONAL TOPICS
  HISTORY = 'history-topic',
  NOTIFICATION = 'notification-topic',
}
