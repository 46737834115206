import { storage } from '@mezo/web/utils';
import { useCallback } from 'react';

export const MEZO_RESIDENT_STORAGE_KEY = `MEZO_V1_resident`;
export type LocalStorageResident = {
  residentId: string;
  customerId: string;
  unitId: string;
  entryNotes?: string;
  permissionToEnter?: boolean;
  currentUserSessionId?: string;
  currentAttemptId?: string;
  howItWorksConfirmationCount?: number;
  howItWorksConfirmationTimestamp?: number;
};

export const MEZO_ANDROID_STORAGE_KEY = `MEZO_V1_android`;
export type LocalStorageAndroid = {
  mediaRequested?: number;
};

export type useLocalStorageType<TInterface> = {
  value: TInterface | undefined;
  clearLocalStorage: VoidFunction;
  setLocalStorage: (value: TInterface) => void;
  updateLocalStorage: (value: Partial<TInterface>) => void;
};

export function useLocalStorage<T>(storageKey: string): useLocalStorageType<T> {
  const clearLocalStorage = useCallback(() => {
    storage.removeItem(storageKey);
  }, [storageKey]);

  const setLocalStorage = useCallback(
    (value: T) => {
      storage.setItem(storageKey, value);
    },
    [storageKey]
  );

  const updateLocalStorage = useCallback(
    (value: Partial<T>) => {
      const current = storage.getItem<T>(storageKey);
      storage.setItem(storageKey, {
        ...current,
        ...value,
      });
    },
    [storageKey]
  );

  return {
    value: storage.getItem(storageKey),
    clearLocalStorage,
    setLocalStorage,
    updateLocalStorage,
  };
}
