import { MediaFileInfoDto } from '@deprecated/dtos';
import { faArrowDownToBracket } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDownloadMedia } from '@mezo/web/queries';
import { useEffect, useState } from 'react';
import tw from 'twin.macro';

const IconContainer = tw.div`flex items-center justify-center w-8 h-8 p-1 border rounded-lg border-text shrink-0`;
const DownloadLinkContainer = tw.a`flex items-center justify-start max-w-full gap-2 py-2`;
const FileName = tw.span`text-sm text-text-light overflow-ellipsis w-[calc(100% - 2.5rem)] overflow-hidden`;

export const DownloadLink = ({ mediaFileInfo }: { mediaFileInfo: MediaFileInfoDto }) => {
  const [fileUrl, setFileUrl] = useState<string>('');

  const { data: fileAsBlob } = useDownloadMedia(mediaFileInfo.url);
  useEffect(() => {
    if (fileAsBlob) {
      const url = URL.createObjectURL(fileAsBlob);
      setFileUrl(url);
    }
  }, [fileAsBlob]);
  return (
    <DownloadLinkContainer href={fileUrl} download={mediaFileInfo.filename} target="_blank" rel="noreferrer">
      <IconContainer>
        <FontAwesomeIcon icon={faArrowDownToBracket} size="lg" />
      </IconContainer>
      <FileName>{mediaFileInfo.filename}</FileName>
    </DownloadLinkContainer>
  );
};
