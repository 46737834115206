import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Upload } from '@mezo/web/hooks';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import tw, { theme } from 'twin.macro';

import { getThumbnailTypeFromContentType } from '@mezo/web/utils';
import { AndroidUpload } from '../../components/chat/file-input';
import ProgressBar from '../../components/progress-bar';
import { ALL_UPLOAD_EXTENSIONS } from '../../types';
import { FileUploadRow } from './file-upload-row';

const UploadButton = styled.button<{ hasSelectedFiles: boolean }>(({ hasSelectedFiles }) => [
  tw`flex justify-center w-full gap-2 p-4 align-middle border rounded-lg`,
  !hasSelectedFiles ? tw`text-white border-white bg-primary ` : tw`bg-white text-primary border-primary`,
]);

const FileIcon = tw(FontAwesomeIcon)`w-4 h-4 mx-2 my-auto`;
const ButtonText = tw.div``;
const Input = tw.input`hidden`;
const Disclaimer = tw.div`flex justify-center w-full text-xs text-center align-middle text-text-light`;
const Container = tw.div`flex flex-col gap-4`;
const ThumbnailContainer = tw(Container)`justify-between`;
const UploadContainer = tw(Container)`gap-2`;
const UploadButtonContainer = tw.div`relative`;
const AndroidUploadContainer = styled('div')(() => [
  tw`absolute right-0 w-1/2 shadow-lg top-1/2`,
  css`
    transform: translateY(-50%);
  `,
]);

const ErrorText = tw.p`text-sm text-error`;

const ONE_HUNDRED_PERCENT = 100;

type FileUploadProps = {
  files: Upload[];
  onMediaSelection: (files: File[]) => void;
  onMediaDeletion: (name: string) => void;
  errors: string[];
  allowMultiple?: boolean;
  uploadText?: string;
  showInput?: boolean;
  disclaimerText?: string;
  fileTypes?: string;
};

const DEFAULT_FILE_TYPES = ALL_UPLOAD_EXTENSIONS.join(', ');

export const FileUpload = ({
  onMediaSelection,
  files,
  onMediaDeletion,
  errors,
  allowMultiple = true,
  uploadText,
  showInput = true,
  disclaimerText,
  fileTypes = DEFAULT_FILE_TYPES,
}: FileUploadProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const [isUploadMenuVisible, setUploadMenuVisible] = useState<boolean>(false);

  const handleOnClick = useCallback(() => {
    if (isAndroid) {
      setUploadMenuVisible(!isUploadMenuVisible);
    } else {
      ref.current?.click();
    }
  }, [isUploadMenuVisible]);

  const handleOnFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        onMediaSelection(Array.from(files));
      }
    },
    [onMediaSelection]
  );

  const handleAndroidFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = (e.target as HTMLInputElement).files;
      if (files) {
        onMediaSelection(Array.from(files));
      }
      setUploadMenuVisible(false);
    },
    [onMediaSelection]
  );

  const hasSelectedFiles = files.length > 0;

  const UploadLabelText = uploadText || (!hasSelectedFiles ? t('media.buttonLabel') : t('media.moreButtonLabel'));

  return (
    <Container>
      {files.length > 0 && (
        <ThumbnailContainer>
          {files.map(({ filename, url, contentType, uploadProgress, uploadError }) => {
            if (uploadError) {
              return null;
            }
            const type = getThumbnailTypeFromContentType(contentType);
            return uploadProgress >= ONE_HUNDRED_PERCENT ? (
              <FileUploadRow
                key={filename}
                url={url}
                name={filename}
                handleRemoveThumbnail={onMediaDeletion}
                contentType={type}
              />
            ) : (
              <ProgressBar key={filename} percentage={uploadProgress} color={theme`colors.primary`} />
            );
          })}
        </ThumbnailContainer>
      )}
      {errors.length > 0 && (
        <Container>
          {errors.map((error) => (
            <ErrorText>{error}</ErrorText>
          ))}
        </Container>
      )}
      {showInput && (
        <UploadContainer>
          <Input
            ref={ref}
            type="file"
            name="files"
            aria-label={UploadLabelText}
            id="files"
            accept={fileTypes}
            onChange={handleOnFileChange}
            multiple={allowMultiple}
          />
          <UploadButtonContainer>
            <UploadButton hasSelectedFiles={hasSelectedFiles} onClick={handleOnClick}>
              <FileIcon icon={faUpload} size={'1x'} />
              <ButtonText>{UploadLabelText}</ButtonText>
            </UploadButton>
            {isUploadMenuVisible && isAndroid && (
              <AndroidUploadContainer>
                <AndroidUpload handleOnChange={handleAndroidFileChange} />
              </AndroidUploadContainer>
            )}
          </UploadButtonContainer>
          <Disclaimer>{disclaimerText}</Disclaimer>
        </UploadContainer>
      )}
    </Container>
  );
};

export default FileUpload;
